import React, { Component } from "react";
import {
  getuserportfolioLogs,
  getuserPersonalLogs,
  getFollowers,
  addFollowing,
  removeFollowing,
  getFollowing,
  followStatus,
  postActions,
  getImageportfolioLogs,
  blockUser,
  userBlockStatus,
  unblockUser,
  getBlockStatus,
  getFeedsLimit,
  getPostCount,
  getPeopleCount
} from "../actions/livingAction";
import MenuWidget from "./menuWidget"
import { getProfile } from "../actions/userAction";
import {
  Card, Avatar, Empty, Modal,
  Divider, Button, Icon, message, Tabs,
  Tooltip, Spin, Dropdown, Popconfirm
} from "antd";
import { Carousel } from "react-responsive-carousel";
import { connect } from "react-redux";
// import EmptyImage from "../assests/img/profile-upload.png"
import _ from "lodash";
// import { db } from "../firebase";
import { Link } from "react-router-dom";
import moment from "moment";
import ReadMoreAndLess from "react-read-more-less";
import Verified from '../assests/img/verified.svg'
import Noprofileimg from '../assests/img/d-avatar.jpg'
import Linkify from 'react-linkify';
import createHistory from "history/createBrowserHistory";
import { BASEURL } from '../actionTypes'
import request from "superagent";
import UserShimmer from './userShimmer';
import GridShimmer from './gridShimmer'
import LogShimmer from './logShimmer'
import InfinitScroll from 'react-infinite-scroll-component'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
} from "react-share"
import { Passers } from "prop-passer";


const { TabPane } = Tabs;

// function callback(key) {
//   // console.log(key);
// }

class PortFolio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      citylist: "",
      userlist: "",
      personallogs: [],
      portfoliologs: [],
      visible: false,
      visible1: false,
      visible2: false,
      visible2: false,
      activeimgset: "",
      activeimgset1: "",
      slideIndex: 1,
      allposts: "",
      activeuser: "",
      photoURL: [],
      Full_Name: "",
      bio: "",
      followers: "",
      userId: "",
      Date_of_Birth: "",
      urlvalue: window.location.origin + "/post/",
      urlvalue1: window.location.origin + "/logpost/",
      getfollowers: [],
      getfollowing: [],
      followstatus: "Follow",
      profile: "",
      blockstatus: "",
      currentSlide: 0,
      imageportfolio: [],
      arraylength: true,
      CreatedDate: "",
      unblock: "",
      userstatus: "",
      lastrecord: 0,
      personalcount: 0,
      portfoliocount: 0,
      followingcount: 0,
      followercount: 0,
      followinglength: 0,
      followingIndex: 0,
      copied: false,
      followingchunk: [],
      profileuser: "",
      verified: false,
    };
    this.getPortfolio = this.getPortfolio.bind(this);
    this.getPersonalLogs = this.getPersonalLogs.bind(this);
  }

  showModal = (images, index) => {
    this.setState({
      visible: true,
      currentSlide: index,
      activeimgset: images
    });
    // console.log(index)
  };


  showModal1 = (index) => {
    this.setState({
      visible2: true,
      currentSlide: index,
    });
    // console.log(index)
  };

  profileModal = (images, index) => {
    this.setState({
      visible3: true,
      currentSlide: index,
      activeimgset1: images
    });
    // console.log(index)
  };


  showreactionmodal = (reactions) => {
    this.setState({
      visible1: true,
      activereactions: reactions,
    });
  };


  onChange(a, b, c) {
    // console.log(a, b, c);
  }


  handleCancel = e => {
    // console.log(e);
    this.setState({
      visible: false,
      visible1: false,
      visible2: false,
      visible3: false
    });
    setTimeout(
      function () {
        this.setState({
          activeimgset: "",
          activeimgset1: ""
        });
      }.bind(this),
      150
    );
  };


  getPortfolio() {
    var res = this.props.location.pathname.split("/");
    // console.log(res)
    var user1 = res[2];

    var dataobj = {
      uid: user1,
      limitperrequest: this.state.limitrequest,
      posttype: "portfolio",
      lastrecord: this.state.lastrecord
    };
    // console.log("Query", dataobj);
    var existingfeeds = this.state.portfoliologs;
    request
      .post(BASEURL + "/getuserfeed")
      // .post(BASEURL + "/getfeedinfo")
      .send(dataobj)
      .then((res) => {
        if (res.body.ReturnCode === 200) {
          console.log(res.body.Data)
          if (res.body.Data.length === 0) {
            this.setState({
              hasMore: false,
              arraylength: false
            })
          } else {
            this.setState({
              portfoliologs: _.concat(existingfeeds, res.body.Data),
              lastrecord: res.body.Data.length > 0 ? (res.body.Data[res.body.Data.length - 1].created_timestamp) : 0,
              hasMore: true,
              arraylength: false,
            });
          }
          // var portfolioArrayLogs = res.body.Data
          // // console.log(portfolioArrayLogs);
          // var images = _.filter(portfolioArrayLogs, { upload_logs_image: [] });
          // var postimages = [];
          // var itemprocessed = 0;
          // portfolioArrayLogs.forEach(function (post) {
          //   if (post.upload_logs_image.length !== 0) {
          //     postimages.push({
          //       images: post.upload_logs_image
          //     })
          //   }
          //   itemprocessed++
          //   // console.log(postimages);
          //   var existingImages = postimages;
          //   if (itemprocessed === portfolioArrayLogs.length) {
          //     this.setState({
          //       imageportfolio: _.concat(existingImages, postimages),
          //       hasMore: true,
          //       arraylength: false,
          //     });
          //   }
          //   else {
          //     this.setState({
          //       hasMore: false,
          //       arraylength: false,
          //     });
          //   }

          // }.bind(this))
        } else {
          this.setState({
            portfoliologs: res.body.Data,
            arraylength: false,
            hasMore: false
          });
        }

      })
      .catch((err) => { });
  }

  getPersonalLogs() {
    var res = this.props.location.pathname.split("/");
    // console.log(res)
    var user1 = res[2];

    var dataobj = {
      uid: user1,
      limitperrequest: this.state.limitrequest,
      posttype: "personal",
      lastrecord: this.state.lastrecord
    };
    // console.log("Query", dataobj);
    var existinglog = this.state.personallogs;
    request
      .post(BASEURL + "/getuserfeed")
      // .post(BASEURL + "/getfeedinfo")
      .send(dataobj)
      .then((res) => {
        if (res.body.ReturnCode === 200) {
          console.log(res.body.Data)
          if (res.body.Data.length === 0) {
            this.setState({
              hasMore: false,
              arraylength: false
            })
          } else {
            this.setState({
              personallogs: _.concat(existinglog, res.body.Data),
              lastrecord: res.body.Data.length > 0 ? (res.body.Data[res.body.Data.length - 1].created_timestamp) : 0,
              hasMore: true,
              arraylength: false,
            });
          }
        } else {
          this.setState({
            personallogs: res.body.Data,
            arraylength: false,
            hasMore: false
          });
        }

      })
      .catch((err) => { });
  }

  postLiveAction = (logid, authorid, uid, type) => {
    var target = this.state.feeds;
    var acted = _.filter(target, {
      Id: logid,
    });
    var actionarr = acted[0].Action;
    var actedAction = _.filter(actionarr, {
      user_Id: uid,
    });
    console.log(acted, actionarr, actedAction);

    if (actedAction.length >= 1) {
      var filtered = _.filter(actionarr, {
        user_Id: uid,
        actionType: type,
        author: authorid,
      });
      var evens = _.remove(actionarr, function (n) {
        return n.user_Id === uid;
      });
      // console.log(evens, filtered, actionarr);
      if (filtered.length === 0) {
        actionarr.push({
          user_Id: uid,
          actionType: type,
          author: authorid,
        });
        console.log(actionarr);
        this.updatelogs(acted, actionarr, target, logid);
      } else {
        this.updatelogs(acted, actionarr, target, logid);
      }
    } else {
      // console.log(actionarr);
      actionarr.push({
        user_Id: uid,
        actionType: type,
        author: authorid,
      });
      // console.log(actionarr);
      this.updatelogs(acted, actionarr, target, logid, authorid);
    }
    // console.log(actionarr);
  };

  updatelogs = (acted, actionarr, target, logid) => {
    var Actionupdated = actionarr;
    // console.log(actionarr, Actionupdated);

    const elementsIndex = target.findIndex((element) => element.Id === logid);
    // console.log(elementsIndex);
    let newArray = [...this.state.portfoliologs];
    newArray[elementsIndex] = {
      ...newArray[elementsIndex],
      action: actionarr,
    };
    // console.log(newArray);
    this.setState({
      portfoliologs: newArray,
    });
  };

  postsaction = (logid, authorid, uid, type, actions, actionfig) => {
    // console.log(logid, authorid, uid, type, actions, actionfig)
    // this.postLiveAction(logid, authorid, uid, type, actions);
    var actionarr = actions;
    var subcollectionid = _.filter(actionarr, {
      User_id: uid,
    });
    // console.log(subcollectionid);
    var subid = subcollectionid.length === 0 ? "" : subcollectionid[0].subid;
    this.props.postActions(logid, authorid, uid, type, subid, actionfig).then((res) => {
      // console.log(res);
      var target = this.state.portfoliologs;
      var id = logid;

      var updatedActionindex = actions.findIndex((element) => element.User_id === res.User_id);
      // console.log(updatedActionindex)
      if (actionfig === 2 || actionfig === 4) {
        actions.splice(updatedActionindex, 1);
        actions = _.remove(actions, function (n) {
          return n.User_id !== uid;
        })
      } else {
        if (updatedActionindex < 0) {
          actions.push({
            Created_Date: res.Created_Date,
            User_id: res.User_id,
            actionType: res.actionType.toString(),
            read: res.read,
            Updated_Date: res.Updated_Date,
            subid: res.subid
          })
        } else {
          actions[updatedActionindex] = {
            Created_Date: res.Created_Date,
            User_id: res.User_id,
            actionType: res.actionType.toString(),
            read: res.read,
            Updated_Date: res.Updated_Date,
            subid: res.subid
          }
        }
      }

      const elementsIndex = target.findIndex((element) => element.Id === id);
      // console.log(elementsIndex, actions);

      let newArray = [...this.state.portfoliologs];
      newArray[elementsIndex] = {
        ...newArray[elementsIndex],
        action: actions,
      };
      // console.log(newArray);
      this.setState({
        portfoliologs: newArray,
      });
    });
  };

  getLikes = (actions) => {
    var likes = _.filter(actions, ["actionType", "true"]);
    var liketext = "";
    if (likes.length >= 2) {
      liketext = ""
    } else {
      liketext = ""
    }
    var likes = this.abbreviateNumber(likes.length)
    // console.log(likes)
    return likes;
    // return likes.length + " " + liketext;
  };


  getdisLikes = (actions) => {
    var dislikes = _.filter(actions, ["actionType", "false"]);
    var disliketext = "";
    if (dislikes.length >= 2) {
      disliketext = ""
    } else {
      disliketext = ""
    }
    var dislikes = this.abbreviateNumber(dislikes.length)
    return dislikes;
    // return dislikes.length + " " + disliketext;
  };


  getIsLiked = (actions, user) => {
    var isliked = _.filter(actions, { User_id: user, actionType: "true" });
    var isdisliked = _.filter(actions, { User_id: user, actionType: "false" });
    // console.log(isliked, isdisliked, actions, user);
    if (isliked.length >= 1) {
      return 2;
    } else if (isdisliked.length >= 1) {
      return 5;
    } else {
      return 1
    }
  };


  getIsdisLiked = (actions, user) => {
    var isliked = _.filter(actions, { User_id: user, actionType: "true" });
    var isdisliked = _.filter(actions, { User_id: user, actionType: "false" });
    // console.log(isliked, isdisliked, actions, user);
    if (isdisliked.length >= 1) {
      return 4;
    } else if (isliked.length >= 1) {
      return 5;
    } else {
      return 3;
    }
  };


  abbreviateNumber = (value) => {
    var newValue = value;
    if (value >= 1000) {
      var suffixes = ["", "k", "M", "B", "T"];
      var suffixNum = Math.floor(("" + value).length / 3);
      var shortValue = '';
      for (var precision = 2; precision >= 1; precision--) {
        shortValue = parseFloat((suffixNum !== 0 ?
          (value / Math.pow(1000, suffixNum)) : value).toPrecision(precision));
        var dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g, '');
        if (dotLessShortValue.length <= 2) { break; }
      }
      if (shortValue % 1 !== 0) shortValue = shortValue.toFixed(1);
      newValue = shortValue + " " + suffixes[suffixNum];
    }
    // console.log(newValue)
    return newValue;
  }

  refreshFeeds = () => {
    setTimeout(function () {
      window.location.reload(false);
    }, 300);
  }

  updateCurrentSlide = (index) => {
    const { currentSlide } = this.state;

    if (currentSlide !== index) {
      this.setState({
        currentSlide: index,
      });
    }
    // console.log(index)
  }

  // fetchFollowingData = () => {

  //   var res = this.props.location.pathname.split(":");
  //   // console.log(res)
  //   var user1 = res[1];

  //   var followingarray = this.state.getfollowing;
  //   this.props.getFollowing(user1).then((res) => {
  //     console.log(res)
  //     this.setState({
  //       getfollowing: res,
  //       arraylength: false
  //     })
  //     followingarray = _.chunk(this.state.getfollowing, 20)
  //     // console.log(followingarray.length);
  //     // console.log(followingarray)
  //     this.setState({
  //       getfollowing: followingarray[this.state.followingIndex],
  //       followinglength: followingarray.length,
  //       followingchunk: followingarray
  //     })
  //   });
  // }

  // addFollowingData = async () => {
  //   var followersarray = this.state.getfollowing;
  //   var fullarray = followersarray;
  //   await this.setState({
  //     followingIndex: this.state.followingIndex + 1
  //   });
  //   var mergearray = _.concat(fullarray, this.state.followingchunk[this.state.followingIndex])
  //   console.log(mergearray)
  //   this.setState({
  //     getfollowing: mergearray,
  //   })
  //   this.renderFollowingList();
  // }

  componentWillMount() {

    const history = createHistory();
    // Get the current location.
    const location = history.location;

    var res = location.pathname.split("/");
    console.log(res)
    console.log(location.pathname, res[2]);
    var user = res[2];
    // console.log(user)
    this.setState({
      profileuser: user
    })
    var uid = this.props.user.uid;

    // const user1 = this.props.location.state.user;
    // console.log(user1);

    if (user === undefined) {
      this.props.history.push("/")
    }

    this.props.getFeedsLimit().then(res => {
      // console.log(res.feedperRquest);
      this.setState({
        limitrequest: res.feedperRquest,
        loading: true
      })
      this.getPortfolio(this.state.limitrequest)
      this.getPersonalLogs(this.state.limitrequest)
    });

    if (user !== null || user !== undefined || user !== "") {

      this.props.getFollowing(user).then((res) => {
        console.log(res)
        this.setState({
          getfollowing: res,
          arraylength: false
        })
      });

      this.props.getPostCount(user).then(res => {
        // console.log(res);
        this.setState({
          portfoliocount: res.Portfolio,
          personalcount: res.Personal
        });
      }).catch(res => {
        this.setState({
          portfoliocount: 0,
          personalcount: 0
        });
      });

      this.props.getPeopleCount(user).then(res => {
        // console.log(res)
        this.setState({
          followingcount: res.Following,
          followercount: res.Follower,
        })
      }).catch(res => {
        this.setState({
          followingcount: 0,
          followercount: 0
        })
      })

      // this.props.getuserPersonalLogs(user, uid).then(res => {
      //   // console.log(res);
      //   this.setState({
      //     personallogs: res,
      //     arraylength: false,
      //   });
      // });

      this.props.followStatus(user, uid).then((res) => {
        // console.log(res);
        this.setState({
          followstatus: res,
        })
      });

      this.props.userBlockStatus(user, uid).then((res) => {
        console.log(res);
        this.setState({
          userstatus: res
        })
      });

      this.props.getBlockStatus(user, uid).then((res) => {
        console.log(res);
        this.setState({
          unblock: res
        })
      });

      this.props.getFollowing(user, uid).then((res) => {
        // console.log(res);
        this.setState({
          getfollowing: res,
          arraylength: false,
        })
      });

      this.props.getFollowers(user, uid).then((res) => {
        // console.log(res);
        this.setState({
          getfollowers: res,
          arraylength: false,
        })
      });


      this.props.getProfile(user, uid).then(res => {
        console.log(res);
        this.setState({
          activeuser: res,
          userId: res.Id,
          photoURL: res.photoURL[0].url,
          First_Name: res.First_Name,
          Last_Name: res.Last_Name,
          Date_of_Birth: res.Date_of_Birth,
          CreatedDate: res.createdDate,
          verified: res.Is_Verified
        });
      })
    }
  }


  handlefollowing = (id) => {
    var uid = this.props.user.uid;

    // console.log(uid, id)
    this.props.addFollowing(uid, id).then((res) => {
      // console.log(res);
      message.success("Added to your friend's list")
      this.props.followStatus(id, uid).then((res) => {
        // console.log(res);
        this.setState({
          followstatus: res
        })
      });
    });
    // }
  };


  handleunfollow = (id) => {
    var uid = this.props.user.uid;
    // console.log(uid, id)

    this.props.removeFollowing(id).then((res) => {
      console.log(res);
      this.props.followStatus(id, uid).then((res) => {
        console.log(res);
        this.setState({
          followstatus: res
        })
      });
    });
  };


  handleblockuser = async (user, uid) => {
    // console.log(user, uid)
    await this.props.blockUser(user, uid).then((res) => {
      message.warning("You have blocked this user")
      console.log(res)
      this.props.userBlockStatus(user, uid).then((res) => {
        console.log(res);
        this.setState({
          blockstatus: res
        })
      });
      this.refreshFeeds()
    })
  }


  handleunblock = async () => {
    console.log(this.state.unblock)
    var uid = this.props.user.uid;

    var Id = this.state.unblock;

    await this.props.unblockUser(Id).then((res) => {
      message.success("You have unblocked this user")
      console.log(res);
      this.props.getBlockStatus(Id, uid).then((res) => {
        console.log(res);
        this.setState({
          unblock: res
        })
      });
    });
    this.refreshFeeds()
  };


  componentDidUpdate(prevProps, prevState) {
    if (prevState.portfoliologs.length !== this.state.portfoliologs.length) {
      this.setState({
        portfoliologs: this.state.portfoliologs
      })
    }

    if (prevState.personallogs.length !== this.state.personallogs.length) {
      this.setState({
        personallogs: this.state.personallogs
      })
    }
  }

  renderFollowingList() {
    return _.map(this.state.getfollowing, (user, index) => {
      return (
        <li key={index} className="col-md-3 col-sm-4 col-xs-6 text-center">
          <div className="userbox">
            <span className="userphoto">
              {user.photoURL === undefined || user.photoURL === "" ? (
                <Avatar
                  // style={{
                  //   color: "#f56a00",
                  //   backgroundColor: "#fde3cf",
                  //   textTransform: "uppercase",
                  // }}
                  size={64}
                  src={Noprofileimg}
                >
                  {/* {user.First_Name.charAt(0)} */}
                </Avatar>
              ) : (
                <Avatar src={user.photoURL} size={64} />
              )}
            </span>
            <br />
            <Link
              to={user.Id === this.props.user.uid ?
                ("/profile")
                :
                ("/profile/" + user.Id)}
              onClick={() => this.refreshFeeds()}
            >
              <p className="userinfo" style={{ minHeight: '42px' }}>
                {user.First_Name} {user.Last_Name}
                <span>
                  {user.Is_Verified === false ? null : (
                    <img src={Verified} className="feeds-verified" />
                  )}
                </span>
              </p>
            </Link>
          </div>
        </li>
      );
    });
  }

  renderUserLogs(username, userimg, created_timestamp) {
    var timestamp = new Date(created_timestamp * 1000);
    // console.log(timestamp);
    return (
      <div className="postuserinfo">
        <span className="userphoto">
          {userimg === "" ? (
            <Avatar
              style={{ color: "#f56a00", backgroundColor: "#fde3cf" }}
              size={46} src={Noprofileimg}
            >
              {/* {username.charAt(0)} */}
            </Avatar>
          ) : (
            <Avatar src={userimg} size={46} />
          )}
        </span>

        <span
          className="userinfo"
          style={{
            marginLeft: "10px",
            fontWeight: "600",
            fontSize: "14px",
            textAlign: 'left'
          }}
        >
          {username}
          <br />
          <span className="memcity">
            {moment(timestamp).startOf('seconds').fromNow()}
          </span>
        </span>
      </div>
    );
  }

  renderUserInfo(username, userimg, created_timestamp) {
    var timestamp = new Date(created_timestamp * 1000);
    // console.log(timestamp);
    return (
      <div className="postuserinfo">
        <span className="userphoto">
          {userimg === "" ? (
            <Avatar
              style={{ color: "#f56a00", backgroundColor: "#fde3cf" }}
              size={46} src={Noprofileimg}
            >
              {/* {username.charAt(0)} */}
            </Avatar>
          ) : (
            <Avatar src={userimg} size={46} />
          )}
        </span>
        <span
          className="userinfo"
          style={{
            marginLeft: "10px",
            fontWeight: "600",
            fontSize: "14px",
            textAlign: 'left'
          }}
        >
          {username}
          <br />
          <span className="memcity">
            {moment(timestamp).startOf('seconds').fromNow()}
          </span>
        </span>
      </div>
    );
  }


  renderGridPostList() {
    return (
      <InfinitScroll
        dataLength={this.state.portfoliologs.length}
        next={this.getPortfolio}
        hasMore={this.state.hasMore === true ? true : false}
        loader={<div className="text-center"><Spin tip="Loading..." /></div>}
        endMessage={<div className="row"><p className="text-center">All Caught Up</p></div>}
        initialScrollY="600px"
      >
        <div className="profileview">
          {this.state.portfoliologs.map((logs, index) => (
            <div key={index}>
              {logs.upload_logs_image.length === 0 ? null : (
                <div className="viewlayout">
                  <div className="postimg">
                    <div className="gridcontainer">
                      <img src={logs.upload_logs_image[0].image}
                        onClick={() => this.showModal1(index)}
                        className="gridimage img1" alt="" />
                    </div>
                  </div>
                </div>
              )}
            </div>
          )
          )}
        </div>
      </InfinitScroll>
    )
  }


  renderPersonalLogsList() {
    // console.log(this.state.personallogs)

    const componentDecorator = (href, text, key) => (
      <a href={href} key={key} target="_blank" rel="noopener noreferrer">
        {text}
      </a>
    );
    return (
      <InfinitScroll
        dataLength={this.state.personallogs.length}
        next={this.getPersonalLogs}
        hasMore={this.state.hasMore === true ? true : false}
        loader={<div className="text-center"><Spin tip="Loading..." /></div>}
        endMessage={<div className="row"><p className="text-center">All Caught Up</p></div>}
        initialScrollY="600px"
      >
        {this.state.personallogs.map((logs, index) => (
          <div className="" style={{ marginBottom: "20px" }} key={index}>
            <Card
              title={this.renderUserLogs(
                logs.username,
                logs.userimg,
                logs.created_timestamp
              )}
              extra={
                <Dropdown trigger={["click"]} placement="bottomRight"
                  overlay={this.renderMenu(logs.Id, logs.posttype)}
                  style={{ marginLeft: '10px' }}>
                  <a className="ant-dropdown-link" href="#"
                    onClick={e => e.preventDefault()}>
                    <Icon type="more" style={{ fontSize: '20px', color: '#333' }} />
                  </a>
                </Dropdown>
              }
              style={{
                width: "100%",
                border: "1px solid #eee",
                borderRadius: "4px"
              }}
            >
              {_.map(logs.log_list, (log, index) => {
                return (
                  <div key={index} className="readdiv"
                    style={{
                      wordBreak: 'break-all',
                      whiteSpace: 'pre-line',
                      marginBottom: '10px',
                      padding: "8px 10px",
                      border: "1px solid #f9f9f9",
                      display: "block",
                      position: "relative",
                      borderRadius: "2px"
                    }}>
                    <Linkify componentDecorator={componentDecorator}>
                      <ReadMoreAndLess
                        ref={this.ReadMore}
                        className="read-more-content"
                        charLimit={500}
                        readMoreText="Read more"
                        readLessText="Read less"
                      >
                        {log.description}
                      </ReadMoreAndLess>
                    </Linkify>
                    <div className="breakdes">
                      <span className="time">
                        {moment.unix(log.text_created / 1000).format("LT")}
                      </span>
                    </div>
                  </div>
                );
              })}
              <div className="row text-center">
                {_.map(logs.upload_logs_image, (logImage, index) => {
                  return (
                    <div className="col-md-4 col-xs-12" key={index}>
                      <div className="imagecontainer">
                        {/* <div className="button">
                        <span onClick={() => this.deleteimage(logs.Id, index)}>
                          <Icon type="delete" theme="filled" />
                        </span>
                      </div> */}
                        <img src={logImage.image}
                          onClick={() => this.showModal(logs.upload_logs_image, index)}
                          className="imgbanner" alt="" />
                      </div>
                    </div>
                  );
                })}
              </div>
            </Card>
          </div>
        )
        )}
      </InfinitScroll>
    )
  }

  renderPostModalimages() {

    const componentDecorator = (href, text, key) => (
      <a href={href} key={key} target="_blank" rel="noopener noreferrer">
        {text}
      </a>
    );

    return (
      <Carousel className="fullgrid"
        showThumbs={true}
        showStatus={false}
        selectedItem={this.state.currentSlide}
        // showArrows={false}
        renderArrowPrev={(onClickHandler, hasPrev, label) =>
          hasPrev && (
            <Button type="primary" onClick={onClickHandler} title={label}
              className="arrowstyles"
              style={{ left: 0 }}>
              <Icon type="left" />
            </Button>
          )
        }
        renderArrowNext={(onClickHandler, hasNext, label) =>
          hasNext && (
            <Button type="primary" onClick={onClickHandler} title={label}
              className="arrowstyles"
              style={{ right: 0 }}>
              <Icon type="right" />
            </Button>
          )
        }>
        {/* {...settings} */}
        {_.map(this.state.portfoliologs, (logs, index) => {
          // console.log(this.state.portfoliologs[0].upload_logs_image.image)
          return (
            <div className="row" key={index}>
              <div className="col-md-12" style={{ padding: 0 }}>
                <div className="col-md-8" style={{ padding: 0 }}>
                  <div>
                    {logs.upload_logs_image.length === 0 ? (
                      <Empty />
                    ) : (
                      <Carousel>
                        {_.map(logs.upload_logs_image, (logImage, index) => {
                          return (
                            <div className="carouselimg" key={index}>
                              <div className="carouselimginner">
                                <img src={logImage.image}
                                  className="imgsliebanner" alt="" />
                              </div>
                            </div>
                          );
                        })}
                      </Carousel>
                    )}
                  </div>
                </div>
                <div className="col-md-4 gridlayout slidetext">
                  <Card
                    title={this.renderUserInfo(
                      logs.username,
                      logs.userimg,
                      logs.created_timestamp
                    )}
                    extra={
                      <Dropdown trigger={["click"]} placement="bottomRight"
                        overlay={this.renderMenu(logs.Id, logs.posttype)}
                        style={{ marginLeft: '10px' }}>
                        <a className="ant-dropdown-link" href="#"
                          onClick={e => e.preventDefault()}>
                          <Icon type="more" style={{ fontSize: '20px', color: '#333' }} />
                        </a>
                      </Dropdown>
                    }
                    style={{
                      border: 0,
                      boxShadow: 'none'
                    }}>
                    {logs.log_list.length === 0 ? (
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description="No Text" />
                    ) : (
                      <div style={logs.log_list.length === 0 ? { padding: '0' } : { padding: '15px' }}>
                        {_.map(logs.log_list, (log, index) => {
                          return (
                            <div className="readdiv" key={index}
                              style={{
                                wordBreak: 'break-all',
                                whiteSpace: 'pre-line',
                                marginBottom: '10px',
                                display: "block",

                              }}>
                              <Linkify componentDecorator={componentDecorator}>
                                {log.description}
                              </Linkify>
                            </div>
                          )
                        })}
                      </div>
                    )}
                  </Card>
                  <div className="postaction">
                    <div className="postactionbtn">
                      <div className="actioncount">
                        {logs.action.length === 0 ? null : (
                          <div>
                            <div className="spacereactors"
                              onClick={() => this.showreactionmodal(logs.action)}>

                              <span className="badge-success">
                                {this.getLikes(logs.action)}</span>
                            </div>
                          </div>
                        )}
                      </div>
                      <Button
                        type="link"
                        className={this.getIsLiked(
                          logs.action,
                          this.props.user.uid)
                          === 1 ? "Like" : this.getIsLiked(
                            logs.action,
                            this.props.user.uid)
                            === 5 ? "Like" : "Liked"}
                        onClick={() =>
                          this.postsaction(logs.Id, logs.author, this.props.user.uid, true,
                            logs.action, this.getIsLiked(
                              logs.action,
                              this.props.user.uid))
                        }>
                        <Icon type="caret-up" className="margin likeicon"
                          theme="filled" />
                      </Button>
                    </div>
                    <div className="postactionbtn">
                      <Button
                        type="link"
                        className={this.getIsdisLiked(
                          logs.action,
                          this.props.user.uid
                        ) === 3 ? "DisLike" : this.getIsdisLiked(
                          logs.action,
                          this.props.user.uid
                        ) === 5 ? "DisLike" : "DisLiked"}
                        onClick={() =>
                          this.postsaction(logs.Id, logs.author, this.props.user.uid,
                            false, logs.action, this.getIsdisLiked(
                              logs.action, this.props.user.uid
                            ))
                        }
                      >
                        <Icon type="caret-down"
                          className="margin likeicon" theme="filled" />

                      </Button>
                      <div className="actioncount">
                        {logs.action.length === 0 ? null : (
                          <div>
                            <div className="spacereactors">
                              <span className="badge-danger">
                                {this.getdisLikes(logs.action)}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </Carousel>
    );
  }

  renderModalimages() {

    return (
      <Carousel
        selectedItem={this.state.currentSlide}
        showThumbs={false}
        // onChange={this.updateCurrentSlide}
        // showArrows={false}
        renderArrowPrev={(onClickHandler, hasPrev, label) =>
          hasPrev && (
            <Button type="primary" onClick={onClickHandler} title={label}
              className="arrowstyles"
              style={{ left: 0 }}>
              <Icon type="left" />
            </Button>
          )
        }
        renderArrowNext={(onClickHandler, hasNext, label) =>
          hasNext && (
            <Button type="primary" onClick={onClickHandler} title={label}
              className="arrowstyles"
              style={{ right: 0 }}>
              <Icon type="right" />
            </Button>
          )
        }>
        {_.map(this.state.activeimgset, (logImage, index) => {
          return (
            <div className="carouselimg" key={index}>
              <div className="carouselimginner">
                <img src={logImage.image} className="imgbanner img1" alt="" />
              </div>
            </div>
          );
        })}
      </Carousel>
    );
  }


  renderFollowersList() {
    // console.log(this.state.getfollowers);
    return _.map(this.state.getfollowers, (user, index) => {
      return (
        <li key={index} className="col-md-3 col-sm-4 col-xs-6 text-center">
          <div className="userbox">
            <span className="userphoto">
              {user.photoURL === undefined || user.photoURL === "" ? (
                <Avatar
                  // style={{
                  //   color: "#f56a00",
                  //   backgroundColor: "#fde3cf",
                  //   textTransform: "uppercase",
                  // }}
                  size={64}
                  src={Noprofileimg}
                >
                  {/* {user.First_Name.charAt(0)} */}
                </Avatar>
              ) : (
                <Avatar src={user.photoURL} size={64} />
              )}
            </span>
            <br />
            <Link
              to={user.Id === this.props.user.uid ?
                ("/profile")
                :
                ("/profile/" + user.Id)}
              onClick={() => this.refreshFeeds()}
            >
              <p className="userinfo" style={{ minHeight: '42px' }}>
                {user.First_Name} {user.Last_Name}
                <span>
                  {user.Is_Verified === false ? null : (
                    <img src={Verified} className="feeds-verified" />
                  )}
                </span>
              </p>
            </Link>
          </div>
        </li>
      );
    });
  }

  abbreviateNumber1(value) {
    var newValue = value;
    if (value >= 10000) {
      var suffixes = ["", "k", "M", "B", "T"];
      var suffixNum = Math.floor(("" + value).length / 4);
      var shortValue = '';
      for (var precision = 3; precision >= 2; precision--) {
        shortValue = parseFloat((suffixNum !== 0 ? (value / Math.pow(1000, suffixNum)) : value).toPrecision(precision));
        var dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g, '');
        if (dotLessShortValue.length <= 3) { break; }
      }
      if (shortValue % 2 !== 0) shortValue = shortValue.toFixed(2);
      newValue = shortValue + suffixes[suffixNum];
    }
    // console.log(newValue)
    return newValue;
  }

  confirm(user, id) {
    this.handleblockuser(user, id)
    // message.success('Log deleted');
  }

  cancel(e) {
    // console.log(e);
    // message.error('Click on No');
  }

  onCopy = () => {
    this.setState({
      copied: true,
    });
    message.success("Copied shared link")
  };

  renderMenu(Id, type) {
    const {
      url = this.state.urlvalue + Id,
      title = "See the livathon post",
      shareImage = "https://www.steadylearner.com/static/images/brand/prop-passer.png",
      size = "2.5rem",
    } = this.props;


    const ShareList = Passers({
      url,
      className: "network__share-button",
    })({
      className: "network cursor-pointer hover transition--default",
      title: `Share ${String(this.state.loc)}`,
    })("li");

    const iconFont = {
      fontSize: '14px'
    }

    return (
      <ul className="feedpost-drop">
        {type === "portfolio" ? (
          <p style={{ margin: 0 }}>
            <ShareList>
              <FacebookShareButton
                windowWidth={750}
                windowHeight={600}
                url={"https://livathon.com/" + "/post" + Id}
                quote={"See the livathon Post"}
              >
                <i className="fab fa-facebook" style={iconFont}></i> Share to facebook
              </FacebookShareButton>
            </ShareList>
            <ShareList>
              <TwitterShareButton
                windowWidth={750}
                windowHeight={600}
                title={title}
                url={url}
              >
                <i className="fab fa-twitter" style={iconFont}></i> Share to twitter
              </TwitterShareButton>
            </ShareList>
            <ShareList>
              <WhatsappShareButton
                windowWidth={750}
                windowHeight={600}
                title={"See the livathon post"}
                url={url}
                separator=":: "
              >
                <i className="fab fa-whatsapp-square" style={iconFont}></i>  share to whatsapp
              </WhatsappShareButton>
            </ShareList>
            <ShareList>
              <LinkedinShareButton
                title={title}
                url={"https://livathon.com/" + "/post" + Id}
                windowWidth={750}
                windowHeight={600}
              >
                <i className="fab fa-linkedin-in" style={iconFont}></i> Share to linkedIn
              </LinkedinShareButton>
            </ShareList>
          </p>
        ) : null}
        <li>
          <CopyToClipboard onCopy={this.onCopy}
            text={type == "portfolio" ? this.state.urlvalue + Id : this.state.urlvalue1 + Id}>
            <span><i className="fas fa-copy" style={iconFont}></i> Copy link</span>
          </CopyToClipboard>
        </li>
      </ul>
    )
  }

  renderBlockMenu(user, Id) {
    return (
      <ul className="feedpost-drop">
        <li>
          <Popconfirm placement="rightBottom"
            title={<div className="text-center">
              <Icon type="question-circle-o"
                style={{ color: 'red', marginRight: '5px', fontSize: '16px' }} />
              <b style={{ fontSize: '16px' }}>Block this User??</b> <br />
              <p className="blocktext"> They won't be able to find your profile,
                <br />posts or story on Livathon. <br /></p>
            </div>}
            icon={null}
            onConfirm={() => this.confirm(user, Id)}
            onCancel={() => this.cancel()}
            okText="Yes"
            cancelText="No">
            <p><i className="fas fa-user-slash"></i> <span>Block user</span></p>
          </Popconfirm>
        </li>
      </ul >
    )
  }

  renderProfileimages() {
    return (
      <div>
        <Carousel
          showIndicators={false}>
          <div className="carouselimg">
            <div className="carouselimginner">
              <img src={this.state.photoURL === undefined || this.state.photoURL === "" ?
                Noprofileimg : this.state.photoURL}
                className="imgbanner img1" alt="" />
            </div>
          </div>
        </Carousel>
      </div >
    );
  }

  render() {

    const { arraylength, portfoliologs, personallogs,
      getfollowing, getfollowers, followstatus, verified,
      followingIndex, followinglength } = this.state;

    var abbreviatedPostCount = this.abbreviateNumber1(this.state.portfoliocount);
    var abbreviatedLogCount = this.abbreviateNumber1(this.state.personalcount);
    var abbreviatedFollowingCount = this.abbreviateNumber1(this.state.followingcount);
    var abbreviatedFollowersCount = this.abbreviateNumber1(this.state.followercount);

    var post_count = "Post" + " " + "(" + abbreviatedPostCount + ")";

    var log_count = "Diary" + " " + "(" + abbreviatedLogCount + ")";

    var following = "Following" + " " + "(" + abbreviatedFollowingCount + ")";

    var followers = "Followers" + " " + "(" + abbreviatedFollowersCount + ")";

    const componentDecorator = (href, text, key) => (
      <a href={href} key={key} target="_blank" rel="noopener noreferrer">
        {text}
      </a>
    );

    const cursor = {
      cursor: 'pointer'
    }

    return (
      <div>
        <Modal
          className="carouselmodal"
          title=""
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          destroyOnClose={true}
          footer={null}
          centered={true}
          width="70%"
        >
          {this.renderModalimages()}
        </Modal>
        <Modal
          className="carouselmodal"
          title=""
          visible={this.state.visible3}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          destroyOnClose={true}
          footer={null}
          centered={true}
          width="70%"
        >
          {this.renderProfileimages()}
        </Modal>

        <Modal
          className="gridmodal carouselmodal"
          title=""
          visible={this.state.visible2}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          destroyOnClose={true}
          footer={null}
          centered={true}
          width="70%"
        >
          {this.renderPostModalimages()}
        </Modal>

        <div className="container-fluid" >
          <div className="row" style={{ marginTop: '80px' }}>
            <div className="col-md-2 sticky hidden-xs hidden-widget"
            >
              <MenuWidget />

              <div style={{ marginTop: '20px' }}>
                <Card style={{ width: '100%' }}>
                  <p style={{ margin: 0 }}>
                    Joined on <br /><b>{this.state.CreatedDate}</b>
                  </p>
                </Card>
              </div>
            </div>
            {this.state.userstatus === "Blocked" ? (
              <div className="col-md-10">
                {this.state.activeuser !== "" ? (
                  <div className="imageup">
                    <div className="row" style={{ marginBottom: '20px' }}>
                      <div className="col-md-3 col-xs-12">
                        <div className="userimage text-center">
                          {this.state.photoURL === undefined || this.state.photoURL === "" ? (
                            <Avatar
                              size={180} src={Noprofileimg}
                            >
                            </Avatar>
                          ) : (
                            <Avatar
                              size={180} src={this.state.photoURL}
                            ></Avatar>
                          )}
                        </div>
                      </div>
                      <div className="col-md-5 col-xs-12 portfolio hidden-xs">
                        <h4 className="userinfo1">
                          <b> {this.state.activeuser.First_Name} {this.state.activeuser.Last_Name} </b>
                          <Divider type="vertical" />
                          <span style={{ fontSize: "16px" }}>
                            {moment(this.state.Date_of_Birth).format("YYYY")}
                          </span>
                          {verified === false ? null : (
                            <img src={Verified} className="verified" />
                          )}
                        </h4>
                        <Linkify componentDecorator={componentDecorator}>
                          <h6 className="m-b-20 usersub"
                          >{this.state.activeuser.Biography}</h6>
                        </Linkify>
                        <div>
                          <Tooltip title="Unblock this user">
                            <Button
                              type="default"
                              block
                              className="userfoll"
                              onClick={() => this.handleunblock(this.state.unblock)}
                            >
                              Unblock
                            </Button>
                          </Tooltip>
                        </div>
                      </div>
                      <div className="col-xs-12 portfolio hidden-lg hidden-md hidden-sm text-center">
                        <h4 className="userinfo1" style={{ color: '#1c41ce' }}>
                          <b> {this.state.activeuser.First_Name}
                            {this.state.activeuser.Last_Name} </b>
                          <Divider type="vertical" />
                          <span style={{ fontSize: "16px" }}>
                            {moment(this.state.Date_of_Birth).format("YYYY")}
                          </span>
                          {verified === false ? null : (
                            <img src={Verified} className="verified" />
                          )}
                        </h4>
                        <Linkify componentDecorator={componentDecorator}>
                          <h6 className="m-b-20 usersub"
                          >{this.state.activeuser.Biography}</h6>
                        </Linkify>
                        <div>
                          <Tooltip title="Unblock this user">
                            <Button
                              type="default"
                              block
                              className="userfoll"
                              onClick={() => this.handlefollowing(this.state.unblock)}
                            >
                              Unblock
                            </Button>
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                <div className="profile-detail">
                  <div className="col-md-12">
                    <p>You have blocked this User!!!</p>
                  </div>
                </div>
              </div>
            ) : (
              <div className="col-md-10">
                {/* <div className="userbg"></div> */}
                {this.state.activeuser !== "" ? (
                  <div className="imageup" style={{ marginBottom: '20px' }}>
                    <div className="row">
                      <div className="col-md-3 col-xs-12">
                        <div className="userimage text-center">
                          {this.state.photoURL === undefined || this.state.photoURL === "" ? (
                            <Avatar style={cursor}
                              size={180} src={Noprofileimg}
                              onClick={() => this.profileModal()}
                            >
                            </Avatar>
                          ) : (
                            <Avatar style={cursor}
                              size={180} src={this.state.photoURL}
                              onClick={() => this.profileModal()}
                            ></Avatar>
                          )}
                        </div>
                      </div>
                      <div className="col-md-5 col-xs-12 portfolio hidden-xs">
                        <h4 className="userinfo1">
                          <b> {this.state.activeuser.First_Name} {this.state.activeuser.Last_Name} </b>
                          <Divider type="vertical" />
                          <span style={{ fontSize: "16px" }}>
                            {moment(this.state.Date_of_Birth).format("YYYY")}
                          </span>
                          {verified === false ? null : (
                            <img src={Verified} className="verified" />
                          )}
                          <Dropdown trigger={["click"]} placement="bottomRight"
                            overlay={this.renderBlockMenu(this.state.userId, this.props.user.uid)}
                            style={{ marginLeft: '10px' }}>
                            <a className="ant-dropdown-link" href="#"
                              onClick={e => e.preventDefault()}>
                              <Icon type="ellipsis" className="blockmenu" />
                            </a>
                          </Dropdown>
                        </h4>
                        <Linkify componentDecorator={componentDecorator}>
                          <h6 className="m-b-20 usersub"
                          >{this.state.activeuser.Biography}</h6>
                        </Linkify>
                        <div className="row">
                          <div className="col-md-4">
                            {this.state.followstatus === "Follow" ? (
                              <Button
                                type="default"
                                block
                                className="userfoll"
                                onClick={() => this.handlefollowing(this.state.activeuser.Id)}
                              >
                                {this.state.followstatus}
                              </Button>
                            ) : (
                              <div>
                                {this.state.followstatus === "Following" ? (
                                  <Button
                                    type="default"
                                    block
                                    className="userfollwg"
                                    onClick={() => this.handleunfollow(this.state.activeuser.Id)}
                                  >
                                    {this.state.followstatus}
                                  </Button>
                                ) : (
                                  <Button
                                    type="default"
                                    block
                                    className="userfollwg"
                                    onClick={() => this.handlefollowing(this.state.activeuser.Id)}
                                  >
                                    {this.state.followstatus}
                                  </Button>
                                )}
                              </div>
                            )}
                          </div>
                          {/* <div className="col-md-4 message">
                            <Link to={{
                              pathname: '/chat',
                              state: {
                                user: this.state.userId
                              }
                            }}> <Tooltip title="Message"><Button type="primary"
                              className="message-btn"
                              icon="mail" />
                              </Tooltip>
                            </Link>
                          </div> */}
                        </div>
                      </div>
                      <div className="col-xs-12 portfolio hidden-lg hidden-md hidden-sm text-center">
                        <h4 className="userinfo1" style={{ color: '#1c41ce' }}>
                          <b> {this.state.activeuser.First_Name}
                            {this.state.activeuser.Last_Name} </b>
                          <Divider type="vertical" />
                          <span style={{ fontSize: "16px" }}>
                            {moment(this.state.Date_of_Birth).format("DD-MM-YYYY")}
                          </span>
                        </h4>
                        <Linkify componentDecorator={componentDecorator}>
                          <h6 className="m-b-20 usersub"
                          >{this.state.activeuser.Biography}</h6>
                        </Linkify>
                        <div>
                          {this.state.followstatus === "Follow" ? (
                            <Button
                              type="default"
                              block
                              className="userfoll"
                              onClick={() => this.handlefollowing(this.state.activeuser.Id)}
                            >
                              {this.state.followstatus}
                            </Button>
                          ) : (
                            <div>
                              {this.state.followstatus === "Following" ? (
                                <Button
                                  type="default"
                                  block
                                  className="userfollwg"
                                  onClick={() => this.handleunfollow(this.state.activeuser.Id)}
                                >
                                  {this.state.followstatus}
                                </Button>
                              ) : (
                                <Button
                                  type="default"
                                  block
                                  className="userfollwg"
                                  onClick={() => this.handlefollowing(this.state.activeuser.Id)}
                                >
                                  {this.state.followstatus}
                                </Button>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                      {/* <div className="col-md-5"
                          style={{ borderTop: '3px solid #1c41ce', marginTop: '30px' }}>
                          <Card className="portfolioprocard">
                            <div className="col-md-3 text-center rightline">
                              <h6>Posts</h6>
                              <span className="badge"
                                style={{ backgroundColor: '#1c41ce' }}>
                                {this.state.portfoliologs.length}
                              </span>
                            </div>
                            <div className="col-md-3 text-center rightline">
                              <h6>Logs</h6>
                              <span className="badge"
                                style={{ backgroundColor: '#1c41ce' }}>
                                {this.state.personallogs.length}
                              </span>
                            </div>
                            <div className="col-md-3 text-center rightline">
                              <h6>Following</h6>
                              <span className="badge"
                                style={{ backgroundColor: '#1c41ce' }}>
                                {this.state.getfollowing.length}
                              </span>
                            </div>
                            <div className="col-md-3 text-center">
                              <h6>Followers</h6>
                              <span className="badge"
                                style={{ backgroundColor: '#1c41ce' }}>

                                {this.state.getfollowers.length}
                              </span>
                            </div>
                          </Card>
                        </div> */}
                    </div>
                  </div>
                ) : null}
                <div className="profile-detail" style={{ marginBottom: '20px' }}>

                  <Tabs defaultActiveKey="1" className="feedstab">
                    <TabPane tab={
                      <span>
                        <Icon type="table" theme="outlined" />
                        {post_count}
                      </span>
                    }
                      key="1">
                      {portfoliologs.length === 0 && arraylength === true ? (
                        <div className="row">
                          <div className="col-md-12">
                            <GridShimmer count={4} />
                          </div>
                        </div>
                      ) : (
                        <div>
                          {portfoliologs.length === 0 ? (
                            <Empty />
                          ) : (
                            <div>
                              {this.renderGridPostList()}
                            </div>
                          )}
                        </div>
                      )}
                    </TabPane>
                    {followstatus === "Follow" ||
                      followstatus === "Follow Back" ? (
                      <TabPane tab={
                        <Tooltip title="Follow to view their diary">
                          <span>Diary</span>
                        </Tooltip>
                      } disabled key="2"></TabPane>
                    ) : (
                      <TabPane tab={
                        <span>
                          <Icon type="unordered-list" theme="outlined" />
                          {log_count}
                        </span>
                      } key="2">
                        {personallogs.length === 0 && arraylength === true ? (
                          <div>
                            <div className="row">
                              <div className="col-md-12">
                                <LogShimmer count={4} />
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div>
                            {personallogs.length === 0 ? (
                              <Empty />
                            ) : (
                              <div>
                                {this.renderPersonalLogsList()}
                              </div>
                            )}
                          </div>
                        )}
                      </TabPane>
                    )}
                    <TabPane tab={
                      <span>
                        <Icon type="user-add" theme="outlined" />
                        {following}
                      </span>
                    } key="3">
                      <h5>Following Friends</h5>
                      <ul className="list-unstyled row">
                        {getfollowing.length === 0 && arraylength === true ? (
                          <div>
                            <div className="row">
                              <div className="col-md-12">
                                <UserShimmer count={4} />
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div>
                            {getfollowing.length === 0 ? (
                              <Empty />
                            ) : (
                              <div>
                                {this.renderFollowingList()}
                                <div className="row">
                                  <div className="col-md-12 text-center">
                                    {followinglength - 1 > followingIndex ? (
                                      <Button type="primary"
                                        onClick={() => this.addFollowingData()}>
                                        View More</Button>
                                    ) : (
                                      null
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </ul>
                    </TabPane>
                    <TabPane tab={
                      <span>
                        <Icon type="usergroup-add" theme="outlined" />
                        {followers}
                      </span>
                    } key="4">
                      <h5>All Followers</h5>
                      <ul className="list-unstyled row">
                        {getfollowers.length === 0 && arraylength === true ? (
                          <div>
                            <div className="row">
                              <div className="col-md-12">
                                <UserShimmer count={4} />
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div>
                            {getfollowers.length === 0 ? (
                              <Empty />
                            ) : (
                              <div>
                                {this.renderFollowersList()}
                              </div>
                            )}
                          </div>
                        )}
                      </ul>
                    </TabPane>
                  </Tabs>
                </div>

              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
    allusers: state.allusers,
    portfoliologs: state.portfoliologs,
    personallogs: state.personallogs,
    getfollowers: state.getfollowers,
    getfollowing: state.getfollowing,
  };
}

export default connect(mapStateToProps, {
  getuserportfolioLogs,
  getuserPersonalLogs,
  getFollowers,
  addFollowing,
  getProfile,
  removeFollowing,
  getFollowing,
  followStatus,
  postActions,
  getImageportfolioLogs,
  blockUser,
  userBlockStatus,
  unblockUser,
  getBlockStatus,
  getFeedsLimit,
  getPostCount,
  getPeopleCount
})(PortFolio);

