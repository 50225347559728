import React, { Component } from "react";
import {
    Avatar,
    Empty,
} from "antd";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { logout, createAccount } from "../actions/userAction";
import { getNotifications, unreadnotify } from "../actions/livingAction";
// import request from "superagent";
import NotificationShimmer from '../component/notificationShimmer'
// import moment from "moment";
import _ from "lodash";
import "../assests/css/styles.css";
import Noprofileimg from "../assests/img/d-avatar.jpg";
// import { BASEURL } from '../actionTypes'
import MenuWidget from "./menuWidget"
import ProfileWidget from "./profileWidget"
import FeedsuserList from './feedsuserlist'
import Verified from '../assests/img/verified.svg'


class Notifications extends Component {
    constructor(props) {
        super(props);
        this.state = {
            photoURL: "",
            current: "1",
            date: [],
            notification: [],
            noticount: [],
            userlist: "",
            removeNotification: "",
            visible: false,
            arraylength: true
        };
    }


    readNotification() {
        var uid = this.props.user.uid;

        var unnotify = this.state.notifications;
        // console.log(unnotify);
        this.props.unreadnotify(uid, unnotify);
    }

    componentDidMount() {
        var uid = this.props.user.uid;

        this.props.getNotifications(uid).then(res => {
            var unreadnoti = _.filter(res, { 'notify_read': false });
            // console.log(unreadnoti)
            var sortDate = res.sort(function (a, b) {

                return new Date(b.Created_Date.seconds * 1000) - new Date(a.Created_Date.seconds * 1000);
            });
            this.setState({
                notification: sortDate,
                noticount: unreadnoti.length
            });
        });
    }

    componentDidUpdate(prevProps, nextProps) {
        var uid = this.props.user.uid;

        // console.log(prevProps.getNotification, nextProps.getNotification, this.props.getNotification)
        if (prevProps.getNotification !== this.props.getNotification) {
            this.setState({
                notification: this.props.getNotification,
                arraylength: false
            });
            // this.removeNotification(uid)
        }
    }

    rendernotifications() {
        // console.log(this.props.getNotification);
        return _.map(this.state.notification, (notify, index) => {
            return (
                <li key={index} className={!notify.read ? "active" : null}>
                    <div className="d-flex">
                        {notify.photoURL === undefined || notify.photoURL === "" ? (
                            <Avatar
                                style={{ marginRight: "5px" }}
                                size={35}
                                src={Noprofileimg}
                            >
                                {/* {username.charAt(0)} */}
                            </Avatar>
                        ) : (
                            <Avatar
                                size={35}
                                src={notify.photoURL}
                                style={{ marginRight: "5px" }}
                            />
                        )}
                        <div>
                            <p style={{ padding: "5px" }} className="notifybody">
                                <Link
                                    to={
                                        notify.Id === this.props.user.uid
                                            ? "/profile"
                                            : "/profile/" + notify.Id
                                    }
                                >
                                    {notify.First_Name} {notify.Last_Name}
                                    {notify.Is_Verified === false ? null : (
                                        <img src={Verified} className="feeds-verified" />
                                    )}
                                </Link> {" "}
                                {notify.actionType ? "liked your post" : "started following you"}
                            </p>
                            <p className="date">
                                {notify.dateago}
                                {/* {moment(notify.date, "MMM Do YYYY, h:mm:ss a").startOf('seconds').fromNow()} */}
                            </p>
                        </div>
                    </div>

                </li>
            );
        });
    }

    render() {

        const { notification, arraylength } = this.state;

        return (
            <div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-2 col-xs-12 sticky hidden-xs hidden-widget">
                            <MenuWidget />
                        </div>
                        <div className="col-md-6 col-xs-12">
                            <div className="feedsuser" style={{ marginTop: "80px" }}>
                                <p>Notifications</p>
                                <ul className="notilist">
                                    <div>
                                        {notification.length === 0 && arraylength === true ? (
                                            <div>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <NotificationShimmer count={4} />
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div>
                                                {notification.length === 0 ? (
                                                    <Empty />
                                                ) : (
                                                    <div>
                                                        {this.rendernotifications()}
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12 col-xs-12 sticky"
                            style={{ marginTop: '80px' }}>
                            <div className="hidden-widget">
                                <ProfileWidget />
                            </div>
                            <div className="sticky">
                                <FeedsuserList />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        user: state.user,
        allusers: state.allusers,
        getNotification: state.getNotification
    };
}

export default connect(mapStateToProps, {
    logout,
    createAccount,
    getNotifications,
    unreadnotify,
})(Notifications);
