import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

//======Initialize Firebase in staging database=======//
// const firebaseConfig = {
//     apiKey: "AIzaSyBUnnfb_-8WrUENHqHppv7jdNvHzn7aFdE",
//     authDomain: "living-921ec.firebaseapp.com",
//     databaseURL: "https://living-921ec.firebaseio.com",
//     projectId: "living-921ec",
//     storageBucket: "living-921ec.appspot.com",
//     messagingSenderId: "118823002113",
//     appId: "1:118823002113:web:b3c26f8a71d4361ac06000",
//     measurementId: "G-FPQKR4CTK7"
// };

// Production database
const firebaseConfig = {
    apiKey: "AIzaSyB3X1Px5fHa1TIYo_Fw92pmvl4MMdKCwUY",
    authDomain: "livathon-f1c58.firebaseapp.com",
    databaseURL: "https://livathon-f1c58.firebaseio.com",
    projectId: "livathon-f1c58",
    storageBucket: "livathon-f1c58.appspot.com",
    messagingSenderId: "371008357520",
    appId: "1:371008357520:web:71ce84af3873847eca8415",
    measurementId: "G-52PYDYVWYE"
};


firebase.initializeApp(firebaseConfig);

export const db = firebase.firestore();
export const storage = firebase.storage();
export const auth = firebase.auth();
