import React, { Component } from "react";
import { connect } from "react-redux";
import { Card } from 'antd';
import Morning from '../assests/img/park.png'
import Afternoon from '../assests/img/desert.png'
import Evening from '../assests/img/sea.png'
import { db } from "../firebase";

class DayWidget extends Component {
    constructor(props) {
        super(props);
        this.state = {
            First_Name: "",
            Last_Name: "",
        };
    }

    componentWillMount() {
        var uid = this.props.user.uid;

        var userRef = db.collection("User_Details").doc(uid);
        userRef
            .get()
            .then(doc => {
                if (doc.exists) {
                    // var Full_Name = doc.data().First_Name + " " + doc.data().Last_Name;
                    // console.log(doc.data().photoURL);
                    this.setState({
                        First_Name: doc.data().First_Name,
                        Last_Name: doc.data().Last_Name,
                    });
                } else {
                    console.log("No such document!");
                }
            })
            .catch(err => {
                console.log("Error getting document", err);
            });

    }

    render() {

        var time = new Date().getHours();

        return (
            <div>
                <Card className="daycard text-center" style={{ borderLeft: '3px solid #1c41ce' }}>
                    {time < 12 ? (
                        <div className="row">
                            <div className="col-md-10 col-xs-9"
                                style={{ marginTop: '5px', marginBottom: '5px', paddingLeft: '30px' }}>
                                <h4 className="text-left"
                                    style={{ fontSize: '16px', fontWeight: '600' }}>
                                    Good Morning, {this.state.First_Name} {this.state.Last_Name}</h4>
                                <p className="text-left"
                                    style={{ marginBottom: 0 }}>
                                    Every new day is a chance to change your life.
                           </p>
                            </div>
                            <div className="col-md-2 col-xs-3">
                                <img src={Morning} alt="evening" className="seasonimg" />
                            </div>
                        </div>
                    ) : (
                            <div>
                                {time < 18 ? (
                                    <div className="row">
                                        <div className="col-md-10 col-xs-9"
                                            style={{ marginTop: '5px', marginBottom: '5px', paddingLeft: '30px' }}>
                                            <h4 className="text-left"
                                                style={{ fontSize: '16px', fontWeight: '600' }}>
                                                Good Afternoon, {this.state.First_Name} {this.state.Last_Name}</h4>
                                            <p className="text-left"
                                                style={{ marginBottom: 0 }}>
                                                May this afternoon be light, blessed, enlightened, productive
                                                and happy.
                                        </p>
                                        </div>
                                        <div className="col-md-2 col-xs-3">
                                            <img src={Afternoon} alt="afternoon" className="seasonimg" />
                                        </div>
                                    </div>
                                ) : (
                                        <div className="row">
                                            <div className="col-md-10 col-xs-9"
                                                style={{ marginTop: '5px', marginBottom: '5px', paddingLeft: '30px' }}>
                                                <h4 className="text-left"
                                                    style={{ fontSize: '16px', fontWeight: '600' }}>
                                                    Good Evening, {this.state.First_Name} {this.state.Last_Name}</h4>
                                                <p className="text-left"
                                                    style={{ marginBottom: 0 }}>
                                                    Evenings are life’s way of saying that you are closer to your dreams.
                                            </p>
                                            </div>
                                            <div className="col-md-2 col-xs-3">
                                                <img src={Evening} alt="evening" className="seasonimg" />
                                            </div>
                                        </div>
                                    )}
                            </div>
                        )}
                </Card>
            </div >
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps, {
})(DayWidget);
