import React, { Component } from "react";
// import Navigation from '../common/header';
import Logo from '../assests/img/logo-new-dark.svg';
import {
    Collapse, Icon
} from 'antd';
import { Link } from "react-router-dom";
import { connect } from "react-redux";


const { Panel } = Collapse;

class PrivacyPolicy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            new_year: new Date().getFullYear()
        }
    }


    render() {
        return (
            <div>
                <div className="container-fluid">
                    <nav className="navbar navbar-default">
                        <div className="container-fluid navcontainer">
                            <div className="navbar-header">
                                <div className="navbar-toggle">
                                    {/* <Signup /> */}
                                </div>
                                <Link className="navbar-brand" to="">
                                    <img
                                        src={Logo}
                                        className="logoimg img-responsive"
                                        alt="logo"
                                        style={{ height: '35px' }}
                                    />
                                </Link>
                            </div>
                            <div id="navbar" className="navbar-collapse collapse">
                                <ul className="nav navbar-nav navbar-right" style={{ marginTop: '30px' }}>
                                    {/* <li><Button className="btn loginBtn color-1"
                                            onClick={() => this.props.logout()} style={{ margin: '0' }}>
                                            Logout
                                          </Button>
                                        </li> */}
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12" style={{ marginTop: '50px' }}>
                            <h3 style={{ color: '#1C41CE', fontWeight: 'bold' }}
                            > <Link to="/"><Icon type="arrow-left" /></Link> Privacy Policy</h3>
                            <p className="privacyterms">Our team (Livathon) is committed in protecting the data collected from the users
                                safeguarding your privacy. This privacy policy outlines how the personal data is
                                collected and the purposes for which it is used. </p>

                            <p className="privacyterms">When you use our App, some of your Personal Data is collected by us. We are
                                committed to protecting the security of this data and safeguarding your privacy.
                                This Privacy Notice outlines the details of the Personal Data collected, the
                                manner in which it collected, and the purposes for which it is used.</p>

                            <p className="privacyterms">Throughout this document, the terms "we", "us", "our" & "ours" refer to our
                                “Livathon” team. And the terms "you", "your" & "yours" refer to YOU
                                (the individual whose Personal Data we are referring to).</p>

                            <p className="privacyterms">We process any Personal Data we collect from you in accordance with the
                                provisions of this Privacy Notice.
                                Please read the following carefully to understand our practices regarding your Personal Data.</p>

                            <div style={{ marginTop: '20px' }}>
                                <Collapse accordion>
                                    <Panel header="Data collected and why?" key="1">
                                        <p className="privacyterms">Categories of Personal Data that we collect, and
                                            process are as follows:</p>
                                        <ul>
                                            <li>Demographic, Identity & Contact Data (for e.g., name, email address, current city,
                                                hometown, mobile phone number )</li>
                                            <li>Preferences or Interests related Data (for e.g., preferences,diary logs, posts,
                                                feedbacks, interests / passion lists , likes/dislikes, other content you
                                                submit while accessing the App)</li>
                                            <li>Online Identifiers and other Technical Data (for e.g., Internet Protocol (IP) address,
                                                mobile platform, and unique device identifier (UDID))</li>
                                            <li>Authentication Data (for e.g., username, password)</li>
                                            <li>Photos ,videos, Blogs, Gps Location,Camera, Gallery, Storage.</li>
                                        </ul>
                                    </Panel>
                                    <Panel header="How data collected from our App ?" key="2">
                                        <p className="privacyterms">We collect your Personal Data in following ways:</p>
                                        <ul>
                                            <li>The Personal Data that you provide when you register on our App and,
                                                further, during usage of the App.</li>
                                            <li>The Personal Data that gets collected in the background and/or is observed
                                                about you during usage of the App</li>
                                        </ul>
                                        <p className="privacyterms">We may receive your Personal Data from other people if they have shared your Personal Data
                                            with us (for e.g., if they have tagged you in their photos or when other users you know
                                            use our App, they may provide your phone number from their mobile address book, or
                                            they may send you a message, send messages to groups to which you belong, or call you).</p>
                                    </Panel>
                                    <Panel header="For What Purposes Do We Use Your Personal Data?" key="3">
                                        <p className="privacyterms"> We use your Personal Data for the following purposes:</p>
                                        <ul>
                                            <li>To deliver our App and services</li>
                                            <li>To verify your account and activity. </li>
                                            <li>To communicate with you regarding our App and existing services availed by you, including notifications
                                                of any alerts or updates to our App and services</li>
                                            <li>To respond to you when you contact us with your requests, enquiries, comments, and complaints</li>
                                            <li>To evaluate, develop and improve our App and services</li>
                                            <li>To comply with legal or regulatory requirements</li>
                                            <li>To investigate, prevent, or take action regarding illegal activities, suspected fraud and
                                                situations involving potential threats to the safety of any person</li>
                                            <li>To provide technical, product and other support and help keep the App working, safe and secure</li>
                                        </ul>
                                    </Panel>
                                    <Panel header="On What Lawful Grounds Do We Process Your Personal Data?" key="4">
                                        <p className="privacyterms">We process your Personal Data by relying on one or more of the
                                            following lawful grounds:</p>
                                        <ul>
                                            <li>You have consented to us processing your Personal Data for specified reasons</li>
                                            <li>The processing is necessary for compliance with a legal obligation we may have
                                                (for e.g., towards law enforcement agencies, government agencies, regulators, etc)</li>
                                            <li>The processing is necessary for provision of certain services to you. For e.g.
                                                <ul style={{ marginTop: '15px' }}>
                                                    <li>to provide technical and App support</li>
                                                    <li>to provide notification of maintenance or any other App related alerts</li>
                                                    <li>to analyse how our services are being used</li>
                                                    <li>to evaluate, develop and improve our App and services</li>
                                                    <li>to investigate, prevent, or take action regarding illegal activities, suspected fraud and situations
                                                        involving potential threats to the safety of any personWhere the processing is based on your consent, you have
                                                        the right to withdraw your consent at any point in time. Upon receipt of your request to withdraw your
                                                        consent, the consequences of withdrawal will be communicated to you. In many cases, upon such a withdrawal,
                                                        we may not be able to continue offering our products and services to you. You may withdraw consent by
                                                        contacting us using the details specified in the ‘Contact Us’ section.
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </Panel>
                                </Collapse>
                            </div>
                            <h4 style={{
                                color: '#1C41CE', fontWeight: 'bold', marginTop: '30px',
                                marginBottom: '30px'
                            }}
                            >We might share the Personal Data with the following (Not restricted to only below)
                            </h4>

                            <p className="privacyterms" style={{ fontWeight: 600 }}
                            >People and Accounts that you Share and Communicate With</p>

                            <p className="privacyterms">Our App lets you post and share Personal Data, Diary logs, photos, Videos
                                and other content. By uploading data about yourself like photos, posts, likes, etc., others may be able to
                                view your profile and other uploaded content on the App. Any data you disclose publicly may
                                be collected and used by others, may be indexable by search engines, and might not be able
                                to be removed. Please be careful when disclosing Personal Data in these public areas.
                                You can control the audience who can view your profile & data submitted on the App, who
                                can message you and interact  with you by adjusting your In-App Privacy Settings. </p>

                            <p className="privacyterms" style={{ fontWeight: 600 }}
                            >Others</p>

                            <p className="privacyterms">We may also disclose your Personal Data to appropriate
                                authorities if we believe that it is reasonably necessary to comply with a law,
                                regulation, legal process; protect the safety of any person; address fraud or
                                security issues; or protect our rights or the rights of those who use our products
                                & services. We may disclose your Personal Data to law enforcement agencies,
                                government agencies, courts, dispute resolution bodies, regulators, auditors,
                                and any party appointed or requested by applicable regulators to carry out
                                investigations or audits of our activities.</p>

                            <div style={{ marginTop: '20px' }}>
                                <Collapse accordion>
                                    <Panel header="How Do We Secure Your Personal Data?" key="1">
                                        <p className="privacyterms">We are committed to protecting your Personal Data in our custody.
                                            We take reasonable steps to ensure appropriate physical, technical, and managerial safeguards
                                            are in place to protect your Personal Data from unauthorized access, alteration, transmission,
                                            and deletion. We train our employees about the importance of maintaining the privacy and security
                                            of your Personal Data. We ensure that the third parties who provide services to us under appropriate
                                            contracts take appropriate security measures to protect your Personal Data in line with our policies</p>
                                    </Panel>
                                    <Panel header="How Long Do We Keep Your Personal Data?" key="2">
                                        <p className="privacyterms">
                                            We retain your Personal Data for as long as it is required to fulfil the purposes outlined
                                            in this Privacy Notice and for legal or regulatory reasons.
                                        </p>
                                        <p className="privacyterms">
                                            All Personal Data collected from you at the time of registration will be retained for as long as
                                            your account remains in existence. When you delete your account, the admin holds the data for
                                            now and it will be deleted once the user makes a request by sending an email to our company
                                            .The things that you have posted, such as your photos and status updates, and you will not be
                                            able to recover this information later. Information that others have shared about you is not part
                                            of your account and will not be deleted.
                                        </p>
                                    </Panel>
                                    <Panel header="How Do We Ensure Children’s Privacy?" key="3">
                                        <p className="privacyterms">We do not knowingly collect Personal Data from children. If you are a parent or
                                            guardian and are aware that your child has provided us with their Personal Data, we request you to ask
                                            your child to delete their account. Alternately please contact us using the details in the ‘Contact us’
                                            section of this notice.</p>
                                    </Panel>
                                    <Panel header="How Do We Use Cookies and Other Tracking Mechanisms?" key="4">
                                        <p className="privacyterms">Our Website and application use cookies and other tracking mechanisms
                                            to collect data about you.</p>
                                        <p className="privacyterms">We collect Personal Data about you via our App using permissions such as camera,
                                            storage, contacts, phone. IOS and Android devices will notify you the permissions that our app seeks
                                            and give you an option to consent or refuse the permission. We use the data obtained through permissions
                                            to enhance the functionality of the App and to analyse it to serve you better.</p>
                                    </Panel>
                                </Collapse>
                            </div>
                            <p style={{
                                marginTop: '20px',
                                marginBottom: '20px'
                            }}>The permissions we take are for the following purposes:</p>

                            <table style={{
                                width: "100%", marginTop: '20px',
                                marginBottom: '20px'
                            }}>
                                <tr>
                                    <th>Permissions</th>
                                    <th>Features Provided based on Permission</th>
                                </tr>
                                <tr>
                                    <td>Camera</td>
                                    <td>Taking photos for uploading in posts, stories, chats etc.</td>
                                </tr>
                                <tr>
                                    <td>Contacts</td>
                                    <td>Syncing contacts for adding to friend list, call, chat etc.</td>
                                </tr>
                                <tr>
                                    <td>Gps Location</td>
                                    <td>To network with potential Users </td>
                                </tr>
                                <tr>
                                    <td>Gallery</td>
                                    <td>For Photographs, Videos </td>
                                </tr>
                                <tr>
                                    <td>Storage</td>
                                    <td>To store & access files</td>
                                </tr>
                            </table>

                            <p className="privacyterms">We also use trackers on our App to collect session data about
                                you. We receive information when you view content on or otherwise interact with our
                                services, which we refer to as “Log Data,". For example, when you sign into the App,
                                or make diary entries  using the App</p>

                            <p className="privacyterms">Cookies are small text files that are placed on your computer
                                by websites that you visit. We only use strictly necessary cookie for technical purpose
                                to keep our website secure and to comply with regulations that apply to us.</p>

                            <h4 style={{
                                color: '#1C41CE', fontWeight: 'bold', marginTop: '30px',
                                marginBottom: '30px'
                            }}>Post photos and that are appropriate for a diverse audience.</h4>
                            <p className="privacyterms">Livathon is a reflection of our diverse community of cultures, ages, and beliefs.
                                We’ve spent a lot of time thinking about the different points of view that create a safe and open
                                environment for everyone.</p>
                            <p className="privacyterms">We created the Community Guidelines so you can help us foster and protect this amazing comm
                                unity. By using Livathon, you agree to these guidelines and our Terms of Use. We’re committed to these guidelines
                                and we hope you are too. Overstepping these boundaries may result in deleted content, disabled accounts,
                                or other restrictions.In some cases, we allow content for public awareness which
                                would otherwise go against our Community Guidelines – if it is newsworthy and in
                                the public interest. We do this only after weighing the public interest value against the risk of harm and
                                we look to international human rights standards to make these judgments.
                            </p>
                            <p className="privacyterms" style={{ fontWeight: 600 }}
                            >Share only photos</p>
                            <p className="privacyterms">Share only photos that you’ve taken or have the right to share.
                                As always, you own the content you post on Livathon. Remember to post authentic content, and don’t post anything you’ve copied or collected from the Internet that you don’t have the right to post
                            </p>
                            <p className="privacyterms">We know that there are times when people might want to share nude images that are artistic or creative in nature, but for a variety of reasons, we don’t allow nudity on Livathon. This includes photos, and some digitally-created content that show sexual intercourse, genitals, and close-ups of fully-nude buttocks. It also includes some photos of female nipples, but photos in the context of breastfeeding, birth giving and after-birth moments, health-related situations (for example, post-mastectomy, breast cancer awareness or gender confirmation surgery) or an act of protest are allowed. Nudity in photos of paintings and sculptures is OK, too.</p>
                            <p className="privacyterms">People like to share photos of their children. For safety reasons, there are times when we may remove images that show nude or partially-nude children. Even when this content is shared with good intentions, it could be used by others in unanticipated ways. You can learn more on our Tips for Parents page.</p>
                            <p className="privacyterms" style={{ fontWeight: 600 }}>Foster meaningful and genuine interactions</p>
                            <p className="privacyterms">Help us stay spam-free by not artificially collecting likes, followers, or shares, posting repetitive comments or content, or repeatedly contacting people for commercial purposes without their consent. Don’t offer money or giveaways of money in exchange for likes, followers, comments or other engagement. Don’t post content that engages in, promotes, encourages, facilitates, or admits to the offering, solicitation or trade of fake and misleading user reviews or ratings.</p>
                            <p className="privacyterms">You don’t have to use your real name on Livathon, but we do require Livathon users to provide us with accurate and up to date information. Don't impersonate others and don't create accounts for the purpose of violating our guidelines or misleading others.</p>
                            <p className="privacyterms" style={{ fontWeight: 600 }}>Follow the law.</p>
                            <p className="privacyterms">Livathon is not a place to support or praise terrorism, organized crime, or hate groups. Offering sexual services, buying or selling firearms, alcohol, and tobacco products between private individuals, and buying or selling non-medical or pharmaceutical drugs are also not allowed. We also remove content that attempts to trade, co-ordinate the trade of, donate, gift, or ask for non-medical drugs, as well as content that either admits to personal use (unless in the recovery context) or coordinates or promotes the use of non-medical drugs. Livathon also prohibits the sale of live animals between private individuals, though brick-and-mortar stores may offer these sales. No one may coordinate poaching or selling of endangered species or their parts.
                                Remember to always follow the law when offering to sell or buy other regulated goods. Accounts promoting online gambling, online real money games of skill or online lotteries must get our prior written permission before using any of our products. We have zero tolerance when it comes to sharing sexual content involving minors or threatening to post intimate images of others.</p>
                            <p className="privacyterms" style={{ fontWeight: 600 }}>Respect other members of the Livathon community.</p>
                            <p className="privacyterms">We want to foster a positive, diverse community. We remove content that contains credible threats or hate speech, content that targets private individuals to degrade or shame them, personal information meant to blackmail or harass someone, and repeated unwanted messages. We do generally allow stronger conversation around people who are featured in the news or have a large public audience due to their profession or chosen activities.</p>
                            <p className="privacyterms">It's never OK to encourage violence or attack anyone based on their race, ethnicity, national origin, sex, gender, gender identity, sexual orientation, religious affiliation, disabilities, or diseases. When hate speech is being shared to challenge it or to raise awareness, we may allow it. In those instances, we ask that you express your intent clearly.</p>
                            <p className="privacyterms">Serious threats of harm to public and personal safety aren't allowed. This includes specific threats of physical harm as well as threats of theft, vandalism, and other financial harm. We carefully review reports of threats and consider many things when determining whether a threat is credible.</p>
                            <p className="privacyterms" style={{ fontWeight: 600 }}>Maintain our supportive environment by not glorifying self-injury.</p>
                            <p className="privacyterms">The Livathon community cares for each other, and is often a place where people facing difficult issues such as eating disorders, cutting, or other kinds of self-injury come together to create awareness or find support. We try to do our part by providing education in the app and adding information in the Help Center so people can get the help they need.</p>
                            <p className="privacyterms">Encouraging or urging people to embrace self-injury is counter to this environment of support, and we’ll remove it or disable accounts if it’s reported to us. We may also remove content identifying victims or survivors of self-injury if the content targets them for attack or humor.</p>
                            <p className="privacyterms" style={{ fontWeight: 600 }}>Be thoughtful when posting newsworthy events.</p>
                            <p className="privacyterms">We understand that many people use Livathon to share important and newsworthy events. Some of these issues can involve graphic images. Because so many different people and age groups use Livathon, we may remove images of intense, graphic violence to make sure Livathon stays appropriate for everyone.</p>
                            <p className="privacyterms">We understand that people often share this kind of content to condemn, raise awareness or educate. If you do share content for these reasons, we encourage you to caption your photo with a warning about graphic violence. Sharing graphic images for sadistic pleasure or to glorify violence is never allowed.</p>
                            <p className="privacyterms" style={{ fontWeight: 600 }}>Violence</p>
                            <p className="privacyterms">We don't allow content that depict or facilitate gratuitous violence or other dangerous activities. Content that depict fictional violence in the context of a game, such as cartoons, hunting or fishing, are generally allowed. </p>
                            <p className="privacyterms" style={{ fontWeight: 600 }}>Sexual Content and Profanity</p>
                            <p className="privacyterms">We don't allow content that contain or promote sexual content or profanity, including pornography, or any content or services intended to be sexually gratifying. We don’t allow   content that appear to promote a sexual act in exchange for compensation. </p>
                            <p className="privacyterms" style={{ fontWeight: 600 }}>Terrorist Content</p>
                            <p className="privacyterms">We do not permit terrorist organizations to publish content apps on Livathon  for any purpose, including recruitment. We don't allow content related to terrorism, such as content that promotes terrorist acts, incites violence, or celebrates terrorist attacks.
                            </p>
                            <p className="privacyterms" style={{ fontWeight: 600 }}
                            >What Are Your Privacy Rights?</p>

                            <p className="privacyterms">We provide many choices about the collection, use and sharing of your data,
                                from deleting or correcting data you include in your profile and controlling the visibility of your
                                posts to communication controls (for e.g., who can call you or chat with you).</p>

                            <p className="privacyterms" style={{ fontWeight: 600 }}>For Personal Data we have
                                about you, you can:</p>
                            <ul className="privacylist">
                                <li>Access the Personal Data: You have a right to access the data that you have provided. For e.g.,
                                    you can visit the profile page to view your profile details. You can also ask us for a copy of
                                    your Personal Data in machine readable form.</li>
                                <li>Change or Correct the Personal Data: You have a right to update or make
                                    corrections to data that you have provided. For e.g., when you upload a post on the
                                    App, you can edit the post at any time. The accuracy of such information is solely
                                    your responsibility</li>
                                <li>Delete the Personal Data: You have a right to delete the data that you have
                                    provided. For e.g., when you upload a post on the App, you can delete the post
                                    at any time. </li>
                                <li>You can delete your account from Livathon When you delete your account, we
                                    delete data that you have posted, such as your photos and status updates, and you
                                    will not be able to recover this data later. Data that others have shared about you
                                    is not part of your account and will not be deleted.</li>
                                <li>Object to, or Limit or Restrict, Use of Data: You can ask us to stop using all or some
                                    of your Personal Data (For e.g., if we have no lawful grounds to keep using it) or to limit our
                                    use of it (For e.g. if your Personal Data is inaccurate or unlawfully held).</li>
                            </ul>
                            <p className="privacyterms">You can contact us using the details in the 'Contact us'
                                section of this notice, and we will consider your request in accordance with applicable
                                laws.</p>

                            <p className="privacyterms" style={{ fontWeight: 600 }}>
                                Continuous Update of the Policy
                            </p>

                            <p className="privacyterms">
                                We regularly review and update our Privacy Notice to ensure it is up-to-date and
                                accurate. Any changes we may make to this Privacy Notice in the future will be
                                posted on this page. When we post changes to this Privacy Notice, we will revise the
                                “last updated” date.
                            </p>

                            <p className="privacyterms" style={{ fontWeight: 600 }}>
                                Contact us
                            </p>

                            <p className="privacyterms">
                                For any further queries and complaints related to privacy, or exercising your rights,
                                you could reach us a
                            </p>
                            <p className="privacyterms" style={{ fontWeight: 600 }}>
                                Contact Email Address: <a href="mailto:support@livathon.com"
                                    style={{ color: '#1C41CE' }}>
                                    support@livathon.com</a>
                            </p>
                            <p className="privacyterms" style={{ fontWeight: 600, marginBottom: '20px' }}>
                                Organization Contact Details: <br />
                                <span>Livathon</span> <br />
                                <span>Chennai, India </span>
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-xs-12">
                            <p className="text-center"
                                style={{ color: '#333' }}>@{this.state.new_year}, Livathon, All rights reserved</p>
                        </div>
                    </div>
                </div>

            </div>

        )

    }

}

function mapStateToProps(state, ownProps) {
    return { user: state.user };
}


export default connect(
    mapStateToProps,
    {}
)(PrivacyPolicy);