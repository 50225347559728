import React, { Component } from "react";
// import Navigation from '../common/header';
import Logo from '../assests/img/logo-new-dark.svg';
import {
    activateAccount
} from "../actions/userAction";
import {
    Form, Icon, Input,
    Button, message
} from 'antd';
import { Link } from "react-router-dom";
import { connect } from "react-redux";


class ActivateUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    enterLoading = () => {
        this.setState({ loading: true });
    };


    componentDidMount() {
        if (this.props.user !== null) {
            this.props.history.push('/feeds');
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.user !== null) {
            nextProps.history.push('/feeds');
        }
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleSubmit = e => {
        this.setState({
            iconLoading: true
        });
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                // console.log('Received values of form: ', values);
                this.props.activateAccount(this.state.email, this.state.password)
                    .then((success) => {
                        this.setState({
                            iconLoading: false
                        })
                        this.props.form.resetFields();
                        this.props.history.push("/feeds");
                    })
                    .catch((err) => {
                        console.log(err.a)
                        if (err.a === null) {
                            message.error("User does not exists! activate again");
                            this.setState({
                                iconLoading: false,
                                reactivate: err.a
                            })
                        } else {
                            message.error("Your Username or Password is incorrect! Try again.");
                            this.setState({
                                iconLoading: false
                            })
                        }
                    });
            } else {

                this.setState({
                    iconLoading: false
                });
            }
        })
    }


    render() {
        const { getFieldDecorator } = this.props.form;

        return (
            <div >
                <div className="container-fluid logincard">

                    <div className="row">
                        <div className="col-md-7 divbg hidden-xs">
                            <div className="centerdiv">
                                <h2>Reflect and Inspire with livathon</h2>
                                {/* Living is a digital diary network that can be used to save your memories. */}
                                <p>Livathon offers News Feed, logs and your memories.
                                    So, Why are you waiting for? Explore it now.</p>
                            </div>
                        </div>
                        <div className="col-md-5 bgright" style={{ marginBottom: '30px' }}>
                            <div className="text-center">
                                <Link to="/">
                                    <img src={Logo} className="img-responsive logoimg"
                                        alt="logo"
                                        style={{ height: '40px' }}
                                    /></Link>
                            </div>
                            <div style={{ padding: '40px', marginTop: '40px' }}>
                                <div style={{ marginBottom: '30px' }}>
                                    <h3 className="loginHead">Signin</h3>
                                    <div className="headline"></div>
                                </div>
                                <Form onSubmit={this.handleSubmit} className="login-form">
                                    <Form.Item>
                                        <label>Username:</label>
                                        {getFieldDecorator('username', {
                                            rules: [
                                                {
                                                    type: 'email',
                                                    message: 'The input is not valid E-mail!',
                                                },
                                                {
                                                    required: true,
                                                    message: 'Please input your E-mail!',
                                                },
                                            ],
                                        })(
                                            <Input
                                                prefix={<Icon type="user"
                                                    style={{ color: 'rgba(0,0,0,.25)' }} />}
                                                placeholder="Username"
                                                name="email"
                                                onChange={this.handleChange}
                                            />,
                                        )}
                                    </Form.Item>
                                    <Form.Item hasFeedback>
                                        <label>Password:</label>
                                        {getFieldDecorator('password', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Please input your Password!'
                                                },
                                                {
                                                    min: 6,
                                                    message: 'Password must be minimum 6 characters',
                                                },
                                            ],
                                        })(
                                            <Input.Password
                                                prefix={<Icon type="lock"
                                                    style={{ color: 'rgba(0,0,0,.25)' }} />}
                                                type="password"
                                                name="password"
                                                onChange={this.handleChange}
                                                placeholder="Password"
                                            />,
                                        )}
                                    </Form.Item>
                                    <Form.Item>
                                        <Button htmlType="submit"
                                            className="btn loginBtn color-1"
                                            loading={this.state.iconLoading}
                                            onClick={this.enterLoading}
                                            style={{ margin: '0' }}>
                                            Activate account
                                        </Button>
                                    </Form.Item>
                                    <div className="row">
                                        <div className="col-md-12"
                                            style={{ marginBottom: '20px' }}>
                                            <div className="col-md-12">
                                                <span className="login-form-forgot"
                                                    style={{ display: 'inline-block', fontWeight: '600' }}>
                                                    New here?</span>
                                                <Link className="registertext" to="/signup"
                                                    style={{ float: "Right" }}>
                                                    Register
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        )

    }

}

const WrappedActivate = Form.create({ name: 'normal_login' })(ActivateUser);
function mapStateToProps(state, ownProps) {
    return { user: state.user };
}


export default connect(
    mapStateToProps,
    { activateAccount }
)(WrappedActivate);