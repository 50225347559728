import React, { Component } from "react";
import {
  addFollowing,
  getFollowing,
  removeFollowing,
  getusernotfollowlist
} from "../actions/livingAction";
import {
  Card, Avatar, Button, message,
  Empty
} from "antd";
import { Link } from "react-router-dom";
import Noprofileimg from '../assests/img/d-avatar.jpg'
import { connect } from "react-redux";
import request from "superagent";
import MenuWidget from "./menuWidget"
import _ from "lodash";
import UserShimmer from "./userShimmer";
import { BASEURL } from '../actionTypes'


function shuffle(array) {
  var currentIndex = array.length, randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}


class UserProfiles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newusers: [],
      followingIndex: 0,
      followinglength: 0,
      arraylength: true,
      chunkedData: []
    };
  }


  getnotFollowing(uid) {

    var notfollowing = this.state.getfollowing;
    this.props.getusernotfollowlist(uid).then((res) => {
      console.log(res, res.length)
      if (res.length != 0) {
        var randomizeArray = shuffle(res);
        // console.log(randomizeArray)

        this.setState({
          newusers: randomizeArray,
          arraylength: false
        })

        notfollowing = _.chunk(this.state.newusers, 20)
        console.log(notfollowing.length);
        console.log(notfollowing)
        this.setState({
          newusers: notfollowing[this.state.followingIndex],
          followinglength: notfollowing.length,
          chunkedData: notfollowing
        })
      } else {
        this.setState({
          newusers: [],
          arraylength: false
        })
      }
    }).catch((err) => {
      this.setState({
        newusers: [],
        arraylength: false
      })
    });

    // var dataobj = {
    //   uid: uid,
    //   // Ref_Interest: interest.toString()
    // };
    // request
    //   .post(BASEURL + "/notfollowinglist")
    //   .send(dataobj)
    //   .then((res) => {
    //     if (res.body.ReturnCode === 200) {
    //       console.log(res.body.Data)
    //       this.setState({
    //         newusers: res.body.Data,
    //         arraylength: false
    //       });
    //     } else {
    //       this.setState({
    //         newusers: res.body.Data,
    //         arraylength: false
    //       });
    //     }
    //   })
    //   .catch((err) => { });
  }


  componentWillMount() {

    var uid = this.props.user.uid;

    this.getnotFollowing(uid);;

  }


  addnotFollowingData = async () => {
    var followersarray = this.state.newusers;
    var fullarray = followersarray;
    await this.setState({
      followingIndex: this.state.followingIndex + 1
    });
    var mergearray = _.concat(fullarray, this.state.chunkedData[this.state.followingIndex])
    this.setState({
      newusers: mergearray,
    })
    this.renderuserList();
  }


  handlefollowing = (followid) => {
    console.log(followid, this.state.newusers)
    var uid = this.props.user.uid;
    var newArray = this.state.newusers
    this.props.addFollowing(uid, followid).then((res) => {
      // console.log(res);
      var filteruser = _.filter(newArray, ['Id', followid]);
      console.log(filteruser)
      _.remove(newArray, ['Id', followid])

      message.success(filteruser[0].Username + " " + "added to your friend's list")
      this.getnotFollowing(uid);
      // console.log(newArray);
      // this.setState({
      //   newusers: newArray,
      // });

    });
  };


  // updateFollowing = (followid, type, res) => {
  //   var Actionupdated = this.state.newusers;
  //   var followType = "";
  //   if (type === "Follow") {

  //     followType = "Following"

  //   }
  //   // console.log(followid, Actionupdated);

  //   const elementsIndex = Actionupdated.findIndex((element) => element.Id === followid);
  //   // console.log(elementsIndex);
  //   let newArray = [...this.state.newusers];
  //   newArray[elementsIndex] = {
  //     ...newArray[elementsIndex],
  //     Follower_type: followType,
  //     Follower_id: this.props.user.uid,
  //     circle_id: res.Id
  //   };
  //   console.log(newArray);
  //   this.setState({
  //     newusers: newArray,
  //   });
  // };


  // updateUnfollow = (followid, type, res) => {
  //   var Actionupdated = this.state.newusers;
  //   // var followType = "";

  //   // console.log(followid, Actionupdated);

  //   const elementsIndex = Actionupdated.findIndex((element) => element.Id === followid);
  //   // console.log(elementsIndex);
  //   let newArray = [...this.state.newusers];
  //   newArray[elementsIndex] = {
  //     ...newArray[elementsIndex],
  //     Follower_type: "",
  //     Follower_id: "",
  //     circle_id: ""
  //   };
  //   console.log(newArray);
  //   this.setState({
  //     newusers: newArray,
  //   });
  // };


  handleunfollow = (id) => {
    // var uid = this.props.user.uid;
    // console.log(id);
    this.props.removeFollowing(id).then((res) => {
      // console.log(res);
      this.updateUnfollow(id, "Unfollow", "")
    });
  };


  renderuserList() {
    return _.map(this.state.newusers, (user, index) => {
      return (
        <li key={index} className="col-md-3 col-sm-4 col-xs-6 text-center">
          <div className="userbox">
            <Link
              to={"/profile/" + user.Id}
            >
              <span className="userphoto">

                {user.photoURL === undefined || user.photoURL === "" ? (
                  <Avatar
                    style={{
                      color: "#f56a00",
                      backgroundColor: "#fde3cf",
                      textTransform: "uppercase",
                    }}
                    size={64}
                    src={Noprofileimg}
                  >
                  </Avatar>
                ) : (
                  <Avatar src={user.photoURL} size={64} />
                )}
              </span>
            </Link>
            <br />
            <Link
              to={"/profile/" + user.Id}
            >
              <span className="userinfo" style={{ height: '42px' }}>
                {user.Username} <br />
              </span>
            </Link>
            {user.Follower_type === "" ? (
              <Button
                type="default"
                block
                className="follow"
                onClick={() => this.handlefollowing(user.Id)}
              >
                Follow
              </Button>
            ) : (
              <Button
                type="default"
                block
                className="userfollowing"
                onClick={() => this.handleunfollow(user.Id)}
              >
                Following
              </Button>
            )}
          </div>
        </li>
      );
    });
  }

  render() {
    const { newusers, arraylength,
      followinglength, followingIndex } = this.state;
    return (
      <div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-2 col-xs-12 sticky hidden-xs hidden-widget">
              <MenuWidget />
            </div>
            <div className="col-md-10 col-xs-12">
              <Card className="feedsuser" style={{ marginTop: "80px" }}>
                <h5>People you may know</h5>
                <ul className="list-unstyled row">
                  {newusers.length === 0 && arraylength === true ? (
                    <div>
                      <div className="row">
                        <div className="col-md-12">
                          <UserShimmer count={4} />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div>
                      {newusers.length === 0 ? (
                        <Empty
                          image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
                          imageStyle={{
                            height: 60,
                          }}
                          description={
                            <span>
                              No Users to show
                            </span>
                          } />
                      ) : (
                        <div>
                          {this.renderuserList()}
                          <div className="row">
                            <div className="col-md-12 text-center">
                              {followinglength - 1 > followingIndex ? (
                                <Button className="blockuser"
                                  onClick={() => this.addnotFollowingData()}>
                                  View More</Button>
                              ) : (
                                null
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </ul>
              </Card>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
    allusers: state.allusers,
  };
}

export default connect(mapStateToProps, {
  addFollowing,
  getFollowing,
  removeFollowing,
  getusernotfollowlist
})(UserProfiles);
