import React, { Component } from "react";
import { getProfile } from "../actions/userAction";
import { Card } from "antd";
import { connect } from "react-redux";
import noFeatured from '../assests/img/nofeaturefeed.svg'

class NoFeaturedCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: "",
        };
    }

    componentWillMount() {
        var uid = this.props.user.uid;
        this.props.getProfile(uid).then((res) => {
            // console.log(res);
            this.setState({
                username: res.First_Name,
            });
        });
    }

    render() {
        // console.log(this.props.allusers);
        var time = new Date().getHours();
        return (
            <div>
                <Card>
                    <div className="nofeeds">
                        <img src={noFeatured} alt="No feeds" />
                        {this.state.username !== "" ? (
                            <div>
                                {time < 12 ? (
                                    <h4>Good Morning, {this.state.username} nice to have you in Livathon!  </h4>
                                ) : (
                                    <div>
                                        {time < 18 ? (
                                            <h4>Good Afternoon, {this.state.username} nice to have you in Livathon!</h4>
                                        ) : (
                                            <h4>Good Evening, {this.state.username} nice to have you in Livathon!</h4>
                                        )}
                                    </div>
                                )}
                            </div>
                        ) : null}
                        <p>Post your first Log or view your feeds in following tab</p>
                    </div>
                </Card>
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps, {
    getProfile,
})(NoFeaturedCard);
