import React, { Component } from "react";
import {
    getuserportfolioLogs, postActions,
    getImageportfolioLogs, getFeedsLimit,
} from "../actions/livingAction";
import { createAccount, getallUserList } from "../actions/userAction";
import {
    Card, Avatar, Empty, Modal, Popconfirm,
    Button, Icon, Tabs, Spin, Dropdown, message
} from "antd";
import { Carousel } from "react-responsive-carousel";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
// import EmptyImage from "../assests/img/profile-upload.png"
import _ from "lodash";
import Linkify from 'react-linkify';
import moment from "moment";
import request from "superagent";
import Noprofileimg from '../assests/img/d-avatar.jpg'
import { BASEURL } from '../actionTypes'
import InfinitScroll from 'react-infinite-scroll-component'
import GridShimmer from './gridShimmer';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
    FacebookShareButton,
    TwitterShareButton,
    LinkedinShareButton,
    WhatsappShareButton,
} from "react-share"
import { Passers } from "prop-passer";
import { db } from "../firebase";



const { TabPane } = Tabs;

class GridUserPortFolio extends Component {
    constructor(props) {
        super(props);
        this.state = {
            citylist: "",
            userlist: "",
            portfoliologs: [],
            visible: false,
            activeimgset: "",
            slideIndex: 1,
            activeuser: "",
            photoURL: "",
            First_Name: "",
            Last_Name: "",
            Full_Name: "",
            hasMore: true,
            bio: "",
            currentSlide: 0,
            imageportfolio: [],
            urlvalue: window.location.origin + "/post/",
            lastrecord: 0,
            limitrequest: 0,
            arraylength: true,
            copied: false
        };
        this.getPortfolio = this.getPortfolio.bind(this)
    }


    showModal = (index) => {
        this.setState({
            visible: true,
            currentSlide: index,
        });
        // console.log(index)
    };

    imageModal = images => {
        this.setState({
            visible1: true,
            activeimgset: images
        });
    };

    showreactionmodal = (reactions) => {
        this.setState({
            visible2: true,
            activereactions: reactions,
        })
    };

    confirm = (id) => {
        this.deleteimage(id)
        // message.success('Log deleted');
    }

    cancel = (e) => {
        console.log(e);
        // message.error('Click on No');
    }

    //delete log image by id & value
    deleteimage = (id) => {
        console.log(id, this.state.portfoliologs)
        var newArray = [...this.state.portfoliologs];
        db.collection("Portfolio_Logs")
            .doc(id)
            .delete()
            .then(() => {
                message.error("Post deleted successfully")

                const idToRemove = id;

                _.remove(newArray, ['Id', idToRemove])

                console.log(newArray);
                this.setState({
                    portfoliologs: newArray,
                    visible: false,
                });
            })
            .catch(function (error) {
                console.error("Error removing document: ", error);
            });
    }


    onChange(a, b, c) {
        // console.log(a, b, c);
    }

    handleCancel = e => {
        // console.log(e);
        this.setState({
            visible: false
        });
        setTimeout(
            function () {
                this.setState({
                    activeimgset: ""
                });
            }.bind(this),
            150
        );
    };

    getPortfolio() {
        var dataobj = {
            uid: this.props.user.uid,
            limitperrequest: this.state.limitrequest,
            posttype: "portfolio",
            lastrecord: this.state.lastrecord
        };
        // console.log("Query", dataobj);
        var existingfeeds = this.state.portfoliologs;
        request
            .post(BASEURL + "/getuserfeed")
            // .post(BASEURL + "/getfeedinfo")
            .send(dataobj)
            .then((res) => {
                if (res.body.ReturnCode === 200) {
                    // console.log(res.body.Data)
                    if (res.body.Data.length !== 0) {
                        this.setState({
                            portfoliologs: _.concat(existingfeeds, res.body.Data),
                            lastrecord: res.body.Data.length > 0 ? (res.body.Data[res.body.Data.length - 1].created_timestamp) : 0,
                            hasMore: true,
                            arraylength: false,
                        });
                    }
                    else {
                        this.setState({
                            hasMore: false,
                            arraylength: false
                        })
                    }
                    // var portfolioArrayLogs = res.body.Data
                    // // console.log(portfolioArrayLogs);
                    // var images = _.filter(portfolioArrayLogs, { upload_logs_image: [] });
                    // var postimages = [];
                    // var itemprocessed = 0;
                    // portfolioArrayLogs.forEach(function (post) {
                    //     if (post.upload_logs_image.length !== 0) {
                    //         postimages.push({
                    //             images: post.upload_logs_image
                    //         })
                    //     }
                    //     itemprocessed++
                    //     console.log(postimages);
                    //     if (itemprocessed === portfolioArrayLogs.length) {
                    //         this.setState({
                    //             imageportfolio: postimages,
                    //             portfoliologs: portfolioArrayLogs,
                    //             hasMore: false,
                    //             arraylength: false,
                    //         });
                    //     }

                    // }.bind(this))
                    // //   this.setState({
                    // //     imageportfolio: res.body.Data,
                    // //     // userPhoto: profileImage
                    // //   });
                } else {
                    this.setState({
                        portfoliologs: res.body.Data,
                        arraylength: false,
                    });
                }

            })
            .catch((err) => { });
    }

    postLiveAction = (logid, authorid, uid, type) => {
        var target = this.state.feeds;
        var acted = _.filter(target, {
            Id: logid,
        });
        var actionarr = acted[0].Action;
        var actedAction = _.filter(actionarr, {
            user_Id: uid,
        });
        console.log(acted, actionarr, actedAction);

        if (actedAction.length >= 1) {
            var filtered = _.filter(actionarr, {
                user_Id: uid,
                actionType: type,
                author: authorid,
            });
            var evens = _.remove(actionarr, function (n) {
                return n.user_Id === uid;
            });
            // console.log(evens, filtered, actionarr);
            if (filtered.length === 0) {
                actionarr.push({
                    user_Id: uid,
                    actionType: type,
                    author: authorid,
                });
                console.log(actionarr);
                this.updatelogs(acted, actionarr, target, logid);
            } else {
                this.updatelogs(acted, actionarr, target, logid);
            }
        } else {
            // console.log(actionarr);
            actionarr.push({
                user_Id: uid,
                actionType: type,
                author: authorid,
            });
            // console.log(actionarr);
            this.updatelogs(acted, actionarr, target, logid, authorid);
        }
        // console.log(actionarr);
    };

    updatelogs = (acted, actionarr, target, logid) => {
        var Actionupdated = actionarr;
        // console.log(actionarr, Actionupdated);

        const elementsIndex = target.findIndex((element) => element.Id === logid);
        // console.log(elementsIndex);
        let newArray = [...this.state.portfoliologs];
        newArray[elementsIndex] = {
            ...newArray[elementsIndex],
            action: actionarr,
        };
        // console.log(newArray);
        this.setState({
            portfoliologs: newArray,
        });
    };

    postsaction = (logid, authorid, uid, type, actions, actionfig) => {
        // console.log(logid, authorid, uid, type, actions, actionfig)
        // this.postLiveAction(logid, authorid, uid, type, actions);
        var actionarr = actions;
        var subcollectionid = _.filter(actionarr, {
            User_id: uid,
        });
        // console.log(subcollectionid);
        var subid = subcollectionid.length === 0 ? "" : subcollectionid[0].subid;
        this.props.postActions(logid, authorid, uid, type, subid, actionfig).then((res) => {
            // console.log(res);
            var target = this.state.portfoliologs;
            var id = logid;

            var updatedActionindex = actions.findIndex((element) => element.User_id === res.User_id);
            // console.log(updatedActionindex)
            if (actionfig === 2 || actionfig === 4) {
                actions.splice(updatedActionindex, 1);
                actions = _.remove(actions, function (n) {
                    return n.User_id !== uid;
                })
            } else {
                if (updatedActionindex < 0) {
                    actions.push({
                        Created_Date: res.Created_Date,
                        User_id: res.User_id,
                        actionType: res.actionType.toString(),
                        read: res.read,
                        Updated_Date: res.Updated_Date,
                        subid: res.subid
                    })
                } else {
                    actions[updatedActionindex] = {
                        Created_Date: res.Created_Date,
                        User_id: res.User_id,
                        actionType: res.actionType.toString(),
                        read: res.read,
                        Updated_Date: res.Updated_Date,
                        subid: res.subid
                    }
                }
            }

            const elementsIndex = target.findIndex((element) => element.Id === id);
            // console.log(elementsIndex, actions);

            let newArray = [...this.state.portfoliologs];
            newArray[elementsIndex] = {
                ...newArray[elementsIndex],
                action: actions,
            };
            // console.log(newArray);
            this.setState({
                portfoliologs: newArray,
            });
        });
    };

    getLikes = (actions) => {
        var likes = _.filter(actions, ["actionType", "true"]);
        var liketext = "";
        if (likes.length >= 2) {
            liketext = ""
        } else {
            liketext = ""
        }
        var likes = this.abbreviateNumber(likes.length)
        // console.log(likes)
        return likes;
        // return likes.length + " " + liketext;
    };


    getdisLikes = (actions) => {
        var dislikes = _.filter(actions, ["actionType", "false"]);
        var disliketext = "";
        if (dislikes.length >= 2) {
            disliketext = ""
        } else {
            disliketext = ""
        }
        var dislikes = this.abbreviateNumber(dislikes.length)
        return dislikes;
        // return dislikes.length + " " + disliketext;
    };


    getIsLiked = (actions, user) => {
        var isliked = _.filter(actions, { User_id: user, actionType: "true" });
        var isdisliked = _.filter(actions, { User_id: user, actionType: "false" });
        // console.log(isliked, isdisliked, actions, user);
        if (isliked.length >= 1) {
            return 2;
        } else if (isdisliked.length >= 1) {
            return 5;
        } else {
            return 1
        }
    };


    getIsdisLiked = (actions, user) => {
        var isliked = _.filter(actions, { User_id: user, actionType: "true" });
        var isdisliked = _.filter(actions, { User_id: user, actionType: "false" });
        // console.log(isliked, isdisliked, actions, user);
        if (isdisliked.length >= 1) {
            return 4;
        } else if (isliked.length >= 1) {
            return 5;
        } else {
            return 3;
        }
    };


    abbreviateNumber = (value) => {
        var newValue = value;
        if (value >= 1000) {
            var suffixes = ["", "k", "M", "B", "T"];
            var suffixNum = Math.floor(("" + value).length / 3);
            var shortValue = '';
            for (var precision = 2; precision >= 1; precision--) {
                shortValue = parseFloat((suffixNum !== 0 ?
                    (value / Math.pow(1000, suffixNum)) : value).toPrecision(precision));
                var dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g, '');
                if (dotLessShortValue.length <= 2) { break; }
            }
            if (shortValue % 1 !== 0) shortValue = shortValue.toFixed(1);
            newValue = shortValue + " " + suffixes[suffixNum];
        }
        // console.log(newValue)
        return newValue;
    }


    componentWillMount() {
        var uid = this.props.user.uid;

        this.props.getFeedsLimit().then(res => {
            // console.log(res.feedperRquest);
            this.setState({
                limitrequest: res.feedperRquest,
                loading: true
            })
            this.getPortfolio(this.props.user.uid, this.state.limitrequest)
            this.setState({
                loading: false
            })
        });
    }

    renderUserInfo(username, userimg, created_timestamp) {
        var timestamp = new Date(created_timestamp * 1000);
        // console.log(timestamp);
        return (
            <div className="postuserinfo">
                <span className="userphoto">
                    {userimg === "" ? (
                        <Avatar
                            style={{ color: "#f56a00", backgroundColor: "#fde3cf" }}
                            size={46} src={Noprofileimg}
                        >
                            {/* {username.charAt(0)} */}
                        </Avatar>
                    ) : (
                        <Avatar src={userimg} size={46} />
                    )}
                </span>

                <span
                    className="userinfo"
                    style={{
                        marginLeft: "10px",
                        fontWeight: "600",
                        fontSize: "14px",
                        textAlign: 'left'
                    }}
                >
                    {username}
                    <br />
                    <span className="memcity">
                        {moment(timestamp).startOf('seconds').fromNow()}
                    </span>
                </span>
            </div>
        );
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.portfoliologs.length !== this.state.portfoliologs.length) {
            this.setState({
                portfoliologs: this.state.portfoliologs
            })
        }
    }

    updateCurrentSlide = (index) => {
        const { currentSlide } = this.state;

        if (currentSlide !== index) {
            this.setState({
                currentSlide: index,
            });

        }
        console.log(index)
    }

    renderPortfolioLogsList() {
        return (
            <InfinitScroll
                dataLength={this.state.portfoliologs.length}
                next={this.getPortfolio}
                hasMore={this.state.hasMore === true ? true : false}
                loader={<div className="text-center"><Spin tip="Loading..." /></div>}
                endMessage={<div className="row"><p className="text-center">All Caught Up</p></div>}
                initialScrollY="600px"
            >
                <div className="profileview">
                    {this.state.portfoliologs.map((logs, index) => (
                        <div key={index}>
                            {logs.upload_logs_image.length === 0 ? null : (
                                <div className="viewlayout">
                                    <div className="postimg">
                                        <div className="gridcontainer">
                                            <img src={logs.upload_logs_image[0].image}
                                                onClick={() => this.showModal(index)}
                                                className="gridimage img1" alt="" />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    )
                    )}
                </div>
            </InfinitScroll>
        )
    }


    onCopy = () => {
        this.setState({
            copied: true,
        });
        message.success("Copied shared link")
    };


    renderMenu(Id, type) {
        const {
            url = this.state.urlvalue + Id,
            title = "See the livathon post",
            shareImage = "https://www.steadylearner.com/static/images/brand/prop-passer.png",
            size = "2.5rem",
        } = this.props;


        const ShareList = Passers({
            url,
            className: "network__share-button",
        })({
            className: "network cursor-pointer hover transition--default",
            title: `Share ${String(this.state.loc)}`,
        })("li");

        const iconFont = {
            fontSize: '14px'
        }

        return (
            <ul className="feedpost-drop">
                <li>
                    <Popconfirm
                        title={"Do you want to delete this post?"}
                        icon={
                            <Icon type="question-circle-o" style={{ color: 'red' }} />}
                        onConfirm={() => this.confirm(Id)}
                        onCancel={() => this.cancel()}
                        okText="Yes"
                        placement="bottom"
                        cancelText="No">
                        <span style={{ margin: 0 }}>
                            <i class="fas fa-trash" style={iconFont}></i> Delete this post</span>
                    </Popconfirm>
                </li>
                <ShareList>
                    <FacebookShareButton
                        windowWidth={750}
                        windowHeight={600}
                        url={"https://livathon.com/" + "/post" + Id}
                        quote={"See the livathon Post"}
                    >
                        <i class="fab fa-facebook" style={iconFont}></i> Share to facebook
                    </FacebookShareButton>
                </ShareList>
                <ShareList>
                    <TwitterShareButton
                        windowWidth={750}
                        windowHeight={600}
                        title={title}
                        url={url}
                    >
                        <i class="fab fa-twitter" style={iconFont}></i> Share to twitter
                    </TwitterShareButton>
                </ShareList>
                <ShareList>
                    <WhatsappShareButton
                        windowWidth={750}
                        windowHeight={600}
                        title={"See the livathon post"}
                        url={url}
                        separator=":: "
                    >
                        <i class="fab fa-whatsapp-square" style={iconFont}></i>  share to whatsapp
                    </WhatsappShareButton>
                </ShareList>
                <ShareList>
                    <LinkedinShareButton
                        title={title}
                        url={"https://livathon.com/" + "/post" + Id}
                        windowWidth={750}
                        windowHeight={600}
                    >
                        <i class="fab fa-linkedin-in" style={iconFont}></i> Share to linkedIn
                    </LinkedinShareButton>
                </ShareList>
                <li>
                    <CopyToClipboard onCopy={this.onCopy} text={this.state.urlvalue + Id}>
                        <span><i class="fas fa-copy" style={iconFont}></i> Copy link</span>
                    </CopyToClipboard>
                </li>
            </ul>
        )
    }

    renderModalimages() {

        // var totalData =
        //     this.props.portfoliologs === "" ? null || undefined : this.props.portfoliologs;

        const componentDecorator = (href, text, key) => (
            <a href={href} key={key} rel="noopener noreferrer" target="_blank">
                {text}
            </a>
        );

        return (
            <Carousel
                selectedItem={this.state.currentSlide}
                // onChange={this.updateCurrentSlide}
                // showArrows={false}
                renderArrowPrev={(onClickHandler, hasPrev, label) =>
                    hasPrev && (
                        <Button type="primary" onClick={onClickHandler} title={label}
                            className="arrowstyles"
                            style={{ left: 0 }}>
                            <Icon type="left" />
                        </Button>
                    )
                }
                renderArrowNext={(onClickHandler, hasNext, label) =>
                    hasNext && (
                        <Button type="primary" onClick={onClickHandler} title={label}
                            className="arrowstyles"
                            style={{ right: 0 }}>
                            <Icon type="right" />
                        </Button>
                    )
                }
            >
                {_.map(this.state.portfoliologs, (logs, index) => {
                    return (
                        <div className="row" key={index}>
                            <div className="col-md-12" style={{ padding: 0 }}>
                                <div className="col-md-8" style={{ padding: 0 }}>
                                    <div>
                                        {logs.upload_logs_image.length === 0 ? (
                                            <Empty />
                                        ) : (
                                            <Carousel>
                                                {_.map(logs.upload_logs_image, (logImage, index) => {
                                                    return (
                                                        <div className="carouselimg" key={index}>
                                                            <div className="carouselimginner">
                                                                <img src={logImage.image}
                                                                    className="imgsliebanner" alt="" />
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </Carousel>
                                        )}
                                    </div>
                                </div>
                                <div className="col-md-4 gridlayout slidetext">
                                    <Card
                                        title={this.renderUserInfo(
                                            logs.username,
                                            logs.userimg,
                                            logs.created_timestamp
                                        )}
                                        extra={
                                            <Dropdown trigger={["click"]} placement="bottomRight"
                                                overlay={this.renderMenu(logs.Id, logs.posttype)}
                                                style={{ marginLeft: '10px' }}>
                                                <a className="ant-dropdown-link" href="#"
                                                    onClick={e => e.preventDefault()}>
                                                    <Icon type="more" style={{ fontSize: '20px', color: '#333' }} />
                                                </a>
                                            </Dropdown>
                                        }
                                        style={{
                                            border: 0,
                                            boxShadow: 'none',
                                            padding: "5px"
                                        }}>
                                        {logs.log_list.length === 0 ? (
                                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}
                                                description="No Text" />
                                        ) : (
                                            <div style={logs.log_list.length === 0 ? { padding: '0' } : { padding: '15px' }}>
                                                {_.map(logs.log_list, (log, index) => {
                                                    return (
                                                        <div className="readdiv" key={index}
                                                            style={{
                                                                wordBreak: 'break-all',
                                                                whiteSpace: 'pre-line',
                                                                marginBottom: '10px',
                                                                display: "block",
                                                                textAlign: 'left'
                                                            }}>
                                                            <Linkify componentDecorator={componentDecorator}>
                                                                {log.description}
                                                            </Linkify>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        )}
                                    </Card>
                                    <div className="postaction">
                                        <div className="postactionbtn">
                                            <div className="actioncount">
                                                {logs.action.length === 0 ? null : (
                                                    <div>
                                                        <div className="spacereactors"
                                                            onClick={() => this.showreactionmodal(logs.action)}>

                                                            <span className="badge-success">
                                                                {this.getLikes(logs.action)}</span>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            <Button
                                                type="link"
                                                className={this.getIsLiked(
                                                    logs.action,
                                                    this.props.user.uid)
                                                    === 1 ? "Like" : this.getIsLiked(
                                                        logs.action,
                                                        this.props.user.uid)
                                                        === 5 ? "Like" : "Liked"}
                                                onClick={() =>
                                                    this.postsaction(logs.Id, logs.author, this.props.user.uid, true,
                                                        logs.action, this.getIsLiked(
                                                            logs.action,
                                                            this.props.user.uid))
                                                }>
                                                <Icon type="caret-up" className="margin likeicon"
                                                    theme="filled" />
                                            </Button>
                                        </div>
                                        <div className="postactionbtn">
                                            <Button
                                                type="link"
                                                className={this.getIsdisLiked(
                                                    logs.action,
                                                    this.props.user.uid
                                                ) === 3 ? "DisLike" : this.getIsdisLiked(
                                                    logs.action,
                                                    this.props.user.uid
                                                ) === 5 ? "DisLike" : "DisLiked"}
                                                onClick={() =>
                                                    this.postsaction(logs.Id, logs.author, this.props.user.uid,
                                                        false, logs.action, this.getIsdisLiked(
                                                            logs.action, this.props.user.uid
                                                        ))
                                                }
                                            >
                                                <Icon type="caret-down"
                                                    className="margin likeicon" theme="filled" />

                                            </Button>
                                            <div className="actioncount">
                                                {logs.action.length === 0 ? null : (
                                                    <div>
                                                        <div className="spacereactors">
                                                            <span className="badge-danger">
                                                                {this.getdisLikes(logs.action)}</span>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </Carousel>
        );
    }

    renderModalReactions() {
        // console.log(this.state.activereactions);
        return (
            <Tabs defaultActiveKey="1" onChange={this.callback} animated={true}>
                {/* <TabPane tab="Likes" key="1">
              {this.getAllReactions()}
            </TabPane> */}
                <TabPane tab="Likes" key="1">
                    <div>
                        {this.getLikeReactions()}
                    </div>
                </TabPane>
                {/* <TabPane tab="DisLikes" key="3">
              {this.getDisLikeReactions()}
            </TabPane> */}
            </Tabs>
        );
    }

    render() {
        const { portfoliologs, arraylength } = this.state
        return (
            <div>
                <Modal
                    className="gridmodal carouselmodal"
                    title=""
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    destroyOnClose={true}
                    footer={null}
                    centered={true}
                    width="70%"
                >
                    {this.renderModalimages()}
                </Modal>
                <div className="row">
                    {portfoliologs.length === 0 && arraylength === true ? (
                        <div>
                            <div className="row">
                                <div className="col-md-12">
                                    <GridShimmer count={4} />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div>
                            {portfoliologs.length === 0 ? (
                                <Empty />
                            ) : (
                                <div>
                                    {this.renderPortfolioLogsList()}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        user: state.user,
        allusers: state.allusers,
        portfoliologs: state.portfoliologs,
        imagePortfolio: state.imageportfolio
    };
}

export default connect(mapStateToProps, {
    getuserportfolioLogs,
    getImageportfolioLogs,
    createAccount,
    getallUserList,
    postActions,
    getFeedsLimit
})(GridUserPortFolio);
