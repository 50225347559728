import React, { Component } from "react";
import {
  getallLogs,
  getallportfolioLogs,
  postActions,
  reportPost,
  getFeedsLimit
} from "../actions/livingAction";
import { getallUsers, cityList } from "../actions/userAction";
import {
  Card, Avatar,
  Empty,
  Modal,
  Button,
  Popconfirm,
  Icon,
  Tabs,
  Form,
  Radio,
  Spin,
  message,
  Tooltip,
  Menu,
  Dropdown,
  Drawer,
} from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import _ from "lodash";
import moment from "moment";
import { Carousel } from "react-responsive-carousel";
import request from "superagent";
import Shimmer from "./shimmer";
import NoFeedCard from "./nofeedcard";
import LazyImage from './lazyImage';
import Noprofileimg from '../assests/img/d-avatar.jpg'
import Linkify from 'react-linkify';
import { db } from "../firebase";
import { BASEURL } from '../actionTypes'
import InfinitScroll from 'react-infinite-scroll-component'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
} from "react-share"
import { Passers } from "prop-passer";


const { TabPane } = Tabs;

class FeedsPost extends Component {
  constructor(props) {
    super(props);
    this.state = {
      citylist: "",
      userlist: "",
      personallogs: "",
      portfoliologs: "",
      visible: false,
      visible1: false,
      activereactions: "",
      activeimgset: "",
      slideIndex: 1,
      allposts: "",
      loading: false,
      hasMore: true,
      active: false,
      lastrecord: 0,
      currentSlide: 0,
      limitrequest: 0,
      arraylength: true,
      author: "",
      id: "",
      urlvalue: window.location.origin + "/post/",
      posttype: "",
      visibleDrawer: false,
      feeds: [],
      personalfeed: "",
      postimages: false,
      visibleimages: [],
      loginuser: this.props.user.uid,
      copied: false
    };
    this.getfeedPost = this.getfeedPost.bind(this)
  }

  isBottom(el) {
    return el.getBoundingClientRect().bottom <= window.innerHeight;
  }

  // componentDidMount() {
  //   document.addEventListener("scroll", this.trackScrolling);
  //   console.log(this.props.user.uid);
  // }

  // componentWillUnmount() {
  //   document.removeEventListener("scroll", this.trackScrolling);
  // }

  getfeedPost() {

    var dataobj = {
      uid: this.props.user.uid,
      limitperrequest: this.state.limitrequest,
      lastrecord: this.state.lastrecord
    };
    var existingfeeds = this.state.feeds;
    // console.log("Query", dataobj);
    request
      //.post(BASEURL + "/getinfobyuid")
      //.post(BASEURL + "/getinfobyuidnew")
      .post(BASEURL + "/getfeedinfo")
      .send(dataobj)
      .then((res) => {
        // console.log(res);
        if (res.body.ReturnCode === 200) {
          // console.log(res.body.Data)
          var portfoliofeeds = _.filter(res.body.Data, { posttype: 'portfolio' });
          // console.log(portfoliofeeds)
          if (res.body.Data.length === 0) {
            this.setState({
              hasMore: false,
              arraylength: false
            })
          }
          else {
            this.setState({
              feeds: _.concat(existingfeeds, portfoliofeeds),
              lastrecord: portfoliofeeds.length > 0 ? (portfoliofeeds[portfoliofeeds.length - 1].created_timestamp) : 0,
              hasMore: true,
              arraylength: false
            });

          }
        } else {
          this.setState({
            feeds: portfoliofeeds,
            loading: false,
            arraylength: false
          });
        }
      })
      .catch((err) => { });
  }

  componentWillMount() {
    // var uid = this.props.user.uid;
    this.props.getFeedsLimit().then(res => {
      // console.log(res.feedperRquest);
      this.setState({
        limitrequest: res.feedperRquest,
        loading: true
      })
      this.getfeedPost(this.props.user.uid, this.state.limitrequest)
      this.setState({
        hasMore: false
      })
    });
  }

  // trackScrolling = () => {
  //   const wrappedElement = document.getElementById("header");
  //   if (this.isBottom(wrappedElement)) {
  //     console.log("header bottom reached");
  //     document.removeEventListener("scroll", this.trackScrolling);
  //   }
  // };


  showModal = (images, index) => {
    this.setState({
      visible: true,
      activeimgset: images,
      currentSlide: index
    });
  };

  postModal = (images, index) => {
    console.log(images)
    this.setState({
      postimages: true,
      visibleimages: images,
      currentSlide: index
    });
  };

  showDrawer = (postid, authorid, uid, type) => {
    console.log(postid, authorid, uid, type)
    this.setState({
      visibleDrawer: true,
      author: authorid,
      id: postid,
      user: uid,
      posttype: type,
    });
  };


  showreactionmodal = (reactions) => {
    this.setState({
      visible1: true,
      activereactions: reactions,
    });
  };

  onChange(a, b, c) {
    console.log(a, b, c);
  }

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
      postimages: false,
    });
    setTimeout(
      function () {
        this.setState({
          activeimgset: "",
          visibleimages: []
        });
      }.bind(this),
      150
    );
  };


  refreshFeeds = () => {
    setTimeout(function () {
      window.location.reload(false);
    }, 200);
  }

  handlereactionCancel = (e) => {
    console.log(e);
    this.setState({
      visible1: false,
    });
    setTimeout(
      function () {
        this.setState({
          activereactions: "",
        });
      }.bind(this),
      150
    );
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    var post = this.state.id;
    var reportedid = this.state.author;
    var reporterid = this.props.user.uid;
    var typepost = this.state.posttype;
    var target = this.state.feeds;

    await this.props.form.validateFields((err, values) => {
      if (err && post === "", reportedid === "" && reporterid === "" && typepost === "") {
        // console.log('Received values of form: ', values, this.state.id, this.state.author, this.props.user.uid, this.state.posttype);
        message.error("Please ignore if you are not reporting this post")
        this.setState({
          visibleDrawer: false
        })
      }
      else {
        this.props.reportPost(values, this.state.id, this.state.author, this.props.user.uid, this.state.posttype)
          .then((res) => {
            console.log(res);
            this.setState({
              visibleDrawer: false
            })
            message.success("Thanks for your feedback")
            let newArray = [...this.state.feeds];
            _.remove(newArray, ['Id', this.state.id])

            console.log(newArray);
            this.setState({
              feeds: newArray,
            });
          })
          .catch((err) => {
            console.log(err)
          })
      }
    });
  };
  ;

  postLiveAction = (logid, authorid, uid, type) => {
    var target = this.state.feeds;
    var acted = _.filter(target, {
      Id: logid,
    });
    var actionarr = acted[0].Action;
    var actedAction = _.filter(actionarr, {
      user_Id: uid,
    });
    console.log(acted, actionarr, actedAction);

    if (actedAction.length >= 1) {
      var filtered = _.filter(actionarr, {
        user_Id: uid,
        actionType: type,
        author: authorid,
      });
      var evens = _.remove(actionarr, function (n) {
        return n.user_Id === uid;
      });
      // console.log(evens, filtered, actionarr);
      if (filtered.length === 0) {
        actionarr.push({
          user_Id: uid,
          actionType: type,
          author: authorid,
        });
        console.log(actionarr);
        this.updatelogs(acted, actionarr, target, logid);
      } else {
        this.updatelogs(acted, actionarr, target, logid);
      }
    } else {
      // console.log(actionarr);
      actionarr.push({
        user_Id: uid,
        actionType: type,
        author: authorid,
      });
      // console.log(actionarr);
      this.updatelogs(acted, actionarr, target, logid, authorid);
    }
    // console.log(actionarr);
  };


  updatelogs = (acted, actionarr, target, logid) => {
    var Actionupdated = actionarr;
    // console.log(actionarr, Actionupdated);

    const elementsIndex = target.findIndex((element) => element.Id === logid);
    // console.log(elementsIndex);
    let newArray = [...this.state.feeds];
    newArray[elementsIndex] = {
      ...newArray[elementsIndex],
      Action: actionarr,
    };
    // console.log(newArray);
    this.setState({
      feeds: newArray,
    });
  };


  postsaction = (logid, authorid, uid, type, actions, actionfig) => {
    // console.log(logid, authorid, uid, type, actions, actionfig)
    // this.postLiveAction(logid, authorid, uid, type, actions);
    var actionarr = actions;
    var subcollectionid = _.filter(actionarr, {
      User_id: uid,
    });
    // console.log(subcollectionid);
    var subid = subcollectionid.length === 0 ? "" : subcollectionid[0].subid;
    this.props.postActions(logid, authorid, uid, type, subid, actionfig).then((res) => {
      // console.log(res);
      var target = this.state.feeds;
      var id = logid;

      var updatedActionindex = actions.findIndex((element) => element.User_id === res.User_id);
      // console.log(updatedActionindex)
      if (actionfig === 2 || actionfig === 4) {
        actions.splice(updatedActionindex, 1);
        actions = _.remove(actions, function (n) {
          return n.User_id !== uid;
        })
      } else {
        if (updatedActionindex < 0) {
          actions.push({
            Created_Date: res.Created_Date,
            User_id: res.User_id,
            actionType: res.actionType.toString(),
            read: res.read,
            Updated_Date: res.Updated_Date,
            subid: res.subid
          })
        } else {
          actions[updatedActionindex] = {
            Created_Date: res.Created_Date,
            User_id: res.User_id,
            actionType: res.actionType.toString(),
            read: res.read,
            Updated_Date: res.Updated_Date,
            subid: res.subid
          }
        }
      }

      const elementsIndex = target.findIndex((element) => element.Id === id);
      // console.log(elementsIndex, actions);

      let newArray = [...this.state.feeds];
      newArray[elementsIndex] = {
        ...newArray[elementsIndex],
        action: actions,
      };
      // console.log(newArray);
      this.setState({
        feeds: newArray,
      });
    });
  };

  getLikes = (actions) => {
    var likes = _.filter(actions, ["actionType", "true"]);
    var liketext = "";
    if (likes.length >= 2) {
      liketext = ""
    } else {
      liketext = ""
    }
    var likes = this.abbreviateNumber(likes.length)
    // console.log(likes)
    return likes;
    // return likes.length + " " + liketext;
  };


  getdisLikes = (actions) => {
    var dislikes = _.filter(actions, ["actionType", "false"]);
    var disliketext = "";
    if (dislikes.length >= 2) {
      disliketext = ""
    } else {
      disliketext = ""
    }
    var dislikes = this.abbreviateNumber(dislikes.length)
    return dislikes;
    // return dislikes.length + " " + disliketext;
  };


  getIsLiked = (actions, user) => {
    var isliked = _.filter(actions, { User_id: user, actionType: "true" });
    var isdisliked = _.filter(actions, { User_id: user, actionType: "false" });
    // console.log(isliked, isdisliked, actions, user);
    if (isliked.length >= 1) {
      return 2;
    } else if (isdisliked.length >= 1) {
      return 5;
    } else {
      return 1
    }
  };

  getIsdisLiked = (actions, user) => {
    var isliked = _.filter(actions, { User_id: user, actionType: "true" });
    var isdisliked = _.filter(actions, { User_id: user, actionType: "false" });
    // console.log(isliked, isdisliked, actions, user);
    if (isdisliked.length >= 1) {
      return 4;
    } else if (isliked.length >= 1) {
      return 5;
    } else {
      return 3;
    }
  };

  // getAllReactions = () => {
  //   var users = this.state.userlist;
  //   var reacted = this.state.activereactions;
  //   var allreactors = [];
  //   _.forEach(reacted, function (value) {
  //     console.log(value, users);
  //     var all = _.filter(users, { Id: value.author });
  //     console.log(all);
  //     allreactors.push({
  //       Id: all[0].Id,
  //       photoURL: all[0].photoURL,
  //       First_Name: all[0].First_Name,
  //       Last_Name: all[0].Last_Name,
  //       Date_of_Birth: all[0].Date_of_Birth,
  //       Ref_Country: all[0].Ref_Country,
  //       Ref_City: all[0].Ref_City,
  //       Ref_Gender: all[0].Ref_Gender,
  //       Education: all[0].Education,
  //       Interest_Passion: all[0].Interest_Passion,
  //       Biography: all[0].Biography,
  //       Updated_At: all[0].Updated_At,
  //       actionType: value.actionType,
  //     });
  //     // console.log(allreactors);
  //   });

  //   if (allreactors.length === reacted.length) {
  //     // console.log(Updated_At);
  //     return (
  //       <ul className="list-unstyled reactionuserlist">
  //         {_.map(allreactors, (user, index) => {
  //           return (
  //             <Link
  //               to={("/profile/" + user.Id)}
  //               key={index}
  //             >
  //               <li>
  //                 <div className="userphoto">
  //                   <span className={user.actionType === true ? "reaclike" : "reacdislike"}>
  //                     <Icon type={user.actionType === true ? "like" : "dislike"}
  //                       className="margin" id="liked " theme="filled" />
  //                   </span>
  //                   {user.photoURL === "" || user.photoURL === undefined ? (
  //                     <Avatar
  //                       style={{ color: "#f56a00", backgroundColor: "#fde3cf" }}
  //                       size="small" src={Noprofileimg}
  //                     >

  //                     </Avatar>
  //                   ) : (
  //                     <Avatar src={user.photoURL} size="small" />
  //                   )}
  //                 </div>
  //                 <div>
  //                   <span className="userinfo">
  //                     {user.First_Name} {user.Last_Name} <br />
  //                   </span>
  //                   <span>{user.Updated_At}</span>
  //                   {/* <span
  //                     className={
  //                       user.actionType === true ? "Liked" : "DisLiked"
  //                     }
  //                   >
  //                     {user.actionType === true ? "Liked" : "DisLiked"}
  //                   </span> */}
  //                 </div>
  //               </li>
  //             </Link>
  //           );
  //         })}
  //       </ul>
  //     );
  //   }
  // };

  // getLikeReactions = () => {
  //   var users = this.state.userlist;
  //   var reacted = _.filter(this.state.activereactions, { actionType: true });
  //   var allreactors = [];
  //   _.forEach(reacted, function (value) {
  //     console.log(value);
  //     var all = _.filter(users, { Id: value.author });
  //     console.log(all);
  //     allreactors.push({
  //       Id: all[0].Id,
  //       photoURL: all[0].photoURL,
  //       First_Name: all[0].First_Name,
  //       Last_Name: all[0].Last_Name,
  //       Date_of_Birth: all[0].Date_of_Birth,
  //       Ref_Country: all[0].Ref_Country,
  //       Ref_City: all[0].Ref_City,
  //       Ref_Gender: all[0].Ref_Gender,
  //       Education: all[0].Education,
  //       Interest_Passion: all[0].Interest_Passion,
  //       Biography: all[0].Biography,
  //       Updated_At: all[0].Updated_At,
  //       actionType: value.actionType,
  //     });

  //   });

  //   if (allreactors.length === reacted.length) {
  //     return (
  //       <ul className="list-unstyled reactionuserlist">
  //         {_.map(allreactors, (user, index) => {
  //           return (
  //             <Link
  //               to={("/profile/" + user.Id)}
  //               key={index}
  //             >
  //               <li>
  //                 <div className="userphoto">
  //                   <span className={user.actionType === false ? "reacdislike" : "reaclike"}>
  //                     <Icon type={user.actionType === false ? "caret-down" : "caret-up"}
  //                       className="margin" id="disliked " theme="filled" />
  //                   </span>
  //                   {user.photoURL === "" || user.photoURL === undefined ? (
  //                     <Avatar
  //                       size="small" src={Noprofileimg}
  //                     >
  //                       {/* {user.First_Name.charAt(0)} */}
  //                     </Avatar>
  //                   ) : (
  //                     <Avatar src={user.photoURL} size="small" />
  //                   )}
  //                 </div>
  //                 <div>
  //                   <span className="userinfo">
  //                     {user.First_Name} {user.Last_Name} <br />
  //                   </span>
  //                   {/* <span>{user.Updated_At}</span> */}
  //                   {/* <span
  //                     className={
  //                       user.actionType === true ? "Liked" : "DisLiked"
  //                     }
  //                   >
  //                     {user.actionType === true ? "Liked" : "DisLiked"}
  //                   </span> */}
  //                 </div>
  //               </li>
  //             </Link>
  //           );
  //         })}
  //       </ul>
  //     );
  //   }
  // };

  // getDisLikeReactions = () => {
  //   var users = this.state.userlist;
  //   var reacted = _.filter(this.state.activereactions, { actionType: false });
  //   var allreactors = [];
  //   _.forEach(reacted, function (value) {
  //     console.log(value);
  //     var all = _.filter(users, { Id: value.author });
  //     console.log(all);
  //     allreactors.push({
  //       Id: all[0].Id,
  //       photoURL: all[0].photoURL,
  //       First_Name: all[0].First_Name,
  //       Last_Name: all[0].Last_Name,
  //       Date_of_Birth: all[0].Date_of_Birth,
  //       Ref_Country: all[0].Ref_Country,
  //       Ref_City: all[0].Ref_City,
  //       Ref_Gender: all[0].Ref_Gender,
  //       Education: all[0].Education,
  //       Interest_Passion: all[0].Interest_Passion,
  //       Biography: all[0].Biography,
  //       Updated_At: all[0].Updated_At,
  //       actionType: value.actionType,
  //     });
  //     // console.log(allreactors);
  //   });

  //   if (allreactors.length === reacted.length) {
  //     return (
  //       <ul className="list-unstyled reactionuserlist">
  //         {_.map(allreactors, (user, index) => {
  //           return (
  //             <Link
  //               to={("/profile/" + user.Id)}
  //               key={index}
  //             >
  //               <li>
  //                 <div className="userphoto">
  //                   <div
  //                     data-icon={user.actionType === true ? "n" : "k"}
  //                     className={
  //                       user.actionType === true
  //                         ? "Liked icon"
  //                         : "icon DisLiked"
  //                     }
  //                   ></div>
  //                   {user.photoURL === undefined ? (
  //                     <Avatar
  //                       style={{ color: "#f56a00", backgroundColor: "#fde3cf" }}
  //                       size="small"
  //                     >
  //                       {user.First_Name.charAt(0)}
  //                     </Avatar>
  //                   ) : (
  //                     <Avatar src={user.photoURL} size="small" />
  //                   )}
  //                 </div>
  //                 <div>
  //                   <span className="userinfo">
  //                     {user.First_Name} {user.Last_Name} <br />
  //                   </span>
  //                   <span>{user.Biography}</span>
  //                   {/* <span
  //                     className={
  //                       user.actionType === true ? "Liked" : "DisLiked"
  //                     }
  //                   >
  //                     {user.actionType === true ? "Liked" : "DisLiked"}
  //                   </span> */}
  //                 </div>
  //               </li>
  //             </Link>
  //           );
  //         })}
  //       </ul>
  //     );
  //   }
  // };

  confirm(id) {
    this.deleteimage(id)
    // message.success('Log deleted');
  }

  cancel(e) {
    console.log(e);
    // message.error('Click on No');
  }

  //delete log image by id & value
  deleteimage = (id) => {
    console.log(id, this.state.feeds)
    var newArray = [...this.state.feeds];
    db.collection("Portfolio_Logs")
      .doc(id)
      .delete()
      .then(() => {
        message.error("Post deleted successfully")

        const idToRemove = id;

        _.remove(newArray, ['Id', idToRemove])

        console.log(newArray);
        this.setState({
          feeds: newArray,
        });
      })
      .catch(function (error) {
        console.error("Error removing document: ", error);
      });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.feeds.length !== this.state.feeds.length) {
      this.setState({
        feeds: this.state.feeds
      })
    }
  }

  abbreviateNumber(value) {
    var newValue = value;
    if (value >= 10000) {
      var suffixes = ["", "k", "M", "B", "T"];
      var suffixNum = Math.floor(("" + value).length / 4);
      var shortValue = '';
      for (var precision = 3; precision >= 2; precision--) {
        shortValue = parseFloat((suffixNum !== 0 ? (value / Math.pow(1000, suffixNum)) : value).toPrecision(precision));
        var dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g, '');
        if (dotLessShortValue.length <= 3) { break; }
      }
      if (shortValue % 2 !== 0) shortValue = shortValue.toFixed(2);
      newValue = shortValue + suffixes[suffixNum];
    }
    // console.log(newValue)
    return newValue;
  }


  renderUserInfo(username, userimg, author, created_timestamp) {
    var timestamp = new Date(created_timestamp * 1000);

    return (
      <div className="postuserinfo">
        <span className="userphoto">
          {userimg === "" ? (
            <Avatar
              style={{ color: "#f56a00", backgroundColor: "#fde3cf" }}
              size={46} src={Noprofileimg}
            >
              {/* {username.charAt(0)} */}
            </Avatar>
          ) : (
            <Avatar src={userimg} size={46} />
          )}
        </span>

        <span
          className="userinfo"
          style={{
            marginLeft: "10px",
            fontWeight: "600",
            fontSize: "14px",
          }}
        >
          <Link
            to={author === this.props.user.uid ?
              ("/profile")
              :
              ("/profile/" + author)}
            onClick={() => this.refreshFeeds()}
            style={{ color: '#333' }}
          >
            {username}
          </Link> <small
            style={{ color: '#777' }}>created new a post</small>
          <br />
          <span className="memcity">
            {moment(timestamp).startOf('seconds').fromNow()}
          </span>
        </span>
      </div>
    );
  }

  onCopy = () => {
    this.setState({
      copied: true,
    });
    message.success("Copied shared link")
  };

  renderMenu(Id, type) {
    const {
      url = this.state.urlvalue + Id,
      title = "See the livathon post",
      shareImage = "https://www.steadylearner.com/static/images/brand/prop-passer.png",
      size = "2.5rem",
    } = this.props;


    const ShareList = Passers({
      url,
      className: "network__share-button",
    })({
      className: "network cursor-pointer hover transition--default",
      title: `Share ${String(this.state.loc)}`,
    })("li");

    const iconFont = {
      fontSize: '14px'
    }

    return (
      <ul className="feedpost-drop">
        <li>
          <Popconfirm
            title={"Do you want to delete this post?"}
            icon={
              <Icon type="question-circle-o" style={{ color: 'red' }} />}
            onConfirm={(() => this.confirm(Id))}
            onCancel={() => this.cancel()}
            okText="Yes"
            cancelText="No">
            <span style={{ margin: 0 }}>
              <i className="fas fa-trash" style={iconFont}></i> Delete this post</span>
          </Popconfirm>
        </li>
        <p style={{ margin: 0 }}>
          <ShareList>
            <FacebookShareButton
              windowWidth={750}
              windowHeight={600}
              url={"https://livathon.com/" + "/post" + Id}
              quote={"See the livathon Post"}
            >
              <i className="fab fa-facebook" style={iconFont}></i> Share to facebook
            </FacebookShareButton>
          </ShareList>
          <ShareList>
            <TwitterShareButton
              windowWidth={750}
              windowHeight={600}
              title={title}
              url={url}
            >
              <i className="fab fa-twitter" style={iconFont}></i> Share to twitter
            </TwitterShareButton>
          </ShareList>
          <ShareList>
            <WhatsappShareButton
              windowWidth={750}
              windowHeight={600}
              title={"See the livathon post"}
              url={url}
              separator=":: "
            >
              <i className="fab fa-whatsapp-square" style={iconFont}></i>  share to whatsapp
            </WhatsappShareButton>
          </ShareList>
          <ShareList>
            <LinkedinShareButton
              title={title}
              url={"https://livathon.com/" + "/post" + Id}
              windowWidth={750}
              windowHeight={600}
            >
              <i className="fab fa-linkedin-in" style={iconFont}></i> Share to linkedIn
            </LinkedinShareButton>
          </ShareList>
        </p>
        <li>
          <CopyToClipboard onCopy={this.onCopy}
            text={this.state.urlvalue + Id}>
            <span><i className="fas fa-copy" style={iconFont}></i> Copy link</span>
          </CopyToClipboard>
        </li>
      </ul>
    )
  }

  renderDrawerMenu(Id, authorid, uid, type) {
    const {
      url = this.state.urlvalue + Id,
      title = "Livathon",
      shareImage = "https://www.steadylearner.com/static/images/brand/prop-passer.png",
      size = "2.5rem",
    } = this.props;

    const iconFont = {
      fontSize: '14px'
    }

    const ShareList = Passers({
      url,
      className: "network__share-button",
    })({
      className: "network cursor-pointer hover transition--default",
      title: `Share ${String(this.state.loc)}`,
    })("li");

    return (
      <ul className="feedpost-drop">
        <li>
          <span onClick={() => this.showDrawer(Id, authorid, uid, type)}>
            <i className="fas fa-flag" style={iconFont}></i> Report Post</span>
        </li>
        <p style={{ margin: 0 }}>
          <ShareList>
            <FacebookShareButton
              windowWidth={750}
              windowHeight={600}
              url={"https://livathon.com/" + "/post" + Id}
              quote={"See the livathon Post"}
            >
              <i className="fab fa-facebook" style={iconFont}></i> Share to facebook
            </FacebookShareButton>
          </ShareList>
          <ShareList>
            <TwitterShareButton
              windowWidth={750}
              windowHeight={600}
              title={title}
              url={url}
            >
              <i className="fab fa-twitter" style={iconFont}></i> Share to twitter
            </TwitterShareButton>
          </ShareList>
          <ShareList>
            <WhatsappShareButton
              windowWidth={750}
              windowHeight={600}
              title={"See the livathon post"}
              url={url}
              separator=":: "
            >
              <i className="fab fa-whatsapp-square" style={iconFont}></i>  share to whatsapp
            </WhatsappShareButton>
          </ShareList>
          <ShareList>
            <LinkedinShareButton
              title={title}
              url={"https://livathon.com/" + "/post" + Id}
              windowWidth={750}
              windowHeight={600}
            >
              <i className="fab fa-linkedin-in" style={iconFont}></i> Share to linkedIn
            </LinkedinShareButton>
          </ShareList>
        </p>
        <li>
          <CopyToClipboard onCopy={this.onCopy}
            text={this.state.urlvalue + Id}>
            <span><i className="fas fa-copy" style={iconFont}></i> Copy link</span>
          </CopyToClipboard>
        </li>
      </ul>
    )
  }


  renderPortfolioLogsList() {
    const componentDecorator = (href, text, key) => (
      <a href={href} key={key} target="_blank">
        {text}
      </a>
    );
    return (
      <InfinitScroll
        dataLength={this.state.feeds.length}
        next={this.getfeedPost}
        hasMore={this.state.hasMore === true ? true : false}
        loader={<div className="text-center"><Spin tip="Loading..." /></div>}
        endMessage={<p className="text-center">All Caught Up</p>}
        initialScrollY="600px"
      >
        <div>
          {this.state.feeds.map((logs, index) => (
            <div className="postcard" style={{ marginBottom: "10px" }} key={index}>
              <Card
                className="feedscard"
                title={this.renderUserInfo(
                  logs.username,
                  logs.userimg,
                  logs.author,
                  logs.created_timestamp
                )}
                extra={logs.author === this.state.loginuser ? (
                  <div style={{ display: 'inline-flex' }}>
                    <Dropdown trigger={["click"]} placement="bottomRight"
                      overlay={this.renderMenu(logs.Id, logs.posttype)}
                      style={{ marginLeft: '10px' }}>
                      <a className="ant-dropdown-link" href="#"
                        onClick={e => e.preventDefault()}>
                        <Icon type="more" style={{ fontSize: '20px', color: '#333' }} />
                      </a>
                    </Dropdown>
                  </div>
                ) : (
                  <div style={{ display: 'inline-flex' }}>
                    <Dropdown trigger={["click"]}
                      overlay={this.renderDrawerMenu(logs.Id, logs.author, this.props.user.uid, logs.posttype)}
                      style={{ marginLeft: '10px' }} >
                      <a className="ant-dropdown-link" href="#"
                        onClick={e => e.preventDefault()}>
                        <Icon type="more" style={{ fontSize: '20px', color: '#333' }} />
                      </a>
                    </Dropdown>
                  </div>
                )}
                style={{
                  width: "100%",
                  border: "1px solid #eee",
                  borderRadius: "4px"
                }}
              >
                <div style={logs.log_list.length === 0 ? { padding: '0' } : { padding: '15px' }}>
                  {_.map(logs.log_list, (log, index) => {
                    return (
                      <div className="readdiv" key={index}
                        style={{
                          wordBreak: 'break-all',
                          whiteSpace: 'pre-line',
                          marginBottom: '10px',
                          padding: "8px 10px",
                          border: "1px solid #f9f9f9",
                          display: "block",
                          position: "relative",
                          borderRadius: "2px"
                        }}>
                        <Linkify componentDecorator={componentDecorator}>
                          {log.description}
                        </Linkify>
                      </div>
                    )
                  })}
                </div>
                <div>
                  <Carousel
                    showIndicators={false}
                  >
                    {_.map(logs.upload_logs_image, (logImage, index) => {
                      return (
                        <div key={index} onClick={() =>
                          this.showModal(logs.upload_logs_image, index)
                        }>
                          <div className="col-md-12 col-xs-12 postimg">
                            <div className="imagecontainer1">
                              <LazyImage
                                // <img 
                                src={logImage.image}
                                className="imgbanner1 img1"
                                alt=""
                                onClick={() =>
                                  this.showModal(logs.upload_logs_image, index)
                                }
                              // />
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </Carousel>
                </div>
                {logs.posttype === "portfolio" ? (
                  <div>
                    <div className="postaction">
                      {/* <div className="row">
                    <div className="col-xs-6 text-right"> */}
                      <div className="postactionbtn">
                        <div className="actioncount">
                          {logs.action.length === 0 ? null : (
                            <div>
                              <div className="spacereactors"
                                onClick={() => this.showreactionmodal(logs.action)}>
                                {/* <span className="isliked">
                                  <Icon type="caret-up"
                                    className="margin likeicon" id="liked " theme="filled" /></span> */}
                                {/* <p className="isliked"> */}
                                <span className="badge-success">
                                  {this.getLikes(logs.action)}</span>
                                {/* </p> */}
                              </div>
                            </div>
                          )}
                        </div>
                        <Button
                          type="link"
                          className={this.getIsLiked(
                            logs.action,
                            this.props.user.uid)
                            === 1 ? "Like" : this.getIsLiked(
                              logs.action,
                              this.props.user.uid)
                              === 5 ? "Like" : "Liked"}
                          onClick={() =>
                            this.postsaction(logs.Id, logs.author, this.props.user.uid, true,
                              logs.action, this.getIsLiked(
                                logs.action,
                                this.props.user.uid))
                          }>
                          <Icon type="caret-up" className="margin likeicon"
                            theme="filled" />
                        </Button>
                      </div>
                      {/* </div>
                    <div className="col-xs-6 text-left"> */}
                      <div className="postactionbtn">
                        <Button
                          type="link"
                          className={this.getIsdisLiked(
                            logs.action,
                            this.props.user.uid
                          ) === 3 ? "DisLike" : this.getIsdisLiked(
                            logs.action,
                            this.props.user.uid
                          ) === 5 ? "DisLike" : "DisLiked"}
                          onClick={() =>
                            this.postsaction(logs.Id, logs.author, this.props.user.uid, false,
                              logs.action, this.getIsdisLiked(
                                logs.action,
                                this.props.user.uid
                              ))
                          }
                        >
                          <Icon type="caret-down"
                            className="margin likeicon" theme="filled" />
                          {/* {this.getIsdisLiked(logs.action, this.props.user.uid)} */}
                        </Button>
                        <div className="actioncount">
                          {logs.action.length === 0 ? null : (
                            <div>
                              <div className="spacereactors">
                                {/* <span className="isdisliked">
                                <Icon type="caret-down" className="margin" id="disliked"
                                  theme="filled" /> </span> */}
                                {/* <p className="isdisliked"> */}
                                <span className="badge-danger">
                                  {this.getdisLikes(logs.action)}</span>
                                {/* </p> */}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      {/* </div>
                  </div> */}
                    </div>
                    {/* <div className="postaction1">
                  <div className="row">
                    <div className="col-xs-12 text-left">
                      <div className="actioncount">
                        {logs.Action.length === 0 ? null : (
                          <div>
                            <Tooltip title="List view">
                              <p className="spacereactors"
                                onClick={() => this.showreactionmodal(logs.Action)}>
                                <span className="isliked">
                                  <Icon type="caret-up"
                                    className="margin likeicon" id="liked " theme="filled" /></span>
                                <span>{this.getLikes(logs.Action)}</span>

                              </p>
                            </Tooltip>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div> */}
                  </div>
                ) : null}
              </Card>
            </div>
          ))}
        </div>
      </InfinitScroll >
    )
  }

  renderPortfolioimages() {
    return (
      <Carousel
        selectedItem={this.state.currentSlide}
        renderArrowPrev={(onClickHandler, hasPrev, label) =>
          hasPrev && (
            <Button type="primary" onClick={onClickHandler} title={label}
              className="arrowstyles"
              style={{ left: 0 }}>
              <Icon type="left" />
            </Button>
          )
        }
        renderArrowNext={(onClickHandler, hasNext, label) =>
          hasNext && (
            <Button type="primary" onClick={onClickHandler} title={label}
              className="arrowstyles"
              style={{ right: 0 }}>
              <Icon type="right" />
            </Button>
          )
        }>
        {_.map(this.state.visibleimages, (logImage, index) => {
          return (
            <div className="carouselimg" key={index}>
              <div className="carouselimginner">
                <img src={logImage.image} className="imgbanner img1" alt="" />
              </div>
            </div>
          );
        })}
      </Carousel>
    );
  }

  renderModalimages() {
    const settings = {
      dots: true,
      infinite: false,
      speed: 500,
      adaptiveHeight: false,
      beforeChange: (current, next) => this.setState({ slideIndex: next }),
    };
    return (
      <Carousel {...settings}>
        {_.map(this.state.activeimgset, (logImage, index) => {
          return (
            <div className="carouselimg" key={index}>
              <div className="carouselimginner">
                <img src={logImage.image} className="imgbanner img" alt="" />
              </div>
            </div>
          );
        })}
      </Carousel>
    );
  }

  renderDrawer() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        <div className="row">
          <div className="col-md-12">
            <p>What you are reporting will be completely anonymous . If you feel someone is in immediate danger
              please follow up it with the local emergency services.</p>

            <Form onSubmit={this.handleSubmit}>
              <Form.Item label="Report Type">
                {getFieldDecorator('report', {
                  rules: [
                    {
                      required: true,
                      message: 'Choose the reason to report this post'
                    }],
                })
                  (
                    <Radio.Group>
                      <Radio value="Its suspicious or spam">Its suspicious or spam</Radio>
                      <Radio value="Its abusive or harmful">Its abusive or harmful</Radio>
                      <Radio value="Its expresses intention of self harm or suicide">Its expresses intention of
                        self harm or suicide</Radio>
                      <Radio value="Its appropriate">Its appropriate</Radio>
                    </Radio.Group>
                  )}
              </Form.Item>
              <Button type="primary"
                htmlType="submit">Report</Button>
            </Form>
          </div>
        </div>
      </div>
    )
  }

  renderModalReactions() {
    // console.log(this.state.activereactions);
    return (
      <Tabs defaultActiveKey="1" onChange={this.callback} animated={false}>
        <TabPane tab="Likes" key="1">
          {/* {this.getAllReactions()} */}
        </TabPane>
        {/* <TabPane tab="Likes" key="2">
          {this.getLikeReactions()}
        </TabPane> */}
        {/* <TabPane tab="DisLikes" key="3">
          {this.getDisLikeReactions()}
        </TabPane> */}
      </Tabs>
    );
  }

  render() {
    // console.log(this.state.allposts);
    const { feeds, arraylength } = this.state;
    return (
      <div>
        <Drawer
          title="Tell us why you are reporting this post/log?"
          placement="right"
          closable={false}
          width={350}
          onClose={this.onClose}
          visible={this.state.visibleDrawer}
        >
          {this.renderDrawer()}
        </Drawer>
        {/* Image preview modal */}
        <Modal
          className="carouselmodal"
          title=""
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={null}
          centered={true}
          width="70%"
        >
          {this.renderModalimages()}
        </Modal>

        {/* Likes dislikes modal  */}
        <Modal
          title="Reactions"
          visible={this.state.visible1}
          onCancel={this.handlereactionCancel}
          footer={null}
          centered={true}
          className="reactionsmodal"
        >
          {this.renderModalReactions()}
        </Modal>

        <Modal
          className="carouselmodal"
          title=""
          visible={this.state.postimages}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={null}
          centered={true}
          width="70%"
        >
          {this.renderPortfolioimages()}
        </Modal>
        {/* posts */}

        <div className="alllogs" id="header">
          {feeds.length === 0 && arraylength === true ? (
            <div>
              <Shimmer count={2} />
            </div>
          ) : (
            <div>
              {feeds.length === 0 ? (
                <NoFeedCard />
              ) : (
                <div>
                  {this.renderPortfolioLogsList()}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

const WrappedAllFeedPosts = Form.create({ name: "register" })(FeedsPost);

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
    personallogs: state.personallogs,
    portfoliologs: state.portfoliologs,
  };
}

export default connect(mapStateToProps, {
  getallLogs,
  getallportfolioLogs,
  postActions,
  reportPost,
  getFeedsLimit
})(WrappedAllFeedPosts);
