import React from "react";
import '../assests/css/loading.css';
import Logo from '../assests/img/logo-new-dark.svg';

class Loading extends React.Component {
  render() {
    return (
      <div className="flex-center position-ref full-height">
        <div className="three col">
          <img src={Logo} alt="logo" className="logo1"/>
          {/* <div className="loader">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div> */}
        </div>
      </div>
    );
  }
}
export default Loading;
