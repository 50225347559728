import React, { Component } from "react";
import { getallUserList, getProfile } from "../actions/userAction";
import {
  addFollowing, getFollowing,
  removeFollowing, getusernotfollowlist
}
  from "../actions/livingAction";
import { Card, Avatar, Button, message, Empty } from "antd";
import Noprofileimg from '../assests/img/d-avatar.jpg'
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import WidgetShimmer from './widgetShimmer'
import request from "superagent";
import _ from "lodash";
import { BASEURL } from '../actionTypes'


function shuffle(array) {
  var currentIndex = array.length, randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}

class UserProfiles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alluser: [],
      followers: [],
      newusers: [],
      arraylength: true,
    };
  }


  // getnotFollowing(uid) {

  //   var dataobj = {
  //     uid: uid,
  //     // Ref_Interest: interest.toString()
  //   };
  //   // console.log("Query", dataobj);
  //   request
  //     .post(BASEURL + "/notfollowinglistrandom")
  //     .send(dataobj)
  //     .then((res) => {
  //       // console.log(res);
  //       if (res.body.ReturnCode === 200) {
  //         // console.log(res.body.Data);
  //         this.setState({
  //           newusers: res.body.Data,
  //           arraylength: false
  //         });
  //       } else {
  //         this.setState({
  //           newusers: res.body.Data,
  //           arraylength: false
  //         });
  //       }
  //     })
  //     .catch((err) => { });
  // }

  getnotFollowing(uid) {
    this.props.getusernotfollowlist(uid).then((res) => {
      console.log(res, res.length)
      if (res.length != 0) {
        var randomizeArray = shuffle(res);
        console.log(randomizeArray)

        const items = randomizeArray.slice(0, 6)

        this.setState({
          newusers: items,
          arraylength: false
        })
      } else {
        this.setState({
          newusers: [],
          arraylength: false
        })
      }
    }).catch((err) => {
      this.setState({
        newusers: [],
        arraylength: false
      })
    });
  }


  componentWillMount() {

    var uid = this.props.user.uid;

    this.getnotFollowing(uid)

    // this.props.getProfile(uid).then((res) => {
    //   // console.log(res.Ref_Interest);
    //   this.getnotFollowing(uid, res.Ref_Interest)
    // });

    this.props.getallUserList(uid).then((res) => {
      // console.log(res);
      this.props.getFollowing(uid).then((res1) => {
        // console.log(res1);
        this.filterusers(res, res1);
      });
    });

    // this.prot().then((res) => {
    //   // console.log(res);
    //   this.setState({
    //  t: res,
    //   });
    // });
  }


  filterusers(origArr, updatingArr) {
    var merged = _.map(origArr, function (item) {
      return _.assign(item, _.find(updatingArr, ["Id", item.Id]));
    });
    // console.log(merged);
    this.setState({
      followers: merged,
    });
  }


  handlefollowing = (followid) => {
    console.log(followid, this.state.newusers)
    var uid = this.props.user.uid;
    var newArray = this.state.newusers
    this.props.addFollowing(uid, followid).then((res) => {
      // console.log(res);

      var filteruser = _.filter(newArray, ['Id', followid]);
      console.log(filteruser)
      // _.remove(newArray, ['Id', followid])

      message.success(filteruser[0].Username + " " + "added to your friend list")
      this.getnotFollowing(uid);

      // console.log(newArray);
      // this.setState({
      //   newusers: newArray,
      // });
    });
  };


  updateFollowing = (followid, type, res) => {
    var Actionupdated = this.state.newusers;
    var followType = "";
    if (type === "Follow") {

      followType = "Following"

    }
    // console.log(followid, Actionupdated);

    const elementsIndex = Actionupdated.findIndex((element) => element.Id === followid);
    // console.log(elementsIndex);
    let newArray = [...this.state.newusers];
    newArray[elementsIndex] = {
      ...newArray[elementsIndex],
      Follower_type: followType,
      Follower_id: this.props.user.uid,
      circle_id: res.Id
    };
    // console.log(newArray);
    this.setState({
      newusers: newArray,
    });
  };


  updateUnfollow = (followid, type, res) => {
    var Actionupdated = this.state.newusers;
    // var followType = "";

    // console.log(followid, Actionupdated);

    const elementsIndex = Actionupdated.findIndex((element) => element.Id === followid);
    // console.log(elementsIndex);
    let newArray = [...this.state.newusers];
    newArray[elementsIndex] = {
      ...newArray[elementsIndex],
      Follower_type: "",
      Follower_id: "",
      circle_id: ""
    };
    // console.log(newArray);
    this.setState({
      newusers: newArray,
    });
  };


  handleunfollow = (id) => {
    // var uid = this.props.user.uid;
    // console.log(id);
    this.props.removeFollowing(id).then((res) => {
      // console.log(res);
      this.updateUnfollow(id, "Unfollow", "")
    });
  };


  renderuserList() {
    // console.log(this.state.followers);
    return _.map(this.state.newusers, (user, index) => {
      return (
        <li key={index} className="col-xs-6">
          <div className="userbox">
            <span className="userphoto">
              {user.photoURL === undefined || user.photoURL === "" ? (
                // <Avatar
                //   style={{
                //     color: "#f56a00",
                //     backgroundColor: "#fde3cf",
                //     textTransform: "uppercase",
                //   }}
                //   size={48}
                // >
                //   {user.First_Name.charAt(0)}
                // </Avatar>
                <Avatar src={Noprofileimg} size={48} />
              ) : (
                <Avatar src={user.photoURL} size={48} />
              )}
            </span>
            <br />
            <Link
              to={"/profile/" + user.Id}
            >
              <span className="userinfo" style={{ minHeight: '42px' }}>
                {user.Username}
              </span>
            </Link>
            {/* <span className="memcity">
              {user.Biography}
            </span> */}
            {user.Follower_type === "" ? (
              <Button
                type="default"
                block
                className="follow"
                onClick={() => this.handlefollowing(user.Id)}
              >
                Follow
              </Button>
            ) : (
              <Button
                type="default"
                block
                className="following"
                onClick={() => this.handleunfollow(user.Id)}
              >
                Following
              </Button>
            )}
          </div>
        </li>
      );
    });
  }


  render() {
    // console.log(this.state.newusers);
    const { newusers, arraylength } = this.state;
    return (
      <div>
        <Card className="feedsuser" style={{ marginTop: "20px" }}>
          <div className="row">
            <div className="col-xs-8">
              <h5>People you may know</h5>
            </div>
            <div className="col-xs-4 text-right">
              <Link to="/users" className="viewall">View All</Link>
            </div>
          </div>

          <ul className="list-unstyled" >
            {newusers.length === 0 && arraylength === true ? (
              <div>
                <div className="row">
                  <div className="col-md-12">
                    <WidgetShimmer count={6} />
                  </div>
                </div>
              </div>
            ) : (
              <div>
                {newusers.length === 0 ? (
                  <Empty
                    image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
                    imageStyle={{
                      height: 60,
                    }}
                    description={
                      <span>
                        No Users to show
                      </span>
                    } />
                ) : (
                  <div>
                    {this.renderuserList()}
                  </div>
                )}
              </div>
            )}
          </ul>
        </Card>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
    allusers: state.allusers,
  };
}

export default connect(mapStateToProps, {
  getallUserList,
  addFollowing,
  getFollowing,
  removeFollowing,
  getProfile,
  getusernotfollowlist
})(UserProfiles);
