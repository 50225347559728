import React, { Component } from "react";
import {
    removeFollowing,
    getFollowing
} from "../actions/livingAction";
import { Avatar, Button, message, Empty } from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Noprofileimg from '../assests/img/d-avatar.jpg'
import _ from "lodash";
import Verified from '../assests/img/verified.svg'
import UserShimmer from "./userShimmer";

class FollowingList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            getfollowing: [],
            followingIndex: 0,
            followinglength: 0,
            arraylength: true,
            chunkedData: []
        };
    }

    fetchFollowingData = (uid) => {
        var uid = this.props.user.uid

        var followingarray = this.state.getfollowing;
        this.props.getFollowing(uid).then((res) => {
            console.log(res)
            this.setState({
                getfollowing: res,
                arraylength: false
            })
            followingarray = _.chunk(this.state.getfollowing, 20)
            // console.log(followingarray.length);
            // console.log(followingarray)
            this.setState({
                getfollowing: followingarray[this.state.followingIndex],
                followinglength: followingarray.length,
                chunkedData: followingarray
            })
        });
    }

    refreshFeeds = () => {
        setTimeout(function () {
            window.location.reload(false);
        }, 500);
    }

    addFollowingData = async () => {
        var followersarray = this.state.getfollowing;
        var fullarray = followersarray;
        await this.setState({
            followingIndex: this.state.followingIndex + 1
        });
        var mergearray = _.concat(fullarray, this.state.chunkedData[this.state.followingIndex])
        this.setState({
            getfollowing: mergearray,
        })
        this.renderFollowingList();
    }

    componentWillMount() {

        var uid = this.props.user.uid;

        this.fetchFollowingData(uid);
    }


    handlefollowing = (followid) => {
        var uid = this.props.user.uid;
        this.props.addFollowing(uid, followid).then((res) => {
            // console.log(res);
            this.props.getFollowing(uid).then((res1) => {
                // console.log(res1);
                this.filterusers(res, res1);
            });
        });
    };


    handleunfollow = (id) => {
        console.log(id, this.state.getfollowing)
        var uid = this.props.user.uid;

        this.props.removeFollowing(id).then((res) => {
            let newArray = this.state.getfollowing;
            _.remove(newArray, ['circle_id', id])

            console.log(newArray);
            this.setState({
                getfollowing: newArray,
                arraylength: false
            });
            message.error("removed from your friend list")
        });
    };


    renderFollowingList() {

        //  console.log(this.state.getfollowing);
        return _.map(this.state.getfollowing, (user, index) => {
            return (
                <li key={index} className="col-md-3 col-sm-4 col-xs-6 text-center">
                    <div className="userbox">
                        <span className="userphoto">
                            {user.photoURL === undefined || user.photoURL === "" ? (
                                <Avatar
                                    style={{
                                        color: "#f56a00",
                                        backgroundColor: "#fde3cf",
                                        textTransform: "uppercase",
                                    }}
                                    size={64}
                                    src={Noprofileimg}
                                >
                                    {/* {user.First_Name.charAt(0)} */}
                                </Avatar>
                            ) : (
                                <Avatar src={user.photoURL} size={64} />
                            )}
                        </span>
                        <br />
                        <Link
                            to={user.Id === this.props.user.uid ?
                                ("/profile")
                                :
                                ("/profile/" + user.Id)}
                            onClick={() => this.refreshFeeds()}
                        >
                            <p className="userinfo">
                                {user.First_Name} {user.Last_Name}
                                <span>
                                    {user.Is_Verified === false ? null : (
                                        <img src={Verified} className="feeds-verified" />
                                    )}
                                </span>
                            </p>
                        </Link>
                        {user.Follower_type === "Following" ? (
                            <Button
                                type="default"
                                block
                                className="userfollowing"
                                onClick={() => this.handleunfollow(user.circle_id)}
                            >
                                Following
                            </Button>
                        ) : (
                            <Button
                                type="default"
                                block
                                className="following"
                                onClick={() => this.handlefollowing(user.Id)}
                            >
                                Follow
                            </Button>

                        )}
                    </div>
                </li>
            );
        });
    }


    render() {
        const { arraylength, followingIndex,
            followinglength, getfollowing } = this.state
        return (
            <div>
                <h5>Following Friends</h5>
                <ul className="list-unstyled row">
                    {getfollowing.length === 0 && arraylength === true ? (
                        <div>
                            <div className="row">
                                <div className="col-md-12">
                                    <UserShimmer count={4} />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div>
                            {getfollowing.length === 0 ? (
                                <Empty />
                            ) : (
                                <div>
                                    {this.renderFollowingList()}
                                    <div className="row">
                                        <div className="col-md-12 text-center">
                                            {followinglength - 1 > followingIndex ? (
                                                <Button className="blockuser"
                                                    onClick={() => this.addFollowingData()}>
                                                    View More</Button>
                                            ) : (
                                                null
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </ul>
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        user: state.user,
        allusers: state.allusers,
        getfollowing: state.getfollowing,
    };
}

export default connect(mapStateToProps, {
    removeFollowing,
    getFollowing
})(FollowingList);

