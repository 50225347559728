import React, { Component } from "react";
import "../assests/css/shimmer.css";
import _ from "lodash";

class GridShimmer extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    renderShimmer(arr) {
        return _.map(arr, (arr, index) => {
            return (
                <div className="col-md-3" key={index}>
                    <div className="gridshimmerwrapper">
                        {/* <div className="profile shine"></div>

                        <div className="text">
                            <span className="shine"></span>
                            <span className="shine"></span>
                            <span className="shine"></span>
                        </div> */}

                        <div className="photo shine"></div>
                    </div>
                </div>
            );
        });
    }

    render() {
        // console.log(this.props.count);
        var arr = new Array(this.props.count).fill("Shimmer");
        // console.log(arr);
        return <div>
            {this.renderShimmer(arr)}
        </div>;
    }
}

export default GridShimmer;
