import React, { Component } from "react";
import { getFollowers, removeFollowing } from "../actions/livingAction";
import { Avatar, Button, message, Empty } from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Noprofileimg from '../assests/img/d-avatar.jpg'
import _ from "lodash";
import Verified from '../assests/img/verified.svg'
import UserShimmer from "./userShimmer";

class FollowersList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      getfollowers: [],
      followerIndex: 0,
      followerslength: 0,
      arraylength: true,
      chunkedData: [],
    };
  }

  handlefollowing = (followid) => {
    var uid = this.props.user.uid;
    this.props.addFollowing(uid, followid).then((res) => {
      // console.log(res);
      this.props.getFollowing(uid).then((res1) => {
        // console.log(res1);
        this.filterusers(res, res1);
      });
    });
  };

  refreshFeeds = () => {
    setTimeout(function () {
      window.location.reload(false);
    }, 500);
  }

  handleunfollow = async (id) => {
    console.log(id, this.state.getfollowers)
    var uid = this.props.user.uid;
    await this.props.removeFollowing(id).then((res) => {
      let newArray = this.state.getfollowers;
      // console.log(newArray)
      _.remove(newArray, ['circle_id', id])

      console.log(newArray);

      this.setState({
        getfollowing: newArray,
      });
      message.error("removed from your friend list")

    });
  };

  fetchFollowersData = (uid) => {
    var uid = this.props.user.uid

    var followersarray = this.state.getfollowers;
    this.props.getFollowers(uid).then((res) => {
      console.log(res)
      this.setState({
        getfollowers: res,
        hasMore: true,
        arraylength: false
      })
      followersarray = _.chunk(this.state.getfollowers, 20)
      // console.log(followersarray.length);
      // console.log(followersarray)
      this.setState({
        getfollowers: followersarray[this.state.followerIndex],
        followerslength: followersarray.length,
        chunkedData: followersarray
      })
    });
  };

  addFollowers = async () => {
    var followersarray = this.state.getfollowers;
    var fullarray = followersarray;
    // console.log(fullarray)
    await this.setState({
      followerIndex: this.state.followerIndex + 1
    });

    var mergearray = _.concat(fullarray, this.state.chunkedData[this.state.followerIndex])
    // console.log(fullarray, mergearray);
    this.setState({
      getfollowers: mergearray,
    })
    this.renderFollowersList();
  }


  componentWillMount() {

    var uid = this.props.user.uid;

    this.fetchFollowersData(uid);
  }


  filterusers(origArr, updatingArr) {
    var merged = _.map(origArr, function (item) {
      return _.assign(item, _.find(updatingArr, ["Id", item.Id]));
    });
    // console.log(merged);
    this.setState({
      followers: merged,
    });
  }


  renderFollowersList() {
    return _.map(this.state.getfollowers, (user, index) => {
      return (
        <li key={index} className="col-md-3 col-sm-4 col-xs-6 text-center">
          <div className="userbox">
            <span className="userphoto">
              {user.photoURL === undefined || user.photoURL === "" ? (
                <Avatar
                  style={{
                    color: "#f56a00",
                    backgroundColor: "#fde3cf",
                    textTransform: "uppercase",
                  }}
                  size={64}
                  src={Noprofileimg}
                >
                  {/* {user.First_Name.charAt(0)} */}
                </Avatar>
              ) : (
                <Avatar src={user.photoURL} size={64} />
              )}
            </span>
            <br />
            <Link
              to={user.Id === this.props.user.uid ?
                ("/profile")
                :
                ("/profile/" + user.Id)}
              onClick={() => this.refreshFeeds()}
            >
              <p className="userinfo">
                {user.First_Name} {user.Last_Name}
                <span>
                  {user.Is_Verified === false ? null : (
                    <img src={Verified} className="feeds-verified" />
                  )}
                </span>
              </p>
            </Link>
            {user.Follower_type === "Following" ? (

              <Button
                type="default"
                block
                className="userfollowing"
                onClick={() => this.handleunfollow(user.circle_id)}
              >
                Following
              </Button>
            ) : (
              <Button
                type="default"
                block
                className="following"
                onClick={() => this.handlefollowing(user.Id)}
              >
                Follow
              </Button>

            )}
          </div>
        </li>
      );
    });
  }

  render() {
    const { arraylength, followerIndex,
      followerslength, getfollowers } = this.state
    return (
      <div>
        <h5>My Followers</h5>
        <ul className="list-unstyled row">
          {getfollowers.length === 0 && arraylength === true ? (
            <div>
              <div className="row">
                <div className="col-md-12">
                  <UserShimmer count={4} />
                </div>
              </div>
            </div>
          ) : (
            <div>
              {getfollowers.length === 0 ? (
                <Empty />
              ) : (
                <div>
                  {this.renderFollowersList()}
                  <div className="row">
                    <div className="col-md-12 text-center">
                      {followerslength - 1 > followerIndex ? (
                        <Button className="blockuser"
                          onClick={() => this.addFollowers()}>
                          View More</Button>
                      ) : (
                        null
                      )}

                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </ul>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
    allusers: state.allusers,
    getfollowers: state.getfollowers,
  };
}

export default connect(mapStateToProps, {
  removeFollowing,
  getFollowers,
})(FollowersList);
