export const GET_USER = 'GET_USER';
export const USER_STATUS = 'USER_STATUS';
export const GET_PERSONALLOGS = 'GET_PERSONALLOGS';
export const GET_PROFILE ='GET_PROFILE';
export const GET_PORTFOLIOLOGS = 'GET_PORTFOLIOLOGS';
export const GET_FOLLOWERS = 'GET_FOLLOWERS';
export const GET_FOLLOWING = 'GET_FOLLOWING';
export const SHOW_MODAL = 'SHOW_MODAL';
// export const HIDE_MODAL = 'HIDE_MODAL';
export const GET_ALL_USER = 'GET_ALL_USER';
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';

export const GET_IMAGEPORTFOLIOLOGS = 'GET_IMAGEPORTFOLIOLOGS';
export const GET_CHATMSGS = 'GET_CHATMSGS';
export const GET_CHATHISTORY = 'GET_CHATHISTORY';
export const BASEURL = 'https://europe-west2-livathon-f1c58.cloudfunctions.net'; 
// export const BASEURL = 'https://europe-west2-living-921ec.cloudfunctions.net';