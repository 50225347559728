import { auth, db, storage } from "../firebase";
import {
  GET_USER,
  USER_STATUS,
  GET_PROFILE,
  GET_ALL_USER,
} from "../actionTypes";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import moment from "moment";
import _ from "lodash";


export function getUser() {
  return (dispatch) => {
    // show loading status before getting user to true
    dispatch({
      type: USER_STATUS,
      payload: true,
    });
    auth.onAuthStateChanged((user) => {
      console.log(user);
      dispatch({
        type: GET_USER,
        payload: user,
      });
      // show loading status to false
      dispatch({
        type: USER_STATUS,
        payload: false,
      });
    });
  };
}

//user signin
export function emailLogin(email, password) {
  return (dispatch) => auth.signInWithEmailAndPassword(email, password);
}

//reset password
export function resetPassword(email) {
  return (dispatch) => auth.sendPasswordResetEmail(email);
}

//update password
export function updatePassword(password1) {
  //console.log(password1);
  return (dispatch) => auth.currentUser.updatePassword(password1);
}

//delete auth & user detail
export function deleteUser() {
  var user = auth.currentUser;

  var authuid = auth.currentUser.uid
  console.log(authuid)

  var porfolioArray = [];

  var docRef = db.collection("User_Details").doc(authuid);

  var perRef = db.collection("Portfolio_Logs");

  return function (dispatch) {
    return docRef.get().then((doc) => {
      perRef.where("author", "==", authuid)
        .get().then((doc2) => {
          if (doc2.empty === false) {
            doc2.forEach(res => {
              console.log(res.id)
              porfolioArray.push(res.id)

              if (doc2.size === porfolioArray.length) {
                var logData = porfolioArray;
                console.log(logData)
              }
              perRef.doc(porfolioArray).delete().then((res) => {
                console.log('documents deleted succesfully')
              })
            })
          } else {
            console.log("no data found")
          }
          if (doc.exists) {
            console.log(doc.data())
            docRef.delete().then((doc1) => {
              console.log("document deleted successfully")
              // user.delete().then(() => {
              //   console.log("user auth deleted")

              // })
              //   .catch((error) => {
              //     console.log(error)
              //   });
            })
              .catch((err) => {
                console.log(err)
              })
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        })
    })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
  };
}


export function activateAccount(email, password) {
  console.log(email, password)

  const user = firebase.auth().currentUser;
  var credentials = firebase.auth.EmailAuthProvider.credential(
    user.email,
    password
  );
  // Now you can use that to reauthenticate
  user.reauthenticateWithCredential(credentials).then(() => {
    console.log("Its good!");
  }).catch((error) => {
    console.log(error);
  })
    ;
}

//user signup
export function signup(email, password) {
  // return (dispatch) => {
  //   return new Promise((resolve, reject) => {
  return (dispatch) => auth.createUserWithEmailAndPassword(email, password)
  // .then((res) => {
  //   console.log(email, password, res);
  //   var uid = res.user.uid;
  //   // console.log(uid)
  //   db.collection("User_Details")
  //     .doc(uid)
  //     .set({
  //       CreatedDate: firebase.firestore.FieldValue.serverTimestamp(),
  //       photoURL: "",
  //       First_Name: "",
  //       Last_Name: "",
  //       Email: email,
  //       address: "",
  //       Location: {
  //         Lat: "",
  //         Lng: ""
  //       },
  //       Date_of_Birth: "",
  //       // Ref_Country: "",
  //       // Ref_City: "",
  //       Ref_Gender: 1,
  //       Ref_Interest: [],
  //       Biography: "",
  //       Updated_At: firebase.firestore.FieldValue.serverTimestamp(),
  //     })
  //     .then((res) => {
  //       console.log("res", res);
  //       resolve(res);
  //     })
  //     .catch(function (error) {
  //       reject(error)
  //     });
  //     })
  //   });
  // };
}

//logout
export function logout() {
  return (dispatch) => auth.signOut();
}

//Register user details
export function createAccount(values, uid, file, address, latlng, Country_Code) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(file, values, uid, address, latlng, Country_Code);
      if (file.length === 0) {
        console.log("if");
        db.collection("User_Details")
          .doc(uid)
          .set({
            CreatedDate: firebase.firestore.FieldValue.serverTimestamp(),
            photoURL: "",
            address: address,
            Location: {
              Lat: latlng.lat,
              Lng: latlng.lng
            },
            Country_Code: Country_Code,
            Mobile_Number: values.mobile,
            First_Name: values.firstname,
            Last_Name: values.lastname,
            Date_of_Birth: parseInt(moment(values.dateofbirth).format("X")),
            Ref_Gender: values.gender,
            Ref_Interest: values.interest,
            Biography: values.bio === undefined ? "" : values.bio,
            Updated_At: firebase.firestore.FieldValue.serverTimestamp(),
            // Ref_Country: "",
            // Ref_City: "",
            // Education: {
            //   schoolname: values.schoolname === undefined ? "" : values.schoolname,
            //   schoolfrom: values.schoolfrom === undefined ? "" : parseInt(moment(values.schoolfrom).format("X")),
            //   schoolto: values.schoolto === undefined ? "" : parseInt(moment(values.schoolto).format("X")),
            //   collegename: values.collegename === undefined ? "" : values.collegename,
            //   collegefrom: values.collegefrom === undefined ? "" : parseInt(moment(values.collegefrom).format("X")),
            //   collegeto: values.collegeto === undefined ? "" : parseInt(moment(values.collegeto).format("X")),
            // },
          })
          .then((res) => {
            console.log("res", res);
            resolve(res);
          })
        // .catch(function (error) {
        //   console.log(error)
        //   reject(error);
        // });
      }
      else {
        file.forEach((f) => {
          storage
            .ref("Profile_Images/" + uid)
            .put(f)
            .then(function (snapshot) {
              snapshot.ref.getDownloadURL().then(function (downloadURL) {
                console.log(downloadURL);
                db.collection("User_Details")
                  .doc(uid)
                  .set({
                    CreatedDate: firebase.firestore.FieldValue.serverTimestamp(),
                    photoURL: downloadURL,
                    First_Name: values.firstname,
                    Last_Name: values.lastname,
                    address: address,
                    Country_Code: Country_Code,
                    Mobile_Number: values.mobile,
                    Location: {
                      Lat: latlng.lat,
                      Lng: latlng.lng
                    },
                    Date_of_Birth: parseInt(moment(values.dateofbirth).format("X")),
                    // Ref_Country: "",
                    // Ref_City: "",
                    Ref_Gender: values.gender,
                    // Education: {
                    //   schoolname: values.schoolname === undefined ? "" : values.schoolname,
                    //   schoolfrom: values.schoolfrom === undefined ? "" : parseInt(moment(values.schoolfrom).format("X")),
                    //   schoolto: values.schoolto === undefined ? "" : parseInt(moment(values.schoolto).format("X")),
                    //   collegename: values.collegename === undefined ? "" : values.collegename,
                    //   collegefrom: values.collegefrom === undefined ? "" : parseInt(moment(values.collegefrom).format("X")),
                    //   collegeto: values.collegeto === undefined ? "" : parseInt(moment(values.collegeto).format("X")),
                    // },
                    Ref_Interest: values.interest,
                    Biography: values.bio === undefined ? "" : values.bio,
                    Updated_At: firebase.firestore.FieldValue.serverTimestamp(),
                  })
                  .then((res) => {
                    console.log("res", res);
                    resolve(res);
                  })
                // .catch(function (error) {
                //   reject(error);
                // });
              });
            });
        });
      }
    });
  };
}

//Get interestlist from DB
export function interestList() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      db.collection("MD_Interest").orderBy("Interest_Name", "asc").onSnapshot(function (doc) {
        const interestList = [];
        // console.log(doc)
        doc.forEach(function (doc) {
          //console.log("Current data: ", doc.data());
          interestList.push({
            id: doc.id,
            interestId: doc.data().Interest_Id,
            interestIcon: doc.data().Interest_Icon,
            interestName: doc.data().Interest_Name,
          });
        });
        resolve(interestList);
      });
    });
  };
}


//Get genderlist from DB
export function genderList() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      db.collection("MD_Gender").orderBy("Gender_Id", "asc").onSnapshot(function (doc) {
        const genderList = [];
        doc.forEach(function (doc) {
          //console.log("Current data: ", doc.data());
          genderList.push({
            id: doc.id,
            genderName: doc.data().Gender_Name,
            genderId: doc.data().Gender_Id,
          });
        });
        resolve(genderList);
      });
    });
  };
}

//Get countrylist from DB
export function countryList() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      db.collection("MD_Country").onSnapshot(function (doc) {
        const countryList = [];
        doc.forEach(function (doc) {
          // console.log("Current data: ", doc.data());
          countryList.push({
            id: doc.id,
            countryName: doc.data().Country_Name,
            countryId: doc.data().Country_Id,
          });
        });
        resolve(countryList);
      });
    });
  };
}

//Get citylist from DB
export function cityList() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      db.collection("MD_City").onSnapshot(function (doc) {
        const cityList = [];
        doc.forEach(function (doc) {
          // console.log("Current data: ", doc.data());
          cityList.push({
            id: doc.id,
            cityName: doc.data().City_Name,
            cityId: doc.data().City_Id,
          });
        });
        resolve(cityList);
      });
    });
  };
}

//get profile from DB
export function getProfile(uid) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      // console.log(uid);
      var profileRef = db.collection("User_Details").doc(uid);
      profileRef
        .get()
        .then(function (doc) {
          if (doc.exists) {
            var arr_img = [];
            var img_arr = {
              uid: -1,
              name: doc.data().Last_Name,
              status: "done",
              url: doc.data().photoURL,
            };
            arr_img.push(img_arr);
            // console.log(img_arr);
            // var collto = doc.data().Education.collegeto.toString() === undefined ? "" : doc.data().Education.collegeto.toString();
            // var colltoString = moment.unix(collto).format("YYYY") === undefined ? "" : moment.unix(collto).format("YYYY");
            // var colltoObj = moment(colltoString, "YYYY") === undefined ? "" : moment(colltoString, "YYYY");

            // var collfrom = doc.data().Education.collegefrom.toString() === undefined ? "" : doc.data().Education.collegefrom.toString();
            // var collfromString = moment.unix(collfrom).format("YYYY-MM-DD") === undefined ? "" : moment.unix(collfrom).format("YYYY-MM-DD");
            // var collfromObj = moment(collfromString, "YYYY-MM-DD") === undefined ? "" : moment(collfromString, "YYYY-MM-DD");

            // var schoolfrom = doc.data().Education.schoolfrom.toString() === undefined ? "" : doc.data().Education.schoolfrom.toString();
            // var schoolfromString = moment.unix(schoolfrom).format("YYYY-MM-DD") === undefined ? "" : moment.unix(schoolfrom).format("YYYY-MM-DD");
            // var schoolfromObj = moment(schoolfromString, "YYYY-MM-DD") === undefined ? "" : moment(schoolfromString, "YYYY-MM-DD");

            // var schoolto = doc.data().Education.schoolto.toString() === undefined ? "" : doc.data().Education.schoolto.toString();
            // var schooltoString = moment.unix(schoolto).format("YYYY-MM-DD") === undefined ? "" : moment.unix(schoolto).format("YYYY-MM-DD");
            // var schooltoObj = moment(schooltoString, "YYYY-MM-DD") === undefined ? "" : moment(schooltoString, "YYYY-MM-DD");

            var dob = doc.data().Date_of_Birth.toString();
            var dobString = moment.unix(dob).format("YYYY");
            var dobObj = moment(dobString, "YYYY");

            var createdDate = doc.data().CreatedDate;

            var createdDateString = new Date(createdDate.seconds * 1000);

            var createdDateObj = moment(createdDateString).format('Do MMMM YYYY')

            var data = doc.data();
            var lat = data.Location.Lat
            var lng = data.Location.Lng

            var obj = {
              createdDate: createdDateObj,
              Id: doc.id,
              Biography: doc.data().Biography === undefined ? "" : doc.data().Biography,
              Date_of_Birth: dobObj,
              // collegefrom: collfromObj  === undefined ? "" : collfromObj,
              // collegename: doc.data().Education.collegename,
              // collegeto: colltoObj === undefined ? "" : colltoObj,
              // schoolfrom: schoolfromObj  === undefined ? "" : schoolfromObj,
              // schoolname: doc.data().Education.schoolname,
              // Date_of_Birth: doc.data().Date_of_Birth,
              countryCode: doc.data().Country_Code,
              Mobile_Number: doc.data().Mobile_Number,
              address: doc.data().address,
              Lat: lat,
              Lng: lng,
              // schoolto: schooltoObj  === undefined ? "" : schooltoObj,
              First_Name: doc.data().First_Name,
              Is_Verified: doc.data().Is_Verified,
              Ref_Interest: doc.data().Ref_Interest,
              Last_Name: doc.data().Last_Name,
              // Ref_City: doc.data().Ref_City,
              // Ref_Country: doc.data().Ref_Country,
              Ref_Gender: doc.data().Ref_Gender,
              photoURL: arr_img,
            };
            // console.log(obj);
            dispatch({
              type: GET_PROFILE,
              payload: obj,
            });
            resolve(obj);
          } else {
            console.log("No such document!");
          }
        })
        .catch((err) => {
          console.log("Error getting document", err);
          reject(err);
        });
    });
  };
}

//edit profile function
export function editProfile(values, file, uid, address, latlng, Country_Code) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(file, values, uid, address, latlng, Country_Code);
      if (file.length === 1) {
        file.forEach((f) => {
          storage
            .ref("Profile_Images/" + uid)
            .put(f)
            .then(function (snapshot) {
              snapshot.ref.getDownloadURL().then(function (downloadURL) {
                console.log(downloadURL);
                db.collection("User_Details")
                  .doc(uid)
                  .update({
                    photoURL: downloadURL,
                    First_Name: values.firstname,
                    Last_Name: values.lastname,
                    Date_of_Birth: parseInt(
                      moment(values.dateofbirth).format("X")
                    ),
                    // Ref_Country: "",
                    // Ref_City: "",
                    Ref_Gender: values.gender,
                    address: address,
                    Location: {
                      Lat: latlng.lat,
                      Lng: latlng.lng
                    },
                    Country_Code: Country_Code,
                    Mobile_Number: values.mobile,
                    // Education: {
                    //   schoolname: values.schoolname === undefined ? "" : values.schoolname,
                    //   schoolfrom: values.schoolfrom === undefined ? "" : parseInt(moment(values.schoolfrom).format("X")),
                    //   schoolto: values.schoolto === undefined ? "" : parseInt(moment(values.schoolto).format("X")),
                    //   collegename: values.collegename === undefined ? "" : values.collegename,
                    //   collegefrom: values.collegefrom === undefined ? "" : parseInt(moment(values.collegefrom).format("X")),
                    //   collegeto: values.collegeto === undefined ? "" : parseInt(moment(values.collegeto).format("X")),
                    // },
                    Ref_Interest: values.interest,
                    Biography: values.bio === undefined ? "" : values.bio,
                    Updated_At: firebase.firestore.FieldValue.serverTimestamp(),
                  })
                  .then(function () {
                    //console.log("Frank created");
                    resolve("Done");
                  });
              });
            });
        });
      } else {
        db.collection("User_Details")
          .doc(uid)
          .update({
            First_Name: values.firstname,
            Last_Name: values.lastname,
            Date_of_Birth: parseInt(moment(values.dateofbirth).format("X")),
            // Ref_Country: "",
            // Ref_City: "",
            Ref_Gender: values.gender,
            address: address,
            Location: {
              Lat: latlng.lat,
              Lng: latlng.lng
            },
            Country_Code: Country_Code,
            Mobile_Number: values.mobile,
            // Education: {
            //   schoolname: values.schoolname === undefined  ? "" : values.schoolname,
            //   schoolfrom: values.schoolfrom === undefined ? "" : parseInt(moment(values.schoolfrom).format("X")),
            //   schoolto: values.schoolto === undefined ? "" : parseInt(moment(values.schoolto).format("X")),
            //   collegename: values.collegename === undefined ? "" : values.collegename,
            //   collegefrom: values.collegefrom === undefined ? "" : parseInt(moment(values.collegefrom).format("X")),
            //   collegeto: values.collegeto === undefined ? "" : parseInt(moment(values.collegeto).format("X")),
            // },
            Ref_Interest: values.interest,
            Biography: values.bio === undefined ? "" : values.bio,
            Updated_At: firebase.firestore.FieldValue.serverTimestamp(),
          })
          .then(function () {
            console.log("Frank created");
            resolve("Done");
          });
      }
    });
  };
}

// //edit profile function
// export function editProfile(values, file, uid) {
//   return (dispatch) => {
//     return new Promise((resolve, reject) => {
//       console.log(file, values, uid);
//       if (file ) {
//         file.forEach((f) => {
//           storage
//             .ref("Profile_Images/" + uid)
//             .put(f)
//             .then(function (snapshot) {
//               snapshot.ref.getDownloadURL().then(function (downloadURL) {
//                 console.log(downloadURL);
//                 db.collection("User_Details")
//                   .doc(uid)
//                   .update({
//                     photoURL: downloadURL,
//                     First_Name: values.firstname,
//                     Last_Name: values.lastname,
//                     Date_of_Birth: parseInt(
//                       moment(values.dateofbirth).format("X")
//                     ),
//                     Ref_Country: values.country,
//                     Ref_City: values.city,
//                     Ref_Gender: values.gender,
//                     Education: {
//                       schoolname: values.schoolname,
//                       schoolfrom: parseInt(
//                         moment(values.schoolfrom).format("X")
//                       ),
//                       schoolto: parseInt(moment(values.schoolto).format("X")),
//                       collegename: values.collegename,
//                       collegefrom: parseInt(
//                         moment(values.collegefrom).format("X")
//                       ),
//                       collegeto: parseInt(moment(values.collegeto).format("X")),
//                     },
//                     Ref_Interest: values.interest,
//                     Biography: values.bio,
//                     Updated_At: firebase.firestore.FieldValue.serverTimestamp(),
//                   })
//                   .then(function () {
//                     //console.log("Frank created");
//                     resolve("Done");
//                   });
//               });
//             });
//         });
//       } else {
//         db.collection("User_Details")
//           .doc(uid)
//           .update({
//             First_Name: values.firstname,
//             Last_Name: values.lastname,
//             Date_of_Birth: parseInt(moment(values.dateofbirth).format("X")),
//             Ref_Country: values.country,
//             Ref_City: values.city,
//             Ref_Gender: values.gender,
//             Education: {
//               schoolname: values.schoolname,
//               schoolfrom: parseInt(moment(values.schoolfrom).format("X")),
//               schoolto: parseInt(moment(values.schoolto).format("X")),
//               collegename: values.collegename,
//               collegefrom: parseInt(moment(values.collegefrom).format("X")),
//               collegeto: parseInt(moment(values.collegeto).format("X")),
//             },
//             Ref_Interest: values.interest,
//             Biography: values.bio,
//             Updated_At: firebase.firestore.FieldValue.serverTimestamp(),
//           })
//           .then(function () {
//             console.log("Frank created");
//             resolve("Done");
//           });
//       }
//     });
//   };
// }


//removeprofileimage

export function removeprofileimage(uid) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      db.collection("User_Details")
        .doc(uid)
        .update({
          photoURL: "",
        })
        .then(function () {
          resolve("Done");
        });
    });
  };
}

//Get all user list from DB
export function getallUsers() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      db.collection("User_Details")
        .limit(7)
        .onSnapshot(function (doc1) {
          const userList = [];
          var itemProcessed = 0;
          doc1.forEach(function (doc) {
            itemProcessed++;
            // console.log("Current data: ", doc.data());
            userList.push({
              Id: doc.id,
              photoURL: doc.data().photoURL,
              First_Name: doc.data().First_Name,
              Last_Name: doc.data().Last_Name,
              Date_of_Birth: doc.data().Date_of_Birth,
              // Ref_Country: doc.data().Ref_Country,
              // Ref_City: doc.data().Ref_City,
              Ref_Gender: doc.data().Ref_Gender,
              Education: doc.data().Education,
              Interest_Passion: doc.data().Interest_Passion,
              Biography: doc.data().Biography,
              Updated_At: doc.data().Updated_At,
            });

            // console.log("Current data: ", doc1.size);
            if (itemProcessed === doc1.size) {
              dispatch({
                type: GET_ALL_USER,
                payload: userList,
              });
              resolve(userList);
            }
          });
        });
    });
  };
}

// get all user details from DB
export function getallUserList(uid) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      db.collection("User_Details")
        .limit(6)
        .onSnapshot(function (doc1) {
          const userList = [];
          var itemProcessed = 0;
          doc1.forEach(function (doc) {
            itemProcessed++;
            // console.log("Current data: ", doc.id);
            userList.push({
              Id: doc.id,
              photoURL: doc.data().photoURL,
              First_Name: doc.data().First_Name,
              Last_Name: doc.data().Last_Name,
              Date_of_Birth: doc.data().Date_of_Birth,
              // Ref_Country: doc.data().Ref_Country,
              // Ref_City: doc.data().Ref_City,
              Ref_Gender: doc.data().Ref_Gender,
              Education: doc.data().Education,
              Interest_Passion: doc.data().Interest_Passion,
              Biography: doc.data().Biography,
              Updated_At: doc.data().Updated_At,
              Follower_id: "",
              Follower_type: "",
              User_id: doc.id,
              circle_id: "",
            });

            // console.log("Current data: ", doc1.size);
            if (itemProcessed === doc1.size) {
              var userList1 = userList.filter(function (obj) {
                return obj.Id !== uid;
              });
              // var userList1 = _.reject(userList, function(el) { return el.Id === uid; });
              // console.log("Current data: ", userList1);
              dispatch({
                type: GET_ALL_USER,
                payload: userList1,
              });
              resolve(userList1);
            }
          });
        });
    });
  };
}

export function getallUserLists(uid) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      db.collection("User_Details").orderBy("CreatedDate", "desc")
        .onSnapshot(function (doc1) {
          const userList = [];
          var itemProcessed = 1;
          doc1.forEach(function (doc) {
            var value = itemProcessed++;
            // console.log("Current data: ", doc.id);
            userList.push({
              Key: value,
              Id: doc.id,
              CreatedDate: moment
                .unix(doc.data().CreatedDate.seconds)
                .format("MMMM Do YYYY"),
              Mobile_Number: doc.data().Mobile_Number,
              photoURL: doc.data().photoURL,
              First_Name: doc.data().First_Name,
              Last_Name: doc.data().Last_Name,
              Date_of_Birth: doc.data().Date_of_Birth,
              // Ref_Country: doc.data().Ref_Country,
              // Ref_City: doc.data().Ref_City,
              Ref_Gender: doc.data().Ref_Gender,
              Education: doc.data().Education,
              Interest_Passion: doc.data().Interest_Passion,
              Biography: doc.data().Biography,
              Username: doc.data().Username,
              Updated_At: doc.data().Updated_At,
              Follower_id: "",
              Follower_type: "",
              User_id: doc.id,
              circle_id: "",
            });

            // console.log("Current data: ", doc1.size);
            if (itemProcessed === doc1.size) {
              var userList1 = userList.filter(function (obj) {
                return obj.Id !== uid;
              });
              // var userList1 = _.reject(userList, function(el) { return el.Id === uid; });
              // console.log("Current data: ", userList1);
              dispatch({
                type: GET_ALL_USER,
                payload: userList1,
              });
              resolve(userList1);
            }
          });
        });
    });
  };
}


export function getallUserDeviceDetails(uid) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      db.collection("User_Device_Details")
        .onSnapshot(function (doc1) {
          const userList = [];
          var itemProcessed = 0;
          doc1.forEach(function (doc) {
            itemProcessed++;
            // console.log("Current data: ", doc.id);
            userList.push({
              Device_Make: doc.data().Device_Make,
              Device_Model: doc.data().Device_Model,
              Device_OS: doc.data().Device_OS,
              Device_Version: doc.data().Device_Version
            })
            if (itemProcessed === doc1.size) {
              var userList1 = userList.filter(function (obj) {
                return obj.Id !== uid;
              });
              // var userList1 = _.reject(userList, function(el) { return el.Id === uid; });
              // console.log("Current data: ", userList1);
              dispatch({
                type: GET_ALL_USER,
                payload: userList1,
              });
              resolve(userList1);
            }
          });
        });
    });
  };
}




