import React, { Component } from "react";
import { connect } from "react-redux";
import {
  List, Input, Button, message, Card,
  Icon, Checkbox, Popconfirm,
} from 'antd';
// import _ from "lodash";
import moment from 'moment';
import { db } from "../firebase";

const todoRef = db.collection("Future_Logs");

const { Search } = Input;

class FutureLogs extends Component {
  constructor() {
    super()
    this.state = {
      todos: [],
      items: [],
      search: false,
    }
    this.handleChange = this.handleChange.bind(this);
    this.addTodo = this.addTodo.bind(this);
    this.filterList = this.filterList.bind(this);
    this.resetForm = this.resetForm.bind(this);
  }


  addTodo = (e) => {
    e.preventDefault()
    if (this.state.todo === '' || this.state.todo === undefined) {
      document.getElementById("myDIV1").classList.add("error-class");
    }
    else {
      // console.log(this.props.user.uid)
      const ref = todoRef.doc()
      ref.set({
        task: this.state.todo,
        createdAt: moment().format('MMMM Do YYYY, HH:mm'),
        done: false,
        id: ref.id,
        author: this.props.user.uid,
      })
        .then(function (docRef) {
          // console.log(docRef)
          message.success("Log Added")

        })
        .catch(function (error) {
          // console.error("Error adding document: ", error);
        });
      this.setState({
        todo: '',
      })
    }
  }


  onChange(e) {
    // console.log(e);
  }

  confirm(id) {
    this.deleteTodo(id)
    // message.success('Log deleted');
  }

  cancel(e) {
    // console.log(e);
    // message.error('Click on No');
  }


  updateTodo = (id, done) => {
    const ref = todoRef.doc(id)
    ref.update({
      done: !done,
      updateAt: moment().format('MMMM Do YYYY, HH:mm')
    })
      .then(function (docRef) {
        // console.log(docRef)
        message.info("Log state update")
      })
    // .catch(function (error) {
    //   console.error("Error adding document: ", error);
    // });
  }


  deleteTodo = (id) => {
    todoRef.doc(id).delete().then(function () {
      // console.log("Document successfully deleted!");
      message.error("Log deleted")
    }).catch(function (error) {
      // console.error("Error removing document: ", error);
    });
  }


  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  //search the filteredLogs in todos
  filterList = (e) => {
    if (e.target.value === "") {
      this.setState({
        search: false,
        filteredLogs: ''
      })
    } else {
      this.setState({
        search: true
      })

      let updateList = this.state.todos;
      let updateList1 = updateList.filter(todo =>
        todo.task.toLowerCase().includes(
          e.target.value.toLowerCase()
        )
      );
      // console.log(updateList1);
      this.setState({
        filteredLogs: updateList1
      });

    }
  }


  componentWillMount() {
    todoRef.where("author", "==", this.props.user.uid).orderBy('createdAt', "desc")
      .onSnapshot((docSnapShot) => {
        let todos = []
        docSnapShot.forEach(doc => {
          // console.log('Document:', doc.data().id);
          todos.push(doc.data())
        })
        this.setState({
          todos,
          loaded: true
        })
      })
  }


  resetForm = (e) => {
    this.setState({
      todo: '',
    })
  }


  renderTodoList() {
    const { todos, filteredLogs, search } = this.state;
    const listHeader = (
      <div className="row">
        <div className="col-md-6 col-xs-12" style={{ paddingTop: '10px' }}>
          <h4 className="list-head-text">Future Logs</h4>
        </div>
        <div className="col-md-2 hidden-sm hidden-xs">
        </div>
        <div className="col-md-4 col-sm-6 col-xs-12 text-right">
          {/* <Input type="text" onChange={this.filterList}/> */}
          <Search
            placeholder="search logs" allowClear
            onChange={this.filterList}
            style={{ width: '100%' }}
          />
        </div>
      </div>
    );

    return (
      <List
        header={<div><h4 className="list-head-text">Future Logs</h4></div>}
        header={listHeader}
        pagination={{
          onChange: page => {
          },
          pageSize: 10,
        }}
        bordered
        dataSource={(!search) ? todos : filteredLogs}
        renderItem={todo => <List.Item >
          <Checkbox onChange={() => this.updateTodo(todo.id, todo.done)}
            checked={todo.done === true ? true : false}
          ></Checkbox>
          <span className={todo.done === true ? "list-align-left done" : "list-align-left"}>
            {todo.task} </span>
          <div className="list-align-right">
            <span className="posttime">{todo.createdAt}</span>
            <Popconfirm
              title="Are you sure delete this log?"
              onConfirm={() => this.confirm(todo.id)}
              onCancel={() => this.cancel()}
              okText="Yes"
              cancelText="No">
              <a href="#"
              ><Icon type="delete" /></a>
            </Popconfirm>
          </div>
        </List.Item>}
      />
    )
  }


  render() {
    return (
      <div className="row list-head">
        <Card>
          <form onSubmit={this.addTodo} onReset={this.resetForm}>
            <div className="" >
              <div className="col-md-offset-2 col-md-10 col-md-offset-2 list-head">
                <div className="col-md-8">
                  <Input
                    prefix={<Icon type="tag" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    placeholder="Add your long term goal" name="todo" id="myDIV1" maxLength="200"
                    onChange={this.handleChange} value={this.state.todo}
                  />
                </div>
                <div className="col-md-2">
                  <Button type="primary" htmlType="submit"
                    onClick={this.addTodo}>Add
               </Button>
                </div>
              </div>
            </div>
          </form>
          <div className="row">
            <div className="col-md-12" style={{ padding: '10px 30px' }}>
              {this.renderTodoList()}
            </div>
          </div>
        </Card>
      </div>
    );
  }
}



function mapStateToProps(state, ownProps) {
  return { user: state.user };
}

export default connect(
  mapStateToProps,
  {}
)(FutureLogs);