import React, { Component } from "react";
import { connect } from "react-redux";
import { createAccount } from "./actions/userAction";
import './App.css';
import { Link } from "react-router-dom";
import { Card } from "antd";
import Logo from './assests/img/logo-new.svg';
import Work from './assests/img/header-img.svg';
import Post from './assests/img/post.svg';
import Share from './assests/img/share.svg';
import Likes from './assests/img/likes.svg';
import Follow from './assests/img/follow.svg';
import Profile from './assests/img/profile.svg';
import Logs from './assests/img/logs.svg';
import Googleplay from './assests/img/playstore.png';
import Applestore from './assests/img/appstore.png';
// import history from './history';
import './assests/css/styles.css';


class Home extends Component {

  constructor(props) {
    super(props);
    this.state = {
      new_year: new Date().getFullYear()
    };
  }

  componentDidMount() {
    if (this.props.user !== null) {
      this.props.history.push('/feeds');
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.user !== null) {
      nextProps.history.push('/feeds');
    }
  }
  render() {
    return (
      <div>
        <div className="container-fluid homebanner">
          <nav className="navbar navbar-default">
            <div className="container-fluid navcontainer">
              <div className="navbar-header">
                <div className="navbar-toggle">
                  <Link to="/login"><button className="btn loginBtn color-1">Sign in
                </button></Link>
                </div>
                <Link className="navbar-brand" to="#">
                  <img
                    src={Logo}
                    className="logoimg img-responsive"
                    style={{ height: '40px' }}
                    alt="logo"
                  />
                </Link>
              </div>
              <div id="navbar" className="navbar-collapse collapse">
                <ul className="nav navbar-nav navbar-right">
                  <li><Link to="/login"><button className="btn loginBtn color-1">Sign in
                </button></Link>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6">
                <img src={Work} alt="social-media"
                  className="img-responsive" />
              </div>
              <div className="col-md-6 hidden-xs">
                <h4>
                  Reflect and inspire
              </h4>
                <div className="">
                  <h5 style={{
                    marginTop: '25px', marginBottom: '25px',
                    fontWeight: '600', color: '#fff', fontSize: '20px'
                  }}>Log, vibe, network and celebrate life with LIVATHON.</h5>
                  <h5 style={{
                    marginTop: '25px', marginBottom: '25px',
                    fontWeight: '600', color: '#fff'
                  }}>Also available in googleplay & appstore.</h5>

                  <a href="https://play.google.com/store/apps/details?id=com.livathon.android" target="_blank" rel="noopener noreferrer">
                    <img src={Googleplay} className="img-responsive"
                      style={{
                        display: 'inline-block',
                        marginRight: '20px'
                      }}
                      alt="googleplay" />
                  </a>
                  <a href="https://apps.apple.com/in/app/livathon/id1536365366" target="_blank" rel="noopener noreferrer">
                    <img src={Applestore} className="img-responsive"
                      style={{ display: 'inline-block' }}
                      alt="googleplay" />
                  </a>
                </div>
              </div>
              <div className="col-md-6 hidden-lg hidden-md text-center">
                <h4 style={{ color: '#1141e1' }}>
                  Reflect and inspire
              </h4>
                <div className="">
                  <h5 style={{
                    marginTop: '25px', marginBottom: '25px',
                    fontWeight: '600', color: '#333', fontSize: '20px'
                  }}>Log, vibe, network and celebrate life with LIVATHON.</h5>
                  <h5 style={{
                    marginTop: '25px', marginBottom: '25px',
                    fontWeight: '600', color: '#333'
                  }}>Also available in googleplay & appstore.</h5>

                  <a href="https://play.google.com/store/apps/details?id=com.livathon.android" target="_blank" rel="noopener noreferrer">
                    <img src={Googleplay} className="img-responsive"
                      style={{
                        display: 'inline-block',
                        marginBottom: '20px'
                      }}
                      alt="googleplay" />
                  </a>
                  <a href="https://apps.apple.com/in/app/livathon/id1536365366" target="_blank" rel="noopener noreferrer">
                    <img src={Applestore} className="img-responsive"
                      style={{ display: 'inline-block' }}
                      alt="googleplay" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid hero ptb-50" id="features">
          <div className="container text-center">
            <h4>About Livathon</h4>
            <p>
              Self reflecting your soul aided with networking for your
              dreams are the fundamental drives of Livathon
      </p>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-4 col-sm-4">
                <Card style={{ marginBottom: '15px' }}>
                  <img
                    src={Post}
                    className="featureimg img-responsive"
                    alt="logo"
                  />
                  <h6>Generate your portfolio</h6>
                  <p>
                    Share your best moments to the world.
                    Show your talents, skills through photos & videos to the world.
            </p>
                </Card>
              </div>
              <div className="col-md-4 col-sm-4">
                <Card style={{ marginBottom: '15px' }}>
                  <img
                    src={Likes}
                    className="featureimg img-responsive"
                    alt="logo"
                  />
                  <h6>Record your diary.</h6>
                  <p>
                    Update your thoughts, opinions, day to day activities right from your heart.
                    All our user memories will be treated equally since the diaries cannot be liked ,
                    commented or shared .
                </p>
                </Card>
              </div>
              <div className="col-md-4 col-sm-4">
                <Card style={{ marginBottom: '15px' }}>
                  <img
                    src={Share}
                    className="featureimg img-responsive"
                    alt="logo"
                  />
                  <h6>Network for a cause</h6>
                  <p>
                    Connect with new people around you based on
                    shared interest , activities and hobbies.
                </p>
                </Card>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid hero1 ptb-50">
          <div className="container">
            <div className="row text-center">
              <h1 className="follow">This platform is for all backgrounds of people who wish to
              contribute positivity, originality and
                ambition around the world.</h1>
              <Link to="/login">
                <button className="btn loginBtn color-1">Explore Now</button>
              </Link>
            </div>
          </div>
        </div>

        <div className="container-fluid hero ptb-50">
          <div className="container">
            <h4>
              Find a way to connect with your circle and choose the community in your interest
            </h4>
            <p>Explore More Activities</p>
          </div>
          <div className="container text-left">
            <div className="row">
              <div className="col-sm-6">
                <h3 className="interesttext">Smart Networking</h3>
                <p><b>Networking that matters is helping  people achieve their goals – Seth Godin.</b></p>
                <p>
                  List your goals set your targets  and get connected with  people
                  around you  who share the same  dreams, drive and enthusiasm
                  to achieve it together.
                </p>
              </div>
              <div className="col-sm-6">
                <img
                  src={Follow}
                  className="img-responsive undrawimg"
                  alt="high"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <img
                  src={Logs}
                  className="img-responsive undrawimg"
                  alt="high"
                />
              </div>
              <div className="col-sm-6">
                <h3 className="interesttext">Diary</h3>
                <p><b>Our self image strongly held, essentially determines what we become </b></p>
                <p>
                  Register your opinions, thoughts,  day to day activities and plan your future in this section
                  without being validated by other users. There is nothing more empowering and liberating than
                  voicing our unfiltered consciousness  to the world .
                </p>
              </div>

            </div>
            <div className="row">

              <div className="col-sm-6">
                <h3 className="interesttext">Follow profiles</h3>
                <p>
                  Follow interesting people , check their profile  , read their diary  and initiate networking.
                </p>
              </div>
              <div className="col-sm-6">
                <img
                  src={Profile}
                  className="img-responsive undrawimg"
                  alt="high"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid footer" id="footer">
          <div className="container footer-top">
            <div className="row">
              <div className="col-sm-6">
                <Link to="/">
                  <img
                    src={Logo}
                    style={{ height: '40px' }}
                    className="logoimg img-responsive"
                    alt="logo"
                  />
                </Link>

                <p>Log, Vibe, Network and celebrate life with livathon.</p>
              </div>
              <div className="col-sm-3" style={{ marginTop: '40px' }}>
                <h4 style={{ color: 'white' }}>QUICK LINKS</h4>
                <ul>
                  <li>
                    <Link to="/privacy-policy">
                      Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <Link to="/terms-and-conditions">
                      Terms and conditions
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col-sm-3" style={{ marginTop: '40px' }}>
                <h4 style={{ color: 'white' }}>CONTACT US</h4>
                <p>
                  Email: <a href="mailto:support@livathon.com" style={{ color: '#ffcb52' }}>support@livathon.com</a>
                </p>
              </div>
            </div>
            <div className="copyright text-center">
              <p style={{ color: '#ffcb52' }}>{this.state.new_year}, Livathon, All rights reserved</p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return { user: state.user };
}

export default connect(
  mapStateToProps,
  { createAccount }
)(Home);
