import React, { Component } from "react";
// import Navigation from '../common/header';
import Logo from '../assests/img/logo-new-dark.svg';
import {
    Collapse, Icon
} from 'antd';
import { Link } from "react-router-dom";
import { connect } from "react-redux";


const { Panel } = Collapse;

class TermsofService extends Component {
    constructor(props) {
        super(props);
        this.state = {
            new_year: new Date().getFullYear()
        }
    }


    render() {
        return (
            <div>
                <div className="container-fluid">
                    <nav className="navbar navbar-default">
                        <div className="container-fluid navcontainer">
                            <div className="navbar-header">
                                <div className="navbar-toggle">
                                    {/* <Signup /> */}
                                </div>
                                <Link className="navbar-brand" to="">
                                    <img
                                        src={Logo}
                                        className="logoimg img-responsive"
                                        alt="logo"
                                        style={{ height: '35px' }}
                                    />
                                </Link>
                            </div>
                            <div id="navbar" className="navbar-collapse collapse">
                                <ul className="nav navbar-nav navbar-right" style={{ marginTop: '30px' }}>
                                    {/* <li><Button className="btn loginBtn color-1"
                                            onClick={() => this.props.logout()} style={{ margin: '0' }}>
                                            Logout
                                          </Button>
                                        </li> */}
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12" style={{ marginTop: '50px' }}>
                            <h3 style={{ color: '#1C41CE', fontWeight: 'bold' }}
                            ><Link to="/"><Icon type="arrow-left" /></Link> Terms and Conditions</h3>
                            <p className="privacyterms">These terms and conditions apply to the use of a Livathon
                            mobile and websites that post a link to these Terms and Conditions made available by
                             <span style={{ fontWeight: 'bold' }}> LIVATHON</span>. Use of our applications ("Apps") are governed by the terms you accepted at
                            the time of purchase or download, these Terms and Conditions and the terms of your
                            mobile service provider and mobile device operator. Our social media pages  are delivered
                            to you in accordance with these Terms and Conditions and the terms of use of each of
                            the Social Media operators. By accessing or using the Site, Apps and Social Media, you
                            agree to be bound by these Terms and Conditions. These Terms and Conditions expressly
                            incorporate by reference and include the Site's Privacy Policy and any guidelines,
                            rules, additional terms or disclaimers that may be posted and updated on the Site or
                            on notices that are sent to you, the License Agreement and the Terms of Use. If you do
                            not agree with these Terms and Conditions, please do not use the Site.
                            </p>
                            <div>
                                <h4 style={{
                                    color: '#1C41CE',
                                    fontWeight: 'bold',
                                    marginTop: '30px',
                                    marginBottom: '30px'
                                }}>1. Changes</h4>
                                <p className="privacyterms">LIVATHON reserves the right to change, modify, add or remove portions of these
                                Terms and Conditions in its sole discretion at any time and without prior notice. Please check these Terms
                                and Conditions periodically for any modifications. Your continued use of the Site following the posting of
                                any changes will mean that you have accepted and agreed to the changes.</p>
                            </div>
                            <div>
                                <h4 style={{
                                    color: '#1C41CE',
                                    fontWeight: 'bold',
                                    marginTop: '30px',
                                    marginBottom: '30px'
                                }}>2. Content</h4>
                                <p className="privacyterms">All content contained on or accessed from the Site, including text, graphics, user
                                interfaces, visual interfaces, photographs, trademarks, logos, videos, images, applications, programs,
                                computer code and other information, including but not limited to the design, layout, look and feel and
                                arrangement of such Content, is owned by Livathon or its licensors and is protected by copyright, trademark,
                                patent and other intellectual property and unfair competition laws. You may print or download Content from the
                                Site for your own personal, non-commercial, informational or scholarly use, provided that you keep intact all
                                copyright and other proprietary notices.</p>
                            </div>
                            <div>
                                <h4 style={{
                                    color: '#1C41CE',
                                    fontWeight: 'bold',
                                    marginTop: '30px',
                                    marginBottom: '30px'
                                }}>3. Restrictions</h4>
                                <p className="privacyterms">You may not copy, display, distribute, modify, publish, reproduce, store, transmit,
                                post, translate or create other derivative works from, or sell, rent or license all or any part of the Content,
                                products or services obtained from the Site in any medium to anyone, except as otherwise expressly permitted
                                under these Terms and Conditions, relevant license or subscription agreement or authorization by us.</p>

                                <p className="privacyterms">You may not reverse engineer, disassemble, decompile or translate any software in the
                                Content, or otherwise attempt to derive the source code of such software, except to the extent expressly
                                permitted under applicable law, without our prior written permission. You may not engage in systematic retrieval
                                of Content from the Site to create or compile, directly or indirectly, a collection, compilation, database or
                                directory without our prior written permission. Unless expressly authorized by us, you may not use any robots,
                                spiders, crawlers or other automated downloading programs, algorithms or devices, or any similar or equivalent
                                manual process, to: (i) continuously and automatically search, scrape, extract, deep link or index any Content;
                                (ii) harvest personal information from the Site for purposes of sending unsolicited or unauthorized material; or
                                (iii) cause disruption to the working of the Site or any other person's use of the Site. If the Site contains
                                robot exclusion files or robot exclusion headers, you agree to honour them and not use any device, software or
                                routine to bypass them. You may not attempt to gain unauthorized access to any portion or feature of the Site,
                                any other systems or networks connected to the Site any of the products or services provided on, accessed from
                                or distributed through the Site. You may not probe, scan or test the vulnerability of the Site or any network
                                connected to the Site or breach the security or authentication measures on the Site or any network connected
                                to the Site.</p>

                                <p className="privacyterms">You may not use the Site to publish or distribute any information (including
                                software or other content) which is illegal, which violates or infringes upon the rights of any other
                                person, which is defamatory, abusive, hateful, profane, pornographic, threatening or vulgar, which
                                contains errors, viruses or other harmful components, or which is otherwise actionable at law. Livathon
                                may at any time exercise editorial control over the Content of the Site. You explicitly agree not to
                                provide to us any information that you are not authorized to disclose. You may not, without the approval
                                of Livathon, use the Site to publish or distribute any advertising, promotional material, or solicitation
                                to other users of the Site to use any goods or services. For example (but without limitation), you may
                                not use the Site to conduct any business, to solicit the performance of any activity that is prohibited
                                by law, or to solicit other users to become subscribers of other information services. Similarly, you
                                may not use the Site to download and redistribute public information or shareware for personal gain or
                                distribute multiple copies of public domain information or shareware.</p>

                                <p className="privacyterms">
                                    You may not use the Site to publish or distribute any information (including software or other content) which
                                    is illegal, which violates or infringes upon the rights of any other person, which is defamatory, abusive,
                                    hateful, profane, pornographic, threatening or vulgar, which contains errors, viruses or other harmful
                                    components, or which is otherwise actionable at law. Livathon may at any time exercise editorial control
                                    over the Content of the Site. You explicitly agree not to provide to us any information that you are not
                                    authorized to disclose. You may not, without the approval of Livathon, use the Site to publish or distribute
                                    any advertising, promotional material, or solicitation to other users of the Site to use any goods or services.
                                    For example (but without limitation), you may not use the Site to conduct any business, to solicit the
                                    performance of any activity that is prohibited by law, or to solicit other users to become subscribers of
                                    other information services. Similarly, you may not use the Site to download and redistribute public
                                    information or shareware for personal gain or distribute multiple copies of public domain information or
                                    shareware.
                                </p>
                                <p className="privacyterms">
                                    You shall not obtain or attempt to obtain lists of users or other information from or through
                                    the Site, Apps or Social Media and you shall not use the Site, Apps or Social Media in violation of
                                    any applicable law, ordinance or regulation. We are not responsible to monitor your use of the
                                    Site, Apps and Social Media or to determine if such use violates any applicable law, ordinance or
                                    regulation. Please govern your use accordingly.
                                </p>
                            </div>
                            <div>
                                <div>
                                    <h4 style={{
                                        color: '#1C41CE',
                                        fontWeight: 'bold',
                                        marginTop: '30px',
                                        marginBottom: '30px'
                                    }}>4. Submitted Material</h4>
                                    <p className="privacyterms">
                                        Any information, creative works, ideas, testimonials, suggestions, concepts, methods, systems,
                                        designs, plans, techniques or other materials submitted that you send to us or post on or through
                                        the Site, Apps or Social Media (including, for example items that you submit or post on our Facebook
                                        page, our message boards, in survey responses, on our blogs, or send to us by e-mail)
                                        ("Submitted Materials") shall be deemed not to be confidential or secret, and may be used by us in
                                        any manner consistent with our Privacy Policy. By submitting or sending Submitted Materials to us
                                        or posting it on or through the Site, Apps or Social Media, you represent and warrant that the
                                        Submitted Materials are original to you, that no other party has any rights thereto, and that any
                                        "moral rights" in Submitted Materials have been waived. We are not responsible for maintaining any
                                        Submitted Material that you provide to us, and we may delete or destroy any such Submitted Material
                                        at any time and for any reason and without advance notice to you.
                                     </p>
                                    <p className="privacyterms">
                                        By submitting or sending Submitted Materials to us or posting it on or through the Site, Apps or
                                        Social Media, you grant us and our subsidiaries and affiliates a royalty-free, unrestricted, worldwide,
                                        perpetual, irrevocable, non-exclusive and fully transferable, assignable and sub-licensable right and
                                        license to use, copy, reproduce, modify, adapt, publish, translate, create derivative works from,
                                        distribute, perform, display and incorporate in other works any Submitted Materials (in whole or part)
                                        in any form, media, or technology now known or later developed, including for promotional and/or
                                        commercial purposes. You agree that we may use, or permit others to use, without charge, your name,
                                        biography, picture and likeness with the content you submit and otherwise in materials promoting,
                                        marketing or advertising the Site, Apps or Social Media or our goods or services, in any media,
                                        without any compensation or advance notice to you.
                                     </p>
                                </div>
                                <div>
                                    <h4 style={{
                                        color: '#1C41CE',
                                        fontWeight: 'bold',
                                        marginTop: '30px',
                                        marginBottom: '30px'
                                    }}>5. Diary & Networking</h4>
                                    <p className="privacyterms">
                                        Our Site, the Apps and Social media contain  diary ,Networking and other areas where you can post
                                        information and view information that has been posted by other users without our advance review.
                                        You agree to follow all applicable third party rules and terms of use related to your use of the Apps
                                        and Social Media. In addition, you agree that you shall not upload, post, transmit, distribute or otherwise
                                        publish through the Site, Apps or Social Media any content which ,
                                        <ul className="privacylist">
                                            <li>Prevents any other user from using and enjoying the Site, Apps or Social Media</li>
                                            <li>is fraudulent, unlawful, threatening, abusive, harassing, libellous, defamatory, obscene, vulgar,
                                                offensive, pornographic or profane</li>
                                            <li>constitutes or encourages conduct that would constitute a criminal offense, give rise to civil
                                            liability or otherwise violate any local, state, national or international law, ordinance or
                                                regulation</li>
                                            <li>violates or infringes the rights of third parties including, without limitation, copyright, trademark,
                                            trade secret, confidentiality, contract, patent, rights of privacy or publicity or any other proprietary
                                                right</li>
                                            <li>contains a virus, spyware, or other harmful component</li>
                                            <li>contains embedded links, advertising, chain letters or pyramid schemes of any kind, or</li>
                                            <li>constitutes or contains false or misleading indications of origin, endorsement or statements of fact.
                                            You further agree not to impersonate any other person or entity, whether actual or fictitious,
                                            including us or our personnel. You also may not offer to buy or sell any product or service on or
                                            through your comments. You alone are responsible for the content and consequences of any posts and
                                            activities made from your account. Do not provide anyone else with your login, password or credentials
                                                that would permit them to impersonate you.</li>
                                        </ul>
                                    </p>
                                    <p className="privacyterms">
                                        We do not claim ownership of any content, application or other material that you or third parties
                                        provide to us (including feedback and suggestions) or post, upload, input or submit on or through
                                        the Site, including our blog pages, message boards, chat rooms and forums, for review by the general
                                        public, registered users of the Site or by the members of any public or private community, testimonials
                                        to be executed with religious leaders/celebrities for recording and telecasting their
                                        speeches/audio-video recording, for sole purpose of advertising to the users of the App,
                                        ("Submission") and we are not responsible for their content, accuracy or compliance with relevant
                                        laws or regulations. However, by posting, uploading, inputting, providing or submitting ("Posting")
                                        your Submission you grant us and sub-licensees a royalty-free, perpetual, irrevocable, worldwide,
                                        non-exclusive right and license to display, publish and otherwise use your Submission in any format
                                        in connection with the operation of our respective businesses (including, without limitation, the Site).
                                        We are under no obligation to display or otherwise use any Submission you may provide, and we may
                                        remove any Submission at any time in our sole discretion. By Posting a Submission, you also warrant
                                        and represent that you own or otherwise control all of the rights to your Submission including,
                                        without limitation, all the rights necessary for granting the permission specified above. We shall
                                        have the right, but not the obligation, to monitor Submissions to determine compliance with these
                                        Terms and Conditions and any operating rules we establish and to satisfy any law, regulation or
                                        authorized government request. We shall have the right in our sole discretion to edit, refuse to
                                        post, or remove any Submission.
                                    </p>
                                </div>
                                <div>
                                    <h4 style={{
                                        color: '#1C41CE',
                                        fontWeight: 'bold',
                                        marginTop: '30px',
                                        marginBottom: '30px'
                                    }}>6. Mobile Devices/Third Party Fees</h4>
                                    <p className="privacyterms">
                                        From time to time Livathon makes mobile device application and mobile marketing campaigns available
                                        to you and may allow you to register for services that involve messages and/or e-mails being sent
                                        to your mobile device. You are responsible for obtaining access to the mobile device services and
                                        that access may involve third-party fees, including mobile carrier, text messaging, or airtime
                                        charges. You are solely responsible for those fees, including any and all fees associated with the
                                        use of the applications, delivery of the messages, e-mails or other materials to your mobile device.
                                        Please consult your mobile service provider's pricing plan prior to purchasing or downloading any
                                        application or registering for any such service to determine the charges for sending and receiving
                                        text messages from/to your mobile device. You will be given the opportunity to opt-out of receiving
                                        text messages or emails to your mobile device at the time you register and with each message or email
                                        you receive.
                                    </p>
                                    <p className="privacyterms">
                                        Mobile marketing campaigns may involve your sending an e-mail from your mobile device to Livathon
                                        or a third-party campaign operator. An e-mail or other response will then be sent to your mobile
                                        device. Opting to participate in a campaign is your choice. When you engage in these mobile
                                        marketing campaigns, Livathon and the third-party campaign operator may collect and store your
                                        cell phone number, the name of your wireless service provider, images that you send using your
                                        cell phone as part of the campaign, and other relevant information.
                                    </p>
                                    <p className="privacyterms">
                                        We use third-party advertising, data analytics and online targeting companies to serve ads when you visit
                                        our sites or use our applications. We may also provide certain personal identifiable information and other
                                        information to these third-parties to allow them to more effectively target advertising to you. When
                                        advertisements are displayed to you on our sites and applications, one or more persistent cookies will
                                        be placed on your computer or device by us or a third party. Some of these third parties may use
                                        information (including personal identifiable information) about your visits to this site and other
                                        websites and usage of applications in order to provide data and targeting recommendations based on which
                                        you may receive advertisements about goods and services of interest to you.
                                    </p>
                                </div>
                                <div>
                                    <h4 style={{
                                        color: '#1C41CE',
                                        fontWeight: 'bold',
                                        marginTop: '30px',
                                        marginBottom: '30px'
                                    }}>7. Systems Reliability</h4>
                                    <p className="privacyterms">
                                        <span style={{ fontWeight: 'bold' }}>LIVATHON</span> aims to keep the Site available twenty-four (24)
                                        hours a day, seven (7) days a week and to maintain saved information. However, Livathon shall not be
                                        liable for lost altered, or corrupted information or non-availability of the Site. You acknowledge and
                                        understand that the violations of system or network security are strictly prohibited, and may result in
                                        criminal and civil liability. Livathon investigates all incidents involving such violations and will
                                        cooperate with law enforcement if a criminal violation is suspected. You are also prohibited from
                                        creating and sending malicious programs into the network or server which are executables intended to
                                        inflict harm. You shall not effect security breaches or disruptions of internet communication and/or
                                        connectivity. Security breaches include, but are not limited to, accessing data of which you are not
                                        an intended recipient or logging into a server or account that you are not expressly authorized to
                                        access. You shall not be involved in activities that disrupt the use of or interfere with the ability
                                        of others to effectively use the network or any connected network, system, service, or equipment is
                                        prohibited. Violators of the policy are responsible, without limitations, for the cost of labour to
                                        correct all damage done to the operation of the network and business operations supported by the
                                        network. Network interference by you that may cause or is currently causing network interference
                                        with other users will be disconnected immediately.
                                    </p>
                                </div>
                                <div>
                                    <h4 style={{
                                        color: '#1C41CE',
                                        fontWeight: 'bold',
                                        marginTop: '30px',
                                        marginBottom: '30px'
                                    }}>8. Disclaimer of Warranties and Liability</h4>
                                    <p className="privacyterms">
                                        You expressly agree that use of the Services is at your sole risk. The Services are provided on an "as is"
                                        and "as available" basis. Livathon makes any warranty whatsoever, including without limitation, that the
                                        operation of the Site or the Services will be timely, secure, uninterrupted or error-free; that any defects
                                        will be corrected; that the Site, including the server that makes it available, is free of viruses or
                                        other harmful components; or as to the accuracy, completeness, reliability, availability, suitability,
                                        quality, non-infringement, operation or result obtained from the use of any Content, product or service
                                        provided on, accessible from or distributed through the Site. The Site and all Content, products and
                                        Services included in or accessible from the Site are provided "as is" and without warranties or
                                        representations of any kind (express, implied and statutory, including but not limited to the
                                        warranties of title and non-infringement and the implied warranties of merchantability and fitness for a
                                        particular purpose), all of which Livathon disclaims to the fullest extent permitted by law. Your use
                                        of the Site and its Content, products and Services is at your sole risk. To the extent permitted under
                                        applicable law, no responsibility is assumed for any injury and/or damage to persons or property as a
                                        matter of products liability, negligence or otherwise, or from any use or operation of any ideas,
                                        instructions, methods, products or procedures contained in the Site. Livathon makes no warranty as
                                        to the results that may be obtained from the Services or that the defects in the Services will be
                                        corrected. You expressly agree that you have relied on the Services completely at your own discretion
                                        and risk and you agree that you will be solely responsible for any financial, personal or any other
                                        damage of any kind or loss of data that results from the download of such material and/or information
                                        and/or reliance on the Site and the Service. No advice or information, whether oral or written,
                                        obtained by you from Livathon or through the Site or the Services will create any warranty not
                                        expressly made herein.
                                    </p>
                                    <p className="privacyterms">
                                        Livathon does not claim ownership, endorse or take responsibility for any third-party products,
                                        information, guidelines, materials or services that may be offered, advertised, provided or
                                        displayed on the Site or incorporated in the Content, products or Services contained on, accessible
                                        from or distributed through the Site.
                                    </p>
                                </div>
                                <div>
                                    <h4 style={{
                                        color: '#1C41CE',
                                        fontWeight: 'bold',
                                        marginTop: '30px',
                                        marginBottom: '30px'
                                    }}>9. Indemnification</h4>
                                    <p className="privacyterms">
                                        You hereby agree to indemnify and hold Livathon, its Proprietor, officers,  successors in
                                        interest, employees, agents, suppliers and licensors harmless from and against any and all
                                        third-party claims of liability, losses, damages and costs, including, without limitation,
                                        reasonable attorneys' fees, arising out of or in connection with your use of or inability to use
                                        the Site or its Content, products or services, unauthorized access and use of the Site and the
                                        Content, improper use of the Services provided by Livathon including the use of the Site by any
                                        other person using your ID, breach or non-compliance of the terms and conditions of the
                                        Agreement, or any third party liability including intellectual property rights infringement
                                        that is incurred in relation to material or Content provided by you pursuant to the Agreement.
                                        This provision relating to indemnification obligation will survive in perpetuity.
                                    </p>
                                </div>
                                <div>
                                    <h4 style={{
                                        color: '#1C41CE',
                                        fontWeight: 'bold',
                                        marginTop: '30px',
                                        marginBottom: '30px'
                                    }}>10. Compliance</h4>
                                    <p className="privacyterms">
                                        You agree to comply with all relevant local, state, national and international
                                        laws, statutes, ordinances and regulations that apply to your access and use of
                                        the Site and its Content, products and services. You represent and warrant that
                                        (i) you are not located in a country that is subject to a U.S. Government
                                        embargo, or that has been designated by the U.S. Government as a "terrorist
                                        supporting" country; and (ii) you are not listed on any U.S. Government list
                                        of prohibited or restricted parties.
                                    </p>
                                </div>
                                <div>
                                    <h4 style={{
                                        color: '#1C41CE',
                                        fontWeight: 'bold',
                                        marginTop: '30px',
                                        marginBottom: '30px'
                                    }}>11. Discontinuation or Modifications to the Services</h4>
                                    <p className="privacyterms">
                                        Livathon reserves the right, in its sole discretion, to deny or restrict the use of the Services
                                        to anyone for any reason. Livathon reserves the right to modify or discontinue the Services, or
                                        any portion of the Services, with or without notice to you. Livathon will not be liable to you or
                                        any third party should we exercise these rights. If you object to any modification, your sole
                                        recourse will be to cease using the Services. Continued use of the Services following a notice of
                                        any change will indicate your acknowledgment of such changes and satisfaction with the Services as
                                        modified.Livathon has no obligation to store, maintain or provide you a copy of any content that
                                        is provided or transmitted when using the Services. We also reserve the right, but have no
                                        obligation, to monitor disputes between you and other members and to restrict, suspend, or close
                                        your account if we determine, in our sole discretion, that doing so is appropriate.
                                    </p>
                                </div>
                                <div>
                                    <h4 style={{
                                        color: '#1C41CE',
                                        fontWeight: 'bold',
                                        marginTop: '30px',
                                        marginBottom: '30px'
                                    }}>12. Minimum age of requirement  </h4>
                                    <p className="privacyterms">
                                        Minimum age of requirement  for having an account for a user is 13. Under 13 users should have their parents or
                                        legal guardian’s permission to use a Livathon account.  Please have your parent or legal guardian read these
                                        terms with you. In case, if you’re a parent or legal guardian, and you allow your child to use the services,
                                        then these terms apply to you and you’re responsible for your child’s activity on the services.
                                    </p>
                                </div>
                                <div>
                                    <h4 style={{
                                        color: '#1C41CE',
                                        fontWeight: 'bold',
                                        marginTop: '30px',
                                        marginBottom: '30px'
                                    }}>13. Governing Law and Venue  </h4>
                                    <p className="privacyterms">
                                        All matters relating to your access to or use of the App, Site or Service, including all disputes,
                                        shall be governed by and construed in accordance with the laws of India, without regard to its
                                        conflicts of law principles. The exclusive jurisdiction and venue with respect to any action or
                                        suit arising out of or pertaining to the subject matter hereof shall be the courts of competent
                                        jurisdiction located in Chennai , Tamil Nadu, India. Any claim arising out of or in connection with
                                        your use of or inability to use the App, Site or its Content, products or services must be brought
                                        within one (1) year after the event or such claim is barred.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-xs-12">
                            <p className="text-center"
                                style={{ color: '#333' }}>@{this.state.new_year}, Livathon, All rights reserved</p>
                        </div>
                    </div>
                </div>
            </div>

        )

    }

}

function mapStateToProps(state, ownProps) {
    return { user: state.user };
}


export default connect(
    mapStateToProps,
    {}
)(TermsofService);