import { db, storage } from "../firebase";
import {
  GET_PERSONALLOGS,
  GET_PORTFOLIOLOGS,
  SHOW_MODAL,
  GET_IMAGEPORTFOLIOLOGS,
  GET_FOLLOWING,
  GET_FOLLOWERS,
  GET_NOTIFICATIONS,
  GET_CHATMSGS,
  GET_CHATHISTORY,
} from "../actionTypes";
import firebase from "firebase/app";
import "firebase/firestore";
import * as moment from 'moment';
import 'moment-duration-format';
import random from "random-string-generator";
import _ from "lodash";


//Post personal logs
export function postpersonalLogs(values, uid, filelist, authorname, authorimg) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      let globalarray = [];
      var currentDate = moment().format("MMM DD YYYY");
      let fid = random("lowernumeric");
      // console.log(values, uid, filelist);
      if (filelist === "") {
        db.collection("Personal_Logs")
          .where("Created_At_Timestamp", "==", currentDate)
          .where("author", "==", uid)
          .orderBy("Created_At", "desc")
          .get()
          .then(function (doc1) {
            // console.log(doc1)
            // console.log(currentTime);
            if (doc1.empty === false) {
              // console.log("Document data:", doc.data());
              doc1.forEach(function (doc) {
                // doc.data() is never undefined for query doc snapshots
                // console.log(doc.id, " => ", doc.data());
                var declist = doc.data().log_list;
                declist.push({
                  description: values.caption,
                  text_created: new Date().getTime(),
                });
                declist.sort(function (a, b) {
                  // Turn your strings into dates, and then subtract them
                  // to get a value that is either negative, positive, or zero.
                  return new Date(b.text_created) - new Date(a.text_created);
                });
                db.collection("Personal_Logs")
                  .doc(doc.id)
                  .update({
                    log_list: declist,
                    UpDated_At: firebase.firestore.FieldValue.serverTimestamp(),
                    character_count:
                      doc.data().character_count + values.caption.length,
                  })
                  .then((res) => {
                    // console.log("res", res);
                    resolve(res);
                    dispatch({
                      type: SHOW_MODAL,
                      payload: false,
                    });
                  })
                  .catch(function (error) {
                    reject(error);
                  });
              });
            } else {
              // doc.data() will be undefined in this case
              // console.log("No such document!");
              var declist = [
                {
                  description: values.caption,
                  text_created: new Date().getTime(),
                },
              ];
              declist.sort(function (a, b) {
                // Turn your strings into dates, and then subtract them
                // to get a value that is either negative, positive, or zero.
                return new Date(b.text_created) - new Date(a.text_created);
              });
              db.collection("Personal_Logs")
                .doc()
                .set({
                  log_list: declist,
                  upload_logs_Image: [],
                  UpDated_At: firebase.firestore.FieldValue.serverTimestamp(),
                  character_count: values.caption.length,
                  author: uid,
                  username: authorname,
                  userimg: authorimg,
                  Created_At_Timestamp: currentDate,
                  Created_At: firebase.firestore.FieldValue.serverTimestamp(),
                })
                .then((res) => {
                  console.log("res", res);
                  resolve(res);
                  dispatch({
                    type: SHOW_MODAL,
                    payload: false,
                  });
                })
                .catch(function (error) {
                  reject(error);
                });
            }
          })
          .catch(function (error) {
            // console.log("Error getting document:", error);
          });
      } else {
        filelist.forEach((f) => {
          storage
            .ref("Personal_Log_Images/" + uid + "/" + fid + f.name)
            .put(f)
            .then(function (snapshot) {
              snapshot.ref.getDownloadURL().then(function (downloadURL) {
                globalarray.push(downloadURL);
                if (filelist.length === globalarray.length) {
                  // console.log(currentDate);
                  db.collection("Personal_Logs")
                    .where("Created_At_Timestamp", "==", currentDate)
                    .where("author", "==", uid)
                    .orderBy("Created_At", "desc")
                    .get()
                    .then(function (doc1) {
                      // console.log(doc1)
                      // var itemlength = 0;
                      if (doc1.empty === false) {
                        // console.log("Document data:", doc.data());
                        doc1.forEach(function (doc) {
                          // doc.data() is never undefined for query doc snapshots
                          // console.log(doc.id, " => ", doc.data());
                          var declist = doc.data().log_list;
                          var imagelist = doc.data().upload_logs_Image;

                          declist.push({
                            description: values.caption,
                            text_created: new Date().getTime(),
                          });
                          declist.sort(function (a, b) {
                            // Turn your strings into dates, and then subtract them
                            // to get a value that is either negative, positive, or zero.
                            return (
                              new Date(b.text_created) -
                              new Date(a.text_created)
                            );
                          });
                          var imageArray = imagelist.concat(globalarray);
                          db.collection("Personal_Logs")
                            .doc(doc.id)
                            .update({
                              log_list:
                                values.caption === undefined
                                  ? doc.data().log_list
                                  : declist,
                              upload_logs_Image: imageArray,
                              UpDated_At: firebase.firestore.FieldValue.serverTimestamp(),
                              character_count:
                                values.caption === undefined
                                  ? doc.data().character_count
                                  : values.caption.length +
                                  doc.data().character_count,
                            })
                            .then((res) => {
                              // console.log("res", res);
                              resolve(res);
                              dispatch({
                                type: SHOW_MODAL,
                                payload: false,
                              });
                            })
                            .catch(function (error) {
                              reject(error);
                            });
                        });
                      } else {
                        // doc.data() will be undefined in this case
                        // console.log("No such document!");
                        var declist = [
                          {
                            description: values.caption,
                            text_created: new Date().getTime(),
                          },
                        ];
                        declist.sort(function (a, b) {
                          // Turn your strings into dates, and then subtract them
                          // to get a value that is either negative, positive, or zero.
                          return (
                            new Date(b.text_created) - new Date(a.text_created)
                          );
                        });
                        db.collection("Personal_Logs")
                          .doc()
                          .set({
                            Created_At_Timestamp: moment().format(
                              "MMM DD YYYY"
                            ),
                            Created_At: firebase.firestore.FieldValue.serverTimestamp(),
                            log_list: declist,
                            upload_logs_Image: globalarray,
                            UpDated_At: firebase.firestore.FieldValue.serverTimestamp(),
                            author: uid,
                            username: authorname,
                            userimg: authorimg,
                            character_count: values.caption.length,
                          })
                          .then((res) => {
                            // console.log("res", res);
                            resolve(res);
                            dispatch({
                              type: SHOW_MODAL,
                              payload: false,
                            });
                          })
                          .catch(function (error) {
                            reject(error);
                          });
                      }
                    })
                    .catch(function (error) {
                      // console.log("Error getting document:", error);
                    });
                }
              });
            });
        });
      }
    });
  };
}


//Get personal logs from DB
export function getPersonalLogs(uid) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      // var sortDate = moment().format("MMM DD YYYY, h:mm:ss")
      db.collection("Personal_Logs")
        .where("author", "==", uid)
        .orderBy("Created_At", "desc")
        .onSnapshot(function (doc1) {
          const personalLogsList = [];
          var itemProcessed = 0;
          var log_count = 0;
          doc1.forEach(function (doc) {
            itemProcessed++;
            personalLogsList.push({
              Id: doc.id,
              Created_At: doc.data().Created_At,
              Created_Date: doc.data().Created_At_Timestamp,
              log_list: doc.data().log_list.sort(function (a, b) {
                // Turn your strings into dates, and then subtract them
                // to get a value that is either negative, positive, or zero.
                return new Date(b.text_created) - new Date(a.text_created);
              }),
              upload_logs_Image: doc.data().upload_logs_Image,
              UpDated_At: doc.data().UpDated_At,
              author: doc.data().author,
            });

            // var date = moment().format("MMM DD YYYY, h:mm:ss")
            // console.log(date);

            log_count = doc.data().log_list.length + log_count;
            // console.log(log_count);

            var logData = personalLogsList;
            // console.log(logData)
            logData.sort(function (a, b) {
              // Turn your strings into dates, and then subtract them
              // to get a value that is either negative, positive, or zero.
              return new Date(b.Created_Log) - new Date(a.Created_Log);
            });
            // console.log("Current data: ", doc1.size);
            if (itemProcessed === doc1.size) {
              var logData1 = personalLogsList;
              dispatch({
                type: GET_PERSONALLOGS,
                payload: logData1,
              });
              resolve(logData1);
            }
          });
        });
    });
  };
}


//check image limit for current day
export function checkImageToday(uid) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var currentDate = moment().format("MMM DD YYYY");
      // console.log(currentDate);
      db.collection("Personal_Logs")
        .where("Created_At_Timestamp", "==", currentDate)
        .where("author", "==", uid)
        .get()
        .then(function (doc1) {
          // console.log(doc1);
          if (doc1.empty === false) {
            // console.log("Document data:", doc.data());
            doc1.forEach(function (doc) {
              // doc.data() is never undefined for query doc snapshots
              var imageCount = doc.data().upload_logs_Image.length;

              var max_char = doc.data().character_count;
              var Daily_limit = [max_char, imageCount];

              if (max_char === 3000 && imageCount === 3) {
                // console.log("Limit Exceed");
                dispatch({
                  type: SHOW_MODAL,
                  payload: false,
                });
                reject("Limit exceeded!!!");
              } else if (max_char === 3000 && imageCount < 3) {
                resolve(Daily_limit);
                // console.log("Daily Limit Exceed");
              } else if (max_char < 3000 && imageCount === 3) {
                resolve(Daily_limit);
                // console.log("Image Limit Exceed");
              } else {
                resolve(Daily_limit);
                // console.log("Limit avail");
              }
            });
          } else {
            var Daily_limit = [0, 0];
            resolve(Daily_limit);
          }
        });
    });
  };
}


export function getuserPersonalLogs(uid) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      // console.log(uid);
      db.collection("Personal_Logs")
        .orderBy("Created_At", "desc")
        .where("author", "==", uid)
        .onSnapshot(function (doc1) {
          const personalLogsList = [];
          var itemProcessed = 0;
          doc1.forEach(function (doc) {
            // console.log(doc.data().author);
            db.collection("User_Details")
              .doc(doc.data().author)
              .get()
              .then(function (doc2) {
                if (doc2.exists) {
                  // console.log("Current data: ", doc2.id);
                  itemProcessed++;
                  personalLogsList.push({
                    Id: doc.id,
                    Created_At: doc.data().Created_At,
                    Created_Date: doc.data().Created_At_Timestamp,
                    log_list: doc.data().log_list,
                    upload_logs_Image: doc.data().upload_logs_Image,
                    address: doc.data().address,
                    UpDated_At: doc.data().UpDated_At,
                    Created_ago: moment.unix(doc.data().Created_At.seconds).startOf('seconds').fromNow(),
                    author: doc.data().author,
                    username:
                      doc2.data().First_Name + " " + doc2.data().Last_Name,
                    userimg: doc2.data().photoURL,
                    Biography: doc2.data().Biography,
                    posttype: "personal",
                  });

                  // console.log(log_count);

                  // console.log("Current data: ", doc1.size, itemProcessed);
                  if (itemProcessed === doc1.size) {
                    // console.log("Current data: ", doc1.size, itemProcessed);
                    var logData = personalLogsList;
                    logData.sort(function (a, b) {
                      // Turn your strings into dates, and then subtract them
                      // to get a value that is either negative, positive, or zero.
                      return new Date(b.Created_Log) - new Date(a.Created_Log);
                    });
                    dispatch({
                      type: GET_PERSONALLOGS,
                      payload: logData,
                    });
                    resolve(logData);
                  }
                } else {
                  // console.log(doc.data().author);
                  // doc.data() will be undefined in this case
                  console.log("No such document!");
                }
              })
              .catch(function (error) {
                reject(error);
                console.log("Error getting document:", error);
              });
          });
        });
    });
  };
}

//Get all logs from DB
export function getallLogs() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      db.collection("Personal_Logs")
        .orderBy("Created_At", "desc")
        .onSnapshot(function (doc1) {
          const personalLogsList = [];
          var itemProcessed = 0;
          doc1.forEach(function (doc) {
            // console.log(doc.data().author);
            db.collection("User_Details")
              .doc(doc.data().author)
              .get()
              .then(function (doc2) {
                if (doc2.exists) {
                  // console.log("Current data: ", doc2.id);
                  itemProcessed++;

                  personalLogsList.push({
                    Id: doc.id,
                    Created_At: doc.data().Created_At,
                    Created_Date: doc.data().Created_At_Timestamp,
                    log_list: doc.data().log_list,
                    upload_logs_Image: doc.data().upload_logs_Image,
                    UpDated_At: doc.data().UpDated_At,
                    address: doc.data().address,
                    author: doc.data().author,
                    username:
                      doc2.data().First_Name + " " + doc2.data().Last_Name,
                    userimg: doc2.data().photoURL,
                    Biography: doc2.data().Biography,
                    posttype: "personal",
                  });

                  // console.log(log_count);

                  // console.log("Current data: ", doc1.size, itemProcessed);
                  if (itemProcessed === doc1.size) {
                    // console.log("Current data: ", doc1.size, itemProcessed);
                    var logData = personalLogsList;
                    logData.sort(function (a, b) {
                      // Turn your strings into dates, and then subtract them
                      // to get a value that is either negative, positive, or zero.
                      return new Date(b.Created_At) - new Date(a.Created_At);
                    });
                    dispatch({
                      type: GET_PERSONALLOGS,
                      payload: logData,
                    });
                    resolve(logData);
                  }
                } else {
                  // console.log(doc.data().author);
                  // doc.data() will be undefined in this case
                  console.log("No such document!");
                }
              })
              .catch(function (error) {
                reject(error);
                console.log("Error getting document:", error);
              });
          });
        });
    });
  };
}

//Post personal logs
export function postPortfolios(values, uid, filelist, authorname, authorimg) {
  return (dispatch) => {
    // console.log(values, uid, filelist)
    return new Promise((resolve, reject) => {
      let globalarray = [];
      var currentDate = moment().format("MMM DD YYYY");
      let fid = random("lowernumeric");
      if (values.caption === undefined && filelist.length !== 0) {
        filelist.forEach((f) => {
          storage
            .ref("Portfolio_Logs_Images/" + uid + "/" + fid + f.name)
            .put(f)
            .then(function (snapshot) {
              snapshot.ref.getDownloadURL().then(function (downloadURL) {
                globalarray.push(downloadURL);
                if (filelist.length === globalarray.length) {
                  // console.log(globalarray, currentDate, uid);
                  db.collection("Portfolio_Logs")
                    .doc()
                    .set({
                      Created_At_Timestamp: currentDate,
                      Created_At: firebase.firestore.FieldValue.serverTimestamp(),
                      log_list: "",
                      upload_logs_Image: globalarray,
                      UpDated_At: firebase.firestore.FieldValue.serverTimestamp(),
                      author: uid,
                      username: authorname,
                      userimg: authorimg,
                      Action: [],
                    })
                    .then((res) => {
                      console.log("res", res);
                      resolve(res);
                      dispatch({
                        type: SHOW_MODAL,
                        payload: false,
                      });
                    })
                    .catch(function (error) {
                      reject(error);
                    });
                }
              });
            });
        });
      }
      else {
        filelist.forEach((f) => {
          storage
            .ref("Portfolio_Logs_Images/" + uid + "/" + fid + f.name)
            .put(f)
            .then(function (snapshot) {
              snapshot.ref.getDownloadURL().then(function (downloadURL) {
                globalarray.push(downloadURL);
                if (filelist.length === globalarray.length) {
                  // console.log(currentDate);
                  db.collection("Portfolio_Logs")
                    .doc()
                    .set({
                      Created_At_Timestamp: currentDate,
                      Created_At: firebase.firestore.FieldValue.serverTimestamp(),
                      log_list: values.caption,
                      upload_logs_Image: globalarray,
                      UpDated_At: firebase.firestore.FieldValue.serverTimestamp(),
                      author: uid,
                      username: authorname,
                      userimg: authorimg,
                      Action: [],
                    })
                    .then((res) => {
                      console.log("res", res);
                      resolve(res);
                      dispatch({
                        type: SHOW_MODAL,
                        payload: false,
                      });
                    })
                    .catch(function (error) {
                      reject(error);
                    });
                }
              });
            });
        });
      }
    });
  };
}


// //Get all logs from DB
export function getallportfolioLogs() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      db.collection("Portfolio_Logs")
        .orderBy("Created_At", "desc")
        .onSnapshot(function (doc1) {
          const portfolioLogsList = [];
          var itemProcessed = 0;
          doc1.forEach(function (doc) {
            // console.log(doc.data().author);
            db.collection("User_Details")
              .doc(doc.data().author)
              .get()
              .then(function (doc2) {
                if (doc2.exists) {
                  // console.log("Current data: ", doc.data());
                  itemProcessed++;

                  portfolioLogsList.push({
                    Id: doc.id,
                    // Created_At: doc.data().Created_At.toDate(),
                    Created_At: doc.data().Created_At,
                    Created_Date: doc.data().Created_At_Timestamp,
                    log_list: doc.data().log_list,
                    upload_logs_Image: doc.data().upload_logs_Image,
                    UpDated_At: doc.data().UpDated_At,
                    address: doc.data().address,
                    author: doc.data().author,
                    Action: doc.data().Action,
                    username:
                      doc2.data().First_Name + " " + doc2.data().Last_Name,
                    userimg: doc2.data().photoURL,
                    Biography: doc2.data().Biography,
                    posttype: "portfolio",
                  });

                  // console.log(log_count);

                  // console.log("Current data: ", doc1.size, itemProcessed);
                  if (itemProcessed === doc1.size) {
                    // console.log("Current data: ", doc1.size, itemProcessed);
                    var logData = portfolioLogsList;
                    // logData.sort(function (a, b) {
                    //   // Turn your strings into dates, and then subtract them
                    //   // to get a value that is either negative, positive, or zero.
                    //   return new Date(b.Created_At) - new Date(a.Created_At);
                    // });

                    logData.sort(function (a, b) {
                      // Turn your strings into dates, and then subtract them
                      // to get a value that is either negative, positive, or zero.
                      return new Date(b.Created_At) - new Date(a.Created_At);
                    });
                    dispatch({
                      type: GET_PORTFOLIOLOGS,
                      payload: logData,
                    });
                    resolve(logData);
                  }
                } else {
                  // console.log(doc.data().author);
                  // doc.data() will be undefined in this case
                  console.log("No such document!");
                }
              })
              .catch(function (error) {
                reject(error);
                console.log("Error getting document:", error);
              });
          });
        });
    });
  };
}

//Get all logs from DB
export function getuserportfolioLogs(uid) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      // console.log(uid);
      db.collection("Portfolio_Logs")
        .where("author", "==", uid)
        .orderBy("Created_At", "desc")
        .onSnapshot(function (doc1) {
          // console.log(doc1);
          const portfolioLogsList = [];
          var itemProcessed = 0;
          doc1.forEach(function (doc) {
            db.collection("User_Details")
              .doc(doc.data().author)
              .get()
              .then(function (doc2) {
                if (doc2.exists) {
                  // console.log("Current data: ", doc.data());
                  itemProcessed++;
                  portfolioLogsList.push({
                    Id: doc.id,
                    Created_At: doc.data().Created_At,
                    Created_Date: doc.data().Created_At_Timestamp,
                    log_list: doc.data().log_list,
                    upload_logs_Image: doc.data().upload_logs_Image,
                    address: doc.data().address,
                    UpDated_At: doc.data().UpDated_At,
                    author: doc.data().author,
                    Action: doc.data().action,
                    username:
                      doc2.data().First_Name + " " + doc2.data().Last_Name,
                    userimg: doc2.data().photoURL,
                    Biography: doc2.data().Biography,
                    posttype: "portfolio",
                  });

                  // console.log("Current data: ", doc1.size);
                  if (itemProcessed === doc1.size) {
                    // console.log("Current data: ", doc1.size, itemProcessed);
                    var logData = portfolioLogsList;
                    logData.sort(function (a, b) {
                      // Turn your strings into dates, and then subtract them
                      // to get a value that is either negative, positive, or zero.
                      return new Date(b.Created_At) - new Date(a.Created_At);
                    });
                    dispatch({
                      type: GET_PORTFOLIOLOGS,
                      payload: logData,
                    });

                    resolve(logData);
                  }
                } else {
                  // console.log(doc.data().author);
                  // doc.data() will be undefined in this case
                  console.log("No such document!");
                }
              })
              .catch(function (error) {
                reject(error);
                console.log("Error getting document:", error);
              });
          });
        });
    });
  };
}

export function getImageportfolioLogs(uid) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(uid);
      db.collection("Portfolio_Logs")
        .where("author", "==", uid)
        .orderBy("Created_At", "desc")
        .onSnapshot(function (doc1) {
          // console.log(doc1);
          const portfolioLogsList = [];
          var itemProcessed = 0;
          doc1.forEach(function (doc) {
            db.collection("User_Details")
              .doc(doc.data().author)
              .get()
              .then(function (doc2) {
                if (doc2.exists) {
                  // console.log("Current data: ", doc.data());
                  itemProcessed++;

                  portfolioLogsList.push({
                    Id: doc.id,
                    Created_At: doc.data().Created_At,
                    Created_Date: doc.data().Created_At_Timestamp,
                    // log_list: doc.data().log_list,
                    upload_logs_Image: doc.data().upload_logs_Image,
                    address: doc.data().address,
                    UpDated_At: doc.data().UpDated_At,
                    author: doc.data().author,
                    Action: doc.data().Action,
                    username:
                      doc2.data().First_Name + " " + doc2.data().Last_Name,
                    userimg: doc2.data().photoURL,
                    Biography: doc2.data().Biography,
                    posttype: "portfolio",
                  });

                  // console.log("Current data: ", doc1.size);
                  if (itemProcessed === doc1.size) {
                    // console.log("Current data: ", doc1.size, itemProcessed);
                    var logData = portfolioLogsList;
                    logData.sort(function (a, b) {
                      // Turn your strings into dates, and then subtract them
                      // to get a value that is either negative, positive, or zero.
                      return new Date(b.Created_At) - new Date(a.Created_At);
                    });
                    dispatch({
                      type: GET_IMAGEPORTFOLIOLOGS,
                      payload: logData,
                    });

                    resolve(logData);
                  }
                } else {
                  // console.log(doc.data().author);
                  // doc.data() will be undefined in this case
                  console.log("No such document!");
                }
              })
              .catch(function (error) {
                reject(error);
                console.log("Error getting document:", error);
              });
          });
        });
    });
  };
}

//post actions actionfig conditions based on integer
// actionfig 1 = 1st Like in post document(new doc in action_master)
// actionfig 3 = 1st dislike in post document(new doc in action_master)
// actionfig 2 = remove like in existing post document(delete existing like document)
// actionfig 4 = remove dislike in existing post document(delete existing dislike document)
export function postActions(postid, authorid, uid, type, subid, actionfig) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      // console.log(postid, authorid, uid, type, subid);
      var dbquery = db.collection("Portfolio_Logs")
        .doc(postid)
        .collection("Action_Master")
        .doc();
      // console.log(dbquery);
      if (actionfig === 1 || actionfig === 3) {
        dbquery
          .set({
            Created_Date: firebase.firestore.FieldValue.serverTimestamp(),
            User_id: uid,
            actionType: type,
            read: false,
            Updated_Date: firebase.firestore.FieldValue.serverTimestamp(),
          })
          .then(function () {
            var res = {
              Created_Date: firebase.firestore.FieldValue.serverTimestamp(),
              User_id: uid,
              actionType: type,
              read: false,
              Updated_Date: firebase.firestore.FieldValue.serverTimestamp(),
              subid: dbquery.id
            }
            resolve(res);
            if (type === true && authorid != uid) {
              db.collection("Notification_Master")
                .doc()
                .set({
                  Created_Date: firebase.firestore.FieldValue.serverTimestamp(),
                  User_id: authorid,
                  Follower_id: uid,
                  Follower_type: "",
                  actionType: type,
                  read: false,
                  notify_read: false,
                  Updated_Date: firebase.firestore.FieldValue.serverTimestamp(),
                })
            } else {
              console.log("Not setting notification master DB")
            }
          })
          .catch((err) => console.log(err));
      } else if (actionfig === 2 || actionfig === 4) {
        db.collection("Portfolio_Logs")
          .doc(postid)
          .collection("Action_Master")
          .doc(subid)
          .delete()
          .then(function () {
            var res = {
              Created_Date: firebase.firestore.FieldValue.serverTimestamp(),
              User_id: uid,
              actionType: type,
              read: false,
              Updated_Date: firebase.firestore.FieldValue.serverTimestamp(),
              subid: subid
            }
            resolve(res);
            if (type === true && authorid != uid) {
              db.collection("Notification_Master")
                .doc()
                .set({
                  Created_Date: firebase.firestore.FieldValue.serverTimestamp(),
                  User_id: authorid,
                  Follower_id: uid,
                  Follower_type: "",
                  actionType: type,
                  read: false,
                  notify_read: false,
                  Updated_Date: firebase.firestore.FieldValue.serverTimestamp(),
                })
            } else {
              console.log("Not setting notification master DB")
            }
          })
          .catch((err) => console.log(err));
      } else {
        db.collection("Portfolio_Logs")
          .doc(postid)
          .collection("Action_Master")
          .doc(subid)
          .update({
            actionType: type,
            Updated_Date: firebase.firestore.FieldValue.serverTimestamp(),
          })
          .then(function () {
            var res = {
              Created_Date: firebase.firestore.FieldValue.serverTimestamp(),
              User_id: uid,
              actionType: type,
              read: false,
              Updated_Date: firebase.firestore.FieldValue.serverTimestamp(),
              subid: subid
            }
            resolve(res);
            if (type === true && authorid != uid) {
              db.collection("Notification_Master")
                .doc()
                .set({
                  Created_Date: firebase.firestore.FieldValue.serverTimestamp(),
                  User_id: authorid,
                  Follower_id: uid,
                  Follower_type: "",
                  actionType: type,
                  read: false,
                  notify_read: false,
                  Updated_Date: firebase.firestore.FieldValue.serverTimestamp(),
                })
            } else {
              console.log("Not setting notification master DB")
            }
          })
          .catch((err) => console.log(err));
      }
    });
  };
}


export function removepostActions(id) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      // console.log(postid, uid, type);
      db.collection("Portfolio_Logs")
        .doc()
        .collection("Action_Master")
        .doc(id)
        .delete()
        .then((res) => {
          resolve(res);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  };
}


//addFollow
export function addFollowing(curuser, followid) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var circle_id = db.collection("Circle_Master")
        .doc();
      circle_id.set({
        Created_At: firebase.firestore.FieldValue.serverTimestamp(),
        UpDated_At: firebase.firestore.FieldValue.serverTimestamp(),
        actionType: "",
        User_id: followid,
        Follower_id: curuser,
        Follower_type: "Following",
      })
        .then((res) => {
          resolve(circle_id);
          // var d = new Date();
          // var getDate = moment().format("YYYYMMDD");
          // console.log(getDate)
          db.collection("Notification_Master").doc().get().then(function (doc) {
            if (doc.exists) {
              // console.log("Document data:", doc.data());
              var notified = doc.data().notifications;
              notified.push({
                // Created_At: firebase.firestore.FieldValue.serverTimestamp(),
                User_id: followid,
                Follower_id: curuser,
                Follower_type: "Following",
                read: false,
                notify_read: false,
                // UpDated_At: firebase.firestore.FieldValue.serverTimestamp(),
              })
              db.collection("Notification_Master")
                .doc()
                .set({
                  Created_Date: firebase.firestore.FieldValue.serverTimestamp(),
                  User_id: followid,
                  Follower_id: curuser,
                  actionType: "",
                  Follower_type: "Following",
                  read: false,
                  notify_read: false,
                  Updated_Date: firebase.firestore.FieldValue.serverTimestamp(),
                })
                .then((res) => {
                  resolve(res);
                })
                .catch(function (error) {
                  reject(error);
                });
            } else {
              // doc.data() will be undefined in this case
              // console.log("No such document!");
              var notified = [];
              notified.push({
                Created_Date: firebase.firestore.FieldValue.serverTimestamp(),
                User_id: followid,
                Follower_id: curuser,
                actionType: "",
                Follower_type: "Following",
                read: false,
                notify_read: false,
                Updated_Date: firebase.firestore.FieldValue.serverTimestamp(),
              })
              db.collection("Notification_Master")
                .doc()
                .set({
                  Created_Date: firebase.firestore.FieldValue.serverTimestamp(),
                  User_id: followid,
                  Follower_id: curuser,
                  actionType: "",
                  Follower_type: "Following",
                  read: false,
                  notify_read: false,
                  Updated_Date: firebase.firestore.FieldValue.serverTimestamp(),
                })
                .then((res) => {
                  resolve(res);
                })
                .catch(function (error) {
                  reject(error);
                });
            }
          }).catch(function (error) {
            reject(error);
            // console.log("Error getting document:", error);
          });

        })
        .catch(function (error) {
          reject(error);
        });
    });
  };
}


//addFollow
export function removeFollowing(id) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      db.collection("Circle_Master")
        .doc(id)
        .delete()
        .then((res) => {
          resolve(res);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  };
}


//addFollow
export function getFollowing(uid) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var itemProcessed = 0;
      // console.log(uid)
      //  console.log(curuser)
      db.collection("Circle_Master")
        .where("Follower_id", "==", uid)
        // .where("User_id", "==", curuser)
        .onSnapshot(function (querySnapshot) {
          // console.log(querySnapshot);
          var peoples = [];
          if (querySnapshot.empty === false) {
            querySnapshot.forEach(function (doc) {
              db.collection("User_Details")
                .doc(doc.data().User_id)
                .get()
                .then(function (doc2) {
                  if (doc2.exists) {
                    // console.log(moment.unix(doc2.data().Updated_At.seconds).format("MMMM Do YYYY, h:mm:ss a"));
                    itemProcessed++;
                    peoples.push({
                      Id: doc2.id,
                      photoURL: doc2.data().photoURL,
                      First_Name: doc2.data().First_Name,
                      Last_Name: doc2.data().Last_Name,
                      Date_of_Birth: doc2.data().Date_of_Birth,
                      Is_Verified: doc2.data().Is_Verified,
                      Ref_Country: doc2.data().Ref_Country,
                      // address: doc.data().address,
                      Ref_City: doc2.data().Ref_City,
                      Ref_Gender: doc2.data().Ref_Gender,
                      Education: doc2.data().Education,
                      Interest_Passion: doc2.data().Interest_Passion,
                      Biography: doc2.data().Biography,
                      Updated_At: doc2.data().Updated_At,
                      Follower_id: doc.data().Follower_id,
                      Follower_type: doc.data().Follower_type,
                      User_id: doc.data().User_id,
                      circle_id: doc.id,
                    });
                    // console.log(peoples);
                    if (querySnapshot.size === itemProcessed) {

                      var followdata = peoples;

                      dispatch({
                        type: GET_FOLLOWING,
                        payload: followdata
                      });
                      resolve(followdata);
                    }
                  }
                });

            });
          } else {
            resolve(peoples);
            // console.log("Current cities in CA: ", peoples);
          }
        });
    });
  };
}


export function getFollowers(uid) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var itemProcessed = 0;
      db.collection("Circle_Master")
        // .where("Follower_id", "==", uid)
        .where("User_id", "==", uid)
        .onSnapshot(function (querySnapshot) {
          // console.log(querySnapshot);
          var peoples = [];
          if (querySnapshot.empty === false) {
            querySnapshot.forEach(function (doc) {
              db.collection("User_Details")
                .doc(doc.data().Follower_id)
                .get()
                .then(function (doc2) {
                  // console.log(doc2, doc.data().Follower_id);
                  if (doc2.exists) {
                    // console.log(moment.unix(doc2.data().Updated_At.seconds).format("MMMM Do YYYY, h:mm:ss a"));
                    itemProcessed++;
                    peoples.push({
                      Id: doc2.id,
                      photoURL: doc2.data().photoURL,
                      First_Name: doc2.data().First_Name,
                      Last_Name: doc2.data().Last_Name,
                      Date_of_Birth: doc2.data().Date_of_Birth,
                      Is_Verified: doc2.data().Is_Verified,
                      // address: doc.data().address,
                      // Ref_Country: doc2.data().Ref_Country,
                      // Ref_City: doc2.data().Ref_City,
                      Ref_Gender: doc2.data().Ref_Gender,
                      // Education: doc2.data().Education,
                      // Interest_Passion: doc2.data().Interest_Passion,
                      Biography: doc2.data().Biography,
                      Updated_At: doc2.data().Updated_At,
                      Follower_id: doc.data().Follower_id,
                      Follower_type: doc.data().Follower_type,
                      User_id: doc.data().User_id,
                      circle_id: doc.id,
                    });
                    // console.log(peoples);
                    var followdata = peoples;
                    // console.log(followdata)
                    // console.log(querySnapshot.size, itemProcessed);
                    if (querySnapshot.size === itemProcessed) {
                      dispatch({
                        type: GET_FOLLOWERS,
                        payload: followdata
                      });

                      resolve(peoples);
                    }
                  }
                });
            });
          } else {
            resolve(peoples);
            // console.log("Current cities in CA: ", peoples);
          }
        });
    });
  };
}

//removeFollow
export function removeFollowers(id) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      db.collection("Circle_Master")
        .doc(id)
        .delete()
        .then((res) => {
          resolve(res);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  };
}

//following status
export function followStatus(pid, uid) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      // console.log(uid)
      // console.log(pid)
      // var following = ""
      // var follower = ""
      db.collection("Circle_Master")
        .where("User_id", "==", pid)
        .where("Follower_id", "==", uid)
        .onSnapshot(function (querySnapshot) {
          // console.log(querySnapshot);
          if (querySnapshot.empty === false) {
            // db.collection("Circle_Master")
            // .where("User_id", "==", uid)
            // .where("Follower_id", "==", pid)
            // .onSnapshot(function (querySnapshot) {
            //   console.log(querySnapshot);

            //   if (querySnapshot.empty === false) {

            //       resolve(true)
            //   } else {
            //     resolve(false);
            //     // console.log("Current cities in CA: ", peoples);
            //   }
            // });
            resolve("Following")
          } else {
            db.collection("Circle_Master")
              .where("User_id", "==", uid)
              .where("Follower_id", "==", pid)
              .onSnapshot(function (querySnapshot) {
                // console.log(querySnapshot);
                if (querySnapshot.empty === false) {
                  resolve("Follow Back")
                } else {
                  resolve("Follow");
                  // console.log("Current cities in CA: ", peoples);
                }
              });
            // console.log("Current cities in CA: ", peoples);
          }
        });
    });
  };
}

// getnotifications
export function getNotifications(uid) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      // console.log(uid);
      var datelimit = moment().subtract(30, 'days').calendar();
      // console.log("datelimit: ", datelimit);

      db.collection("Notification_Master")
        .where("User_id", "==", uid)
        .where("Created_Date", ">", new Date(datelimit))
        .orderBy("Created_Date", 'desc')
        .onSnapshot(function (doc1) {
          // console.log("Current data: ", doc1);
          var notifies = [];
          if (!doc1.empty) {
            var notifications = doc1;
            var itemProcessed = 0;
            notifications.forEach(function (doc) {
              // console.log("Current data: ", doc.data());
              db.collection("User_Details")
                .doc(doc.data().Follower_id)
                .get()
                .then(function (doc2) {
                  // console.log(doc2, doc.Follower_id);
                  if (doc2.exists) {
                    // console.log(moment.unix(doc2.data().Updated_At.seconds).format("MMMM Do YYYY, h:mm:ss a"));
                    itemProcessed++;
                    notifies.push({
                      Id: doc2.id,
                      photoURL: doc2.data().photoURL,
                      First_Name: doc2.data().First_Name,
                      Last_Name: doc2.data().Last_Name,
                      Is_Verified: doc2.data().Is_Verified,
                      Follower_id: doc.data().Follower_id,
                      Follower_type: doc.data().Follower_type,
                      Created_Date: doc.data().Created_Date,
                      User_id: doc.data().User_id,
                      read: doc.data().read,
                      notify_read: doc.data().read,
                      date: moment.unix(doc.data().Created_Date.seconds).format('MMM Do YYYY, h:mm:ss a'),
                      dateago: moment.unix(doc.data().Created_Date.seconds).startOf('hour').fromNow(),
                      actionType: doc.data().actionType
                    });
                    // console.log(notifies);
                    // var sortDate = notifies.sort((a, b) => new Moment(b.date).format('YYYYMMDD') - new Moment(a.date).format('YYYYMMDD'))

                    // var sortDate = notifylist.sort(function (a, b) {
                    //     // Turn your strings into dates, and then subtract them
                    //     // to get a value that is either negative, positive, or zero.
                    //     return new Date(b.date) - new Date(a.date);
                    //   });
                    // console.log(sortDate)
                    // console.log(notifications.size, itemProcessed);
                    if (notifications.size === itemProcessed) {
                      // var sortDate = notifies.sort((a, b) => new moment(b.date).format('YYYYMMDD') - new moment(a.date).format('YYYYMMDD'))
                      var notifylist = notifies;
                      dispatch({
                        type: GET_NOTIFICATIONS,
                        payload: notifylist
                      });

                      resolve(notifylist);
                    }
                  }
                });

            });
          } else {
            resolve(notifies);
          }
        });
    });
  }
}

// getnotifications
export function getNavNotifications(uid) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      // console.log(uid);
      var datelimit = moment().subtract(30, 'days').calendar();
      // console.log("datelimit: ", datelimit);

      db.collection("Notification_Master")
        .where("User_id", "==", uid)
        .where("Created_Date", ">", new Date(datelimit))
        .orderBy("Created_Date", 'desc')
        .limit(10)
        .onSnapshot(function (doc1) {
          // console.log("Current data: ", doc1);
          var notifies = [];
          if (!doc1.empty) {
            var notifications = doc1;
            var itemProcessed = 0;
            notifications.forEach(function (doc) {
              // console.log("Current data: ", doc.data());
              db.collection("User_Details")
                .doc(doc.data().Follower_id)
                .get()
                .then(function (doc2) {
                  // console.log(doc2, doc.Follower_id);
                  if (doc2.exists) {
                    // console.log(moment.unix(doc2.data().Updated_At.seconds).format("MMMM Do YYYY, h:mm:ss a"));
                    itemProcessed++;
                    notifies.push({
                      Id: doc2.id,
                      photoURL: doc2.data().photoURL,
                      First_Name: doc2.data().First_Name,
                      Last_Name: doc2.data().Last_Name,
                      Is_Verified: doc2.data().Is_Verified,
                      Follower_id: doc.data().Follower_id,
                      Follower_type: doc.data().Follower_type,
                      Created_Date: doc.data().Created_Date,
                      User_id: doc.data().User_id,
                      read: doc.data().read,
                      notify_read: doc.data().read,
                      date: moment.unix(doc.data().Created_Date.seconds).format('MMM Do YYYY, h:mm:ss a'),
                      dateago: moment.unix(doc.data().Created_Date.seconds).startOf('hour').fromNow(),
                      actionType: doc.data().actionType
                    });
                    // console.log(notifies);
                    // var sortDate = notifies.sort((a, b) => new Moment(b.date).format('YYYYMMDD') - new Moment(a.date).format('YYYYMMDD'))

                    // var sortDate = notifylist.sort(function (a, b) {
                    //     // Turn your strings into dates, and then subtract them
                    //     // to get a value that is either negative, positive, or zero.
                    //     return new Date(b.date) - new Date(a.date);
                    //   });
                    // console.log(sortDate)
                    // console.log(notifications.size, itemProcessed);
                    if (notifications.size === itemProcessed) {
                      // var sortDate = notifies.sort((a, b) => new moment(b.date).format('YYYYMMDD') - new moment(a.date).format('YYYYMMDD'))
                      var notifylist = notifies;
                      dispatch({
                        type: GET_NOTIFICATIONS,
                        payload: notifylist
                      });

                      resolve(notifylist);
                    }
                  }
                });

            });
          } else {
            resolve(notifies);
          }
        });
    });
  }
}

export function getallInterest() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var interests = [];
      var itemProcessed = 0;
      db.collection("MD_Interest").orderBy("Interest_Name", "asc")
        .get()
        .then(function (querySnapshot) {
          // console.log(querySnapshot);
          querySnapshot.forEach(function (doc) {
            itemProcessed++;
            interests.push({
              Interest_Id: doc.data().Interest_Id,
              Interest_Name: doc.data().Interest_Name,
              InterestIcon: doc.data().Interest_Icon,
            });
            // console.log(doc.id, " => ", doc.data());
            if (itemProcessed === querySnapshot.size) {
              resolve(interests);
            }
          });
        });
    });
  };
}


export function unreadnotify(uid, notifications) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      // console.log(uid, notifications);
      db.collection("Notification_Master")
        .doc(uid)
        .get()
        .then(function (doc2) {
          // console.log(doc2.data());
          var readnotify = [];
          var itemProcessed = 0;
          var dbNotification = doc2.data().notifications;
          console.log(dbNotification);
          dbNotification.forEach(function (doc) {
            itemProcessed++;
            console.log(doc.notify_read);
            readnotify.push({
              Date: doc.Date,
              Follower_id: doc.Follower_id,
              Follower_type: doc.Follower_type,
              User_id: doc.User_id,
              notify_read: true,
            });
            // console.log(doc2.size)
            if (itemProcessed === dbNotification.length) {
              db.collection("Notification_Master")
                .doc(uid)
                .update({
                  notifications: readnotify,
                })
                .then(function () {
                  // console.log("Document successfully updated!");
                })
                .catch(function (error) {
                  // The document probably doesn't exist.
                  // console.error("Error updating document: ", error);
                });
            }
          });
        });
    });
  };
}


export function sendChat(fromUser, toUser, text, date, time) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var user1 = fromUser;
      var user2 = toUser;
      console.log(fromUser, toUser, text, date, time);
      var roomName =
        "chat_" + (user1 < user2 ? user1 + "_" + user2 : user2 + "_" + user1);
      // console.log(user1 + ", " + user2 + " => " + roomName);
      // Get a key for a new Post.

      db.collection("Chat_Messages")
        .doc(roomName)
        .collection("messages")
        .doc()
        .set({
          type: "text",
          text: text,
          date: date,
          time: time,
          from: fromUser,
          to: toUser,
          read: false,
          Created_At: firebase.firestore.FieldValue.serverTimestamp(),
        })
        .then(function () {
          // console.log("Document successfully updated!");
          resolve("Sent");
        })
        .catch(function (error) {
          // The document probably doesn't exist.
          reject("Not sent");
          console.error("Error updating document: ", error);
        });
      // Write the new post's data simultaneously in the posts list and the user's post list.

      db.collection("User_Details")
        .doc(user2)
        .get()
        .then(function (doc) {
          db.collection("Chat_history")
            .doc(user1)
            .collection("Recents")
            .doc(user2)
            .set({
              userid: toUser,
              username: doc.data().First_Name + " " + doc.data().Last_Name,
              photoURL: doc.data().photoURL,
              Updated_At: firebase.firestore.FieldValue.serverTimestamp(),
              lastmsg: text,
              // unreadcount: 0,
            })
            .then(function () {
              // console.log("Document successfully updated!");
            })
            .catch(function (error) {
              // The document probably doesn't exist.
              console.error("Error updating document: ", error);
            });
        });

      db.collection("User_Details")
        .doc(user1)
        .get()
        .then(function (doc) {
          db.collection("Chat_history")
            .doc(user2)
            .collection("Recents")
            .doc(user1)
            .set({
              userid: user1,
              username: doc.data().First_Name + " " + doc.data().Last_Name,
              photoURL: doc.data().photoURL,
              bio: doc.data().Biography,
              Updated_At: firebase.firestore.FieldValue.serverTimestamp(),
              lastmsg: text,
              // unreadcount: 0,
            })
            .then(function () {
              // console.log("Document successfully updated!");
            })
            .catch(function (error) {
              // The document probably doesn't exist.
              console.error("Error updating document: ", error);
            });
        });
    });
  };
}


export function getChat(fromUser, toUser, prevUser) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var user1 = fromUser;
      var user2 = toUser;
      var userp = prevUser;
      console.log(fromUser, toUser, prevUser);
      var prevRoom =
        "chat_" + (user1 < userp ? user1 + "_" + userp : userp + "_" + user1);

      var unsubscribe = db
        .collection("Chat_Messages")
        .doc(prevRoom)
        .collection("messages")
        .orderBy("Created_At", "asc")
        .onSnapshot(function (doc) {
          // do something with the data.
        });

      // Stop listening to changes
      unsubscribe();

      var roomName =
        "chat_" + (user1 < user2 ? user1 + "_" + user2 : user2 + "_" + user1);
      // console.log(roomName);

      db.collection("Chat_Messages")
        .doc(roomName)
        .collection("messages")
        .orderBy("Created_At", "asc")
        .onSnapshot(function (doc) {
          if (!doc.empty) {
            var msgs = [];
            doc.forEach(function (m) {
              // console.log("Current data: ", m.data());
              msgs.push({
                id: m.id,
                text: m.data().text,
                time: m.data().time,
                from: m.data().from,
                to: m.data().to,
                date: m.data().date,
                type: m.data().type,
                read: m.data().read,
              });
            });
            console.log(doc.size, msgs.length);
            if (doc.size === msgs.length) {

              resolve(msgs);
              dispatch({
                type: GET_CHATMSGS,
                payload: msgs,
              });
            }
          } else {
            resolve("");
            dispatch({
              type: GET_CHATMSGS,
              payload: "",
            });
          }
          // console.log("Current data: ", doc);
        });
    });
  };
}


export function getChathistory(fromUser) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      db.collection("Chat_history")
        .doc(fromUser)
        .collection("Recents")
        .orderBy("Updated_At", "desc")
        .onSnapshot(
          {
            includeMetadataChanges: true,
          },
          function (doc) {
            if (!doc.empty) {
              var userslist = [];
              doc.forEach(function (document) {
                // console.log(document.data());
                userslist.push({
                  docid: document.id,
                  username: document.data().username,
                  photoURL:
                    document.data().photoURL === undefined
                      ? ""
                      : document.data().photoURL,
                  Id: document.data().userid,
                  Updated_At: document.data().Updated_At,
                  lastmsg: document.data().lastmsg,
                  unreadcount: document.data().unreadcount,
                });
              });
              if (doc.size === userslist.length) {
                dispatch({
                  type: GET_CHATHISTORY,
                  payload: userslist,
                });
                resolve(userslist);
              }
            } else {
              dispatch({
                type: GET_CHATHISTORY,
                payload: [],
              });
              resolve("");
            }
          }
        );
    });
  };
}


export function updatereadChat(fromUser, toUser, msgs) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var user1 = fromUser;
      var user2 = toUser;
      console.log(fromUser, toUser);
      var roomName =
        "chat_" + (user1 < user2 ? user1 + "_" + user2 : user2 + "_" + user1);
      // console.log(user1 + ", " + user2 + " => " + roomName);
      // Get a key for a new Post.
      msgs.forEach((e) => {
        db.collection("Chat_Messages")
          .doc(roomName)
          .collection("messages")
          .doc(e.id)
          .update({
            read: true,
          })
          .then(function () {
            console.log("Document successfully updated!");
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error("Error updating document: ", error);
          });
      });

    });
  };
}


export function reportPost(values, postid, authorid, uid, type) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      // console.log(values, postid, authorid, uid, type);
      var reportDocument = db.collection("Report_Problem").doc();
      reportDocument.set({
        Created_At: firebase.firestore.FieldValue.serverTimestamp(),
        Post_ID: postid,
        Post_Type: type,
        Report_Type: values.report,
        Reported_User_ID: authorid,
        Reporter_User_ID: uid,
        Updated_At: firebase.firestore.FieldValue.serverTimestamp(),
      })
        .then(function () {
          console.log("document added successfully")
          resolve("document report successfully")
        })
        .catch((err) => {
          console.log(err);
          reject(err)
        })
    });
  };
}


export function blockUser(authorid, uid) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(authorid, uid);
      var query = db.collection("Circle_Master");
      db.collection("Block_Unblock_User")
        .doc()
        .set({
          Created_At: firebase.firestore.FieldValue.serverTimestamp(),
          BlockType: "BLOCK",
          Blocked_Id: authorid,
          UserId: uid,
          Updated_At: firebase.firestore.FieldValue.serverTimestamp(),
        })
        .then((doc) => {
          var id;
          query.where("User_id", "==", uid)
            .where("Follower_id", "==", authorid)
            .get()
            .then((querySnapshot) => {
              var size = querySnapshot.size;
              if (size === 0) {

                query.where("User_id", "==", authorid)
                  .where("Follower_id", "==", uid)
                  .get()
                  .then((querySnapshot) => {
                    var size = querySnapshot.size;
                    if (size === 0) {
                      resolve(size)
                    } else {
                      querySnapshot.forEach((doc) => {
                        console.log(doc.id, " => ", doc.data())
                        id = doc.id
                        console.log(id)
                      })
                      query.doc(id)
                        .delete().then(result => {
                          resolve(result)
                        })
                        .catch((err) => console.log(err));
                    }
                  }).catch((err) => console.log(err));
              } else {
                querySnapshot.forEach((doc) => {
                  console.log(doc.id, " => ", doc.data())
                  id = doc.id
                  console.log(id)
                })
                // console.log(query)
                query.doc(id)
                  .delete().then(result => {
                    var query = db.collection("Circle_Master");
                    query.where("User_id", "==", authorid)
                      .where("Follower_id", "==", uid)
                      .get()
                      .then((querySnapshot) => {
                        var size = querySnapshot.size;
                        if (size === 0) {
                          resolve(size)
                        } else {
                          querySnapshot.forEach((doc) => {
                            console.log(doc.id, " => ", doc.data())
                            id = doc.id
                            console.log(id)
                          })
                          query.doc(id)
                            .delete().then(result => {
                              resolve(result)
                            })
                            .catch((err) => console.log(err));
                        }
                      }).catch((err) => console.log(err));
                  })
              }

            }).catch((err) => console.log(err));
        })
        .catch((err) => console.log(err));
    });
  };
}


export function unblockUser(id) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      // console.log(id)
      db.collection("Block_Unblock_User")
        .doc(id)
        .delete()
        .then(function () {
          resolve("Unblocked user");
        })
        .catch(function (error) {
          reject(error);
        });
    });
  };
}

//following status
export function userBlockStatus(pid, uid) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      // console.log(uid, pid)
      db.collection("Block_Unblock_User")
        .where("Blocked_Id", "==", pid)
        .where("UserId", "==", uid)
        .onSnapshot(function (querySnapshot) {
          // console.log(querySnapshot);
          if (querySnapshot.empty === false) {
            resolve("Blocked")
          } else {
            db.collection("Block_Unblock_User")
              .where("UserId", "==", uid)
              .where("Blocked_Id", "==", pid)
              .onSnapshot(function (querySnapshot) {
                // console.log(querySnapshot);
                if (querySnapshot.empty === false) {
                  resolve("Block")
                } else {
                  resolve("Not blocked");
                }
              });
            // console.log("Current cities in CA: ", peoples);
          }
        });
    });
  };
}

export function getBlockStatus(pid, uid) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      // console.log(uid, pid)
      var blocklist = [];
      var itemProcessed = 0;
      db.collection("Block_Unblock_User")
        .where("Blocked_Id", "==", pid)
        .where("UserId", "==", uid)
        .get()
        .then((doc) => {
          doc.forEach(function (doc2) {
            if (doc2.exists) {
              itemProcessed++
              // doc.data() is never undefined for query doc snapshots
              blocklist = doc2.id
              if (doc.size === itemProcessed) {

                var followdata = blocklist;

                dispatch({
                  type: GET_FOLLOWING,
                  payload: followdata
                });
                resolve(followdata);
              }
            }
            else {
              resolve(blocklist)
            }
            // console.log(obj);
          });
        })
        .catch((err) => {
          reject(err)
        })
    });
  };
}


export function getPeopleCount(uid) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var peoplecount;
      db.collection("Follower_Following_Count").doc(uid)
        .get().then((doc) => {
          if (doc.exists) {
            // console.log(doc.id, " => ", doc.data());
            peoplecount = {
              Follower: doc.data().FollowerCount === undefined ? 0 : doc.data().FollowerCount,
              Following: doc.data().FollowingCount === undefined ? 0 : doc.data().FollowingCount
            }
            resolve(peoplecount)
          } else {
            // doc.data() will be undefined in this case
            resolve(peoplecount)
          }

        }).catch((error) => {
          // console.log("Error getting document:", error);
          reject(error)
        });
    });
  }
}


export function getPostCount(uid) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var postcount;
      db.collection("Portfolio_Personal_Count").doc(uid)
        .get().then((doc) => {
          if (doc.exists) {
            // console.log(doc.id, " => ", doc.data());
            postcount = {
              Personal: doc.data().PersonalCount === undefined ? 0 : doc.data().PersonalCount,
              Portfolio: doc.data().PortfolioCount === undefined ? 0 : doc.data().PortfolioCount
            }
            resolve(postcount)
          } else {
            // doc.data() will be undefined in this case
            resolve(postcount)
          }

        }).catch((error) => {
          // console.log("Error getting document:", error);
          reject(error)
        });
    });
  }
}


export function getFeedsLimit(id) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {

      db.collection("Config").get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          // console.log(doc.id, " => ", doc.data());
          var feedlimit = {
            feedLimit: doc.data().Feed_Data_Limit,
            feedperRquest: doc.data().Feed_Per_Request
          }
          resolve(feedlimit)
        });
      });
    });
  }
}

//Post feedback
export function postFeedback(values, uid, filelist) {
  return (dispatch) => {
    // console.log(values, uid, filelist)
    return new Promise((resolve, reject) => {
      let globalarray = [];
      let fid = random("lowernumeric");
      if (filelist.length === 0) {
        db.collection("Feedback_Master")
          .doc()
          .set({
            Created_At: firebase.firestore.FieldValue.serverTimestamp(),
            Description: values.caption,
            Reference_Images: [],
            Feedback_User_Id: uid,
            UpDated_At: firebase.firestore.FieldValue.serverTimestamp(),
          })
          .then((res) => {
            console.log("res", res);
            resolve(res);
            dispatch({
              type: SHOW_MODAL,
              payload: false,
            });
          })
          .catch(function (error) {
            reject(error);
          });
      }
      else {
        filelist.forEach((f) => {
          storage
            .ref("Feedback_Images/" + uid + "/" + fid + f.name)
            .put(f)
            .then(function (snapshot) {
              snapshot.ref.getDownloadURL().then(function (downloadURL) {
                globalarray.push(downloadURL);
                if (filelist.length === globalarray.length) {
                  // console.log(currentDate);
                  db.collection("Feedback_Master")
                    .doc()
                    .set({
                      Created_At: firebase.firestore.FieldValue.serverTimestamp(),
                      Description: values.caption,
                      Reference_Images: globalarray,
                      Feedback_User_Id: uid,
                      UpDated_At: firebase.firestore.FieldValue.serverTimestamp(),
                    })
                    .then((res) => {
                      console.log("res", res);
                      resolve(res);
                      dispatch({
                        type: SHOW_MODAL,
                        payload: false,
                      });
                    })
                    .catch(function (error) {
                      reject(error);
                    });
                }
              });
            });
        });
      }
    });
  };
}

export function getPostwithId(id) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      db.collection("Portfolio_Logs").doc(id)
        .get().then((doc) => {
          // console.log("Current data: ", doc.data());
          var postlist = [];
          var docaction = [];
          var itemProcessed = 0;
          db.collection("User_Details")
            .doc(doc.data().author)
            .get()
            .then((doc1) => {
              if (doc1.exists) {
                console.log("Document data:", doc1.data())
              } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
              }
              if (doc.exists) {
                db.collection('Portfolio_Logs').doc(id).collection('Action_Master').get()
                  .then(res => {
                    res.forEach(doc3 => {
                      if (doc3.exists) {
                        itemProcessed++
                        const fetchedData = {
                          Id: doc.id,
                          subid: doc3.id,
                          User_id: doc3.data().User_id,
                          read: String(doc3.data().read),
                          actionType: String(doc3.data().actionType),
                          Created_Date: doc3.data().Created_Date,
                          Updated_Date: doc3.data().Updated_Date,
                        };
                        docaction.push(fetchedData);
                        // console.log(fetchedData)
                      } else {
                        console.log("no sucn document")
                      }
                    })
                  })
                  .catch((error) => {
                    // console.log("Error getting document:", error);
                    console.log(error)
                  });
                postlist = {
                  Id: doc.id,
                  Created_At: moment.unix(doc.data().Created_At.seconds).startOf('seconds').fromNow(),
                  Created_Date: doc.data().Created_At_Timestamp,
                  username: doc1.data().Username,
                  userimg: doc1.data().photoURL,
                  upload_logs_Image: doc.data().upload_logs_Image,
                  log_list: doc.data().log_list,
                  Is_Verified: doc1.data().Is_Verified,
                  UpDated_At: doc.data().UpDated_At,
                  author: doc.data().author,
                  action: docaction,
                  posttype: "portfolio"
                }
                resolve(postlist)
              }
              else {
                console.log("no such document")
              }
            })
            .catch(function (error) {
              reject(error);
              console.log("Error getting document:", error);
            });
        });
    });
  };
}

export function getLogPostwithId(id) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      db.collection("Personal_Logs").doc(id)
        .get().then((doc) => {
          var postlist = [];
          var itemProcessed = 0;
          console.log(doc.data().author);
          db.collection("User_Details")
            .doc(doc.data().author)
            .get()
            .then((doc1) => {
              if (doc1.exists) {
                console.log("Document data:", doc1.data())
              } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
              }
              if (doc.exists) {
                // console.log("Current data: ", doc.data());
                itemProcessed++;
                postlist = {
                  Id: doc.id,
                  Created_At: moment.unix(doc.data().Created_At.seconds).startOf('seconds').fromNow(),
                  Created_Date: doc.data().Created_At_Timestamp,
                  username: doc1.data().Username,
                  userimg: doc1.data().photoURL,
                  upload_logs_Image: doc.data().upload_logs_Image,
                  log_list: doc.data().log_list.sort(function (a, b) {
                    // Turn your strings into dates, and then subtract them
                    // to get a value that is either negative, positive, or zero.
                    return new Date(b.text_created) - new Date(a.text_created);
                  }),
                  Is_Verified: doc1.data().Is_Verified,
                  upload_logs_Image: doc.data().upload_logs_Image,
                  UpDated_At: doc.data().UpDated_At,
                  author: doc.data().author,
                  posttype: "personal"
                }
                resolve(postlist)
              }
              else {
                console.log("no such document")
              }
            })
            .catch(function (error) {
              reject(error);
              console.log("Error getting document:", error);
            });
        });
    });
  };
}


export function getusernotfollowlist(uid) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var userList = [];
      var itemProcessed = 0;
      db.collection("User_Not_Following_List")
        .doc(uid).collection('Not_Following').get()
        .then(res => {
          if (res.empty === false) {
            res.forEach(doc1 => {
              // console.log(res)
              db.collection("User_Details")
                .doc(doc1.data().User_Not_follow_Id)
                .onSnapshot(function (doc2) {
                  itemProcessed++;
                  if (doc2.exists) {
                    // console.log("Current data: ", doc2.data());
                    userList.push({
                      Id: doc1.id,
                      Is_Verified: doc2.data().Is_Verified,
                      Username: doc2.data().Username,
                      User_id: doc1.id,
                      Follower_id: "",
                      Follower_type: "",
                      circle_id: "",
                      Date_of_Birth: doc2.data().Date_of_Birth,
                      address: doc2.data().address,
                      Biography: doc2.data().Biography,
                      photoURL: doc2.data().photoURL,
                    });
                    // console.log(userList, userList.length, res.size)
                    if (res.size === itemProcessed) {
                      var logData = userList;
                      console.log(logData);
                      resolve(logData);
                    }
                  }
                  else {
                    console.log("No data found")
                    // reject(userList);
                    console.log("Current data: ", doc2.exists, doc1.data().User_Not_follow_Id);
                  }

                })
            })
          }
          else {
            console.log("no such document")
            reject(userList)
          }
        })
        .catch(function (error) {
          reject(error);
          console.log("Error getting document:", error);
        });
    });
  };
};







