import React, { Component } from "react";
import Logo from '../assests/img/logo-new-dark.svg';
import {
    Card, Dropdown, Modal, Button,
    Empty, Avatar, Icon, Tabs,
    message, Popconfirm
} from 'antd';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
    getPostwithId, postActions,
    reportPost,
} from "../actions/livingAction";
import createHistory from "history/createBrowserHistory";
import { Carousel } from "react-responsive-carousel";
import _ from "lodash";
import Noprofileimg from '../assests/img/d-avatar.jpg'
import Verified from '../assests/img/verified.svg'
import Linkify from 'react-linkify';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import LogShimmer from './logShimmer'
import {
    FacebookShareButton,
    TwitterShareButton,
    LinkedinShareButton,
    WhatsappShareButton,
} from "react-share"
import { Passers } from "prop-passer";
import { db } from "../firebase";



const { TabPane } = Tabs;
class Posts extends Component {
    constructor() {
        super();
        this.state = {
            postlist: [],
            username: "",
            userimg: "",
            posttype: "",
            date: "",
            logs: "",
            uploadImage: [],
            arraylength: true,
            activeimgset: "",
            currentSlide: 0,
            visible: false,
            userexists: false,
            urlvalue: window.location.origin + "/post/",
            urlvalue1: window.location.origin + "/logpost/",
            action: [],
            author: "",
            Id: "",
            close: true,
            visibleDrawer: false,
            visible1: false,
            Is_Verified: false,
            user: "",
            copied: false

        }
    }

    showModal = (images, index) => {
        this.setState({
            visible: true,
            currentSlide: index,
            activeimgset: images
        });
        // console.log(index)
    };

    showModal1 = () => {
        this.setState({
            visible1: true,
        });
    };

    showDrawer = (postid, authorid, uid, type) => {
        this.setState({
            visibleDrawer: true,
            author: authorid,
            id: postid,
            user: uid,
            posttype: type,
        });
    };

    onClose = () => {
        this.setState({
            visibleDrawer: false,
        })
    }

    confirm = (id) => {
        this.deleteimage(id)
        // message.success('Log deleted');
    }

    cancel = (e) => {
        console.log(e);
        // message.error('Click on No');
    }

    //delete log image by id & value
    deleteimage = (Id) => {
        db.collection("Portfolio_Logs")
            .doc(Id)
            // console.log(Id)
            .delete()
            .then(function () {
                // console.log("Document successfully deleted!");
                message.error("Post deleted successfully")
                setTimeout(function () {
                    window.location.reload(false);
                }, 1000);
            })
            .catch(function (error) {
                console.error("Error removing document: ", error);
            });
    }

    deletepersonallog = (Id, type) => {
        this.setState({
            posttype: type
        })
        db.collection("Personal_Logs")
            .doc(Id)
            // console.log(Id)
            .delete()
            .then(function () {
                // console.log("Document successfully deleted!");
                message.error("Log deleted successfully")
                setTimeout(function () {
                    window.location.reload(false);
                }, 1000);
            })
            .catch(function (error) {
                console.error("Error removing document: ", error);
            });
    }

    confirmLog = (id, type) => {
        this.deletepersonallog(id, type);
    }

    confirmdelete = (Id, value) => {
        this.deleteLogImage(Id, value)
        // message.success('Log deleted');
    }

    deleteLogImage = (Id, value) => {
        var docRef = db.collection("Personal_Logs").doc(Id);
        docRef
            .get()
            .then(function (doc) {
                if (doc.exists) {
                    // console.log(doc.data().upload_logs_Image);
                    var ListImage = doc.data().upload_logs_Image;
                    ListImage.splice(value, 1);
                    message.success("Log image deleted successfully");
                    docRef
                        .update({
                            upload_logs_Image: ListImage
                        })
                        .then(function () {
                            console.log("Document successfully updated!");
                            setTimeout(function () {
                                window.location.reload(false);
                            }, 1000);
                        })
                        .catch(function (error) {
                            console.error("Error updating document: ", error);
                        });
                } else {
                    console.log("No such document!");
                }
            })

            .catch(function (error) {
                console.log("Error getting document:", error);
            });
    };


    confirmdeleteLogs(Id, value) {
        this.deleteLogs(Id, value);
    }


    deleteLogs = (Id, value) => {
        var docList = db.collection("Personal_Logs").doc(Id);
        docList
            .get()
            .then(function (doc) {
                if (doc.exists) {
                    var ListLogs = doc.data().log_list;
                    var character = doc.data().character_count;
                    // console.log(ListLogs[value], ListLogs[value].description, ListLogs[value].description.length);
                    var logscount = character - ListLogs[value].description.length;
                    // console.log(logscount);
                    ListLogs.splice(value, 1);
                    message.success("This log has been deleted successfully");
                    docList
                        .update({
                            log_list: ListLogs,
                            character_count: logscount
                        })
                        .then(function () {
                            // console.log("Document successfully updated!");
                            setTimeout(function () {
                                window.location.reload(false);
                            }, 1000);
                        })
                        .catch(function (error) {
                            console.error("Error updating document: ", error);
                        });
                } else {
                    // console.log("No such document!");
                }
            })

            .catch(function (error) {
                console.log("Error getting document:", error);
            });
    };

    handleSubmit = (e) => {
        e.preventDefault();

        var post = this.state.Id;
        var reportedid = this.state.author;
        var reporterid = this.props.user.uid;
        var typepost = this.state.posttype;

        this.props.form.validateFields((err, values) => {
            if (!err && post !== "", reportedid !== "" && reporterid !== "" && typepost !== "") {
                // console.log('Received values of form: ', values, this.state.id, this.state.author, this.props.user.uid, this.state.posttype);
                this.setState({
                    visibleDrawer: false
                })
                message.success("Thanks for your feedback")
                this.refreshReport();
                this.props.reportPost(values, this.state.Id, this.state.author, this.props.user.uid, this.state.posttype).then((res) => {
                    // console.log(res);
                });
            }
            else {
                message.error("Please ignore if you are not reporting this post")
                this.setState({
                    visibleDrawer: false
                })
            }
        });
    };


    componentDidMount() {

        const history = createHistory();
        // Get the current location.
        const location = history.location;

        var path = location.pathname
        var res = path.split("/");
        var docid = res[2];
        if (this.props.user !== null) {
            console.log(this.props.user)
        }

        if (!_.isEmpty(this.props.user)) {
            console.log(this.props.user)
            this.setState({
                userexists: true
            })
        }
        // if (this.props.user.uid === null || this.props.user.uid === undefined) {
        this.props.getPostwithId(docid).then((res) => {
            console.log(res);
            this.setState({
                postlist: res,
                username: res.username,
                userimg: res.userimg,
                date: res.Created_At,
                posttype: res.posttype,
                logs: res.log_list,
                Is_Verified: res.Is_Verified,
                uploadImage: res.upload_logs_Image,
                action: res.action,
                author: res.author,
                Id: res.Id,
                arraylength: false
            })
        });
        // }
    }


    abbreviateNumber(value) {
        var newValue = value;
        if (value >= 10000) {
            var suffixes = ["", "k", "M", "B", "T"];
            var suffixNum = Math.floor(("" + value).length / 4);
            var shortValue = '';
            for (var precision = 3; precision >= 2; precision--) {
                shortValue = parseFloat((suffixNum !== 0 ? (value / Math.pow(1000, suffixNum)) : value).toPrecision(precision));
                var dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g, '');
                if (dotLessShortValue.length <= 3) { break; }
            }
            if (shortValue % 2 !== 0) shortValue = shortValue.toFixed(2);
            newValue = shortValue + suffixes[suffixNum];
        }
        // console.log(newValue)
        return newValue;
    }


    postsaction = (logid, authorid, uid, type, actions, actionfig) => {
        console.log(logid, authorid, uid, type, actions, actionfig)
        // this.postLiveAction(logid, authorid, uid, type, actions);
        var actionarr = actions;
        console.log(actions);
        var subcollectionid = _.filter(actionarr, {
            User_id: uid,
        });
        console.log(subcollectionid);
        var subid = subcollectionid.length === 0 ? "" : subcollectionid[0].subid;
        this.props.postActions(logid, authorid, uid, type, subid, actionfig).then((res) => {
            console.log(res);
            var target = this.state.postlist;
            var id = logid;
            console.log(id)
            var updatedActionindex = actions.findIndex((element) => element.User_id === res.User_id);
            console.log(updatedActionindex)
            if (actionfig === 2 || actionfig === 4) {
                actions.splice(updatedActionindex, 1);
                actions = _.remove(actions, function (n) {
                    return n.User_id !== uid;
                })
            } else {
                if (updatedActionindex < 0) {
                    actions.push({
                        Created_Date: res.Created_Date,
                        User_id: res.User_id,
                        actionType: res.actionType.toString(),
                        read: res.read,
                        Updated_Date: res.Updated_Date,
                        subid: res.subid
                    })
                } else {
                    actions[updatedActionindex] = {
                        Created_Date: res.Created_Date,
                        User_id: res.User_id,
                        actionType: res.actionType.toString(),
                        read: res.read,
                        Updated_Date: res.Updated_Date,
                        subid: res.subid
                    }
                }
            }
            console.log(target)
            // const elementsIndex = target.findIndex((element) => element.Id === id);
            const elementsIndex = _.findIndex(target, function (e) { return e.Id === id; })
            console.log(elementsIndex, actions);

            let newArray = [this.state.postlist];
            newArray[elementsIndex] = {
                action: actions,
            };
            // console.log(newArray);
            this.setState({
                postlist: newArray,
            });
        });
    };

    getLikes = (actions) => {
        // console.log(actions)
        var likes = _.filter(actions, ["actionType", "true"]);
        var liketext = "";
        if (likes.length >= 2) {
            liketext = ""
        } else {
            liketext = ""
        }
        var likes = this.abbreviateNumber(likes.length)
        console.log(likes)
        return likes;
        // return likes.length + " " + liketext;
    };


    getdisLikes = (actions) => {
        // console.log(actions)
        var dislikes = _.filter(actions, ["actionType", "false"]);
        var disliketext = "";
        if (dislikes.length >= 2) {
            disliketext = ""
        } else {
            disliketext = ""
        }
        var dislikes = this.abbreviateNumber(dislikes.length)
        return dislikes;
        // return dislikes.length + " " + disliketext;
    };


    getIsLiked = (actions, user) => {
        var isliked = _.filter(actions, { User_id: user, actionType: "true" });
        var isdisliked = _.filter(actions, { User_id: user, actionType: "false" });
        // console.log(isliked, isdisliked, actions, user);
        if (isliked.length >= 1) {
            return 2;
        } else if (isdisliked.length >= 1) {
            return 5;
        } else {
            return 1
        }
    };


    getIsdisLiked = (actions, user) => {
        var isliked = _.filter(actions, { User_id: user, actionType: "true" });
        var isdisliked = _.filter(actions, { User_id: user, actionType: "false" });
        // console.log(isliked, isdisliked, actions, user);
        if (isdisliked.length >= 1) {
            return 4;
        } else if (isliked.length >= 1) {
            return 5;
        } else {
            return 3;
        }
    };

    getLikeReactions = () => {
        var users = this.state.userlist;
        var reacted = _.filter(this.state.activereactions, { actionType: true });
        var allreactors = [];
        _.forEach(reacted, function (value) {
            console.log(value);
            var all = _.filter(users, { Id: value.author });
            console.log(all);
            if (all.length != 0) {
                allreactors.push({
                    Id: all[0].Id,
                    photoURL: all[0].photoURL,
                    First_Name: all[0].First_Name,
                    Last_Name: all[0].Last_Name,
                    Date_of_Birth: all[0].Date_of_Birth,
                    Ref_Country: all[0].Ref_Country,
                    Ref_City: all[0].Ref_City,
                    Ref_Gender: all[0].Ref_Gender,
                    Education: all[0].Education,
                    Interest_Passion: all[0].Interest_Passion,
                    Biography: all[0].Biography,
                    Updated_At: all[0].Updated_At,
                    actionType: value.actionType,
                });
            }
        });

        if (allreactors.length === reacted.length) {
            return (
                <ul className="list-unstyled reactionuserlist">
                    {_.map(allreactors, (user, index) => {
                        return (
                            <Link
                                to={
                                    user.Id === this.props.user.uid
                                        ? "/profile"
                                        : "/profile/" + user.Id
                                }
                                key={index}
                            >
                                <li>
                                    <div className="userphoto">
                                        <span className={user.actionType === false ? "reacdislike" : "reaclike"}>
                                            <Icon type={user.actionType === false ? "caret-down" : "caret-up"}
                                                className="margin" id="disliked " theme="filled" />
                                        </span>
                                        {user.photoURL === "" || user.photoURL === undefined ? (
                                            <Avatar
                                                size="small" src={Noprofileimg}
                                            >
                                                {/* {user.First_Name.charAt(0)} */}
                                            </Avatar>
                                        ) : (
                                            <Avatar src={user.photoURL} size="small" />
                                        )}
                                    </div>
                                    <div>
                                        <span className="userinfo">
                                            {user.First_Name} {user.Last_Name} <br />
                                        </span>
                                        {/* <span>{user.Updated_At}</span> */}
                                        {/* <span
                          className={
                            user.actionType === true ? "Liked" : "DisLiked"
                          }
                        >
                          {user.actionType === true ? "Liked" : "DisLiked"}
                        </span> */}
                                    </div>
                                </li>
                            </Link>
                        );
                    })}
                </ul>
            );
        }
    };


    getDisLikeReactions = () => {
        var users = this.state.userlist;
        var reacted = _.filter(this.state.activereactions, { actionType: false });
        var allreactors = [];
        _.forEach(reacted, function (value) {
            console.log(value);
            var all = _.filter(users, { Id: value.author });
            console.log(all);
            allreactors.push({
                Id: all[0].Id,
                photoURL: all[0].photoURL,
                First_Name: all[0].First_Name,
                Last_Name: all[0].Last_Name,
                Date_of_Birth: all[0].Date_of_Birth,
                Ref_Country: all[0].Ref_Country,
                Ref_City: all[0].Ref_City,
                Ref_Gender: all[0].Ref_Gender,
                Education: all[0].Education,
                Interest_Passion: all[0].Interest_Passion,
                Biography: all[0].Biography,
                Updated_At: all[0].Updated_At,
                actionType: value.actionType,
            });
            // console.log(allreactors);
        });

        if (allreactors.length === reacted.length) {
            return (
                <ul className="list-unstyled reactionuserlist">
                    {_.map(allreactors, (user, index) => {
                        return (
                            <Link
                                to={("/profile/" + user.Id)}
                                key={index}
                            >
                                <li>
                                    <div className="userphoto">
                                        <div
                                            data-icon={user.actionType === true ? "n" : "k"}
                                            className={
                                                user.actionType === true
                                                    ? "Liked icon"
                                                    : "icon DisLiked"
                                            }
                                        ></div>
                                        {user.photoURL === undefined ? (
                                            <Avatar
                                                style={{ color: "#f56a00", backgroundColor: "#fde3cf" }}
                                                size="small"
                                            >
                                                {user.First_Name.charAt(0)}
                                            </Avatar>
                                        ) : (
                                            <Avatar src={user.photoURL} size="small" />
                                        )}
                                    </div>
                                    <div>
                                        <span className="userinfo">
                                            {user.First_Name} {user.Last_Name} <br />
                                        </span>
                                        <span>{user.Biography}</span>
                                        {/* <span
                          className={
                            user.actionType === true ? "Liked" : "DisLiked"
                          }
                        >
                          {user.actionType === true ? "Liked" : "DisLiked"}
                        </span> */}
                                    </div>
                                </li>
                            </Link>
                        );
                    })}
                </ul>
            );
        }
    };


    handleCancel = e => {
        // console.log(e);
        this.setState({
            visible: false,
            visible1: false
        });
        setTimeout(
            function () {
                this.setState({
                    activeimgset: ""
                });
            }.bind(this),
            150
        );
    };


    renderUserInfo() {
        const { username, userimg, date, Is_Verified } = this.state
        return (
            <div className="postuserinfo">
                <span className="userphoto">
                    {userimg === "" ? (
                        <Avatar
                            style={{ color: "#f56a00", backgroundColor: "#fde3cf" }}
                            size={46} src={Noprofileimg}
                        >
                            {/* {username.charAt(0)} */}
                        </Avatar>
                    ) : (
                        <Avatar src={userimg} size={46} />
                    )}
                </span>
                <span
                    className="userinfo"
                    style={{
                        marginLeft: "10px",
                        fontWeight: "600",
                        fontSize: "14px",
                        textAlign: 'left'
                    }}
                >
                    {username}
                    <small
                        style={{ color: '#777', marginLeft: '5px' }}>created a new post
                        {Is_Verified === false ? null : (
                            <img src={Verified} className="feeds-verified" />
                        )}
                    </small>
                    <br />
                    <span className="memcity">{date}</span>
                </span>
            </div>
        );
    }

    onCopy = () => {
        this.setState({
            copied: true,
        });
        message.success("Copied shared link")
    };


    renderMenu(Id, type) {
        const {
            url = this.state.urlvalue + Id,
            title = "See the livathon post",
            shareImage = "https://www.steadylearner.com/static/images/brand/prop-passer.png",
            size = "2.5rem",
        } = this.props;


        const ShareList = Passers({
            url,
            className: "network__share-button",
        })({
            className: "network cursor-pointer hover transition--default",
            title: `Share ${String(this.state.loc)}`,
        })("li");

        const iconFont = {
            fontSize: '14px'
        }
        return (
            <ul className="feedpost-drop">
                <p style={{ margin: 0 }}>
                    <ShareList>
                        <FacebookShareButton
                            windowWidth={750}
                            windowHeight={600}
                            url={"https://livathon.com/" + "/post" + Id}
                            quote={"See the livathon Post"}
                        >
                            <i class="fab fa-facebook" style={iconFont}></i> Share to facebook
                        </FacebookShareButton>
                    </ShareList>
                    <ShareList>
                        <TwitterShareButton
                            windowWidth={750}
                            windowHeight={600}
                            title={title}
                            url={url}
                        >
                            <i class="fab fa-twitter" style={iconFont}></i> Share to twitter
                        </TwitterShareButton>
                    </ShareList>
                    <ShareList>
                        <WhatsappShareButton
                            windowWidth={750}
                            windowHeight={600}
                            title={"See the livathon post"}
                            url={url}
                            separator=":: "
                        >
                            <i class="fab fa-whatsapp-square" style={iconFont}></i>  share to whatsapp
                        </WhatsappShareButton>
                    </ShareList>
                    <ShareList>
                        <LinkedinShareButton
                            title={title}
                            url={url}
                            windowWidth={750}
                            windowHeight={600}
                        >
                            <i class="fab fa-linkedin-in" style={iconFont}></i> Share to linkedIn
                        </LinkedinShareButton>
                    </ShareList>
                </p>
                <li>
                    <CopyToClipboard onCopy={this.onCopy}
                        text={type == "portfolio" ? this.state.urlvalue + Id : this.state.urlvalue1 + Id}>
                        <span><i className="fas fa-copy" style={iconFont}></i> Copy link</span>
                    </CopyToClipboard>
                </li>
            </ul >
        )
    }

    renderLogsList() {

        const { postlist, posttype, logs,
            uploadImage, action, author, Id, userexists
        } = this.state
        const componentDecorator = (href, text, key) => (
            <a href={href} key={key} rel="noopener noreferrer" target="_blank">
                {text}
            </a>
        );
        return (
            <div className="postcard" style={{ marginBottom: "10px" }}>
                <Card
                    className="feedscard"
                    title={this.renderUserInfo()}
                    extra={
                        <div style={{ display: 'inline-flex' }}>
                            <Dropdown trigger={["click"]} placement="bottomRight"
                                overlay={this.renderMenu(postlist.Id, postlist.posttype)}
                                style={{ marginLeft: '10px' }}>
                                <a className="ant-dropdown-link" href="#"
                                    onClick={e => e.preventDefault()}>
                                    <Icon type="more" style={{ fontSize: '20px', color: '#333' }} />
                                </a>
                            </Dropdown>
                        </div>
                    }
                >
                    <div style={logs.length === 0 ? { padding: '0' } : { padding: '15px' }}>
                        <div className="readdiv">
                            <Linkify componentDecorator={componentDecorator}>
                                {logs}
                            </Linkify>
                        </div>
                    </div>
                    <div>
                        <Carousel
                            showIndicators={false}>
                            {_.map(uploadImage, (logImage, index) => {
                                return (
                                    <div className="col-md-12 col-xs-12 postimg" key={index}>
                                        <div className="imagecontainer1">
                                            <img
                                                src={logImage}
                                                className=""
                                                alt=""
                                                style={{ maxHeight: '600px' }}
                                            />
                                        </div>
                                    </div>
                                );
                            })}
                        </Carousel>
                    </div>
                    <div>
                        <div className="postaction">
                            <div className="postactionbtn">
                                <div className="actioncount">
                                    {action.length === 0 ? null : (
                                        <div>
                                            <div className="spacereactors"
                                                onClick={() => this.showreactionmodal(logs.action)}>
                                                <span className="badge-success">
                                                    {this.getLikes(action)}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                {!userexists ?
                                    (
                                        <Button
                                            type="link"
                                            className=""
                                            onClick={() => this.showModal1()}>
                                            <Icon type="caret-up" className="margin likeicon"
                                                theme="filled" />
                                        </Button>
                                    ) : (
                                        <Button
                                            type="link"
                                            className={this.getIsLiked(
                                                action,
                                                this.props.user.uid)
                                                === 1 ? "Like" : this.getIsLiked(
                                                    action,
                                                    this.props.user.uid)
                                                    === 5 ? "Like" : "Liked"}
                                            onClick={() =>
                                                this.postsaction(this.state.Id,
                                                    this.state.author, this.props.user.uid, true,
                                                    action, this.getIsLiked(
                                                        action, this.props.user.uid))
                                            }>
                                            <Icon type="caret-up" className="margin likeicon"
                                                theme="filled" />
                                        </Button>
                                    )}
                            </div>
                            <div className="postactionbtn">
                                {!userexists ?
                                    (
                                        <Button
                                            type="link"
                                            className=""
                                            onClick={() => this.showModal1()}
                                        >
                                            <Icon type="caret-down"
                                                className="margin likeicon" theme="filled" />
                                        </Button>
                                    ) : (
                                        <Button
                                            type="link"
                                            className={this.getIsdisLiked(
                                                action,
                                                this.props.user.uid
                                            ) === 3 ? "DisLike" : this.getIsdisLiked(
                                                action,
                                                this.props.user.uid
                                            ) === 5 ? "DisLike" : "DisLiked"}
                                            onClick={() =>
                                                this.postsaction(this.state.Id, this.state.author,
                                                    this.props.user.uid, false,
                                                    action, this.getIsdisLiked(
                                                        action, this.props.user.uid
                                                    ))
                                            }
                                        >
                                            <Icon type="caret-down"
                                                className="margin likeicon" theme="filled" />
                                        </Button>
                                    )}
                                <div className="actioncount">
                                    {action.length === 0 ? null : (
                                        <div>
                                            <div className="spacereactors">
                                                <span className="badge-danger">
                                                    {this.getdisLikes(action)}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                    </div>
                </Card>
            </div>
        );

    }

    renderModalimages() {
        return (
            <Carousel
                selectedItem={this.state.currentSlide}
                // onChange={this.updateCurrentSlide}
                // showArrows={false}
                renderArrowPrev={(onClickHandler, hasPrev, label) =>
                    hasPrev && (
                        <Button type="primary" onClick={onClickHandler} title={label}
                            className="arrowstyles"
                            style={{ left: 0 }}>
                            <Icon type="left" />
                        </Button>
                    )
                }
                renderArrowNext={(onClickHandler, hasNext, label) =>
                    hasNext && (
                        <Button type="primary" onClick={onClickHandler} title={label}
                            className="arrowstyles"
                            style={{ right: 0 }}>
                            <Icon type="right" />
                        </Button>
                    )
                }>
                {_.map(this.state.activeimgset, (logImage, index) => {
                    return (
                        <div className="carouselimg" key={index}>
                            <div className="carouselimginner">
                                <img src={logImage} className="imgbanner img1" alt="" />
                            </div>
                        </div>
                    );
                })}
            </Carousel>
        );
    }

    renderAlert() {
        const logoImage = {
            height: '50px',
            padding: '15px 15px',
            fontSize: '18px',
            lineHeight: '20px'
        }
        return (
            <div>
                <div className="row">
                    <div className="col-md-12 text-center">
                        <Link style={logoImage} to="">
                            <img
                                src={Logo}
                                className="logoimg img-responsive text-center"
                                style={{ height: '30px', display: 'inline-block' }}
                                alt="logo"
                            />
                        </Link>
                    </div>
                    <div className="col-md-12 text-center"
                        style={{ marginTop: '15px', marginBottom: '15px' }}>
                        <p>Kindly login or signup to livathon</p>
                        <div style={{ display: 'inline-flex' }}>
                            <Link className="navbar-brand" to="/login">
                                <Button type="primary">Login</Button>
                            </Link>
                            <Link className="navbar-brand" to="/signup">
                                <Button type="default">Signup</Button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div >
        )
    }

    renderModalReactions() {
        // console.log(this.state.activereactions);
        return (
            <Tabs defaultActiveKey="1" onChange={this.callback} animated={true} className="reacttab">
                {/* <TabPane tab="Likes" key="1">
              {this.getAllReactions()}
            </TabPane> */}
                <TabPane tab="Likes" key="1">
                    {this.state.activereactions === "" ? (
                        <Empty />
                    ) : (
                        <div>
                            {this.getLikeReactions()}
                        </div>
                    )}
                </TabPane>
                {/* <TabPane tab="DisLikes" key="3">
              {this.getDisLikeReactions()}
            </TabPane> */}
            </Tabs>
        );
    }

    onclose = () => {
        this.setState({
            close: false,
        })
    }


    render() {

        const { postlist, arraylength, userexists } = this.state

        const space = {
            marginRight: '15px'
        }
        return (
            <div>
                <Modal
                    className="gridmodal carouselmodal"
                    title=""
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={null}
                    centered={true}
                    width="70%"
                >
                    {this.renderModalimages()}
                </Modal>
                <Modal
                    title=""
                    className="loginalert"
                    visible={this.state.visible1}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={null}
                    closable={true}
                    centered={true}
                    destroyOnClose={true}
                >
                    {this.renderAlert()}
                </Modal>
                <Modal
                    title="Reactions"
                    visible={this.state.visible2}
                    onCancel={this.handlereactionCancel}
                    footer={null}
                    centered={true}
                    className="reactionsmodal"
                >
                    {this.renderModalReactions()}
                </Modal>
                <div className="container-fluid">
                    <nav className="navbar navbar-default">
                        <div className="container-fluid navcontainer">
                            <div className="navbar-header">
                                <div className="navbar-toggle">
                                    {/* <Signup /> */}
                                </div>
                                <Link className="navbar-brand" to="">
                                    <img
                                        src={Logo}
                                        className="logoimg img-responsive"
                                        alt="logo"
                                        style={{ height: '35px' }}
                                    />
                                </Link>
                            </div>
                            <div id="navbar" className="navbar-collapse collapse">
                                <ul className="nav navbar-nav navbar-right" style={{ marginTop: '30px' }}>
                                    {!userexists ? (
                                        <Link to="/login">
                                            <Button type="primary">Login</Button>
                                        </Link>
                                    ) : null}

                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-offset-2 col-md-8 col-md-offset-2"
                            style={{ padding: 0 }}>
                            <div>
                                {postlist.length === 0 && arraylength === true ? (
                                    <div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <LogShimmer count={1} />
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        {postlist.length === 0 ? (
                                            <Empty />
                                        ) : (
                                            <div>
                                                {this.renderLogsList()}
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {!userexists && this.state.close === true ? (
                    <div className="logincard-div" id="closeDiv">
                        <div className="logincard-closable">
                            <div className="logincard-content">
                                <div className="logincard-message">
                                    <p>Log In to Livathon </p>
                                    <p>Log in to see photos from friends and discover
                                        other accounts you'll love.</p>
                                </div>
                                <div className="logincard-close">
                                    <Link to="/login">
                                        <Button type="primary" style={space}>Login</Button>
                                    </Link>
                                    <Link to="/signup">
                                        <Button type="default" style={space}>Signup</Button>
                                    </Link>
                                    <a onClick={() => this.onclose()}>
                                        <Icon type="close" style={{ color: "#fff" }} />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}

            </div>
        )
    }

}


function mapStateToProps(state, ownProps) {
    return { user: state.user };
}

export default connect(
    mapStateToProps,
    {
        getPostwithId,
        postActions,
        reportPost,
    }
)(Posts);


