import React, { Component } from "react";
import {
  Layout, Menu, Icon,
  Avatar, Dropdown, Badge,
  Select, Spin, Empty,
  Button, Drawer
} from "antd";
import debounce from "lodash/debounce";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Offline, Online } from "react-detect-offline";
import { logout, createAccount } from "../actions/userAction";
import { getNavNotifications, getNotifications, unreadnotify } from "../actions/livingAction";
import Logo from "../assests/img/logo-new.svg";
import request from "superagent";
import NotificationShimmer from '../component/notificationShimmer'
import _ from "lodash";
import { db } from "../firebase";
import "../assests/css/styles.css";
import Noprofileimg from "../assests/img/d-avatar.jpg";
import { BASEURL } from '../actionTypes'
import { createBrowserHistory } from 'history'
import Verified from '../assests/img/verified.svg'
import OfflineContent from './offlineContent'


const { Header } = Layout;

const { Option } = Select;

function onChange(value) {
  // console.log(`selected ${value}`);
}


function onSearch(val) {
  // console.log("search:", val);
};

function handleClick(e) {
  // console.log('click ', e);
}


class Navigation extends Component {
  constructor(props) {
    super(props);
    this.fetchUser = debounce(this.fetchUser, 800);
    this.state = {
      photoURL: "",
      current: "1",
      date: [],
      notifications: [],
      noticount: [],
      data: [],
      value: [],
      fetching: false,
      userlist: "",
      removeNotification: "",
      arraylength: true,
      visible: false,
      verified: false
    };
  }

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  closeDrawer = () => {
    this.setState({
      visible: false,
    });
  };


  readNotification() {
    var uid = this.props.user.uid;

    var unnotify = this.state.notifications;
    console.log(unnotify);
    this.props.unreadnotify(uid, unnotify);
  }


  // getMyNotification(uid) {

  //   var dataobj = {
  //     uid: uid,
  //     type: "No Limit",
  //   };

  //   // console.log("Query", dataobj);
  //   request
  //     .post(
  //       BASEURL + "/notificationuserlist"
  //     )
  //     .send(dataobj)
  //     .then((res) => {
  //       // console.log(res);
  //       var records = res.body.Data
  //       if (res.body.ReturnCode === 200) {
  //         // console.log(res.body.Data);
  //         // var record = res.body.Data
  //         if (records.length !== 0) {
  //           // record.length = 5;
  //           this.setState({
  //             notifications: records,
  //             arraylength: false
  //           });
  //         } else {
  //           this.setState({
  //             notifications: res.body.Data,
  //             arraylength: false
  //           });
  //         }
  //       }
  //     })
  //     .catch((err) => { });
  // }


  removeNotification() {
    var uid = this.props.user.uid;

    var dataobj = {
      uid: uid,
    };

    request
      .post(
        BASEURL + "/removenotification"
      )
      .send(dataobj)
      .then((res) => {
        // console.log(res);
        if (res.body.ReturnCode === 200) {
          // console.log(res.body.Data);
          // var record = res.body.Data
          // if (record.length > 5) {
          //   record.length = 5;
          this.setState({
            removeNotification: res.body.Data
          });
          // } else {
          //   this.setState({
          //     notifications: res.body.Data
          //   });
          // }
        } else {
          // console.log(res.body);   
        }
      })
      .catch((err) => { });
  }


  fetchUser = async (value) => {
    //console.log('fetching user', value.length);
    this.setState({ data: [], value: [], fetching: true });
    try {
      const res = await request
        .post(BASEURL + "/searchuser")
        .send({
          UsernameText: value,
          uid: this.props.user.uid
        });
      // res.body, res.headers, res.status
      console.log(res.body);
      if (res.body.ReturnCode === 201) {
        this.setState({ fetching: false });
      } else {
        console.log(res.body.User_Details)
        this.setState({
          userlist: res.body.User_Details,
        });
        const data = res.body.User_Details.map((user) => ({
          Id: user.Id,
          value: user.Username,
          image: user.photoURL,
          verified: user.Is_Verified
        }));
        this.setState({
          data: [],
          value,
          fetching: false,
          // userlist: res.body.User_Details,
        });
        // console.log(this.state.userlist);
      }
    } catch (err) {
      return err;
    }
  };


  handleChange = (value) => {
    this.setState({
      value,
      data: [],
      fetching: false,
    });
  };


  componentDidMount() {

    var uid = this.props.user.uid;

    const history = createBrowserHistory();
    // Get the current location.
    const location = history.location;
    // console.log(location.pathname);
    this.setState({ current: location.pathname });

    //getnotification by api
    // this.getMyNotification(uid);

    //get user details
    var userRef = db.collection("User_Details").doc(uid);
    userRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          var Full_Name = doc.data().First_Name + " " + doc.data().Last_Name;
          // console.log(doc.data().photoURL);
          this.setState({
            Full_Name: Full_Name,
            photoURL: doc.data().photoURL,
          });
        } else {
          console.log("No such document!");
        }
      })
      .catch((err) => {
        // console.log("Error getting document", err);
      });

    this.props.getNavNotifications(uid).then(res => {
      // console.log(res);
      var sortDate = res.sort(function (a, b) {

        return new Date(b.Created_Date.seconds * 1000) - new Date(a.Created_Date.seconds * 1000);
      });
      this.setState({
        notifications: sortDate,
      });
      // console.log(this.state.date);
    });

    this.props.getNotifications(uid).then(res => {
      var unreadnoti = _.filter(res, { 'read': false });

      this.setState({
        noticount: unreadnoti
      });
    });
  }


  componentDidUpdate(prevProps, nextProps) {
    // console.log(prevProps.getNotification, nextProps.getNotification, this.props.getNotification)
    if (prevProps.getNavNotifications !== this.props.getNavNotifications) {
      var unreadnoti = _.filter(this.props.getNavNotifications, { 'read': false });
      // console.log(unreadnoti)
      this.setState({
        notifications: this.props.getNavNotificationss ?? [],
        noticount: unreadnoti ?? [],
        arraylength: false
      });
    }
  }


  handleClick = e => {
    // console.log('click ', e);
    this.setState({
      current: e.key
    });
  };

  handleresClick = e => {
    // console.log('click ', e);
    this.setState({
      current: e.key,
      collapsed: true
    });
  };

  refreshFeeds = () => {
    setTimeout(function () {
      window.location.reload(false);
    }, 500);
  };

  rendernotifications() {
    // console.log(this.state.notifications);
    return _.map(this.state.notifications, (notify, index) => {
      return (
        <Menu.Item key={index} className="notimenulist">
          <div className="notifflex">
            {notify.photoURL === undefined || notify.photoURL === "" ? (
              <Avatar
                style={{ marginRight: "5px" }}
                size={24}
                src={Noprofileimg}
              >
                {/* {username.charAt(0)} */}
              </Avatar>
            ) : (
              <Avatar
                size={24}
                src={notify.photoURL}
                style={{ marginRight: "5px" }}
              />
            )}
            <p style={{ padding: "5px" }} className="notifybody">
              <Link
                to={
                  notify.Id === this.props.user.uid
                    ? "/profile"
                    : "/profile/" + notify.Id
                }
              >
                {notify.First_Name} {notify.Last_Name}
                {notify.Is_Verified === false ? null : (
                  <img src={Verified} className="feeds-verified" />
                )}
              </Link>
              {notify.Follower_type === "" && notify.actionType === true ? (
                <p style={{ marginLeft: "3px" }}>
                  likes your post on
                  <span className="notifydate" style={{ color: "#1c41ce" }}>
                    {notify.dateago}
                  </span>
                </p>
              ) : (
                <div>
                  <p style={{ marginLeft: "3px" }}>
                    started following you from
                    <span className="notifydate"
                      style={{ color: '#1c41ce' }}>
                      {notify.dateago}
                    </span>
                  </p>

                </div>
              )}
            </p>
          </div>
        </Menu.Item>
      );
    });
  }

  rendersearchUser() {
    // console.log(this.state.userlist);
    return _.map(this.state.userlist, (user, index) => {
      return (
        <Option
          value={user.Id}
          // label={interest.interestName}
          key={index}
        >
          <Link
            onClick={() => this.refreshFeeds()}
            to={
              user.Id === this.props.user.uid
                ? "/profile"
                : "/profile/" + user.Id
            }
          >
            {user.photoURL === "" ? (
              <Avatar
                src={Noprofileimg}
                size={24}
                style={{ marginRight: "10px" }}
              />
            ) : (
              <Avatar
                src={user.photoURL}
                size={24}
                style={{ marginRight: "10px" }}
              />
            )}

            {user.Username}
            {user.Is_Verified === false ? null : (
              <img src={Verified} className="feeds-verified" />
            )}
          </Link>
        </Option>
      );
    });
  }

  renderMobilemenu() {
    return (
      <div>
        <div>
          <Button type="primary"
            className="drawerlogout"
            onClick={() => this.props.logout()}>
            <Icon type="logout"
              className="logouticon"
            />
          </Button>
          <p className="drawerclose"
            onClick={() => this.closeDrawer()}>
            <Icon type="close"
              className="closeicon"
            />
          </p>
        </div> <br />
        <div className="userbox mobileuser">
          <span className="userphoto">
            {this.state.photoURL === undefined || this.state.photoURL === "" ? (
              <Avatar
                style={{
                  color: "#f56a00",
                  backgroundColor: "#fde3cf",
                  textTransform: "uppercase",
                }}
                size={64}
                src={Noprofileimg}
              >
              </Avatar>
            ) : (
              <Avatar src={this.state.photoURL} size={36} />
            )}
          </span>
          <span className="mobileuserinfo">
            {this.state.Full_Name} <br />
          </span>
        </div>
        <Menu
          style={{ width: 234 }}
          onClick={
            this.props.windowWidth >= 768
              ? this.handleClick
              : this.handleresClick
          }
          selectedKeys={[this.state.current]}
          mode="inline"
        >
          <Menu.Item key="/feeds">
            <Link to="feeds">
              <Icon type="home" />Home</Link>
          </Menu.Item>
          <Menu.Item key="/logs">
            <Link to="/logs">
              <Icon type="profile" />Diary</Link>
          </Menu.Item>
          <Menu.Item key="/profile">
            <Link to="/profile">
              <Icon type="user" />Profile</Link>
          </Menu.Item>
          <Menu.Item key="/network">
            <Link to="/network">
              <Icon type="environment" />Networking
      </Link>
          </Menu.Item>
          <Menu.Item key="/users">
            <Link to="/users">
              <Icon type="usergroup-add" />Add Friends</Link>
          </Menu.Item>
          {/* <Menu.Item key="/chat">
            <Link to="/chat">
              <Icon type="message" />Chat
          </Link>
          </Menu.Item> */}
        </Menu>
      </div>
    )
  }

  render() {

    const { notifications, fetching, arraylength } = this.state;

    const cusmenu = (
      <ul className="profiledropdown">
        <Link to="/profile">
          <li className="prodrop-header">
            {this.state.photoURL === "" ? (
              <Avatar
                style={{ display: "inline-block" }}
                size={35}
                src={Noprofileimg}
              ></Avatar>
            ) : (
              <Avatar
                style={{ display: "inline-block" }}
                size={35}
                src={this.state.photoURL}
              ></Avatar>
            )}
            <p
              style={{
                marginLeft: "10px",
                display: "inline-block",
                verticalAlign: "middle"
              }}
            >
              <b>{this.state.Full_Name}</b>
            </p>
          </li>
        </Link>
        <Link to="/editprofile">
          <li>
            <p>
              <Icon type="user" /> Edit Profile </p>
          </li>
        </Link>
        <Link to="/logout" onClick={() => this.props.logout()}>
          <li>
            <p> <Icon type="logout" /> Logout </p>
          </li>
        </Link>
      </ul >
    );

    const notify = (
      <Menu style={{ width: 280 }}>
        <div className="notifyhead">
          <p className="notifytitle">
            Notification
          {notifications.length === 0 ?
              (
                null
              ) : (
                <span
                  style={{ float: "Right", cursor: 'pointer' }}
                  onClick={() => this.removeNotification()}
                >
                  Clear All
                </span>
              )}
          </p>
        </div>
        <div>
          {this.state.noticount.length === 0 && arraylength === true ? (
            <div>
              <div className="row">
                <div className="col-md-12">
                  <NotificationShimmer count={5} />
                </div>
              </div>
            </div>
          ) : (
            <div>
              {this.state.noticount.length === 0 ? (
                <Empty />
              ) : (
                <div>
                  {this.rendernotifications()}
                </div>
              )}
            </div>
          )}
        </div>
        <Menu.Item>
          <Link to="/notifications">
            {notifications.length === 0 ?
              (
                null
              ) : (
                <div>
                  <span className="allnotify text-center">See All Notifications</span>
                </div>
              )}
          </Link>
        </Menu.Item>
      </Menu>
    );
    const onClose = e => {
      console.log(e, 'I was closed.');
    };
    return (
      <div>
        <Offline>
          <OfflineContent />
        </Offline>
        <div className="mobilemenuwidget">
          <Drawer
            title=""
            placement="right"
            closable={false}
            width={235}
            onClose={this.onClose}
            visible={this.state.visible}
          >
            {this.renderMobilemenu()}
          </Drawer>
        </div>
        <Header style={{ position: "fixed", zIndex: 100, width: "100%" }}>
          <div className="logo">
            <Link to="/">
              <img src={Logo} className="logo-pic" alt="logo" />
            </Link>
          </div>
          <div style={{ display: "inline-block" }} className="linelement">
            <div
              className="hidden-lg hidden-md hidden-sm"
              style={{
                display: "inline-block",
                position: "absolute",
                right: "15px",
                top: '10px'
              }}
            >
              <Button type="default"
                className="menubutton"
                onClick={() => this.showDrawer()}>
                <Icon
                  type="menu"
                  className="menutype" />
              </Button>
            </div>
          </div>
          <Select
            showSearch
            labelInValue
            // showArrow={false}
            suffixIcon={<Icon type="search" />}
            clearIcon={<Icon type="close-circle" theme="filled" />}
            value={this.state.value}
            placeholder="Search people..."
            notFoundContent={
              fetching ? (
                <Spin size="small" />
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              )
            }
            filterOption={false}
            onSearch={this.fetchUser}
            onChange={this.handleChange}
            className="searchwidth"
          >
            {this.rendersearchUser()}
          </Select>
          <Menu
            theme="dark"
            mode="horizontal"
            defaultSelectedKeys={["1"]}
            style={{ lineHeight: "64px", float: "Right" }}
            className="hidden-xs"
            onClick={this.handleClick}
          >
            <Menu.Item key="2">
              <Dropdown
                trigger={["click"]}
                overlay={this.state.noticount.length === 0 ?
                  (
                    <Link to="/notifications"></Link>
                  )
                  :
                  (notify)
                }
                placement="bottomRight"
              >
                <div className="navbadge">
                  {this.state.noticount.length === 0 ? (
                    <Link to="/notifications" onClick={e => e.preventDefault()}>
                      <Icon type="bell" />
                    </Link>
                  ) : (
                    <Badge
                      count={this.state.noticount.length}
                      style={{ backgroundColor: "#fff", color: "#999" }}
                    >
                      <Icon type="bell" />
                    </Badge>
                  )}
                </div>
              </Dropdown>
            </Menu.Item>
            <Menu.Item key="3">
              <div className="profilemenu">
                <span>
                  <Dropdown
                    overlay={cusmenu}
                    trigger={["click"]}
                    placement="bottomRight"
                  >
                    {this.state.photoURL === "" ? (
                      <Avatar src={Noprofileimg} size="small">
                        {/* {this.state.First_Name.charAt(0)} */}
                      </Avatar>
                    ) : (
                      <Avatar src={this.state.photoURL} size="small" />
                    )}
                    {/* <b>{this.state.First_Name}</b> */}
                  </Dropdown>
                </span>
              </div>
            </Menu.Item>
          </Menu>
        </Header>
      </div >
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
    allusers: state.allusers,
    getNotification: state.getNotification
  };
}

export default connect(mapStateToProps, {
  logout,
  createAccount,
  getNavNotifications,
  getNotifications,
  unreadnotify,
})(Navigation);
