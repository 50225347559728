import { set } from 'nprogress';
import React, { useState } from 'react';
import { connect } from "react-redux";


function Calculator() {
    const [count, setCount] = useState(0);
    const [text, setText] = useState('');
    const [checked, setChecked] = useState(false);

    const handleText = (e) => {
        setText(e.target.value);
        console.log(text)
    }

    const handleInputChange = (e) => {
        setChecked(e.target.value)
        console.log(e, checked)
    }

    const handleChange = (e) => {
        e.preventDefault();
        // console.log(e)

        const totalCount = count + 1;
        setCount(totalCount);
    }

    const handleDecrement = (e) => {
        e.preventDefault();
        // console.log(e)

        const totalCount = count - 1;
        setCount(totalCount);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(count, text)
        alert(`Your total Quantity is ${count} Product`)
    }

    return (
        <div className="container-fluid ">
            <div className='row' style={{ marginTop: '80px' }}>
                <div>
                    <form onSubmit={handleSubmit}>
                        <input
                            name="isGoing"
                            type="checkbox"
                            checked={checked}
                            onChange={handleInputChange} />
                        <input type="text" onChange={handleText} value={text}
                            placeholder='Name' />
                        <div>
                            <p>Click me if you want {count}</p>
                            {count === 20 ? (<button onClick={handleChange} disabled>Plus</button>) :
                                (<button onClick={handleChange}>Plus</button>)}

                            {count === 0 ? (<button onClick={handleDecrement} disabled>Minus</button>) : (
                                <button onClick={handleDecrement}>Minus</button>
                            )}
                        </div>
                        <div>
                            <button type="submit" value="Submit">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

function mapStateToProps(state, ownProps) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps, {
})(Calculator);