import React, { Component } from "react";
// import Navigation from '../common/header';
import Logo from '../assests/img/logo-new-dark.svg';
import {
    Collapse, Icon
} from 'antd';
import { Link } from "react-router-dom";
import { connect } from "react-redux";

const { Panel } = Collapse;

const customPanelStyle = {
    background: '#f7f7f7',
    borderRadius: 4,
    marginBottom: 24,
    border: 0,
    overflow: 'hidden',
};

const fontStyle = {
    fontSize: '16px'
}

class FAQ extends Component {
    constructor(props) {
        super(props);
        this.state = {
            new_year: new Date().getFullYear()
        }
    }

    render() {
        return (
            <div>
                <div className="container-fluid">
                    <nav className="navbar navbar-default">
                        <div className="container-fluid navcontainer">
                            <div className="navbar-header">
                                <div className="navbar-toggle">
                                    {/* <Signup /> */}
                                </div>
                                <Link className="navbar-brand" to="">
                                    <img
                                        src={Logo}
                                        className="logoimg img-responsive"
                                        alt="logo"
                                        style={{ height: '35px' }}
                                    />
                                </Link>
                            </div>
                            <div id="navbar" className="navbar-collapse collapse">
                                <ul className="nav navbar-nav navbar-right" style={{ marginTop: '30px' }}>
                                    {/* <li><Button className="btn loginBtn color-1"
                                            onClick={() => this.props.logout()} style={{ margin: '0' }}>
                                            Logout
                                          </Button>
                                        </li> */}
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12" style={{ marginTop: '50px' }}>
                            <h3 style={{ color: '#1C41CE', fontWeight: 'bold' }}
                            ><Link to="/"><Icon type="arrow-left" /></Link> Livathon - FAQ</h3>

                            <p className="privacyterms">Livathon was designed so you can drive conversations, and discover topics you care about.
                            Your homepage is your landing page you reach when you first log in to your Livathon account. It includes your feed, access
                            to your networks, profile, messages, and notifications. Your Livathon Feed contains posts from your network,
                            people you follow, and more.</p>
                            <p className="privacyterms">Check out some of our frequently asked questions about the Livathon Homepage</p>

                            <Collapse style={{ marginTop: '20px' }}
                                bordered={false}
                                // defaultActiveKey={['1']}
                                expandIcon={({ isActive }) => <Icon type="caret-right" rotate={isActive ? 90 : 0} />}
                            >
                                <Panel header="How do I customize my feed?" key="1" style={customPanelStyle}>
                                    <p style={fontStyle}>Your Livathon Feed can be customized, by following or unfollowing people, hiding
                                    content in your feed that you don't want to see, and reporting inappropriate or spam content.</p>
                                </Panel>
                                <Panel header="How do I search on Livathon?" key="2" style={customPanelStyle}>
                                    <p style={fontStyle}>The Livathon Search bar at the top of every page allows you to search people from there you can click the users
                                    to check out their post, like their posts and also you can follow by clicking on follow button. Once you follow
                                        the particular users their posts and logs will start displaying on your feed page</p>
                                </Panel>
                                <Panel header="How do I share an update or post?" key="3" style={customPanelStyle}>
                                    <p style={fontStyle}>Livathon doesn't allows you to update or share any of their post.</p>
                                </Panel>
                                <Panel header="How can I receive notifications?" key="4" style={customPanelStyle}>
                                    <p style={fontStyle}>You can receive notifications from your connections. These include alerts for your posts, like for posts and
                                        people who have started following you.</p>
                                </Panel>
                                <Panel header="How do I edit my Livathon Profile?" key="5" style={customPanelStyle}>
                                    <p style={fontStyle}>You can access your Livathon Profile from the navigation click on <i class="fas fa-user-circle"></i> icon at the top of your Livathon homepage.
                                    You can then select edit profile and you can manage your profile.</p>
                                </Panel>
                                <Panel header="How can I view users profile?" key="6" style={customPanelStyle}>
                                    <p style={fontStyle}>You can directly click users name from feed, people or your following & followers users. If your
                                    not following any users which you checked their logs won't be viewed until they follow back your request</p>
                                </Panel>
                            </Collapse>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-xs-12">
                            <p className="text-center"
                                style={{ color: '#333' }}>@{this.state.new_year}, Livathon, All rights reserved</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

function mapStateToProps(state, ownProps) {
    return { user: state.user };
}


export default connect(
    mapStateToProps,
    {}
)(FAQ);