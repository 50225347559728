import React, { Component } from "react";
// import Navigation from '../common/header';
import Logo from '../assests/img/logo-new.svg';
import {
    resetPassword
} from "../actions/userAction";
import { Card, Form, Icon, Input, Button, message } from 'antd';
import { Link } from "react-router-dom";
import { connect } from "react-redux";


class Forgetpassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            loading: false,
            iconLoading: false
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    enterLoading = () => {
        this.setState({ loading: true });
    };

    componentDidMount() {
        if (this.props.user !== null) {
            this.props.history.push('/login');
        }
    }
    componentDidUpdate(nextProps) {
        if (nextProps.user !== null) {
            nextProps.history.push('/login');
        }
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleSubmit = e => {
        this.setState({
            iconLoading: true
        });

        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
            }
        })
        e.preventDefault();
        // console.log(this.state.email);
        this.props
            .resetPassword(this.state.email)
            .then(succ => {
                message.success("Password reset mail has been sent!");
                this.setState({
                    iconLoading: false,
                });
                this.props.form.resetFields();
                this.props.history.push("/login");
            })
            .catch(err => {
                message.error("Your Email is incorrect! Try again.");
                this.setState({
                    iconLoading: false,
                });
                this.props.form.resetFields();
            });
    }


    render() {
        const { getFieldDecorator } = this.props.form;

        return (
            <div>
                <div className="container-fluid loginscreen">
                    <div className="container">
                        <Link to="/">
                            <img src={Logo} class="logo" alt="logo" style={{ marginTop: '15px' }} /></Link>
                        <div className="row">
                            <div className="col-md-12" style={{ marginTop: '100px' }}>
                                <div className="col-md-8" style={{ marginTop: '50px' }}>
                                    <h2>Make Cool Friends !!!</h2>
                                    <p>Living is a digital diary network that can be used to save your memories. <br />
                                        Living also offers News Feed, Image/Video
                                    Feed.</p>
                                    <p>Why are you waiting for? Explore it now.</p>
                                </div>
                                <div className="col-md-4" style={{ marginBottom: '30px;' }}>
                                    <Card bordered={false} style={{ width: '100%' }}>
                                        <div style={{ marginBottom: '30px' }}>
                                            <h3 className="loginHead">Forget Password</h3>
                                            <div class="headline"></div>
                                        </div>
                                        <Form onSubmit={this.handleSubmit} className="login-form">
                                            <Form.Item>
                                                <label>Email:</label>
                                                {getFieldDecorator('emai;', {
                                                    rules: [
                                                        {
                                                            type: 'email',
                                                            message: 'The input is not valid E-mail!',
                                                        },
                                                        {
                                                            required: true,
                                                            message: 'Please input your E-mail!',
                                                        },
                                                    ],
                                                })(
                                                    <Input
                                                        prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                                        placeholder="Email" name="email"
                                                        onChange={this.handleChange}
                                                        value={this.state.email} id="email"
                                                    />,
                                                )}
                                            </Form.Item>

                                            <Form.Item>
                                                <Button htmlType="submit"
                                                    className="btn loginBtn color-1" loading={this.state.iconLoading}
                                                    onClick={this.enterLoading} style={{ margin: '0' }}>
                                                    Reset Password
                                            </Button>
                                            </Form.Item>
                                            <div class="row">
                                                <div class="col-md-12" style={{ marginBottom: '20px' }}>
                                                    <span class="login-form-forgot" style={{ display: 'inline-block', fontWeight: '600' }}>
                                                        Remember your account?</span>
                                                    <Link className="registertext" to="/login"
                                                        style={{ float: "Right" }}>
                                                        Login
                                                    </Link>
                                                </div>
                                            </div>
                                        </Form>
                                    </Card>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

        )

    }

}

const WrappedForgetpassword = Form.create({ name: 'forget_login' })(Forgetpassword);
function mapStateToProps(state, ownProps) {
    return { user: state.user };
}


export default connect(
    mapStateToProps,
    { resetPassword }
)(WrappedForgetpassword);