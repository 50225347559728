import React, { Component } from "react";
import {
    Button,
    Avatar,
    Form,
    Icon,
    Input,
    Upload,
    message,
    Modal,
    Card
} from "antd";
import { connect } from "react-redux";
import {
    postFeedback,
} from "../actions/livingAction";
import { Link } from "react-router-dom";
import { db } from "../firebase";

const { TextArea } = Input;

class Feedback extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            fileList1: [],
            caption: "",
            First_Name: "",
            photoURL: "",
            fullName: "",
            visible: false,
        };
        // this.onChange = this.onChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    handleOk = e => {
        this.setState({
            visible: false,
        });
    };

    handleCancel = e => {
        this.setState({
            visible: false,
        });
    }

    componentDidMount() {
        var uid = this.props.user.uid;
        var userRef = db.collection("User_Details").doc(uid);
        userRef
            .get()
            .then(doc => {
                if (doc.exists) {
                    // console.log(doc.data().First_Name);
                    this.setState({
                        photoURL: doc.data().photoURL,
                        First_Name: doc.data().First_Name,
                        fullName: doc.data().Username
                    });
                } else {
                    // console.log("No such document!");
                }
            })
            .catch(err => {
                // console.log("Error getting document", err);
            });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({
                    loading: true,
                });
                this.props
                    .postFeedback(values, this.props.user.uid, this.state.fileList1, this.state.fullName, this.state.photoURL)
                    .then((res) => {
                        // console.log("res", res);
                        message.success("Thanks for you feedback");
                        // window.location.reload(false);
                        this.props.form.resetFields();
                        this.setState({
                            visible: false,
                            loading: false,
                            fileList1: [],
                        });
                    })
                // .catch(function (error) {
                //   // message.error("error occurred while sending data");
                // });
            }
        });
    };

    onChangee = (newFileList) => {
        // newFileList.preventDefault()
        // console.log(newFileList);
        // this.setState({
        //   fileList: newFileList,
        //   images: newFileList,
        // });
    };

    normFile = (e) => {
        console.log("Upload event:", e);
        if (Array.isArray(e)) {
            return e;
        }
        console.log(this.state.fileList1);
        return e && e.fileList;
    };

    renderFeedback() {
        const { fileList1, First_Name } = this.state;

        const propsthumb = {
            multiple: true,
            listType: "picture-card",
            accept: ".png,.jpg",
            showUploadList: { showPreviewIcon: false, showRemoveIcon: true },
            onRemove: (file) => {
                this.setState((state) => {
                    const index = state.fileList1.indexOf(file);
                    const newFileList = state.fileList1.slice();
                    newFileList.splice(index, 1);
                    return {
                        fileList1: newFileList,
                    };
                });
                console.log(this.state.fileList1);
            },
            beforeUpload: (file) => {
                this.setState((state) => ({
                    fileList1: [...state.fileList1, file],
                }));
                return false;
            },
            fileList1,
        };

        const { getFieldDecorator } = this.props.form;
        return (
            <div>
                <Form onSubmit={this.handleSubmit} className="login-form">
                    <div>
                        <Form.Item label="">
                            {getFieldDecorator("caption", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Please input your feedback!",
                                    },
                                    {
                                        max: 200,
                                        message: "Post character should not exceed more than 200",
                                    },
                                ],
                            })(
                                <TextArea
                                    placeholder="Post your feedback to us..."
                                    onChange={this.handleChange}
                                    rows="2"
                                    resize
                                />
                            )}
                        </Form.Item>
                    </div>
                    <Form.Item label="Add Screenshots (Optional)">
                        {getFieldDecorator("UploadImage", {
                            getValueFromEvent: this.normFile,
                        })(
                            <div className="clearfix">
                                {/* <ImgCrop grid aspect={16 / 9}> */}
                                <Upload name="logImage" {...propsthumb}>
                                    <div>
                                        <Icon type="plus" />
                                    </div>
                                </Upload>
                                {/* </ImgCrop> */}
                            </div>
                        )}
                    </Form.Item>
                    <Form.Item style={{ textAlign: 'right' }}>
                        <Button
                            htmlType="submit"
                            type="primary"
                            loading={this.state.loading}
                            style={{ margin: "0" }}
                        >
                            Submit
              </Button>
                    </Form.Item>
                </Form>
            </div>
        )
    }

    render() {
        return (
            <div>
                <Card>
                    <h5 style={{
                        color: '#696969',
                        lineHeight: '22px'
                    }}>Livathon always welcomes <a style={{ marginRight: '3px' }}
                        onClick={() => this.showModal()}>feedbacks</a>
                     from our user's. This helps us to improve our community.</h5>
                    <div className="sticky" style={{ marginTop: '20px' }}>
                        <div className="text-center">
                            <p style={{ display: 'inline-block', marginRight: '30px' }}>
                                <Link to="/privacy-policy">Privay Policy</Link></p>
                            <p style={{ display: 'inline-block', marginRight: '30px' }}>
                                <Link to="/terms-and-conditions">Terms and Conditions</Link></p>
                        </div>
                        <div className="text-center">
                            <p style={{ display: 'inline-block', marginRight: '30px' }}>
                                <Link to="/faq">FAQ</Link></p>
                            <p style={{ display: 'inline-block', marginRight: '30px' }}>
                                <Link to="/helpCenter" target="_blank">Help Center</Link></p>
                        </div>
                    </div>
                </Card>
                <Modal
                    title="Feedback Form"
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    destroyOnClose={true}
                    onCancel={this.handleCancel}
                    footer={null}
                    centered={true}
                >
                    {this.renderFeedback()}
                </Modal>
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return { user: state.user };
}

const WrappedpostFeedback = Form.create({ name: "feedback" })(Feedback);
export default connect(mapStateToProps, {
    postFeedback
})(WrappedpostFeedback);
