import React, { Component } from "react";
import { getallUserList, cityList, getProfile } from "../actions/userAction";
import {
  getFollowers,
  sendChat,
  getChat,
  getChathistory,
  updatereadChat,
} from "../actions/livingAction";
import { Card, Avatar, Icon, Badge, Empty, Input, Radio } from "antd";
import { connect } from "react-redux";
import _ from "lodash";
import moment from "moment";
import MenuWidget from "./menuWidget";
import { Scrollbars } from "react-custom-scrollbars";
import Search from "antd/lib/input/Search";
import messageempty from "../assests/img/message-empty.svg";

class Chat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      citylist: [],
      alluser: [],
      // followers: [],
      getfollowers: "",
      activeUser: "",
      activeChatname: "Javeed",
      activeChatimg: "",
      activeChatbio: "",
      recent: true,
      msgtext: "",
      discussion: [],
      seen: ""
    };
    this.msgchange = this.msgchange.bind(this);
  }

  componentWillMount() {
    var uid = this.props.user.uid;
    // console.log(uid);

    var user = this.props.location.state.user;

    this.setState({
      activeUser: user
    })

    if (user === undefined) {
      this.props.getProfile(uid).then((res) => {
        console.log(res, uid);
        this.setState({
          activeChatimg: res.photoURL[0].url,
          activeChatname: res.First_Name,
          activeChatbio: res.Biography
        })
        // console.log(this.state.activeChatbio)
      });
    }
    else {
      this.props.getProfile(user).then((res) => {
        console.log(res, uid);
        this.setState({
          activeChatimg: res.photoURL[0].url,
          activeChatname: res.First_Name,
          activeChatbio: res.Biography
        })
        // console.log(this.state.activeChatbio)
      });

      this.props.getChathistory(uid).then((res) => {
        // console.log(res);
      });
    }

    if (user === undefined) {
      this.props.history.push("/")
    }

    this.props.getFollowers(uid).then((res) => {
      console.log(res);
      this.setState({
        getfollowers: res,
      });
    });

  }

  componentDidUpdate(prevProps, prevState) {
    // only update chart if the data has changed
    if (prevProps.chatmsgs !== this.props.chatmsgs) {
      const { scrollbar } = this.refs;

      console.log(this.props.chatmsgs);
      if (this.props.chatmsgs !== "") {
        var chatmsgs = this.props.chatmsgs;
        // Object.values(this.props.chatmsgs)[0]
        console.log(this.props.chatmsgs, chatmsgs[0]);
        if (
          chatmsgs[0].from === this.state.activeUser ||
          chatmsgs[0].to === this.state.activeUser
        ) {
          var chunk = _.groupBy(this.props.chatmsgs, "date");
          console.log(chunk);
          this.setState({
            discussion: chunk,
          });
          setTimeout(
            function () {
              scrollbar.scrollToBottom();
              this.readmsgs(this.props.chatmsgs);
            }.bind(this),
            100
          );
        }
      }
    } else {
      // console.log(this.state.datas);
    }
  }

  readmsgs(msgs) {
    // console.log(msgs);
    var unreadmsgs = _.filter(msgs, {
      read: false,
      from: this.state.activeUser,
    });
    // console.log(unreadmsgs);
    if (unreadmsgs.length !== 0) {
      var fromUser = this.props.user.uid;
      var toUser = this.state.activeUser;
      this.props.updatereadChat(fromUser, toUser, unreadmsgs).then((res) => {
        console.log(res);
      });
    }
  }

  msgchange = (v) => {
    // console.log(v.target.value);
    this.setState({
      msgtext: v.target.value,
    });
  };

  sendChat(text) {
    var fromUser = this.props.user.uid;
    var toUser = this.state.activeUser;
    var date = moment(new Date()).format("DD MMMM YYYY");
    var time = moment(new Date()).format("hh:mm a");
    if (text !== "") {
      this.setState({
        msgtext: "",
      });
      this.props.sendChat(fromUser, toUser, text, date, time).then((res) => {
        console.log(res);
      });
    }
  }

  onChange = (e) => {
    // console.log(e.target.value);
    var input, filter, ul, li, h4, i, ull, lii, j;
    input = e.target.value;
    filter = input.toUpperCase();
    ul = document.getElementById("userUL");
    li = ul.getElementsByTagName("li");

    for (i = 0; i < li.length; i++) {
      h4 = li[i].getElementsByTagName("h5")[0];
      if (h4.innerHTML.toUpperCase().indexOf(filter) > -1) {
        li[i].style.display = "";
      } else {
        li[i].style.display = "none";
      }
    }
    ull = document.getElementById("userULmob");
    lii = ull.getElementsByTagName("li");
    for (j = 0; j < lii.length; j++) {
      h4 = lii[j].getElementsByTagName("h5")[0];
      if (h4.innerHTML.toUpperCase().indexOf(filter) > -1) {
        lii[j].style.display = "";
      } else {
        lii[j].style.display = "none";
      }
    }
  };

  changeuser = (toUser) => {
    console.log(toUser);
    var fromUser = this.props.user.uid;
    var prevUser = this.state.activeUser;
    if (prevUser !== toUser.Id) {
      this.setState({
        activeUser: toUser.Id,
        activeChatname:
          toUser.First_Name === undefined ? toUser.username : toUser.First_Name,
        activeChatimg: toUser.photoURL,
        activeChatbio: toUser.bio,
        discussion: [],
        seen: toUser.Updated_At
      });
      this.props.getChat(fromUser, toUser.Id, prevUser).then((res) => {
        // this.setState({
        //   discussion: res,
        // });
      });
    }
  };

  renderrecentUsers() {
    // console.log(this.props.chathistory.unreadcount)
    return _.map(this.props.chathistory, (user, key) => {

      return (
        <li
          className={this.state.activeUser === user.Id ? "active" : null}
          key={key}
          onClick={() => this.changeuser(user)}
        >
          <div className="chatuser">
            <div className="row hidden-xs">
              <div
                className="col-sm-3 col-xs-3 text-center"
                style={{ padding: "0px" }}
              >
                {user.photoURL === "" ? (
                  <Avatar
                    style={{ color: "#f56a00", backgroundColor: "#fde3cf" }}
                  >
                    {user.username.charAt(0)}
                  </Avatar>
                ) : (
                  <Avatar src={user.photoURL} />
                )}
              </div>
              <div className="col-sm-9 col-xs-9 nopaddl">
                <h5 className="chatusername">{user.username}</h5>
                <p className="chatusermsg">{user.lastmsg}</p>
                <Badge
                  className="unreadcount"
                  count={user.unreadcount}
                  style={{ backgroundColor: "#52c41a" }}
                />
              </div>
            </div>
          </div>
        </li>
      );
    });
  }

  renderUsers() {
    return _.map(this.state.getfollowers, (user, key) => {
      return (
        <li
          // className={this.state.activeUser === user.Id ? "active" : null}
          key={key}
          onClick={() => this.changeuser(user)}
        >
          <div className="chatuser">
            <div className="row hidden-xs">
              <div
                className="col-sm-3 col-xs-3 text-center"
                style={{ padding: "0px" }}
              >
                {user.photoURL === "" ? (
                  <Avatar
                    style={{ color: "#f56a00", backgroundColor: "#fde3cf" }}
                  >
                    {user.First_Name.charAt(0)}
                  </Avatar>
                ) : (
                  <Avatar src={user.photoURL} />
                )}
              </div>
              <div className="col-sm-9 col-xs-9 nopaddl">
                <h5 className="chatusername">
                  {user.First_Name} {user.Last_Name}
                </h5>
                <p className="chatusermsg">{user.Biography}</p>
              </div>
            </div>
          </div>
        </li>
      );
    });
  }


  renderchatmessages(curuser) {
    return _.map(this.state.discussion, (m, key) => {
      return (
        <div key={key}>
          <li className="text-center" style={{ justifyContent: "center" }}>
            <p className="chatdate">
              {/* {moment(m[0].date,"DD MMMM YYYY").fromNow()} */}
              {m[0].date}
            </p>
          </li>
          {_.map(m, (message, key) => {
            return (
              <div key={key}>
                {curuser === message.from ? (
                  <li className="rightlist">
                    {/* <Popover content={content(message.Id)} placement="leftTop"> */}
                    <div className="rightchat">
                      <h5 className="msgtext">{message.text}</h5>
                      {/* {message.type === "text" ? (
                  <h5 className="msgtext">{message.text}</h5>
                ) : (
                  <div>
                    {message.type.split(".").pop() === "png" ? (
                      <div>
                        <img
                          className="chatimg img-responsive"
                          src={message.text}
                          alt="Chat"
                        />
                        <a
                          className="downloadlink"
                          target="_blank"
                          rel="noopener noreferrer"
                          href={message.text}
                        >
                          <Icon type="download" />
                        </a>
                      </div>
                    ) : (
                      <div>
                        {message.type.split(".").pop() === "jpeg" ? (
                          <div>
                            <img
                              className="chatimg img-responsive"
                              src={message.text}
                              alt="Chat"
                            />
                            <a
                              className="downloadlink"
                              target="_blank"
                              rel="noopener noreferrer"
                              href={message.text}
                            >
                              <Icon type="download" />
                            </a>
                          </div>
                        ) : (
                          <div>
                            <h5 className="msgtext">{message.type}</h5>
                            <a
                              className="downloadlink"
                              target="_blank"
                              rel="noopener noreferrer"
                              href={message.text}
                            >
                              <Icon type="download" />
                            </a>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )} */}
                      <h6 className="senttime">{message.time}</h6>
                      {/* <Icon
                  className="deleteIcon"
                  type="delete"
                  size="small"
                  onClick={() => this.deleteSingleChat(message.id)}
                /> */}
                    </div>
                    {/* </Popover> */}
                  </li>
                ) : (
                  <li className="leftlist">
                    <div
                      className={!message.read ? "leftchat unread" : "leftchat"}
                    >
                      <h5 className="msgtext">{message.text}</h5>
                      {/* {message.type === "text" ? (
                  <h5 className="msgtext">{message.text}</h5>
                ) : (
                  <div>
                    {message.type.split(".").pop() === "jpeg" ? (
                      <div>
                        <img
                          className="chatimg img-responsive"
                          src={message.text}
                          alt="Chat"
                        />
                        <a
                          className="downloadlink"
                          target="_blank"
                          rel="noopener noreferrer"
                          href={message.text}
                        >
                          <Icon type="download" />
                        </a>
                      </div>
                    ) : (
                      <div>
                        {message.type.split(".").pop() === "png" ? (
                          <div>
                            <img
                              className="chatimg img-responsive"
                              src={message.text}
                              alt="Chat"
                            />
                            <a
                              className="downloadlink"
                              target="_blank"
                              rel="noopener noreferrer"
                              href={message.text}
                            >
                              <Icon type="download" />
                            </a>
                          </div>
                        ) : (
                          <div>
                            {message.type.split(".").pop() === "jpg" ? (
                              <div>
                                <img
                                  className="chatimg img-responsive"
                                  src={message.text}
                                  alt="Chat"
                                />
                                <a
                                  className="downloadlink"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={message.text}
                                >
                                  <Icon type="download" />
                                </a>
                              </div>
                            ) : (
                              <div>
                                <h5 className="msgtext filetype">
                                  <Icon type="file" /> {message.type}
                                </h5>
                                <a
                                  className="downloadlink"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={message.text}
                                >
                                  <Icon type="download" />
                                </a>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )} */}
                      <h6 className="senttime">{message.time}</h6>
                    </div>
                  </li>
                )}
              </div>
            );
          })}
        </div>
      );
    });
  }

  render() {
    // console.log(this.props.chatmsgs);
    return (
      <div>
        <div className="container-fluid ">
          <div className="row">
            <div className="col-md-2 col-xs-12 sticky hidden-xs">
              <MenuWidget />
            </div>
            <div className="col-md-10 col-xs-12">
              <Card className="chatwrapper" style={{ marginTop: "80px" }}>
                <div className="container-fluid text-left chatdiv">
                  <div className="row">
                    <div className="col-sm-3 chatrightbor chatuserlist">
                      <div className="searchuser">
                        <Input
                          placeholder="Search"
                          allowClear
                          onChange={this.onChange}
                        />
                      </div>
                      <div className="visible-xs">
                        <div className="userlist">
                          <ul id="userULmob">{this.renderUsers()}</ul>
                        </div>
                      </div>
                      <div className="text-center">
                        <Radio.Group
                          defaultValue={this.state.recent ? "Recent" : "All"}
                          buttonStyle="solid"
                          onChange={() =>
                            this.setState({ recent: !this.state.recent })
                          }
                        >
                          <Radio.Button value="Recent">Recent</Radio.Button>
                          <Radio.Button value="All">All</Radio.Button>
                        </Radio.Group>
                      </div>
                      <Scrollbars
                        className="hidden-xs"
                        style={{ width: "100%", height: "68.5vh" }}
                        autoHide
                      >
                        <div className="userlist">
                          {this.state.recent ? (
                            <ul id="userUL">
                              <p className="recentchats">Recents Chats</p>
                              {this.renderrecentUsers()}
                            </ul>
                          ) : (
                            <ul id="userUL">
                              <p className="allchats">All Chats</p>
                              {this.renderUsers()}
                            </ul>
                          )}
                        </div>
                      </Scrollbars>
                    </div>
                    <div className="col-sm-6 chatrightbor">
                      {this.state.activeUser !== "" ? (
                        <div>
                          <div className="userinfo ">
                            <div className="row">
                              <div className="userde col-xs-8 text-left">
                                {this.state.activeChatimg === "" ? (
                                  <Avatar
                                    style={{
                                      color: "#f56a00",
                                      backgroundColor: "#fde3cf",
                                    }}
                                  >
                                    {this.state.activeChatname.charAt(0)}
                                  </Avatar>
                                ) : (
                                  <Avatar src={this.state.activeChatimg} />
                                )}

                                <p className="username">
                                  {this.state.activeChatname} <br />
                                  <span>active chat </span>
                                </p>
                              </div>

                              {/* <div className="userchataction col-xs-4">
                                <Icon type="setting" />
                              </div> */}
                            </div>
                          </div>
                          <Scrollbars
                            style={{ width: "100%", height: "61vh" }}
                            autoHide
                            ref="scrollbar"
                          >
                            <div className="msgdiv">
                              <div className="startchat">
                                <p>
                                  This is the beginning of your chat with{" "}
                                  {this.state.activeChatname}
                                </p>
                              </div>
                              <ul className="messages" id="msgUL">
                                {this.state.discussion.length === 0 ? (
                                  <Empty />
                                ) : (
                                  <div>
                                    {this.renderchatmessages(
                                      this.props.user.uid
                                    )}
                                  </div>
                                )}
                              </ul>
                            </div>
                          </Scrollbars>
                          <div className="msginput">
                            <Search
                              onChange={this.msgchange}
                              value={this.state.msgtext}
                              placeholder="Type your message.."
                              onSearch={(value) => this.sendChat(value)}
                              enterButton="Send"
                            />
                          </div>
                        </div>
                      ) : (
                        <div
                          className="text-center"
                          style={{
                            width: "100%",
                            height: "84vh",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div>
                            <img
                              src={messageempty}
                              className="messageempty"
                              alt="messageempty"
                              style={{ marginBottom: "30px" }}
                            />
                            <p>Select User to start chat</p>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="col-sm-3 padd0 hidden-xs">
                      <div className="chatprofile padd15 text-center">
                        {this.state.activeUser !== "" ? (
                          <div>
                            <h5>Profile</h5>
                            {this.state.activeChatimg === "" ? (
                              <Avatar
                                style={{
                                  color: "#f56a00",
                                  backgroundColor: "#fde3cf",
                                }}
                                size={64}
                              >
                                {this.state.activeChatname.charAt(0)}
                              </Avatar>
                            ) : (
                              <Avatar
                                size={64}
                                src={this.state.activeChatimg}
                              />
                            )}

                            <p className="activeusername">
                              <b>{this.state.activeChatname}</b>
                            </p>
                            <p className="activeusername">
                              {this.state.activeChatbio}</p>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
    allusers: state.allusers,
    getfollowers: state.getfollowers,
    chatmsgs: state.chatmsgs,
    chathistory: state.chathistory,
  };
}

export default connect(mapStateToProps, {
  getallUserList,
  cityList,
  getProfile,
  getFollowers,
  sendChat,
  getChat,
  getChathistory,
  updatereadChat,
})(Chat);
