import React, { Component } from "react";
import "../assests/css/shimmer.css";
import _ from "lodash";

class WidgetShimmer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderShimmer(arr) {
    return _.map(arr, (arr, index) => {
      return (
        <div className="col-md-6" key={index}>
          <div className="usershimmerwrapper" >
            <div className="profile shine"
              style={{ margin: 'auto', marginLeft: '15px' }}>
            </div>
            <div className="photo">
              <span className="shine"></span>
              <span className="shine"></span>
              <span className="shine"></span>
            </div>
          </div>
        </div>
      );
    });
  }

  render() {
    // console.log(this.props.count);
    var arr = new Array(this.props.count).fill("Shimmer");
    // console.log(arr);
    return <div>
      {this.renderShimmer(arr)}
    </div>;
  }
}

export default WidgetShimmer;
