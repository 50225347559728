import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Menu, Icon } from 'antd';
import { createBrowserHistory } from 'history'
import {
    getChathistory, 
    // getPeopleCount
} from "../actions/livingAction";
// import _ from "lodash";
// import home from '../assests/img/home1.svg'
// import list from '../assests/img/logs1.svg'
// import identity from '../assests/img/profile1.svg'
// import location from '../assests/img/map1.svg'
// import user from '../assests/img/friends.svg'

const { SubMenu } = Menu;

class MenuWidget extends Component {
    constructor(props) {
        super(props);
        this.state = {
            current: "/feeds",
            chatread: "",
        };
    }
    setCurrent(key) {
        this.setState({
            current: key
        });
    }

    componentDidMount() {
        var uid = this.props.user.uid

        const history = createBrowserHistory();
        // Get the current location.
        const location = history.location;
        // console.log(location.pathname);
        this.setState({ current: location.pathname });

      
    }

    handleClick = e => {
        // console.log('click ', e);
        this.setState({
            current: e.key
        });
    };

    handleresClick = e => {
        // console.log('click ', e);
        this.setState({
            current: e.key,
            collapsed: true
        });
    };


    render() {
        const { current } = this.state
        // console.log(this.state.chatread);
        return (
            <div>
                {/* <div className="listmenu">
                    <ul>
                        <li><Link to="/feeds">
                            <img src={home} alt="home" />Home
                                </Link></li>
                        <li><Link to="/logs">
                            <img src={list} alt="home" />Diary</Link></li>
                        <li> <Link to="/profile">
                            <img src={identity} alt="home" />Profile</Link></li>
                        <li> <Link to="/network">
                            <img src={location} alt="home" />Networking</Link></li>
                        <li> <Link to="/users">
                            <img src={user} alt="home" />Add Friends</Link></li>
                    </ul>
                </div> */}

                {/* <Menu
                    onClick={this.handleClick}
                    style={{ width: 210 }}
                    onClick={
                        this.props.windowWidth >= 768
                            ? this.handleClick
                            : this.handleresClick
                    }
                    selectedKeys={[this.state.current]}
                    mode="inline"
                >
                    <Menu.Item key="/feeds">
                        <Link to="/feeds">
                            <Icon type="home" />Home</Link>
                    </Menu.Item>
                    <Menu.Item key="/logs">
                        <Link to="/logs">
                            <Icon type="profile" />Diary</Link>
                    </Menu.Item>
                    {loguser === this.props.user.uid ? (
                        <Menu.Item key="/profile">
                            <Link to="/profile">
                                <Icon type="user" />Profile</Link>
                        </Menu.Item>
                    ) : (
                            <Menu.Item key="/profile:id">
                                <Link to="/profile:id">
                                    <Icon type="user" />Profile</Link>
                            </Menu.Item>
                        )}
                    <Menu.Item key="/network">
                        <Link to="/network">
                            <Icon type="environment" />Networking
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="/users">
                        <Link to="/users">
                            <Icon type="usergroup-add" />Add Friends</Link>
                    </Menu.Item>
                    <Menu.Item key="/chat">
                        <Link to="/chat">
                            <Icon type="message" />Chat
                        </Link>
                    </Menu.Item> 
                </Menu> */}

                <div className="liv-menu">
                    <ul>
                        <Link to="/feeds">
                            <li className={current === "/feeds" ? "active" : ""} onClick={() => this.setCurrent("/feeds")}>
                                <p>
                                    <Icon type="home" />
                                    Home
                                </p>
                            </li>
                        </Link>
                        <Link to="/logs">
                            <li className={current === "/logs" ? "active" : ""} onClick={() => this.setCurrent("/logs")}>
                                <p>
                                    <Icon type="profile" />
                                    Diary
                                </p>
                            </li>
                        </Link>
                        <Link to="/profile">
                            <li className={current === "/profile" ? "active" : ""} onClick={() => this.setCurrent("/profile")}>
                                <p>
                                    <Icon type="user" />
                                    Profile
                                </p>
                            </li>
                        </Link>
                        <Link to="/network">
                            <li className={current === "/network" ? "active" : ""} onClick={() => this.setCurrent("/network")}>
                                <p>
                                    <Icon type="environment" />
                                    Networking
                                </p>
                            </li>
                        </Link>
                        <Link to="/users">
                            <li className={current === "/users" ? "active" : ""} onClick={() => this.setCurrent("/users")}>
                                <p>
                                    <Icon type="usergroup-add" />
                                    Add Friends
                                </p>
                            </li>
                        </Link>
                    </ul>
                </div>
            </div >
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        user: state.user,
        chathistory: state.chathistory,
    };
}


export default connect(mapStateToProps, {
    getChathistory,
    // getPeopleCount
})(MenuWidget);


