import React, { Component } from "react";
import Logo from '../assests/img/logo-new-dark.svg';
// import Navigation from '../common/header';
import {
    signup,
} from "../actions/userAction";
import {
    Form, Icon, Input, Button, message,
    Checkbox
} from 'antd';
import { Link } from "react-router-dom";
import { connect } from "react-redux";



class Signup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            iconLoading: false,
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    enterLoading = () => {
        this.setState({ iconLoading: true });
    };

    enterIconLoading = () => {
        this.setState({ iconLoading: true });
    };


    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }


    handleSubmit = e => {
        this.setState({
            iconLoading: true
        });
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                // console.log('Received values of form: ', values);
                this.props
                    .signup(values.email, values.password)
                    .then(succ => {
                        console.log(succ)
                        message.success("You registered successfully");
                        this.setState({
                            iconLoading: false
                        });
                        this.props.form.resetFields();
                        this.props.history.push("/registerAccount");
                    })
                    .catch(err => {
                        // console.log(err)
                        this.setState({
                            iconLoading: false,
                            loading: false
                        })
                    });

            } else {
                message.error("Please fill all required details carefully")
                this.setState({
                    iconLoading: false
                });

            }
        });

    };


    handleConfirmBlur = e => {
        const { value } = e.target;
        this.setState({ confirmDirty: this.state.confirmDirty || !!value });
    };

    compareToFirstPassword = async (rule, value, callback) => {
        const { form } = this.props;
        if (value && value !== form.getFieldValue('password')) {
            callback('Two passwords that you enter is inconsistent!');
        } else {
            callback();
        }
    };

    validateToNextPassword = async (rule, value, callback) => {
        const { form } = this.props;
        if (value && this.state.confirmDirty) {
            form.validateFields(['confirm'], { force: true });
        }
        callback();
    };


    componentDidMount() {
        if (this.props.user !== null) {
            this.props.history.push('/registerAccount');
        }
    }

    componentDidUpdate(nextProps) {
        if (nextProps.user !== null) {
            nextProps.history.push('/registerAccount');
        }
    }


    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <div>
                <div >
                    <div className="container-fluid logincard">
                        <div className="row">
                            <div className="col-md-7 divbg1 hidden-xs">
                                <div className="centerdiv">
                                    <h2>Join our community</h2>
                                    {/*  */}
                                    <p>Livathon is a digital diary network that can be used to
                                        save your memories.</p>
                                </div>
                            </div>
                            <div className="col-md-5 bgright"
                                style={{ marginBottom: '30px' }}>
                                <div className="text-center">
                                    <Link to="/">
                                        <img src={Logo}
                                            className="img-responsive logoimg"
                                            alt="logo"
                                            style={{ height: '40px' }}
                                        /></Link>
                                </div>
                                <div style={{ padding: '40px', marginTop: '40px' }}>
                                    <div style={{ marginBottom: '30px' }}>
                                        <h3 className="loginHead">Signup</h3>
                                        <div className="headline"></div>
                                    </div>
                                    <Form onSubmit={this.handleSubmit} className="login-form">
                                        {/* <Form.Item>
                                            <label>username:</label>
                                            {getFieldDecorator('username', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Please input your username!',
                                                    },
                                                ],
                                            })(<Input prefix={<Icon type="user"
                                                style={{ color: 'rgba(0,0,0,.25)' }} />}
                                                placeholder="Username"
                                                name="username"
                                                onChange={this.handleChange}
                                                id="username" /> ,
                                            )}
                                        </Form.Item> */}
                                        <Form.Item>
                                            <label>Email:</label>
                                            {getFieldDecorator('email', {
                                                rules: [
                                                    {
                                                        type: 'email',
                                                        message: 'The input is not valid E-mail!',
                                                    },
                                                    {
                                                        required: true,
                                                        message: 'Please input your E-mail!',
                                                    },
                                                ],
                                            })(<Input prefix={<Icon type="mail"
                                                style={{ color: 'rgba(0,0,0,.25)' }} />}
                                                placeholder="Email"
                                                name="email"
                                                onChange={this.handleChange}
                                                id="email" /> ,
                                            )}
                                        </Form.Item>
                                        <Form.Item hasFeedback>
                                            <label>Password:</label>
                                            {getFieldDecorator('password', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Please input your Password!'
                                                    },
                                                    {
                                                        min: 6,
                                                        message: 'Password must be minimum 6 characters',
                                                    },
                                                    {
                                                        validator: this.validateToNextPassword,
                                                    }
                                                ],
                                            })(
                                                <Input.Password
                                                    prefix={<Icon type="lock"
                                                        style={{ color: 'rgba(0,0,0,.25)' }} />}
                                                    type="password"
                                                    placeholder="Password"
                                                    name="password"
                                                    onChange={this.handleChange}

                                                />,
                                            )}
                                        </Form.Item>
                                        <Form.Item hasFeedback>
                                            <label>Confirm Password:</label>
                                            {getFieldDecorator('confirm', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Please confirm your password!'
                                                    },
                                                    {
                                                        validator: this.compareToFirstPassword,
                                                    },
                                                ],
                                            })(
                                                <Input.Password
                                                    prefix={<Icon type="lock"
                                                        style={{ color: 'rgba(0,0,0,.25)' }} />}
                                                    type="password"
                                                    placeholder="Confirm Password"
                                                    name="confirmpassword"
                                                    onBlur={this.handleConfirmBlur}
                                                    onChange={this.handleChange}

                                                />,
                                            )}
                                        </Form.Item>
                                        <Form.Item >
                                            {getFieldDecorator('agreement', {
                                                valuePropName: 'checked',
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'kindly agree to our terms and conditions',
                                                    }]
                                            })(
                                                <Checkbox style={{ lineHeight: '20px' }}>
                                                    By clicking sign up, you agree to our terms and conditions
                                                </Checkbox>
                                            )}
                                        </Form.Item>
                                        <Form.Item>
                                            <div className="row">
                                                <div className="col-sm-3">
                                                    <Button htmlType="submit"
                                                        className="btn loginBtn color-1"
                                                        loading={this.state.iconLoading}
                                                        onClick={this.enterLoading} style={{ margin: '0' }}>
                                                        Signup
                                            </Button>
                                                </div>
                                                <div className="col-sm-9">
                                                    <span className="login-form-forgot" style={{
                                                        display: 'inline-block',
                                                        fontWeight: '600', float: "Right", marginTop: '15px'
                                                    }}>
                                                        I m already member?<Link className="registertext" to="/login"
                                                            style={{ marginLeft: "5px" }}>
                                                            Login
                                                            </Link></span>
                                                </div>
                                            </div>
                                        </Form.Item>
                                    </Form>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>


            </div >

        )

    }

}

const Wrappedsignup = Form.create({ name: 'register' })(Signup);
function mapStateToProps(state, ownProps) {
    return { user: state.user };
}


export default connect(
    mapStateToProps,
    { signup }
)(Wrappedsignup);