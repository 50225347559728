import React, { Component } from "react";
import { connect } from "react-redux";
// import { Link } from "react-router-dom";

class FooterBottom extends Component {
    constructor(props) {
        super(props);
        this.state = {
            new_year: new Date().getFullYear()
        };
    }



    render() {
        return (
            <footer>
                <p>@{this.state.new_year} Livathon.All rights reserved</p>
            </footer>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        user: state.user,
        allusers: state.allusers,
    };
}

export default connect(mapStateToProps, {

})(FooterBottom);
