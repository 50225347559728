import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getallInterest,
  addFollowing,
  getFollowing,
  removeFollowing
} from "../actions/livingAction";
import { getallUserLists, interestList } from "../actions/userAction";
import { Link } from "react-router-dom";
import {
  compose,
  withProps,
  // withHandlers,
  withStateHandlers,
  lifecycle,
} from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
} from "react-google-maps";
import demoFancyMapStyles from "../assests/css/style.json";
import You from "../assests/img/location-you.svg";
// import { MarkerClusterer } from "react-google-maps/lib/components/addons/MarkerClusterer";
import {
  Form,
  Button,
  message,
  Slider,
  Select,
  Avatar,
  Empty,
} from "antd";
import Noprofileimg from "../assests/img/d-avatar.jpg";
import _ from "lodash";
import MenuWidget from "./menuWidget"
import request from "superagent";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { BASEURL } from '../actionTypes'
import Geocode from "react-geocode";
Geocode.setApiKey("AIzaSyCcugEZd84OFFpaKZP1Eo0uCMmc0mA8bSk");
Geocode.enableDebug();

const { Option } = Select;

const MapWithAMarkerClusterer = compose(
  withStateHandlers(
    () => ({
      isOpen: false,
      infoIndex: null,
    }),
    {
      showInfo: ({ isOpen, infoIndex }) => (index) => ({
        isOpen: infoIndex !== index || !isOpen,
        infoIndex: index,
      }),
    }
  ),
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyCcugEZd84OFFpaKZP1Eo0uCMmc0mA8bSk",
    loadingElement: <div style={{ height: `400px` }} />,
    containerElement: <div style={{ height: `400px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  lifecycle({
    componentDidMount() {
      this.setState({
        zoomToMarkers: (map) => {
          const bounds = new window.google.maps.LatLngBounds();
          map.props.children.forEach((child) => {
            // console.log(child)
            if (child.type === Marker) {
              bounds.extend(
                  new window.google.maps.LatLng(
                  child.props.position.lat,
                  child.props.position.lng
                )
              );
            }
          });
          map.fitBounds(bounds);
        },
      });
    },
  }),
  withScriptjs,
  withGoogleMap
)((props) => (
  // console.log(props.mylocation),
  <GoogleMap
    // ref={props.zoomToMarkers}
    // ref='map'
    zoom={13}
    center={{ lat: props.mylocation.lat, lng: props.mylocation.lng }}
    defaultCenter={{ lat: props.mylocation.lat, lng: props.mylocation.lng }}
    options={{
      styles: demoFancyMapStyles,
      streetViewControl: false,
      mapTypeControl: false,
    }}
  >
    {props.markers.map((marker, index) => (
      <Marker
        key={index}
        icon={You}
        position={{
          lat: parseFloat(marker.Location.Lat),
          lng: parseFloat(marker.Location.Lng),
        }}
        onClick={() => props.showInfo(index)}
      >
        {props.isOpen && props.infoIndex === index && (
          <InfoWindow onCloseClick={props.onToggleOpen}>
            <div>
              <span className="userphoto">
                {marker.photoURL === undefined || marker.photoURL === "" ? (
                  <Avatar
                    style={{
                      color: "#f56a00",
                      backgroundColor: "#fde3cf",
                      textTransform: "uppercase",
                    }}
                    size={40}
                    src={Noprofileimg}
                  ></Avatar>
                ) : (
                  <Avatar src={marker.photoURL} size={40} />
                )}
              </span>
              <p className="mname">
                <b style={{ fontFamily: 'Google Sans' }}>
                  {marker.First_Name} {marker.Last_Name}</b>
              </p>
              <p className="maddress">
                <b style={{ fontFamily: 'Google Sans', marginRight: '5px' }}>Address:</b>
                <span style={{ fontFamily: 'Google Sans' }}>{marker.address}</span>
              </p>

            </div>
          </InfoWindow>
        )}
      </Marker>
    ))}
  </GoogleMap>
));

class NetworkView extends Component {
  state = {
    currentLatLng: {
      lat: "",
      lng: "",
    },
    iconLoading: false,
    rangevalue: 0,
    interests: [],
    interestlist: [],
    citylist: "",
    formatterValue: "",
    allusers: [],
    latLng: {
      lat: 10.499219,
      lng: 79.245441,
    },
    loading: false,
    applyLoading: false,
    selectedinterest: [],
  };


  handleChange = (value) => {
    // console.log(`selected ${value}`);
    this.setState({
      selectedinterest: value,
    });
  };


  enterLoading = () => {
    this.setState({ loading: true });
  }


  handleChangee = (address) => {
    this.setState({ address });
  };


  interestMaxLimit = async (rule, value, callback) => {
    if (value) {
      if (value.length > 10) {
        callback("should not select more than 10 interests");
      } else if (value.length <= 10) {
        callback();
      }
    }
    return;
  };

  handleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => {
        console.log(getLatLng(results[0]));
        getLatLng(results[0]).then((latLng) => {
          Geocode.fromLatLng(latLng.lat, latLng.lng).then(
            (response) => {
              const results = response.results[0];
              console.log(address, results, latLng);
              this.setState({
                address: address,
                latLng: latLng,
                iconLoading: false,
                applyLoading: false,
              });
            },
            (error) => {
              console.error(error);
            }
          );
          // console.log("Success", latLng);
        });
        // console.log(address, results[0]);
      })
      .catch((error) => console.error("Error", error));
  };

  componentWillMount() {
    // var uid = this.props.user.uid;
    // this.props.cityList().then((res) => {
    //   // console.log(res);
    //   this.setState({
    //     citylist: res,
    //   });
    // });

    // this.getMyLocation();
    this.props.getallInterest().then((res) => {
      console.log(res);
      this.setState({
        interests: res,
      });
    });


    this.props
      .interestList()
      .then((res) => {
        // console.log("res", res);
        this.setState({
          interestlist: res,
        });
      })
      .catch(function (error) {
        message.error("error occurred while sending data");
      });
  }

  componentDidMount() {

    this.getMyLocation();

  }

  getMyLocation = () => {
    const location =
      window.navigator && window.navigator.geolocation && navigator.geolocation;
    this.setState({
      loading: true,
    });
    if (location) {
      location.getCurrentPosition(
        (position) => {
          var lat = position.coords.latitude;
          var lng = position.coords.longitude;
          Geocode.fromLatLng(lat, lng).then(
            (response) => {
              // const sellocate = response.results[0];
              // console.log(lat, lng)
              this.setState({
                currentLatLng: {
                  lat: lat,
                  lng: lng
                }
              });
            },
            (error) => {
              // console.error(error);
            }
          );
        },
        (error) => {
          this.setState({
            loading: false,
          });
          message.warning(
            "Enable browser location permission to detect your current location!"
          );
          // console.log(error);
        }
      );
    }
  };


  handleSubmit = (e) => {
    this.setState({
      iconLoading: true,
      applyLoading: true
    });
    var rangevalue = this.state.rangevalue;
    var address = this.state.address;
    var selectedinterest = this.state.selectedinterest;
    var location = this.state.latLng;
    var uid = this.props.user.uid
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (rangevalue !== 0 && address !== "" && selectedinterest.length !== 0) {
        // console.log("Received values of form: ", values, this.state.latLng);
        this.getusers(selectedinterest, rangevalue, location, uid);
        this.setState({
          // iconLoading: false,
          applyLoading: true,
          iconLoading: true,
        });
      } else {
        message.warning("Please input all required fields!");
        this.setState({
          applyLoading: false,
          iconLoading: false,
        });
      }
    });
  };


  getusers(selectedinterest, rangevalue, location, uid) {
    var dataobj = {
      Distance: rangevalue,
      Ref_Interest: selectedinterest.toString(),
      Lat: location.lat,
      Lng: location.lng,
      uid: uid,
    };
    // console.log("Query", dataobj);
    this.setState({
      currentLatLng: location,
      applyLoading: true,
      iconLoading: true
    });
    request
      .post(BASEURL + "/dynamicuserlist")
      .send(dataobj)
      .then((res) => {
        this.setState({
          iconLoading: true,
          loading: true,
          applyLoading: true
        });
        // console.log(res);
        if (res.body.ReturnCode === 200) {
          // console.log(res.body);
          if (res.body.UserInfo.length === 0) {
            message.warning("No users found!");
            this.setState({
              allusers: [],
              applyLoading: false,
              iconLoading: false,
            });
          } else {
            this.setState({
              applyLoading: false,
              iconLoading: false
            });
            message.success(res.body.UserInfo.length + " " + "users found!");
            this.setState({
              allusers: res.body.UserInfo,
              applyLoading: false,
              iconLoading: false
            });
            // console.log(this.state.allusers, res.body.UserInfo);
          }

        } else {
          // console.log(res.body.Message.message);
          this.setState({
            allusers: [],
            applyLoading: false,
            iconLoading: false
          });
          message.warning("No users found!");
        }
      })
      .catch((err) => { });
  }


  handlefollowing = (followid) => {
    var uid = this.props.user.uid;

    this.props.addFollowing(uid, followid).then((res) => {
      // console.log(res);
      message.success("Added to your friend list")
      this.updateFollowing(followid, "Follow", res);
      // setTimeout(function () { 
      //   window.location.reload(false);
      // }, 1000);

      // console.log(merged);
      // this.setState({
      //   followers: merged,
      // });
    });
  };


  updateFollowing = (followid, type, res) => {
    var Actionupdated = this.state.allusers;
    var followType = "";
    if (type === "Follow") {

      followType = "Following"

    }
    // console.log(followid, Actionupdated);

    const elementsIndex = Actionupdated.findIndex((element) => element.Id === followid);
    // console.log(elementsIndex);
    let newArray = [...this.state.allusers];
    newArray[elementsIndex] = {
      ...newArray[elementsIndex],
      Follower_type: followType,
      Follower_id: this.props.user.uid,
      circle_id: res.Id
    };
    // console.log(newArray);
    this.setState({
      allusers: newArray,
    });
  };


  updateUnfollow = (followid, type, res) => {
    var Actionupdated = this.state.allusers;
    // var followType = "";

    // console.log(followid, Actionupdated);

    const elementsIndex = Actionupdated.findIndex((element) => element.circle_id === followid);
    // console.log(elementsIndex);
    let newArray = [...this.state.allusers];
    newArray[elementsIndex] = {
      ...newArray[elementsIndex],
      Follower_type: "",
      Follower_id: "",
      circle_id: ""
    };
    // console.log(newArray);
    this.setState({
      allusers: newArray,
    });
  };


  handleunfollow = (id) => {
    // var uid = this.props.user.uid;
    // console.log(id);
    this.props.removeFollowing(id).then((res) => {
      // console.log(res);
      message.error("removed from friend list")
      this.updateUnfollow(id, "Unfollow", res)
    });
  };


  // refreshFeeds = () => {
  //   setTimeout(function () {
  //     window.location.reload(false);
  //   }, 500);
  // }


  onChange = (value) => {
    // console.log(value);
    this.setState({
      rangevalue: value,
    });
  };


  formatter = (value) => {
    // console.log(value)
    return `${value} mile`;
  }

  renderinterests() {
    return _.map(this.state.interests, (interest, key) => {
      return (
        <Option value={interest.Interest_Name}
          // label={interest.interestName}
          key={key}>
          <div>
            <Avatar size="small" src={interest.InterestIcon}></Avatar>{" "}
            {interest.Interest_Name}
          </div>

        </Option>
      );
    });
  }


  renderuserList() {

    // console.log(this.state.allusers);

    return _.map(this.state.allusers, (user, index) => {
      return (
        <li key={index} className="text-center feedsuser">
          <div className="userbox">
            <span className="userphoto">
              {user.photoURL === undefined || user.photoURL === "" ? (
                <Avatar
                  style={{
                    color: "#f56a00",
                    backgroundColor: "#fde3cf",
                    textTransform: "uppercase",
                  }}
                  size={64}
                  src={Noprofileimg}
                ></Avatar>
              ) : (
                <Avatar src={user.photoURL} size={64} />
              )}
            </span>
            <br />
            <Link
              to={user.Id === this.props.user.uid ?
                ("/profile")
                :
                ("/profile/" + user.Id)}
            // onClick={() => this.refreshFeeds()}
            >
              <span className="userinfo">
                {user.First_Name} {user.Last_Name} <br />
              </span>
            </Link>
            <div>
              <span className="memcity">
                {user.Ref_Interest.join(" , ")}
              </span>
            </div>
            {user.Follower_type === "" ? (
              <Button
                type="default"
                block
                className="follow"
                onClick={() => this.handlefollowing(user.Id)}
              >
                Follow
              </Button>
            ) : (
              <Button
                type="default"
                block
                disabled
                className="userfollowing"
                onClick={() => this.handleunfollow(user.Id)}
              >
                Following
              </Button>
            )}
          </div>
        </li>
      );
    });
  }


  render() {
    const { getFieldDecorator } = this.props.form;
    const { rangevalue, applyLoading } = this.state;
    // console.log(this.state.allusers);
    return (
      <div>
        <div className="container-fluid ">
          <div className="row" style={{ marginTop: "80px" }}>
            <div className="col-md-2 col-xs-12 sticky hidden-xs hidden-widget">
              <MenuWidget />
            </div>
            <div className="col-md-10 col-xs-12">
              <div className="networkform">
                <h4>Networking <span>(Search people based on interest, location & range)</span></h4>
                <Form onSubmit={this.handleSubmit}
                  className="network-form"
                >
                  <div className="row">
                    <div className="col-md-3 col-sm-4 col-xs-12">
                      <Form.Item>
                        <label>Interest</label>
                        {getFieldDecorator(
                          "Interest",
                          {
                            rules: [
                              {
                                required: true,
                                message: "Choose atleast one interest",
                              },
                              { validator: this.interestMaxLimit },
                            ]
                          }
                        )(
                          <Select
                            mode="multiple"
                            style={{ width: "100%" }}
                            placeholder="Please select"
                            onChange={this.handleChange}
                          >
                            {this.renderinterests()}
                          </Select>
                        )}
                      </Form.Item>
                    </div>
                    <div className="col-md-3 col-sm-4 col-xs-12">
                      <Form.Item>
                        <label>Location</label>

                        <PlacesAutocomplete
                          value={this.state.address}
                          onChange={this.handleChangee}
                          onSelect={this.handleSelect}
                        >
                          {({
                            getInputProps,
                            suggestions,
                            getSuggestionItemProps,
                            loading,
                          }) => (
                            <div className="autocompletediv">
                              <input
                                {...getInputProps({
                                  placeholder: "Search Places ...",
                                  className: "location-search-input form-control",
                                })}
                              />
                              <div className="autocomplete-dropdown-container">
                                {loading && (
                                  <div className="autoloading">Loading...</div>
                                )}
                                {suggestions.map((suggestion) => {
                                  const className = suggestion.active
                                    ? "suggestion-item--active"
                                    : "suggestion-item";

                                  return (
                                    <div
                                      {...getSuggestionItemProps(suggestion, {
                                        className,
                                      })}
                                    >
                                      <span>{suggestion.description}</span>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                        </PlacesAutocomplete>

                      </Form.Item>
                    </div>
                    <div className="col-md-3 col-sm-4 col-xs-12">
                      <Form.Item>
                        <label>
                          Range <span>{rangevalue} mile(s)</span>
                        </label>
                        {getFieldDecorator("Range", {
                          setFieldsValue: `{typeof rangevalue === "number" ? rangevalue : 0}`,
                        })(
                          <Slider
                            tipFormatter={this.formatter}
                            min={1}
                            max={20}
                            onChange={this.onChange}
                          //   value={typeof rangevalue === "number" ? rangevalue : 0}
                          />
                        )}
                      </Form.Item>
                    </div>
                    <div className="col-md-3 col-sm-4 col-xs-12">
                      <Form.Item>
                        <Button
                          htmlType="submit"
                          className="btn loginBtn color-1"
                          loading={this.state.iconLoading}
                          onClick={this.enterLoading}
                          disabled={applyLoading === true ? true : false}
                          style={{ margin: '0 15px 15px 0' }}
                        >
                          {applyLoading === true ? "Searching.." : "Search"}
                        </Button>
                        <Button
                          htmlType="reset"
                          className="btn loginBtn color-1"
                          onClick={(e) => {
                            this.props.form.resetFields();
                            this.setState({
                              address: "",
                              allusers: [],
                              latLng: "",
                              rangevalue: 0,
                              formatterValue: "",
                              applyLoading: false,
                              iconLoading: false
                            });
                          }}
                          style={{ margin: '0 15px 15px 0' }}
                        >
                          Clear
                        </Button>
                      </Form.Item>
                    </div>
                  </div>
                </Form>
              </div>

              <div>
                <MapWithAMarkerClusterer
                  markers={this.state.allusers}
                  mylocation={this.state.currentLatLng}
                />
              </div>

              <div className="networkuserslist">
                {this.state.allusers.length !== 0 ? (
                  <ul>{this.renderuserList()}</ul>
                ) : (
                  <div style={{ marginTop: '20px' }}>
                    <Empty />
                  </div>

                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const Networking = Form.create({ name: "Networking" })(NetworkView);
function mapStateToProps(state, ownProps) {
  return { user: state.user };
}

export default connect(mapStateToProps, {
  getallInterest,
  getallUserLists,
  interestList,
  addFollowing,
  getFollowing,
  removeFollowing
})(Networking);
