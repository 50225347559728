import React, { Component } from "react";
import Logo from '../assests/img/logo-new-dark.svg';
import {
    Card, Dropdown, Modal, Button,
    Empty, Tooltip, Avatar, Icon,
    message
} from 'antd';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
    getLogPostwithId
} from "../actions/livingAction";
import createHistory from "history/createBrowserHistory";
import { Carousel } from "react-responsive-carousel";
import ReadMoreAndLess from "react-read-more-less";
import _ from "lodash";
import moment from "moment";
import Noprofileimg from '../assests/img/d-avatar.jpg'
import Verified from '../assests/img/verified.svg'
import Linkify from 'react-linkify';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import LogShimmer from './logShimmer'



class LogPosts extends Component {
    constructor() {
        super();
        this.state = {
            postlist: [],
            copied: false,
            username: "",
            userimg: "",
            posttype: "",
            date: "",
            logs: "",
            uploadImage: [],
            arraylength: true,
            activeimgset: "",
            currentSlide: 0,
            visible: false,
            action: [],
            close: true,
            Is_Verified: false,
            userexists: false,
            visible1: false,
        }
    }

    showModal = (images, index) => {
        this.setState({
            visible: true,
            currentSlide: index,
            activeimgset: images
        });
        // console.log(index)
    };

    showModal1 = () => {
        this.setState({
            visible1: true,
        });
    };


    componentDidMount() {

        const history = createHistory();
        // Get the current location.
        const location = history.location;

        var path = location.pathname
        var res = path.split("/");
        var docid = res[2];

        if (this.props.user !== null) {
            console.log(this.props.user)
        }

        if (!_.isEmpty(this.props.user)) {
            console.log(this.props.user)
            this.setState({
                userexists: true
            })
        }

        this.props.getLogPostwithId(docid).then((res) => {
            console.log(res);
            this.setState({
                postlist: res,
                username: res.username,
                userimg: res.userimg,
                date: res.Created_At,
                Is_Verified: res.Is_Verified,
                posttype: res.posttype,
                logs: res.log_list,
                uploadImage: res.upload_logs_Image,
                arraylength: false
            })
        });
    }


    abbreviateNumber(value) {
        var newValue = value;
        if (value >= 10000) {
            var suffixes = ["", "k", "M", "B", "T"];
            var suffixNum = Math.floor(("" + value).length / 4);
            var shortValue = '';
            for (var precision = 3; precision >= 2; precision--) {
                shortValue = parseFloat((suffixNum !== 0 ? (value / Math.pow(1000, suffixNum)) : value).toPrecision(precision));
                var dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g, '');
                if (dotLessShortValue.length <= 3) { break; }
            }
            if (shortValue % 2 !== 0) shortValue = shortValue.toFixed(2);
            newValue = shortValue + suffixes[suffixNum];
        }
        // console.log(newValue)
        return newValue;
    }

    handleCancel = e => {
        // console.log(e);
        this.setState({
            visible: false,
            visible1: false
        });
        setTimeout(
            function () {
                this.setState({
                    activeimgset: ""
                });
            }.bind(this),
            150
        );
    };


    renderUserInfo() {
        const { username, userimg, date, Is_Verified } = this.state
        return (
            <div className="postuserinfo">
                <span className="userphoto">
                    {userimg === "" ? (
                        <Avatar
                            style={{ color: "#f56a00", backgroundColor: "#fde3cf" }}
                            size={46} src={Noprofileimg}
                        >
                            {/* {username.charAt(0)} */}
                        </Avatar>
                    ) : (
                        <Avatar src={userimg} size={46} />
                    )}
                </span>
                <span
                    className="userinfo"
                    style={{
                        marginLeft: "10px",
                        fontWeight: "600",
                        fontSize: "14px",
                        textAlign: 'left'
                    }}
                >
                    {username}
                    <small
                        style={{ color: '#777', marginLeft: '5px' }}>created a new post
                        {Is_Verified === false ? null : (
                            <img src={Verified} className="feeds-verified" />
                        )}
                    </small>
                    <br />
                    <span className="memcity">{date}
                        {/* {moment.unix(Created_At.seconds).startOf('seconds').fromNow()} */}
                    </span>
                </span>
            </div>
        );
    }

    onCopy = () => {
        this.setState({
            copied: true,
        });
        message.success("Copied shared link")
    };


    renderMenu(Id) {
        return (
            <ul className="feedpost-drop">
                <li>
                    <CopyToClipboard onCopy={this.onCopy} text={this.state.urlvalue + Id}>
                        <span>Copy Link</span>
                    </CopyToClipboard>
                </li>
            </ul>
        )
    }

    renderLogsList() {

        const { postlist, posttype, logs,
            uploadImage, userexists } = this.state

        const componentDecorator = (href, text, key) => (
            <a href={href} key={key} rel="noopener noreferrer" target="_blank">
                {text}
            </a>
        );
        return (
            <div className="postcard" style={{ marginBottom: "10px" }}>
                <Card
                    className="feedscard"
                    title={this.renderUserInfo()}
                    extra={
                        <div style={{ display: 'inline-flex' }}>
                            <Dropdown trigger={["click"]} placement="bottomRight"
                                overlay={this.renderMenu(postlist.Id)}
                                style={{ marginLeft: '10px' }}>
                                <a className="ant-dropdown-link" href="#"
                                    onClick={e => e.preventDefault()}>
                                    <Icon type="more" style={{ fontSize: '20px', color: '#333' }} />
                                </a>
                            </Dropdown>
                        </div>
                    }
                >
                    <div style={logs.length === 0 ? { padding: '0' } : { padding: '15px' }}>
                        {_.map(logs, (log, index) => {
                            return (
                                <div className="readdiv" key={index}
                                    style={{
                                        wordBreak: 'break-all',
                                        whiteSpace: 'pre-line',
                                        marginBottom: '10px',
                                        padding: "8px 10px",
                                        border: "1px solid #f9f9f9",
                                        display: "block",
                                        position: "relative",
                                        borderRadius: "2px"
                                    }}>
                                    <Linkify componentDecorator={componentDecorator}>
                                        <ReadMoreAndLess
                                            ref={this.ReadMore}
                                            className="read-more-content"
                                            charLimit={300}
                                            readMoreText="Read more"
                                            readLessText="Read less"
                                        >
                                            {log.description}
                                        </ReadMoreAndLess>
                                    </Linkify>
                                    <div className="breakdes">
                                        <span className="time">
                                            {moment.unix(log.text_created / 1000).format("LT")}
                                        </span>
                                    </div>
                                </div>
                            )
                        })
                        }
                    </div>
                    <div>
                        <div className="row text-center">
                            {_.map(uploadImage, (logImage, index) => {
                                return (
                                    <div
                                        className="col-md-4 col-xs-4 postimg"
                                        style={{ padding: "15px" }}
                                        key={index}
                                    >
                                        <div className="imagecontainer">
                                            <Tooltip title="Click to full view" placement="top">
                                                <img
                                                    src={logImage}
                                                    onClick={!userexists ? (() =>
                                                        this.showModal1()) : (() =>
                                                            this.showModal(uploadImage, index))
                                                    }
                                                    className="imgbanner"
                                                    alt=""
                                                />
                                            </Tooltip>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </Card>
            </div>

        );

    }

    renderModalimages() {
        return (
            <Carousel
                selectedItem={this.state.currentSlide}
                // onChange={this.updateCurrentSlide}
                // showArrows={false}
                renderArrowPrev={(onClickHandler, hasPrev, label) =>
                    hasPrev && (
                        <Button type="primary" onClick={onClickHandler} title={label}
                            className="arrowstyles"
                            style={{ left: 0 }}>
                            <Icon type="left" />
                        </Button>
                    )
                }
                renderArrowNext={(onClickHandler, hasNext, label) =>
                    hasNext && (
                        <Button type="primary" onClick={onClickHandler} title={label}
                            className="arrowstyles"
                            style={{ right: 0 }}>
                            <Icon type="right" />
                        </Button>
                    )
                }>
                {_.map(this.state.activeimgset, (logImage, index) => {
                    return (
                        <div className="carouselimg" key={index}>
                            <div className="carouselimginner">
                                <img src={logImage} className="imgbanner img1" alt="" />
                            </div>
                        </div>
                    );
                })}
            </Carousel>
        );
    }

    renderAlert() {
        const logoImage = {
            height: '50px',
            padding: '15px 15px',
            fontSize: '18px',
            lineHeight: '20px'
        }
        return (
            <div>
                <div className="row">
                    <div className="col-md-12 text-center">
                        <Link style={logoImage} to="">
                            <img
                                src={Logo}
                                className="logoimg img-responsive text-center"
                                style={{ height: '30px', display: 'inline-block' }}
                                alt="logo"
                            />
                        </Link>
                    </div>
                    <div className="col-md-12 text-center"
                        style={{ marginTop: '15px', marginBottom: '15px' }}>
                        <p>Kindly login or signup to livathon</p>
                        <div style={{ display: 'inline-flex' }}>
                            <Link className="navbar-brand" to="/login">
                                <Button type="primary">Login</Button>
                            </Link>
                            <Link className="navbar-brand" to="/signup">
                                <Button type="default">Signup</Button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div >
        )
    }


    onclose = () => {
        this.setState({
            close: false,
        })
    }

    render() {

        const { postlist, arraylength, userexists } = this.state

        const space = {
            marginRight: '15px'
        }
        return (
            <div>
                <Modal
                    className="gridmodal carouselmodal"
                    title=""
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={null}
                    centered={true}
                    width="70%"
                >
                    {this.renderModalimages()}
                </Modal>
                <Modal
                    title=""
                    className="loginalert"
                    visible={this.state.visible1}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={null}
                    closable={true}
                    centered={true}
                    destroyOnClose={true}
                >
                    {this.renderAlert()}
                </Modal>
                <div className="container-fluid">
                    <nav className="navbar navbar-default">
                        <div className="container-fluid navcontainer">
                            <div className="navbar-header">
                                <div className="navbar-toggle">
                                    {/* <Signup /> */}
                                </div>
                                <Link className="navbar-brand" to="">
                                    <img
                                        src={Logo}
                                        className="logoimg img-responsive"
                                        alt="logo"
                                        style={{ height: '35px' }}
                                    />
                                </Link>
                            </div>
                            <div id="navbar" className="navbar-collapse collapse">
                                <ul className="nav navbar-nav navbar-right" style={{ marginTop: '30px' }}>
                                    {!userexists ? (
                                        <Link to="/login">
                                            <Button type="primary">Login</Button>
                                        </Link>
                                    ) : null}

                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-offset-2 col-md-8 col-md-offset-2"
                            style={{ padding: 0 }}>
                            <div>
                                {postlist.length === 0 && arraylength === true ? (
                                    <div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <LogShimmer count={1} />
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        {postlist.length === 0 ? (
                                            <Empty />
                                        ) : (
                                            <div>
                                                {this.renderLogsList()}
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {!userexists && this.state.close === true ? (
                    <div className="logincard-div" id="closeDiv">
                        <div className="logincard-closable">
                            <div className="logincard-content">
                                <div className="logincard-message">
                                    <p>Log In to Livathon </p>
                                    <p>Log in to see photos from friends and discover
                                        other accounts you'll love.</p>
                                </div>
                                <div className="logincard-close">
                                    <Link to="/login">
                                        <Button type="primary" style={space}>Login</Button>
                                    </Link>
                                    <Link to="/signup">
                                        <Button type="default" style={space}>Signup</Button>
                                    </Link>
                                    <a onClick={() => this.onclose()}>
                                        <Icon type="close" style={{ color: "#fff" }} />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}

            </div>
        )
    }

}

function mapStateToProps(state, ownProps) {
    return { user: state.user };
}


export default connect(
    mapStateToProps,
    {
        getLogPostwithId,
    }
)(LogPosts);


