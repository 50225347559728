import React, { Component } from "react";
import { Tabs } from "antd";
import { connect } from "react-redux";
import _ from "lodash";
import MenuWidget from "./menuWidget"
import ProfileWidget from './profileWidget'
import FeedsuserList from "./feedsuserlist";
import UserPersonalLog from './UserPersonalLog'
import PlannedLogs from "./plannedLogs";
import FutureLogs from "./futureLogs";


const { TabPane } = Tabs;

function callback(key) {
  // console.log(key);
}

class Logs extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentWillMount() {

  }

  render() {
    return (
      <div>
        <div className="container-fluid">
          <div className="row" style={{ marginTop: "80px" }} >
            <div className="col-md-2 sticky hidden-widget">
              <MenuWidget />
            </div>
            <div className="col-md-6">
              <Tabs defaultActiveKey="1" className="feedstab" onChange={callback}>
                <TabPane tab="Diary" key="1">
                  <div>
                    <UserPersonalLog />
                  </div>
                </TabPane>
                <TabPane tab="Planned" key="2">
                  <PlannedLogs />
                </TabPane>
                <TabPane tab="Future" key="3">
                  <FutureLogs />
                </TabPane>
              </Tabs>
            </div>
            <div className="col-md-4">
              <ProfileWidget />
              <div>
                <FeedsuserList />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
    personallogs: state.personallogs,
    modalvisible: state.modalvisible
  };
}


export default connect(mapStateToProps, {
})(Logs);