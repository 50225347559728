import React, { Component } from "react";
import {
    getuserPersonalLogs,
    getFeedsLimit,
} from "../actions/livingAction";
import { createAccount, getallUserList } from "../actions/userAction";
import {
    Card, Avatar, Empty, Modal,
    Button, Icon, message, Popconfirm, Dropdown
} from "antd";
import { Carousel } from "react-responsive-carousel";
import { connect } from "react-redux";
// import { Link } from "react-router-dom";
// import EmptyImage from "../assests/img/profile-upload.png"
import _ from "lodash";
import Linkify from 'react-linkify';
import { db } from "../firebase";
import ReadMoreAndLess from "react-read-more-less";
import moment from "moment";
// import request from "superagent";
import Noprofileimg from '../assests/img/d-avatar.jpg'
// import { BASEURL } from '../actionTypes'
// import InfinitScroll from 'react-infinite-scroll-component'
import LogShimmer from './logShimmer'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
    FacebookShareButton,
    TwitterShareButton,
    LinkedinShareButton,
    WhatsappShareButton,
} from "react-share"
import { Passers } from "prop-passer";


class UserPersonalLog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            citylist: "",
            userlist: "",
            personallogs: [],
            visible: false,
            activeimgset: "",
            slideIndex: 1,
            urlvalue1: window.location.origin + "/logpost/",
            activeuser: "",
            photoURL: "",
            First_Name: "",
            Last_Name: "",
            Full_Name: "",
            hasMore: true,
            bio: "",
            currentSlide: 0,
            lastrecord: 0,
            limitrequest: 0,
            arraylength: true
        };
    }

    showModal = (images, index) => {
        this.setState({
            visible: true,
            currentSlide: index,
            activeimgset: images
        });
        // console.log(index)
    };

    onChange(a, b, c) {
        // console.log(a, b, c);
    }

    handleCancel = e => {
        // console.log(e);
        this.setState({
            visible: false
        });
        setTimeout(
            function () {
                this.setState({
                    activeimgset: ""
                });
            }.bind(this),
            150
        );
    };


    confirmdelete(Id, value) {
        this.deleteLogImage(Id, value)
        // message.success('Log deleted');
    }

    confirmLog = (id, type) => {
        this.deletepersonallog(id, type);
    }
    //delete log image by id & value
    deletepersonallog = (id, type) => {
        this.setState({
            posttype: type
        })
        var newArray = [...this.state.personallogs];
        db.collection("Personal_Logs")
            .doc(id)
            // console.log(Id)
            .delete()
            .then(() => {
                // console.log("Document successfully deleted!");
                message.error("Log deleted successfully")
                const idToRemove = id;

                _.remove(newArray, ['Id', idToRemove])

                console.log(newArray);
                this.setState({
                    personallogs: newArray,
                });
            })
            .catch(function (error) {
                console.error("Error removing document: ", error);
            });
    }

    deleteLogImage = (Id, value) => {
        var docRef = db.collection("Personal_Logs").doc(Id);
        docRef
            .get()
            .then(function (doc) {
                if (doc.exists) {
                    // console.log(doc.data().upload_logs_Image);
                    var ListImage = doc.data().upload_logs_Image;
                    ListImage.splice(value, 1);
                    message.success("Log image deleted successfully");
                    docRef
                        .update({
                            upload_logs_Image: ListImage
                        })
                        .then(function () {
                            // console.log("Document successfully updated!");
                            setTimeout(function () {
                                window.location.reload(false);
                            }, 1000);
                        })
                        .catch(function (error) {
                            // console.error("Error updating document: ", error);
                        });
                } else {
                    // console.log("No such document!");
                }
            })

            .catch(function (error) {
                console.log("Error getting document:", error);
            });
    };


    confirmdeleteLogs(Id, value) {
        this.deleteLogs(Id, value);
    }

    deleteLogs = (Id, value) => {
        var target = this.state.personallogs;

        var docList = db.collection("Personal_Logs").doc(Id);
        docList
            .get()
            .then((doc) => {
                if (doc.exists) {
                    var ListLogs = doc.data().log_list;
                    var character = doc.data().character_count;
                    // console.log(ListLogs[value], ListLogs[value].description, ListLogs[value].description.length);
                    var logscount = character - ListLogs[value].description.length;
                    // console.log(logscount);
                    ListLogs.splice(value, 1);
                    console.log(ListLogs);
                    message.success("This log has been deleted successfully");
                    docList
                        .update({
                            log_list: ListLogs,
                            character_count: logscount
                        })
                        .then(() => {
                            const elementsIndex = target.findIndex((element) => element.Id === Id);

                            let newArray = [...this.state.personallogs];
                            newArray[elementsIndex] = {
                                ...newArray[elementsIndex],
                                log_list: ListLogs,
                                character_count: logscount
                            };
                            console.log(newArray);
                            this.setState({
                                personallogs: newArray,
                            });
                            console.log("Document successfully updated!");

                        })
                        .catch((error) => {
                            console.error("Error updating document: ", error);
                        });
                } else {
                    // console.log("No such document!");
                }
            })

            .catch((error) => {
                console.log("Error getting document:", error);
            });
    };

    cancel(e) {
        // console.log(e);
        // message.error('Click on No');
    }

    componentWillMount() {

        var uid = this.props.user.uid;

        this.props.getuserPersonalLogs(uid).then(res => {
            // console.log(res)
            this.setState({
                personallogs: res,
                arraylength: false
            })
        })

        // this.props.getFeedsLimit().then(res => {
        //     // console.log(res.feedperRquest);
        //     this.setState({
        //         limitrequest: res.feedperRquest,
        //         loading: true
        //     })
        //     this.getLog(this.props.user.uid, this.state.limitrequest)
        //     this.setState({
        //         loading: false
        //     })
        // });
    }

    renderUserInfo(username, userimg, Created_ago) {
        // var timestamp = new Date(Created_At.seconds * 1000).toLocaleDateString();
        // console.log(timestamp);
        return (
            <div className="postuserinfo">
                <span className="userphoto">
                    {userimg === "" ? (
                        <Avatar
                            style={{ color: "#f56a00", backgroundColor: "#fde3cf" }}
                            size={46} src={Noprofileimg}
                        >
                            {/* {username.charAt(0)} */}
                        </Avatar>
                    ) : (
                        <Avatar src={userimg} size={46} />
                    )}
                </span>
                <span
                    className="userinfo"
                    style={{
                        marginLeft: "10px",
                        fontWeight: "600",
                        fontSize: "14px",
                        textAlign: 'left'
                    }}
                >
                    {username}
                    <br />
                    <span className="memcity">{Created_ago}
                        {/* {moment.unix(Created_At.seconds).startOf('seconds').fromNow()} */}
                    </span>
                </span>
            </div>
        );
    }

    updateCurrentSlide = (index) => {
        const { currentSlide } = this.state;

        if (currentSlide !== index) {
            this.setState({
                currentSlide: index,
            });

        }
        // console.log(index)
    }

    renderPersonalLogsList() {
        const componentDecorator = (href, text, key) => (
            <a href={href} key={key} rel="noopener noreferrer" target="_blank">
                {text}
            </a>
        );
        return (
            <div>
                {this.state.personallogs.map((logs, index) => (
                    <div className="profilelog" style={{ marginBottom: "20px" }} key={index}>
                        <Card
                            title={this.renderUserInfo(
                                logs.username,
                                logs.userimg,
                                logs.Created_ago
                            )}
                            extra={
                                <div style={{ display: 'inline-flex' }}>
                                    <Dropdown trigger={["click"]} placement="bottomRight"
                                        overlay={this.renderMenu(logs.Id, logs.posttype)}
                                        style={{ marginLeft: '10px' }}>
                                        <a className="ant-dropdown-link" href="#"
                                            onClick={e => e.preventDefault()}>
                                            <Icon type="more" style={{ fontSize: '20px', color: '#333' }} />
                                        </a>
                                    </Dropdown>
                                </div>
                            }
                            style={{
                                width: "100%",
                                border: "1px solid #eee",
                                borderRadius: "4px",
                                padding: 0
                            }}
                        >
                            {logs.log_list.length.length === 0 ? (
                                <Empty />
                            ) : (
                                <div style={logs.log_list.length === 0 ? { padding: '0' } : { padding: '15px' }}>
                                    {_.map(logs.log_list, (log, index) => {
                                        return (
                                            <div className="readdiv" key={index}
                                                style={{
                                                    wordBreak: 'break-all',
                                                    whiteSpace: 'pre-line',
                                                    marginBottom: '10px',
                                                    padding: "8px 10px",
                                                    border: "1px solid #f9f9f9",
                                                    display: "block",
                                                    position: "relative",
                                                    borderRadius: "2px"
                                                }}>
                                                <Linkify componentDecorator={componentDecorator}>
                                                    <ReadMoreAndLess
                                                        ref={this.ReadMore}
                                                        className="read-more-content"
                                                        charLimit={300}
                                                        readMoreText="Read more"
                                                        readLessText="Read less"
                                                    >
                                                        {log.description}
                                                    </ReadMoreAndLess>
                                                </Linkify>
                                                <div className="breakdes">
                                                    <span className="time">
                                                        {moment.unix(log.text_created / 1000).format("LT")}
                                                    </span>
                                                    <Popconfirm
                                                        title="Do you want to delete this log?"
                                                        icon={
                                                            <Icon type="question-circle-o" style={{ color: 'red' }} />}
                                                        onConfirm={() => this.confirmdeleteLogs(logs.Id, index)}
                                                        onCancel={() => this.cancel()}
                                                        okText="Yes"
                                                        cancelText="No">
                                                        <span style={{ marginLeft: '10px' }}>
                                                            <Icon type="delete"
                                                                style={{ color: '#1c41ce', cursor: 'pointer' }} />
                                                        </span>
                                                    </Popconfirm>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            )}

                            <div className="row text-center">
                                {_.map(logs.upload_logs_Image, (logImage, index) => {
                                    return (
                                        <div className="col-md-4 col-xs-12" key={index}>
                                            <div className="imagecontainer">
                                                <div className="button">
                                                    <Popconfirm
                                                        title="Do you want to delete this Log image?"
                                                        icon={
                                                            <Icon type="question-circle-o" style={{ color: 'red' }} />}
                                                        onConfirm={() => this.confirmdelete(logs.Id, index)}
                                                        onCancel={() => this.cancel()}
                                                        okText="Yes"
                                                        cancelText="No">
                                                        <Button
                                                            type="default"
                                                            block
                                                            className="delete userfollowing"
                                                        >
                                                            <Icon type="delete" />
                                                        </Button>
                                                    </Popconfirm>
                                                </div>
                                                <img src={logImage}
                                                    onClick={() => this.showModal(logs.upload_logs_Image, index)}
                                                    className="imgbanner" alt="" />
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </Card>
                    </div>
                )
                )}
            </div>
            // </InfinitScroll >
        )
    }

    renderModalimages() {
        return (
            <Carousel
                selectedItem={this.state.currentSlide}
                // onChange={this.updateCurrentSlide}
                // showArrows={false}
                renderArrowPrev={(onClickHandler, hasPrev, label) =>
                    hasPrev && (
                        <Button type="primary" onClick={onClickHandler} title={label}
                            className="arrowstyles"
                            style={{ left: 0 }}>
                            <Icon type="left" />
                        </Button>
                    )
                }
                renderArrowNext={(onClickHandler, hasNext, label) =>
                    hasNext && (
                        <Button type="primary" onClick={onClickHandler} title={label}
                            className="arrowstyles"
                            style={{ right: 0 }}>
                            <Icon type="right" />
                        </Button>
                    )
                }>
                {_.map(this.state.activeimgset, (logImage, index) => {
                    return (
                        <div className="carouselimg" key={index}>
                            <div className="carouselimginner">
                                <img src={logImage} className="imgbanner img1" alt="" />
                            </div>
                        </div>
                    );
                })}
            </Carousel>
        );
    }


    onCopy = () => {
        this.setState({
            copied: true,
        });
        message.success("Copied shared link")
    };

    renderMenu(Id, type) {
        const {
            url = this.state.urlvalue + Id,
            title = "See the livathon post",
            shareImage = "https://www.steadylearner.com/static/images/brand/prop-passer.png",
            size = "2.5rem",
        } = this.props;


        const ShareList = Passers({
            url,
            className: "network__share-button",
        })({
            className: "network cursor-pointer hover transition--default",
            title: `Share ${String(this.state.loc)}`,
        })("li");

        const iconFont = {
            fontSize: '14px'
        }

        return (
            <ul className="feedpost-drop">
                <li>
                    <Popconfirm
                        title={type === "personal" ? ("Do you want to delete this Log?") :
                            ("Do you want to delete this post?")}
                        icon={
                            <Icon type="question-circle-o" style={{ color: 'red' }} />}
                        onConfirm={(() => this.confirmLog(Id, type))}
                        onCancel={() => this.cancel()}
                        okText="Yes"
                        cancelText="No">
                        <span style={{ margin: 0 }}>
                            <i className="fas fa-trash" style={iconFont}></i> Delete this Log post</span>
                    </Popconfirm>
                </li>
                {type === "portfolio" ? (
                    <p style={{ margin: 0 }}>
                        <ShareList>
                            <FacebookShareButton
                                windowWidth={750}
                                windowHeight={600}
                                url={"https://livathon.com/" + "/post" + Id}
                                quote={"See the livathon Post"}
                            >
                                <i className="fab fa-facebook" style={iconFont}></i> Share to facebook
                            </FacebookShareButton>
                        </ShareList>
                        <ShareList>
                            <TwitterShareButton
                                windowWidth={750}
                                windowHeight={600}
                                title={title}
                                url={url}
                            >
                                <i className="fab fa-twitter" style={iconFont}></i> Share to twitter
                            </TwitterShareButton>
                        </ShareList>
                        <ShareList>
                            <WhatsappShareButton
                                windowWidth={750}
                                windowHeight={600}
                                title={"See the livathon post"}
                                url={url}
                                separator=":: "
                            >
                                <i className="fab fa-whatsapp-square" style={iconFont}></i>  share to whatsapp
                            </WhatsappShareButton>
                        </ShareList>
                        <ShareList>
                            <LinkedinShareButton
                                title={title}
                                url={url}
                                windowWidth={750}
                                windowHeight={600}
                            >
                                <i className="fab fa-linkedin-in" style={iconFont}></i> Share to linkedIn
                            </LinkedinShareButton>
                        </ShareList>
                    </p>
                ) : null}
                <li>
                    <CopyToClipboard onCopy={this.onCopy}
                        text={this.state.urlvalue1 + Id}>
                        <span><i className="fas fa-copy" style={iconFont}></i> Copy link</span>
                    </CopyToClipboard>
                </li>
            </ul>
        )
    }

    render() {
        const { personallogs, arraylength } = this.state
        return (
            <div>
                <Modal
                    className="gridmodal carouselmodal"
                    title=""
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={null}
                    destroyOnClose={true}
                    centered={true}
                    width="70%"
                >
                    {this.renderModalimages()}
                </Modal>
                <div className="row">
                    {personallogs.length === 0 && arraylength === true ? (
                        <div>
                            <div className="row">
                                <div className="col-md-12">
                                    <LogShimmer count={4} />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div>
                            {personallogs.length === 0 ? (
                                <Empty />
                            ) : (
                                <div>
                                    {this.renderPersonalLogsList()}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        user: state.user,
        allusers: state.allusers,
    };
}

export default connect(mapStateToProps, {
    getuserPersonalLogs,
    createAccount,
    getallUserList,
    getFeedsLimit
})(UserPersonalLog);
