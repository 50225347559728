import React, { Component } from "react";
// import Navigation from '../common/header';
import Logo from '../assests/img/logo-new.svg';
import {
    Dropdown, Avatar, Icon
} from 'antd';
import { Link } from "react-router-dom";
import MenuWidget from "./menuWidget"
import { logout } from "../actions/userAction";
import { connect } from "react-redux";
// import { db } from "../firebase";
import Noprofileimg from "../assests/img/d-avatar.jpg";


const heading = {
    fontSize: '20px',
    fontWeight: '600'
}

const topSpace = {
    marginTop: '50px'
}

const subtext = {
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.65)',
    marginTop: '15px'
}

class HelpCenter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Full_Nam: "",
            photoURL: "",
            First_Name: "",
            new_year: new Date().getFullYear()
        }
    }

    componentDidMount() {
        // var uid = this.props.user.uid;
        // console.log(uid)
        // var userRef = db.collection("User_Details").doc(uid);
        // userRef
        //     .get()
        //     .then((doc) => {
        //         if (doc.exists) {
        //             var Full_Name = doc.data().First_Name + " " + doc.data().Last_Name;
        //             // console.log(doc.data().photoURL);
        //             this.setState({
        //                 Full_Name: Full_Name,
        //                 First_Name: doc.data().First_Name,
        //                 photoURL: doc.data().photoURL,
        //             });
        //         } else {
        //             console.log("No such document!");
        //         }
        //     })
        //     .catch((err) => {
        //         // console.log("Error getting document", err);
        //     });
    }

    render() {
        const cusmenu = (
            <ul className="profiledropdown">
                {/* <Link to="/profile">
                    <li className="prodrop-header">
                        {this.state.photoURL === "" ? (
                            <Avatar
                                style={{ display: "inline-block" }}
                                size={35}
                                src={Noprofileimg}
                            ></Avatar>
                        ) : (
                            <Avatar
                                style={{ display: "inline-block" }}
                                size={35}
                                src={this.state.photoURL}
                            ></Avatar>
                        )}
                        <p
                            style={{
                                marginLeft: "10px",
                                display: "inline-block",
                                verticalAlign: "middle"
                            }}
                        >
                            <b>{this.state.Full_Name}</b>
                        </p>
                    </li>
                </Link>
                <Link to="/editprofile">
                    <li>
                        <p>
                            <Icon type="user" /> Edit Profile </p>
                    </li>
                </Link> */}
                <Link to="/logout" onClick={() => this.props.logout()}>
                    <li>
                        <p> <Icon type="logout" /> Logout </p>
                    </li>
                </Link>
            </ul >
        );
        return (
            <div className="helpcenter">
                <div className="container-fluid helpbg">
                    <nav className="navbar navbar-default">
                        <div className="container-fluid navcontainer">
                            <div className="navbar-header">
                                <div className="navbar-toggle">
                                    {/* <Signup /> */}
                                </div>
                                <Link className="navbar-brand" to="#">
                                    <img
                                        src={Logo}
                                        className="logoimg img-responsive"
                                        alt="logo"
                                        style={{ height: '35px' }}
                                    />
                                </Link>
                            </div>
                            <div id="navbar" className="navbar-collapse collapse">
                                <ul className="nav navbar-nav navbar-right" style={{ marginTop: '30px' }}>
                                    <div className="profilemenu">
                                        <span>
                                            <Dropdown
                                                overlay={cusmenu}
                                                trigger={["click"]}
                                                placement="bottomRight"
                                            >
                                                {this.state.photoURL === "" ? (
                                                    <Avatar src={Noprofileimg} size="small">
                                                        {/* {this.state.First_Name.charAt(0)} */}
                                                    </Avatar>
                                                ) : (
                                                    <Avatar src={this.state.photoURL} size="small" />
                                                )}
                                                {/* <b>{this.state.First_Name}</b> */}
                                            </Dropdown>
                                        </span>
                                    </div>
                                </ul>
                            </div>
                        </div>
                    </nav>
                    <h3 className="titletext">Hi, Livathon always there to
                    help our user's</h3>
                </div>
                <div className="container" style={{ marginTop: '50px' }}>
                    <div className="row">
                        {/* <div className="col-md-2 col-xs-12 sticky hidden-xs hidden-widget">
                            <MenuWidget />
                        </div> */}
                        <div className="col-md-8">
                            <h3 style={{ color: '#1C41CE', fontWeight: 'bold' }}
                            ><Icon type="file-search" /> Action suggested for you</h3>
                            <div style={topSpace}>
                                <h4 style={heading}>
                                    <Link to="/faq"> Livathon Homepage - FAQs</Link></h4>
                                <p style={subtext}>Livathon was designed so you can drive conversations, and discover topics you care about.
                                Your homepage is your landing page you reach when you first log in to your Livathon account. It includes your feed, access
                                to your networks, profile, messages, and notifications. Your Livathon Feed contains posts from your network,
                            people you follow, and more...</p>
                            </div>
                            <div style={topSpace}>
                                <h4 style={heading}>
                                    <Link to="/editProfile">Manage your Profile</Link></h4>
                                <p style={subtext}>You can edit individual sections of your Livathon profile to best
                                reflect to your friends. To edit sections on your profile: Click…</p>
                            </div>
                            <div style={topSpace}>
                                <h4 style={heading}>
                                    <Link to="/privacy-policy">Data Secure - Privacy Policy</Link></h4>
                                <p style={subtext}>Livathon is committed in protecting the data collected from the users
                                safeguarding your privacy. This privacy policy outlines how the personal data is
                                collected and the purposes for which it is used...</p>
                            </div>
                            <div style={topSpace}>
                                <h4 style={heading}>
                                    <Link to="/terms-and-conditions">User Agreement - Terms & Conditions</Link></h4>
                                <p style={subtext}>Use of our applications ("Apps") are governed by the terms you accepted at
                                the time of purchase or download, these Terms and Conditions and the terms of your
                            mobile service provider and mobile device operator...</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-xs-12">
                            <p className="text-center"
                                style={{ color: '#333' }}>@{this.state.new_year}, Livathon, All rights reserved</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

function mapStateToProps(state, ownProps) {
    return { user: state.user };
}


export default connect(
    mapStateToProps,
    { logout }
)(HelpCenter);