import React, { Component } from "react";
import { connect } from "react-redux";
import FeedsuserList from "./feedsuserlist";
import FeedsPosts from "./feedsposts";
import ProfileWidget from "./profileWidget"
import MenuWidget from "./menuWidget"
import DayWidget from "./dayWidget"
import FeedsLog from './feedsLog'
import FeedsPost from './allfeedsPost'
import ExplorerFeeds from './feedFeatured'
import WrappedpostPortfolio from './postform'
import WrappedpostFeedback from './feedback'
import { Tabs, Icon, BackTop } from 'antd';
import FooterBottom from '../common/footer'



const { TabPane } = Tabs;

// function callback(key) {
//   // console.log(key);
// }

class Feeds extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
  }

  refreshFeeds = () => {
    setTimeout(function () {
      window.location.reload(false);
    }, 500);
  }

  componentWillMount() { }

  render() {
    return (
      <div>
        <div className="container feedsclip">
          <div>
            <BackTop onClick={() => this.refreshFeeds()}>
              <div className="ant-back-top-inner">
                <Icon type="vertical-align-top" />
              </div>
            </BackTop>
          </div>

          <div className="row" style={{ marginTop: '80px' }}>
            <div className="col-md-2 col-sm-12 col-xs-12 sticky hidden-widget">
              <MenuWidget />
            </div>
            <div className="col-md-6 col-sm-12 col-xs-12">
              <DayWidget />
              <WrappedpostPortfolio />
              <Tabs defaultActiveKey="1" size="small" className="feedstab">
                <TabPane tab={
                  <span>
                    <div className="icon dripicons-article"
                      style={{
                        display: 'inline-block',
                        marginRight: '5px'
                      }}></div>
                    Featured
                  </span>
                } key="1">
                  <ExplorerFeeds />
                </TabPane>
                <TabPane tab={
                  <span>
                    <Icon type="solution" theme="outlined" />
                    Following
                  </span>
                } key="2">
                  <FeedsPosts />
                </TabPane>
                <TabPane tab={
                  <span>
                    <Icon type="unordered-list" theme="outlined" />
                    Diary
                  </span>
                } key="3">
                  <FeedsLog />
                </TabPane>
                <TabPane tab={
                  <span>
                    <Icon type="idcard" theme="outlined" />
                    Posts
                  </span>
                } key="4">
                  <FeedsPost />
                </TabPane>
              </Tabs>
            </div>
            <div className="col-md-4 col-sm-12 col-xs-12">
              <div className="hidden-widget">
                <ProfileWidget />
              </div>
              <div>
                <FeedsuserList />
              </div>
              <div style={{ marginTop: '20px' }}>
                <WrappedpostFeedback />
              </div>
              <div className="sticky" style={{ marginTop: '20px' }}>
                <FooterBottom />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


function mapStateToProps(state, ownProps) {
  return {
    user: state.user
  };
}

export default connect(mapStateToProps, {})(Feeds);
