import React, { Component } from "react";
import {
  getallLogs,
  getallportfolioLogs,
  postActions,
  reportPost,
  getFeedsLimit
} from "../actions/livingAction";
import { getallUsers, cityList } from "../actions/userAction";
import {
  Card, Avatar,
  Empty,
  Modal,
  Button,
  Popconfirm,
  Icon,
  Form,
  Radio,
  Spin,
  message,
  Dropdown,
  Drawer,
  Tooltip,
  Menu,
} from "antd";
import { Link } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import { connect } from "react-redux";
import _, { truncate } from "lodash";
import moment from "moment";
import ReadMoreAndLess from "react-read-more-less";
import request from "superagent";
import { db } from "../firebase";
import Shimmer from "./shimmer";
import NoFeedCard from "./nofeedcard";
import Noprofileimg from '../assests/img/d-avatar.jpg'
import Linkify from 'react-linkify';
import { BASEURL } from '../actionTypes'
import InfinitScroll from 'react-infinite-scroll-component'
import { CopyToClipboard } from 'react-copy-to-clipboard';

// const { TabPane } = Tabs;

class FeedsLog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      citylist: "",
      userlist: "",
      personallogs: "",
      portfoliologs: "",
      visible: false,
      visible1: false,
      activereactions: "",
      activeimgset: "",
      slideIndex: 1,
      allposts: "",
      loading: false,
      hasMore: true,
      author: "",
      id: "",
      posttype: "",
      visibleDrawer: false,
      active: false,
      feeds: [],
      personalfeed: "",
      currentSlide: 0,
      lastrecord: 0,
      limitrequest: 0,
      arraylength: true,
      urlvalue: window.location.origin + "/logpost/",
      loginuser: this.props.user.uid,
      copied: false
    };
    this.getfeedLog = this.getfeedLog.bind(this)
  }

  isBottom(el) {
    return el.getBoundingClientRect().bottom <= window.innerHeight;
  }

  // componentDidMount() {
  //   document.addEventListener("scroll", this.trackScrolling);
  //   // console.log(this.props.user.uid);
  // }

  // componentWillUnmount() {
  //   document.removeEventListener("scroll", this.trackScrolling);
  // }


  getfeedLog() {

    var dataobj = {
      uid: this.props.user.uid,
      limitperrequest: this.state.limitrequest,
      lastrecord: this.state.lastrecord
    };
    var existingfeeds = this.state.feeds;
    // console.log("Query", dataobj);
    request
      //.post(BASEURL + "/getinfobyuid")
      //.post(BASEURL + "/getinfobyuidnew")
      .post(BASEURL + "/getfeedinfo")
      .send(dataobj)
      .then((res) => {
        // console.log(res);
        if (res.body.ReturnCode === 200) {
          // console.log(res.body.Data)
          var personalfeeds = _.filter(res.body.Data, { posttype: 'personal' });
          // console.log(personalfeeds)
          if (res.body.Data.length === 0) {
            this.setState({
              hasMore: false,
              arraylength: false
            })
          }
          else {
            this.setState({
              feeds: _.concat(existingfeeds, personalfeeds),
              lastrecord: personalfeeds.length > 0 ? (personalfeeds[personalfeeds.length - 1].created_timestamp) : 0,
              hasMore: true,
              arraylength: false
            });
          }
        } else {
          this.setState({
            feeds: personalfeeds,
            loading: false,
            arraylength: false,
          });
        }
      })
      .catch((err) => { });
  }

  // trackScrolling = () => {
  //   const wrappedElement = document.getElementById("header");
  //   if (this.isBottom(wrappedElement)) {
  //     console.log("header bottom reached");
  //     document.removeEventListener("scroll", this.trackScrolling);
  //   }
  // };

  componentWillMount() {
    // var uid = this.props.user.uid;

    this.props.getFeedsLimit().then(res => {
      // console.log(res.feedperRquest);
      this.setState({
        limitrequest: res.feedperRquest,
        loading: true
      })
      this.getfeedLog(this.props.user.uid, this.state.limitrequest)
      this.setState({
        hasMore: false
      })
    });

  }

  showModal = (images, index) => {
    this.setState({
      visible: true,
      activeimgset: images,
      currentSlide: index
    });
  };

  showDrawer = (postid, authorid, uid, type) => {
    console.log(postid, authorid, uid, type)
    this.setState({
      visibleDrawer: true,
      author: authorid,
      id: postid,
      user: uid,
      posttype: type,
    });
  };


  onClose = () => {
    this.setState({
      visibleDrawer: false,
    })
  }

  onChange(a, b, c) {
    console.log(a, b, c);
  }

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
    setTimeout(
      function () {
        this.setState({
          activeimgset: "",
        });
      }.bind(this),
      150
    );
  };

  handlereactionCancel = (e) => {
    console.log(e);
    this.setState({
      visible1: false,
    });
    setTimeout(
      function () {
        this.setState({
          activereactions: "",
        });
      }.bind(this),
      150
    );
  };

  confirmdelete(Id, value) {
    this.deleteLogImage(Id, value)
    // message.success('Log deleted');
  }

  cancel(e) {
    console.log(e);
    // message.error('Click on No');
  }

  deleteLogImage = (Id, value) => {
    var docRef = db.collection("Personal_Logs").doc(Id);
    docRef
      .get()
      .then(function (doc) {
        if (doc.exists) {
          // console.log(doc.data().upload_logs_Image);
          var ListImage = doc.data().upload_logs_Image;
          ListImage.splice(value, 1);
          message.success("File deleted successfully");
          docRef
            .update({
              upload_logs_Image: ListImage
            })
            .then(function () {
              console.log("Document successfully updated!");
              setTimeout(function () {
                window.location.reload(false);
              }, 1000);
            })
            .catch(function (error) {
              console.error("Error updating document: ", error);
            });
        } else {
          console.log("No such document!");
        }
      })

      .catch(function (error) {
        console.log("Error getting document:", error);
      });
  };

  refreshFeeds = () => {
    setTimeout(function () {
      window.location.reload(false);
    }, 200);
  }

  confirmdeleteLogs(Id, value) {
    this.deleteLogs(Id, value);
  }

  deleteLogs = (Id, value) => {
    var target = this.state.feeds;

    var docList = db.collection("Personal_Logs").doc(Id);
    docList
      .get()
      .then((doc) => {
        if (doc.exists) {
          var ListLogs = doc.data().log_list;
          var character = doc.data().character_count;
          // console.log(ListLogs[value], ListLogs[value].description, ListLogs[value].description.length);
          var logscount = character - ListLogs[value].description.length;
          // console.log(logscount);
          ListLogs.splice(value, 1);
          console.log(ListLogs);
          message.success("This log has been deleted successfully");
          docList
            .update({
              log_list: ListLogs,
              character_count: logscount
            })
            .then(() => {
              const elementsIndex = target.findIndex((element) => element.Id === Id);

              let newArray = [...this.state.feeds];
              newArray[elementsIndex] = {
                ...newArray[elementsIndex],
                log_list: ListLogs,
                character_count: logscount
              };
              console.log(newArray);
              this.setState({
                feeds: newArray,
              });
              console.log("Document successfully updated!");

            })
            .catch((error) => {
              console.error("Error updating document: ", error);
            });
        } else {
          // console.log("No such document!");
        }
      })

      .catch((error) => {
        console.log("Error getting document:", error);
      });
  };

  deletepersonallog = (id, type) => {
    this.setState({
      posttype: type
    })
    var newArray = [...this.state.feeds];
    db.collection("Personal_Logs")
      .doc(id)
      // console.log(Id)
      .delete()
      .then(() => {
        // console.log("Document successfully deleted!");
        message.error("Log deleted successfully")
        const idToRemove = id;

        _.remove(newArray, ['Id', idToRemove])

        console.log(newArray);
        this.setState({
          feeds: newArray,
        });
      })
      .catch(function (error) {
        console.error("Error removing document: ", error);
      });
  }

  confirmLog = (id, type) => {
    this.deletepersonallog(id, type);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.feeds.length !== this.state.feeds.length) {
      this.setState({
        feeds: this.state.feeds
      })
    }
  }

  handleSubmit = async (e) => {
    e.preventDefault();

    var post = this.state.id;
    var reportedid = this.state.author;
    var reporterid = this.props.user.uid;
    var typepost = this.state.posttype;
    var target = this.state.feeds;

    await this.props.form.validateFields((err, values) => {
      if (err && post === "", reportedid === "" && reporterid === "" && typepost === "") {
        // console.log('Received values of form: ', values, this.state.id, this.state.author, this.props.user.uid, this.state.posttype);
        message.error("Please ignore if you are not reporting this post")
        this.setState({
          visibleDrawer: false
        })
      }
      else {
        this.props.reportPost(values, this.state.id, this.state.author, this.props.user.uid, this.state.posttype)
          .then((res) => {
            console.log(res);
            this.setState({
              visibleDrawer: false
            })
            message.success("Thanks for your feedback")
            let newArray = [...this.state.feeds];
            _.remove(newArray, ['Id', this.state.id])

            console.log(newArray);
            this.setState({
              feeds: newArray,
            });
          })
          .catch((err) => {
            console.log(err)
          })
      }
    });
  };

  renderUserInfo(username, userimg, author, created_timestamp) {
    var timestamp = new Date(created_timestamp * 1000);
    return (
      <div className="postuserinfo">
        <span className="userphoto">
          {userimg === "" ? (
            <Avatar
              style={{ color: "#f56a00", backgroundColor: "#fde3cf" }}
              size={46} src={Noprofileimg}
            >
              {/* {username.charAt(0)} */}
            </Avatar>
          ) : (
            <Avatar src={userimg} size={46} />
          )}
        </span>

        <span
          className="userinfo"
          style={{
            marginLeft: "10px",
            fontWeight: "600",
            fontSize: "14px",
          }}
        >
          <Link
            to={author === this.props.user.uid ?
              ("/profile")
              :
              ("/profile/" + author)}
            onClick={() => this.refreshFeeds()}
            style={{ color: '#333' }}
          >
            {username}
          </Link><small
            style={{ color: '#777', marginLeft: '5px' }}>created a new log</small>
          <br />
          <span className="memcity">
            {moment(timestamp).startOf('minutes').fromNow()}
          </span>
        </span>
      </div>
    );
  }

  onCopy = () => {
    this.setState({
      copied: true,
    });
    message.success("Copied shared link")
  };

  renderMenu(Id, type) {

    const iconFont = {
      fontSize: '14px'
    }

    return (
      <ul className="feedpost-drop">
        <li>
          <Popconfirm
            title={"Do you want to delete this Log?"}
            icon={
              <Icon type="question-circle-o" style={{ color: 'red' }} />}
            onConfirm={(() => this.confirmLog(Id, type))}
            onCancel={() => this.cancel()}
            okText="Yes"
            cancelText="No">
            <span style={{ margin: 0 }}>
              <i className="fas fa-trash" style={iconFont}></i> Delete this post</span>
          </Popconfirm>
        </li>
        <li>
          <CopyToClipboard onCopy={this.onCopy}
            text={this.state.urlvalue + Id}>
            <span><i className="fas fa-copy" style={iconFont}></i> Copy link</span>
          </CopyToClipboard>
        </li>
      </ul>
    )
  }

  renderDrawerMenu(Id, authorid, uid, type) {

    const iconFont = {
      fontSize: '14px'
    }

    return (
      <ul className="feedpost-drop">
        <li>
          <span onClick={() => this.showDrawer(Id, authorid, uid, type)}>
            <i className="fas fa-flag"
              style={iconFont}></i> Report Post</span>
        </li>
        <li>
          <CopyToClipboard onCopy={this.onCopy}
            text={this.state.urlvalue + Id}>
            <span><i className="fas fa-copy" style={iconFont}></i> Copy link</span>
          </CopyToClipboard>
        </li>
      </ul>
    )
  }

  renderPersonalLogsList() {

    const componentDecorator = (href, text, key) => (
      <a href={href} key={key} target="_blank" rel="noopener noreferrer">
        {text}
      </a>
    );
    return (
      <InfinitScroll
        dataLength={this.state.feeds.length}
        next={this.getfeedLog}
        hasMore={this.state.hasMore === true ? true : false}
        loader={<div className="text-center"><Spin tip="Loading..." /></div>}
        endMessage={<p className="text-center">All Caught Up</p>}
        initialScrollY="600px"
      >
        <div>
          {this.state.feeds.map((logs, index) => (
            <div className="postcard" style={{ marginBottom: "10px" }} key={index}>
              <Card
                className="feedscard"
                title={this.renderUserInfo(
                  logs.username,
                  logs.userimg,
                  logs.author,
                  logs.created_timestamp
                )}
                extra={logs.author === this.state.loginuser ? (
                  <div style={{ display: 'inline-flex' }}>
                    <Dropdown trigger={["click"]} placement="bottomRight"
                      overlay={this.renderMenu(logs.Id, logs.posttype)}
                      style={{ marginLeft: '10px' }}>
                      <a className="ant-dropdown-link" href="#"
                        onClick={e => e.preventDefault()}>
                        <Icon type="more" style={{ fontSize: '20px', color: '#333' }} />
                      </a>
                    </Dropdown>
                  </div>
                ) : (
                  <div style={{ display: 'inline-flex' }}>
                    <Dropdown trigger={["click"]}
                      overlay={this.renderDrawerMenu(logs.Id, logs.author, this.props.user.uid, logs.posttype)}
                      style={{ marginLeft: '10px' }} >
                      <a className="ant-dropdown-link" href="#"
                        onClick={e => e.preventDefault()}>
                        <Icon type="more" style={{ fontSize: '20px', color: '#333' }} />
                      </a>
                    </Dropdown>
                  </div>
                )}
                style={{
                  width: "100%",
                  border: "1px solid #eee",
                  borderRadius: "4px",
                  padding: 0,
                }}
              >
                <div style={logs.log_list.length === 0 ? { padding: '0' } : { padding: '15px' }}>
                  {_.map(logs.log_list, (log, index) => {
                    return (
                      <div className="readdiv" key={index}
                        style={{
                          wordBreak: 'break-all',
                          whiteSpace: 'pre-line',
                          marginBottom: '10px',
                          padding: "8px 10px",
                          border: "1px solid #f9f9f9",
                          display: "block",
                          position: "relative",
                          borderRadius: "2px"
                        }}>
                        <Linkify componentDecorator={componentDecorator}>
                          <ReadMoreAndLess
                            ref={this.ReadMore}
                            className="read-more-content"
                            charLimit={300}
                            readMoreText="Read more"
                            readLessText="Read less"
                          >
                            {log.description}
                          </ReadMoreAndLess>
                        </Linkify>
                        {logs.posttype === "personal" && logs.author === this.props.user.uid ? (
                          <div className="breakdes">
                            <span className="time">
                              {moment.unix(log.text_created / 1000).format("LT")}
                            </span>
                            <Popconfirm
                              title="Do you want to delete this log?"
                              icon={
                                <Icon type="question-circle-o" style={{ color: 'red' }} />}
                              onConfirm={() => this.confirmdeleteLogs(logs.Id, index)}
                              onCancel={() => this.cancel()}
                              okText="Yes"
                              cancelText="No">
                              <span style={{ marginLeft: '10px' }}>
                                <Icon type="delete"
                                  style={{ color: '#1c41ce', cursor: 'pointer' }} />
                              </span>
                            </Popconfirm>
                          </div>
                        ) : (
                          <div>
                            {logs.posttype === "personal" && logs.author != this.props.user.uid ? (
                              <div className="breakdes">
                                <span className="time">
                                  {moment.unix(log.text_created / 1000).format("LT")}
                                </span>
                              </div>
                            ) : (
                              null
                            )}
                          </div>
                        )}
                      </div>
                    )
                  })}
                </div>
                <div className="row text-center">
                  {_.map(logs.upload_logs_image, (logImage, index) => {
                    return (
                      <div className="col-md-4 col-xs-12"
                        style={{ padding: "15px" }} key={index}>
                        <div className="imagecontainer">
                          {logs.posttype === "personal" && logs.author === this.props.user.uid ? (
                            <div className="imagecontainer">
                              <div className="button">
                                <Popconfirm
                                  title="Do you want to delete this Log image?"
                                  icon={
                                    <Icon type="question-circle-o" style={{ color: 'red' }} />}
                                  onConfirm={() => this.confirmdelete(logs.Id, index)}
                                  onCancel={() => this.cancel()}
                                  okText="Yes"
                                  cancelText="No">
                                  <Button
                                    type="default"
                                    block
                                    className="delete userfollowing"
                                  >
                                    <Icon type="delete" />
                                  </Button>
                                </Popconfirm>
                              </div>
                            </div>
                          ) : (
                            <div className="imagecontainer">
                              <div className="button">
                                <Popconfirm
                                  title="You can't delete other user's log image"
                                  // onConfirm={() => this.confirmdelete(logs.Id, index)}
                                  // onCancel={() => this.cancel()}
                                  okText="ok"
                                  cancelText="cancel"
                                >
                                  <Button
                                    type="default"
                                    block
                                    className="delete userfollowing"
                                  >
                                    <Icon type="delete" />
                                  </Button>
                                </Popconfirm>
                              </div>
                            </div>
                          )}
                          <img src={logImage.image}
                            onClick={() => this.showModal(logs.upload_logs_image, index)}
                            className="imgbanner" alt="" />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </Card>
            </div>
          ))}
        </div>
      </InfinitScroll>
    )
  }

  renderModalimages() {
    return (
      <Carousel
        selectedItem={this.state.currentSlide}
        // onChange={this.updateCurrentSlide}
        // showArrows={false}
        renderArrowPrev={(onClickHandler, hasPrev, label) =>
          hasPrev && (
            <Button type="primary" onClick={onClickHandler} title={label}
              className="arrowstyles"
              style={{ left: 0 }}>
              <Icon type="left" />
            </Button>
          )
        }
        renderArrowNext={(onClickHandler, hasNext, label) =>
          hasNext && (
            <Button type="primary" onClick={onClickHandler} title={label}
              className="arrowstyles"
              style={{ right: 0 }}>
              <Icon type="right" />
            </Button>
          )
        }>
        {_.map(this.state.activeimgset, (logImage, index) => {
          return (
            <div className="carouselimg" key={index}>
              <div className="carouselimginner">
                <img src={logImage.image} className="imgbanner img1" alt="" />
              </div>
            </div>
          );
        })}
      </Carousel>
    );
  }

  renderDrawer() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        <div className="row">
          <div className="col-md-12">
            <p>What you are reporting will be completely anonymous . If you feel someone is in immediate danger
              please follow up it with the local emergency services.</p>

            <Form onSubmit={this.handleSubmit}>
              <Form.Item label="Report Type">
                {getFieldDecorator('report', {
                  rules: [
                    {
                      required: true,
                      message: 'Choose the reason to report this post'
                    }],
                })
                  (
                    <Radio.Group>
                      <Radio value="Its suspicious or spam">Its suspicious or spam</Radio>
                      <Radio value="Its abusive or harmful">Its abusive or harmful</Radio>
                      <Radio value="Its expresses intention of self harm or suicide">Its expresses intention of
                        self harm or suicide</Radio>
                      <Radio value="Its appropriate">Its appropriate</Radio>
                    </Radio.Group>
                  )}
              </Form.Item>
              <Button type="primary"
                htmlType="submit">Report</Button>
            </Form>
          </div>
        </div>
      </div>
    )
  }

  render() {
    // console.log(this.state.allposts);
    const { feeds, arraylength } = this.state;
    return (
      <div>
        <Drawer
          title="Tell us why you are reporting this post/log?"
          placement="right"
          closable={false}
          width={350}
          onClose={this.onClose}
          visible={this.state.visibleDrawer}
        >
          {this.renderDrawer()}
        </Drawer>
        {/* Image preview modal */}
        <Modal
          className="carouselmodal"
          title=""
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          destroyOnClose={true}
          footer={null}
          centered={true}
          width="70%"
        >
          {this.renderModalimages()}
        </Modal>

        <div className="alllogs" id="header">
          {feeds.length === 0 && arraylength === true ? (
            <div>
              <Shimmer count={2} />
            </div>
          ) : (
            <div>
              {feeds.length === 0 ? (
                <NoFeedCard />
              ) : (
                <div>
                  {this.renderPersonalLogsList()}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

const WrappedFeedLog = Form.create({ name: "register" })(FeedsLog);

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
    personallogs: state.personallogs,
    portfoliologs: state.portfoliologs,
  };
}

export default connect(mapStateToProps, {
  getallLogs,
  getallUsers,
  cityList,
  getallportfolioLogs,
  postActions,
  reportPost,
  getFeedsLimit
})(WrappedFeedLog);
