import React, { Component } from "react";
import { getProfile } from "../actions/userAction";
import { Card, Divider, Modal } from "antd";
import { connect } from "react-redux";
import { db } from "../firebase";
import { Link } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import moment from "moment";
import Noprofileimg from '../assests/img/d-avatar.jpg'
import {
    getPeopleCount,
    getPostCount
} from "../actions/livingAction";
import Linkify from 'react-linkify';


class ProfileWidget extends Component {
    constructor(props) {
        super(props);
        this.state = {
            First_Name: "",
            Last_Name: "",
            bio: "",
            Full_Name: "",
            Date_of_Birth: "",
            getfollowers: 0,
            getfollowing: 0,
            photoURL: "",
            portfoliologs: 0,
            personallogs: 0,
            visible: false,
        };
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    handleCancel = e => {
        // console.log(e);
        this.setState({
            visible: false,
        })
    }

    componentWillMount() {

        var uid = this.props.user.uid;

        this.props.getPostCount(uid).then(res => {
            // console.log(res);
            this.setState({
                portfoliologs: res.Portfolio,
                personallogs: res.Personal
            });
        }).catch(res => {
            this.setState({
                portfoliologs: 0,
                personallogs: 0
            });
        });


        this.props.getPeopleCount(uid).then(res => {
            // console.log(res)
            this.setState({
                getfollowing: res.Following,
                getfollowers: res.Follower,
            })
        }).catch(res => {
            this.setState({
                getfollowers: 0,
                getfollowing: 0
            })
        })
    }

    componentDidMount() {
        var uid = this.props.user.uid;

        var userRef = db.collection("User_Details").doc(uid);
        userRef
            .get()
            .then(doc => {
                if (doc.exists) {
                    var Full_Name = doc.data().First_Name + " " + doc.data().Last_Name;
                    // console.log(doc.data().photoURL);
                    this.setState({
                        First_Name: doc.data().First_Name,
                        Last_Name: doc.data().Last_Name,
                        Full_Name: Full_Name,
                        Date_of_Birth: doc.data().Date_of_Birth,
                        photoURL: doc.data().photoURL,
                        bio: doc.data().Biography
                    });
                } else {
                    console.log("No such document!");
                }
            })
            .catch(err => {
                console.log("Error getting document", err);
            });

    }

    renderModalimages() {
        return (
            <div>
                <Carousel
                    showIndicators={false}>
                    <div className="carouselimg">
                        <div className="carouselimginner">
                            <img src={this.state.photoURL === undefined || this.state.photoURL === "" ?
                                Noprofileimg : this.state.photoURL}
                                className="imgbanner img1" alt="" />
                        </div>
                    </div>
                </Carousel>

            </div >
        );
    }

    abbreviateNumber(value) {
        var newValue = value;
        if (value >= 10000) {
            var suffixes = ["", "k", "M", "B", "T"];
            var suffixNum = Math.floor(("" + value).length / 4);
            var shortValue = '';
            for (var precision = 3; precision >= 2; precision--) {
                shortValue = parseFloat((suffixNum !== 0 ? (value / Math.pow(1000, suffixNum)) : value).toPrecision(precision));
                var dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g, '');
                if (dotLessShortValue.length <= 3) { break; }
            }
            if (shortValue % 2 !== 0) shortValue = shortValue.toFixed(2);
            newValue = shortValue + suffixes[suffixNum];
        }
        // console.log(newValue)
        return newValue;
    }


    render() {

        const componentDecorator = (href, text, key) => (
            <a href={href} key={key} target="_blank" rel="noopener noreferrer">
                {text}
            </a>
        );

        return (
            <div>
                <Modal
                    className="carouselmodal"
                    title=""
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={null}
                    centered={true}
                    width="80%"
                >
                    {this.renderModalimages()}
                </Modal>
                <Card className="portfolioprocard text-center">
                    <div>
                        {this.state.photoURL === undefined || this.state.photoURL === "" ? (
                            <img
                                className="profileimg"
                                size={100} src={Noprofileimg}
                                alt="profile"
                                onClick={() => this.showModal()}
                            />
                        ) : (
                            <div style={{ marginBottom: '15px' }}>
                                <img
                                    className="profileimg"
                                    src={this.state.photoURL}
                                    alt="User"
                                    onClick={() => this.showModal()}
                                />

                            </div>
                        )}
                    </div>
                    <div className="topdetail">
                        <h4 className="profilewidget">
                            <Link to="/userprofile" style={{ color: '#333' }}>
                                <b> {this.state.Full_Name}</b>
                            </Link>
                            <Divider type="vertical" style={{ color: '#ccc' }} />
                            <span style={{ fontSize: "16px", color: '#333' }}>
                                {moment.unix(this.state.Date_of_Birth).format("YYYY")}
                            </span>
                        </h4>
                        <Linkify componentDecorator={componentDecorator}>
                            <h6 className="m-b-20" style={{ fontSize: '14px' }}>
                                {this.state.bio}</h6>
                        </Linkify>
                        <div className="row text-center">
                            <div className="col-xs-3">
                                <h6>Posts</h6>
                                <span className="badge"
                                    style={{ backgroundColor: '#333' }}>
                                    {this.abbreviateNumber(this.state.portfoliologs)}
                                </span>
                            </div>
                            <div className="col-xs-3">
                                <h6>Logs</h6>
                                <span className="badge"
                                    style={{ backgroundColor: '#333' }}>
                                    {this.abbreviateNumber(this.state.personallogs)}
                                </span>
                            </div>
                            <div className="col-xs-3">
                                <h6>Following</h6>
                                <span className="badge"
                                    style={{ backgroundColor: '#333' }}>
                                    {this.abbreviateNumber(this.state.getfollowing)}
                                </span>
                            </div>
                            <div className="col-xs-3">
                                <h6>Followers</h6>
                                <span className="badge"
                                    style={{ backgroundColor: '#333' }}>

                                    {this.abbreviateNumber(this.state.getfollowers)}
                                </span>
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps, {
    getProfile,
    getPostCount,
    getPeopleCount,
})(ProfileWidget);
