import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'antd/dist/antd.css';
import 'bootstrap/dist/css/bootstrap.css';
// import 'bootstrap/dist/js/bootstrap.js';
// import 'jquery/dist/jquery.min.js';
import 'react-phone-input-2/lib/style.css'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Home from './home.js';
import Login from './component/login';
import Signup from './component/signup';
import Forgetpassword from './component/forgetpassword';
import LoadingComponent from "./component/LoadingComponent";
import AuthenticatedComponent from "./component/AuthenticatedComponent";
import Register from "./component/registerAccount";
import Navigation from "./common/navigation";
import EditProfile from "./component/editProfile";
import Logs from "./component/logs";
import Feeds from "./component/feeds";
import NetworkView from "./component/network";
import PortFolio from "./component/profile";
import UserPortFolio from "./component/userProfile";
import HelpCenter from './component/helpCenter'
import Notifications from "./component/notifications"
import FAQ from './component/faq';
import UserProfiles from "./component/user";
import * as serviceWorker from './serviceWorker';
import { BrowserRouter, Route, Switch, Router } from "react-router-dom";
import history from './history';
import rootReducer from "./reducers";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import PrivacyPolicy from './component/privacy-policy';
import TermsofService from './component/terms-and-conditions';
import Chat from "./component/chat";
import FooterBottom from "./common/footer"
import ProfileChat from './component/profilechat'
import Posts from './component/post'
import LogPosts from './component/logpost'
import ActivateUser from './component/activateUser';
import Calculator from './component/test';


const devTools = process.env.NODE_ENV === 'production' ? applyMiddleware(thunk)
    : composeWithDevTools(applyMiddleware(thunk));

// create redux store -> reducers -> 'actions - actionType' | applyMiddleware()
const store = createStore(
    rootReducer,
    devTools
);

// Hide all console in production

// console.log(process.env.NODE_ENV)
if (process.env.NODE_ENV === 'production') {
    console.log = function () { };
}

// const id = this.props.location.state.user.Id; 
// console.log(id) 

ReactDOM.render(
    <Provider store={store}>
        <Router history={history}>
            <BrowserRouter>
                <LoadingComponent>
                    <div>
                        <Switch>
                            <Route path="/" component={Home} exact={true} key="/" />
                            <Route path="/login" component={Login} exact={true} key="/login" />
                            <Route path="/activateUser" component={ActivateUser} exact={true}
                                key="/activateUser" />
                            <Route path="/forgetpassword" component={Forgetpassword} exact={true}
                                key="/forgetpassword" />
                            <Route path="/signup" component={Signup} exact={true} key="/signup" />
                            <Route path="/privacy-policy" component={PrivacyPolicy} exact={true}
                                key="/privacy-policy" />
                            <Route path="/faq" component={FAQ} exact={true}
                                key="/faq" />
                            <Route path="/terms-and-conditions" component={TermsofService} exact={true}
                                Key="/terms-and-conditions" />
                            <Route path="/helpCenter" component={HelpCenter} exact={true}
                                key="/helpCenter" />
                            <Route path="/post/:id" component={Posts} exact={true}
                                key="/post/:id" />
                            <Route path="/logpost/:id" component={LogPosts} exact={true}
                                key="/logpost/:id" />
                            <AuthenticatedComponent>
                                <Route path="/registerAccount" component={Register} exact={true}
                                    key="/registerAccount" />
                                <Navigation />
                                <Route path="/feeds" component={Feeds} exact={true}
                                    key="/feeds" />
                                <Route path="/users" component={UserProfiles} exact={true}
                                    key="/users" />
                                <Route path="/logs" component={Logs} exact={true}
                                    key="/logs" />
                                <Route path="/profile/:id" component={PortFolio} exact={true}
                                    key="/profile/:id" />
                                <Route path="/profile" component={UserPortFolio} exact={true}
                                    key="/profile" />
                                <Route path="/notifications" component={Notifications} exact={true}
                                    key="/notifications" />
                                <Route path="/editprofile" component={EditProfile} exact={true}
                                    key="/editprofile" />
                                <Route path="/network" component={NetworkView} exact={true}
                                    key="/network" />
                                <Route path="/chat" component={Chat} exact={true}
                                    key="/chat" />
                                <Route path="/profilechat" component={ProfileChat} exact={true}
                                    key="/profilechat" />
                                <Route path="/test" component={Calculator} exact={true}
                                    key="/test" />
                                <FooterBottom />
                            </AuthenticatedComponent>
                        </Switch>
                    </div>
                </LoadingComponent>
            </BrowserRouter>
        </Router>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
