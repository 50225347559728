import React, { Component } from "react";
import {
  Button,
  Avatar,
  Form,
  Icon,
  Input,
  Upload,
  message,
  Select,
} from "antd";
import { connect } from "react-redux";
import {
  postPortfolios,
  checkImageToday,
  postpersonalLogs,
} from "../actions/livingAction";
// import ImgCrop from "antd-img-crop";
// import moment from "moment";
import Noprofileimg from '../assests/img/d-avatar.jpg'
import { db } from "../firebase";

const { TextArea } = Input;
const { Option } = Select;

class postForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      visible: false,
      fileList: "",
      max_char: 0,
      chars_left: 0,
      image_count: 0,
      caption: "",
      posttype: "personal",
      First_Name: "",
      photoURL: "",
      fullName: ""
    };

    this.handleChange = this.handleChange.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange = (e) => {
    // console.log(e.target.value, this.state.max_char);
    const charCount = e.target.value.length;
    const maxChar = this.state.max_char;
    const charLength = maxChar - charCount;
    this.setState({
      caption: e.target.value,
      //   change_char: this.state.max_char - e.target.value.length
      chars_left: charLength < 0 ? 0 : charLength,
    });
    // console.log(charLength)
  }

  onChange = (value) => {
    // console.log(`selected` + value);
    this.setState({
      posttype: value,
    });

  };

  componentDidMount() {
    var uid = this.props.user.uid;
    var userRef = db.collection("User_Details").doc(uid);
    userRef
      .get()
      .then(doc => {
        if (doc.exists) {
          // console.log(doc.data().First_Name);
          this.setState({
            photoURL: doc.data().photoURL,
            First_Name: doc.data().First_Name,
            fullName: doc.data().Username
          });
        } else {
          // console.log("No such document!");
        }
      })
      .catch(err => {
        // console.log("Error getting document", err);
      });
  }


  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        // console.log(
        //   "Received values of form: ",
        //   values,
        //   this.props.user.uid,
        //   this.state.fileList,
        //   this.state.caption,
        //   this.state.fullName,
        //   this.state.photoURL
        // );
        this.setState({
          loading: true,
        });
        if (this.state.posttype === "portfolio") {
          if (this.state.fileList === "") {
            message.warning("Upload atleast one image")
            this.setState({
              loading: false
            })
          } else {
            // console.log(
            //   "Received values of form: ",
            //   values,
            //   this.props.user.uid,
            //   this.state.fileList,
            //   this.state.caption
            // );
            this.props
              .postPortfolios(values, this.props.user.uid, this.state.fileList, this.state.fullName, this.state.photoURL)
              .then((res) => {
                // console.log("res", res);
                message.success("Posted Portfolio successfully");
                window.location.reload(false);
                this.props.form.resetFields();
                this.props.form.setFieldsValue({
                  UploadImage: "",
                });
                this.setState({
                  visible: false,
                  loading: false,
                  fileList: "",
                });
              })
            // .catch(function (error) {
            //   // message.error("error occurred while sending data");
            // });
          }
        }
        else {
          // console.log(
          //   "Received values of form: ",
          //   values,
          //   this.props.user.uid,
          //   this.state.fileList,
          //   this.state.caption,
          //   this.state.fullName,
          //   this.state.photoURL
          // );
          var imagelength = this.state.fileList.length;
          var dailylimit = this.state.image_count;
          if (dailylimit >= imagelength) {
            this.props
              .postpersonalLogs(
                values,
                this.props.user.uid,
                this.state.fileList,
                this.state.fullName,
                this.state.photoURL
              )
              .then((res) => {
                // console.log("res", res);
                message.success("Posted personal logs successfully");
                window.location.reload(false);
                this.props.form.resetFields();
                this.props.form.setFieldsValue({
                  UploadImage: "",
                });
                this.setState({
                  visible: false,
                  loading: false,
                  fileList: "",
                });
              })
              .catch(function (error) {
                message.error("error occurred while sending data");
              });
          } else {
            message.warning("Diary images should not exceed more than 3!");
            this.setState({
              loading: false,
            })
          }
        }
      }
    });
  };

  componentWillMount() {
    this.checkimage();
  }

  checkimage() {
    // var uid = this.props.user.uid;
    this.props
      .checkImageToday(this.props.user.uid)
      .then((res) => {
        // console.log(res);
        this.setState({
          max_char: 3000 - res[0],
          chars_left: 3000 - res[0],
          image_count: 3 - res[1],
        });
        // console.log(this.state.max_char, this.state.image_count);
      })
      .catch((err) => {
        // message.warning(err);
      });
  }

  formclose = (boolean) => {
    this.setState({
      visible: boolean,
      loading: false,
      posttype: "portfolio",
    });
  }

  formopen = (boolean) => {
    this.setState({
      visible: boolean,
    });
  }

  onChangee = (newFileList) => {
    // newFileList.preventDefault()
    // console.log(newFileList);
    // this.setState({
    //   fileList: newFileList,
    //   images: newFileList,
    // });
  };

  normFile = (e) => {
    console.log("Upload event:", e);
    if (Array.isArray(e)) {
      return e;
    }
    // console.log(this.state.fileList);
    return e && e.fileList;
  };

  render() {
    const { max_char, fileList, image_count,
      visible, posttype, First_Name } = this.state;

    const propsthumb = {
      multiple: true,
      listType: "picture-card",
      accept: ".png,.jpg,.jpeg",
      showUploadList: { showPreviewIcon: false, showRemoveIcon: true },
      onRemove: (file) => {
        this.setState((state) => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList,
          };
        });
        // console.log(this.state.fileList);
      },
      beforeUpload: (file) => {
        this.setState((state) => ({
          fileList: [...state.fileList, file],
        }));
        return false;
      },
      fileList,
    };

    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        <div
          className={!visible ? "mainformbackdrop" : "mainformbackdrop show"}
          onClick={() => this.formclose(false)}
        >
        </div>
        <div
          className={!visible ? "mainformdiv" : "mainformdiv show"}
          onClick={() => this.formopen(true)}
        >
          <div className="mainformheader">
            <Form onSubmit={this.handleSubmit} className="login-form">
              <div className="formflex">
                {this.state.photoURL === "" ? (
                  <Avatar
                    src={Noprofileimg} size="small"
                  >
                    {/* {this.state.First_Name.charAt(0)} */}
                  </Avatar>
                ) : (
                  <Avatar src={this.state.photoURL} size="small" />
                )}
                {posttype === "portfolio" ? (
                  <Form.Item label="">
                    {getFieldDecorator("caption", {
                      rules: [
                        {
                          required: false,
                          message: "Please input your caption!",
                        },
                        {
                          max: 200,
                          message: "Post character should not exceed more than 200",
                        },
                      ],
                    })(
                      <TextArea
                        placeholder={"Hi" + " " + First_Name + "," + " " + "Whats going on..?"}
                        onChange={this.handleChange}
                        rows="4"
                        autoSize
                      />
                    )}
                  </Form.Item>
                ) : (
                  <Form.Item label="">
                    {getFieldDecorator("caption", {
                      rules: [
                        {
                          required: false,
                          message: "Please input your caption!",
                        },
                        {
                          max: posttype === "personal" ? max_char : null,
                          message:
                            "Caption must be minimum " +
                            max_char +
                            " characters. This exceeds your daily limit of 3000!",
                        },
                      ],
                    })(
                      <TextArea
                        placeholder={"Hi" + " " + First_Name + "," + " " + "Whats going on..?"}
                        onChange={this.handleChange}
                        rows="4"
                        autoSize
                        disabled={
                          posttype === "personal"
                            ? max_char === 0
                              ? true
                              : false
                            : false
                        }
                      />
                    )}
                  </Form.Item>
                )}

              </div>
              {/* <div className={!visible ? "hide" : "show"}>
                <div className="clearfix">
                  <ImgCrop grid aspect={16 / 9}>
                    <Upload
                      action={null}
                      name="logImage"
                      listType="picture-card"
                      accept=".png,.jpg"
                      multiple={true}
                      {...propsthumb}
                      tooltip={false}
                      onChange={this.onChangee}
                    >
                      <div>
                        <Icon type="picture" />
                      </div>
                    </Upload>
                  </ImgCrop>
                </div>
              </div> */}
              <Form.Item label="" className={!visible ? "hide" : "show"}>
                {getFieldDecorator("UploadImage", {
                  getValueFromEvent: this.normFile,
                })(
                  <div className="clearfix">
                    {/* <ImgCrop grid aspect={16 / 9}> */}
                    <Upload name="logImage" {...propsthumb}>
                      {posttype === "personal" ? (
                        fileList.length >= image_count ? null : (
                          <div>
                            <Icon type="plus" />
                          </div>
                        )
                      ) : (
                        <div>
                          <Icon type="plus" />
                        </div>
                      )}
                    </Upload>
                    {/* </ImgCrop> */}
                  </div>
                )}
              </Form.Item>

              <Form.Item
                className={!visible ? "row m-b-0 hide" : "row m-b-0 show"}
              >
                <div className="col-xs-8">
                  <Select
                    defaultValue={posttype}
                    style={{ width: 120 }}
                    onChange={this.onChange}
                  >
                    {posttype === "personal" || max_char <= 3000 && image_count <= 3 ? (
                      <Option value="personal">Diary</Option>
                    ) : (null)}
                    <Option value="portfolio">Post</Option>
                  </Select>
                  <div
                    className={
                      posttype === "personal"
                        ? "postformcount"
                        : "postformcount hide"
                    }
                  >
                    {max_char === 0 && image_count === 0 ? 
                    "Limit Exceed for the day" :
                      "Remaining Characters:" + "  " + 
                      this.state.chars_left}
                  </div>
                </div>
                <div className="text-right col-xs-4">
                  <Button
                    htmlType="submit"
                    type="primary"
                    loading={this.state.loading}
                    style={{ margin: "0" }}
                  >
                    Share
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return { user: state.user };
}

const WrappedpostPortfolio = Form.create({ name: "postForm" })(postForm);
export default connect(mapStateToProps, {
  postPortfolios,
  checkImageToday,
  postpersonalLogs,
})(WrappedpostPortfolio);
