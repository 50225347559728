import React, { Component } from "react";
import _ from "lodash";
import {
  getProfile,
  genderList,
  countryList,
  interestList,
  cityList,
  editProfile,
  removeprofileimage,
} from "../actions/userAction";
import PhoneInput from 'react-phone-input-2'
import {
  Card,
  Form,
  Icon,
  Input,
  Button,
  Upload,
  message,
  DatePicker,
  Avatar,
  Select,
} from "antd";
import { connect } from "react-redux";
// import YearPicker from "react-year-picker";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import moment from "moment";
// import {
//   // compose,
//   withProps,
//   withHandlers,
//   withStateHandlers,
//   lifecycle,
// } from "recompose";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import Geocode from "react-geocode";
Geocode.setApiKey("AIzaSyCcugEZd84OFFpaKZP1Eo0uCMmc0mA8bSk");

// const {  } = DatePicker;

const { Option } = Select;

// function onChange(date, dateString) {
//   console.log(date, dateString);
// }

function handleChange(value) {
  console.log(`selected ${value}`);
}

const { TextArea } = Input;

class EditProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userinfo: "",
      fileList1: [],
      Country_Code: "",
      iconLoading: false,
      userid: "",
      profileimg: "",
      latLng: {
        lat: "",
        lng: "",
      },
      address: "",
      isOpen: false,
      time: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }


  enterLoading = () => {
    this.setState({ loading: true });
  };

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }


  handlePhone = (value) => {
    // console.log(Country_Code, selectCountry)
    this.setState({
      Country_Code: value
    });
  }

  interestMaxLimit = async (rule, value, callback) => {
    if (value) {
      if (value.length > 10) {
        callback("should not select more than 10 interests");
      } else if (value.length <= 10) {
        callback();
      }
    }
    return;
  };

  handleChangee = (address) => {
    this.setState({ address });
  };

  getMyLocation = () => {
    const location =
      window.navigator && window.navigator.geolocation && navigator.geolocation;
    // navigator.geolocation.getCurrentPosition(this.showMap);
    // navigator.geolocation.getCurrentPosition(() => { }, (err) => { console.log(err) }, { timeout: Infinity })
    this.setState({
      loading: true,
    });
    if (location) {
      location.getCurrentPosition(
        (position) => {
          this.setState({
            currentLatLng: {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            },
          });
          Geocode.fromLatLng(
            position.coords.latitude,
            position.coords.longitude
          ).then(
            (response) => {
              var results = response.results[0];
              // console.log(response.results[0]);
              this.handleState(results);
            },
            (error) => {
              console.error(error);
            }
          );
          // console.log(position, position.coords.longitude);
        },
        (error) => {
          // this.setState({ latitude: 'err-latitude', longitude: 'err-longitude' })
          this.setState({
            loading: false,
          });
          message.warning(
            "Enable browser location permission to detect your current location! or Try searching your location manually!"
          );
          console.log(error);
        }
      );
    }
  };

  handleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => {
        // console.log(getLatLng(results[0]));
        getLatLng(results[0]).then((latLng) => {
          Geocode.fromLatLng(latLng.lat, latLng.lng).then(
            (response) => {
              const results = response.results[0];
              console.log(address, results, latLng);
              this.setState({
                address: address,
                latLng: latLng,
                // phone: phone,
              });
            },
            (error) => {
              console.error(error);
            }
          );
          // console.log("Success", latLng);
        });
        // console.log(address, results[0]);
      })
      .catch((error) => console.error("Error", error));
  };


  // handleSubmit = (e) => {
  //   this.setState({
  //     iconLoading: true,
  //   });

  //   e.preventDefault();
  //   var uid = this.props.user.uid;
  //   var address = this.state.address;
  //   var location = this.state.latLng;

  //   this.props.form.validateFieldsAndScroll((err, values) => {
  //     if (!err && address !== "") {
  //       NProgress.inc();
  //       this.setState({
  //         iconLoading: true,
  //       });
  //       console.log(values, uid, this.state.fileList, this.state.address, this.state.latLng);
  //       this.props
  //         .editProfile(values, uid, this.state.fileList, this.state.address, this.state.latLng)
  //         .then((res) => {
  //           this.setState({
  //             iconLoading: false,
  //             fileList: [],
  //             address: this.state.address,
  //             latlng: this.state.latLng,
  //           });
  //           this.props.form.resetFields();
  //           NProgress.done();
  //           message.success("Profile edited successfully!");
  //           //   this.props.history.push("/profile");
  //         })
  //         .catch(function (error) {
  //           message.error("error occurred while sending data");
  //           this.setState({
  //             iconLoading: false,
  //             loading: false
  //           });
  //         });
  //     }
  //     else {
  //       this.setState({
  //         iconLoading: false,
  //         loading: false
  //       });
  //       message.warning("Please input required fields to search!");
  //       this.setState({
  //         iconLoading: false,
  //       });
  //     }
  //   });
  // };


  removeproimg = () => {
    this.setState({
      profileimg: "",
    });
    this.props.removeprofileimage(this.props.user.uid).then((res) => {
      console.log(res);
    });
  };


  disabledDate = (current) => {
    // Can not select days before today and today
    return current && current > moment().subtract(13, "years");
  }

  componentWillMount() {
    NProgress.start();
    var uid = this.props.user.uid;
    this.props.getProfile(uid).then((res) => {
      console.log(res, uid);
      this.setState({
        userinfo: res,
        address: res.address,
        Country_Code: res.countryCode,
        userid: uid,
        latLng: {
          lat: res.Lat,
          lng: res.Lng,
        },
        profileimg: res.photoURL[0].url,
        fileList1: [],
      });
      console.log(this.state.year);
      NProgress.done();
    });


    this.props
      .genderList()
      .then((res) => {
        // console.log("res", res);
        this.setState({
          genderList: res,
        });
      })
      .catch(function (error) {
        message.error("error occurred while sending data");
      });


    this.props
      .interestList()
      .then((res) => {
        // console.log("res", res);
        this.setState({
          interestList: res,
        });
      })
      .catch(function (error) {
        message.error("error occurred while sending data");
      });
  }

  handleReset = () => {
    this.props.form.resetFields();
    this.setState({
      iconLoading: false,
      fileList1: [],
    });
  };


  handleSubmit = (e) => {
    e.preventDefault();
    var uid = this.state.userid;
    var address = this.state.address;
    var location = this.state.latLng;
    var countryPhone = this.state.Country_Code;

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err && address !== "" && location !== "" && countryPhone !== "") {
        NProgress.inc();
        this.setState({
          iconLoading: true,
        });
        // console.log(this.state.fileList1, values, uid, this.state.address, this.state.latLng, this.state.Country_Code);
        this.props
          .editProfile(values, this.state.fileList1, uid, this.state.address, this.state.latLng, this.state.Country_Code)
          .then((res) => {
            this.setState({
              iconLoading: false,
              fileList1: [],
              address: "",
              latlng: "",
              year: "",
              Country_Code: ""
            });
            this.props.form.resetFields();
            NProgress.done();
            message.success("Profile edited successfully!");
            this.props.history.push("/profile");
            // setTimeout(function () {
            //   window.location.reload(false);
            // }, 300);
          })
          .catch(function (error) {
            message.error("error occurred while sending data");
            this.setState({
              iconLoading: false,
              loading: false
            });
          });
      } else {
        this.setState({
          iconLoading: false,
          loading: false
        });
        message.warning("Please input all the required fields");
        this.setState({
          iconLoading: false,
        });
      }
    });
  };

  rendergenderList() {
    return _.map(this.state.genderList, (gender, key) => {
      return (
        <Option value={gender.genderId} key={key}>
          {gender.genderName}
        </Option>
      );
    });
  }


  renderinterestList() {
    return _.map(this.state.interestList, (interest, key) => {
      return (
        <Option value={interest.interestName}
          // label={interest.interestName}
          key={key}>
          <div>
            <Avatar size="small" src={interest.interestIcon}></Avatar>{" "}
            {interest.interestName}
          </div>

        </Option>
      );
    });
  }


  normFile = (e) => {
    console.log("Upload event:", e);
    if (Array.isArray(e)) {
      return e;
    }
    console.log(this.state.fileList1);
    return e && e.fileList;
  };

  render() {

    const { fileList1 } = this.state;

    const propsthumb = {
      multiple: false,
      listType: "picture-card",
      accept: ".png,.jpg",
      showUploadList: { showPreviewIcon: false, showRemoveIcon: true },
      onRemove: (file) => {
        this.setState((state) => {
          const index = state.fileList1.indexOf(file);
          const newFileList = state.fileList1.slice();
          newFileList.splice(index, 1);
          return {
            fileList1: newFileList,
          };
        });
        console.log(this.state.fileList1);
      },
      beforeUpload: (file) => {
        this.setState((state) => ({
          fileList1: [...state.fileList1, file],
        }));
        return false;
      },
      fileList1,
    };

    const { getFieldDecorator } = this.props.form;

    const config = {
      rules: [
        { type: "object", required: true, message: "Please select Date!" },
      ],
    };

    return (
      <div>
        <div className="container-fluid userbg1"></div>
        <div className="container-fluid topup">
          <div className="container">
            <div className="row">
              <p className="topregister">Edit your profile Information</p>
              {this.state.profileimg !== "" ? (
                <div className="profileimgage">
                  <img src={this.state.profileimg} alt="profile" />
                  <Button type="danger" onClick={() => this.removeproimg()}>
                    Remove
                  </Button>
                </div>
              ) : null}
              <Form onSubmit={this.handleSubmit} className="login-form">
                {this.state.profileimg === "" ? (
                  <Form.Item>
                    {getFieldDecorator("photoURL", {
                      // in
                      valuePropName: "fileList",
                      getValueFromEvent: this.normFile,
                    })(
                      <Upload name="photoURL" {...propsthumb}>
                        {fileList1.length >= 1 ? null : (
                          <div>
                            <Icon
                              type={this.state.loading ? "loading" : "plus"}
                            />
                            <div className="ant-upload-text">Upload</div>
                          </div>
                        )}
                      </Upload>
                    )}
                  </Form.Item>
                ) : null}
                <Card style={{ marginBottom: "50px" }}>
                  <div style={{ marginBottom: "30px" }}>
                    <h3 className="loginHead">Personal Information</h3>
                    <div className="headline1"></div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-sm-6 col-xs-12">
                      <Form.Item>
                        <label>
                          First Name<span className="error">*</span>:
                        </label>
                        {getFieldDecorator("firstname", {
                          initialValue: this.state.userinfo.First_Name,
                          rules: [
                            {
                              required: true,
                              message: "Please input your firstname!",
                            },
                          ],
                        })(<Input />)}
                      </Form.Item>
                    </div>

                    <div className="col-md-6 col-sm-6 col-xs-12">
                      <Form.Item>
                        <label>
                          Last Name<span className="error">*</span>:
                        </label>
                        {getFieldDecorator("lastname", {
                          initialValue: this.state.userinfo.Last_Name,
                          rules: [
                            {
                              required: true,
                              message: "Please input your lastname!",
                            },
                          ],
                        })(<Input />)}
                      </Form.Item>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-sm-12 col-xs-12">
                      <Form.Item>
                        <label>
                          Year of Birth<span className="error">*</span>:
                        </label>
                        {getFieldDecorator(
                          "dateofbirth",
                          {
                            initialValue: this.state.userinfo.Date_of_Birth,
                            rules: [
                              {
                                required: true,
                                message: "Please choose your year of date",
                              },
                            ],
                          },
                          config
                        )(
                          <DatePicker
                            id="dateofbirth"
                            disabledDate={this.disabledDate}
                            defaultPickerValue={moment().subtract(13, 'years').calendar()}
                            style={{ width: "100%" }}
                            open={this.state.isOpen}
                            mode="year"
                            placeholder="Select Year"
                            format="YYYY"
                            onOpenChange={(status) => {
                              if (status) {
                                this.setState({ isOpen: true });
                              } else {
                                this.setState({ isOpen: false });
                              }
                            }}
                            onPanelChange={(v) => {
                              console.log(v);
                              this.setState({ isOpen: false });
                              this.props.form.setFieldsValue({
                                dateofbirth: v,
                              });
                            }}
                          />
                        )}
                      </Form.Item>
                    </div>
                    <div className="col-md-6 col-sm-12 col-xs-12">
                      <div className="row">
                        <div className="col-md-5 col-sm-6 col-xs-12 countrydiv">
                          <label className="countrylabel"
                          >Country Code:<span className="error">*</span>:</label>
                          <PhoneInput
                            inputProps={{
                              name: 'phone',
                              required: true,
                              autoFocus: true
                            }}
                            enableSearch={true}
                            country={'in'}
                            initialValue={this.state.Country_Code}
                            onChange={this.handlePhone}
                          />
                        </div>
                        <div className="col-md-7 col-sm-6 col-xs-12">
                          <Form.Item>
                            <label>Mobile Number<span className="error">*</span>:</label>
                            {getFieldDecorator('mobile', {
                              initialValue: this.state.userinfo.Mobile_Number,
                              rules: [
                                {
                                  required: true,
                                  pattern: /^(\+{1}\d{2,3}\s?[(]{1}\d{1,3}[)]{1}\s?\d+|\+\d{2,3}\s{1}\d+|\d+){1}[\s|-]?\d+([\s|-]?\d+){1,2}$/,
                                  message: 'Please input your mobile number!'
                                },
                                {
                                  min: 10,
                                  message: 'Mobile number should contain 10 digits'
                                },

                              ],
                            })(<Input placeholder="Mobile Number" id="mobile"
                              maxLength={10} name="mobile" style={{ width: '100%' }} />)}
                            <span style={{ fontSize: '12px', color: "#1c41ce", lineHeight: '20px' }}>
                              (<b>NOTE:</b> Collected only for invite purpose, this data will not be
                                 shared to anyone)</span>
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-sm-6 col-xs-12">
                      <Form.Item>
                        <label>Location<span className="error">*</span>:</label>
                        <PlacesAutocomplete
                          value={this.state.address}
                          onChange={this.handleChangee}
                          onSelect={this.handleSelect}
                        >
                          {({
                            getInputProps,
                            suggestions,
                            getSuggestionItemProps,
                            loading,
                          }) => (
                            <div className="autocompletediv">
                              <input
                                {...getInputProps({
                                  placeholder: "Search Places ...",
                                  className: "location-search-input form-control",
                                })}
                              />
                              <div className="autocomplete-dropdown-container">
                                {loading && (
                                  <div className="autoloading">Loading...</div>
                                )}
                                {suggestions.map((suggestion) => {
                                  const className = suggestion.active
                                    ? "suggestion-item--active"
                                    : "suggestion-item";

                                  return (
                                    <div
                                      {...getSuggestionItemProps(suggestion, {
                                        className,
                                      })}
                                    >
                                      <span>{suggestion.description}</span>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                        </PlacesAutocomplete>
                        <span style={{ fontSize: '12px', color: "#1c41ce", lineHeight: '20px' }}>
                          (<b>NOTE:</b> Choose your area or locality from the address provided. It is collected for
                            networking purposes only)</span>
                      </Form.Item>
                    </div>
                    <div className="col-md-6 col-sm-6">
                      <Form.Item>
                        <label>
                          Gender<span className="error">*</span>:
                        </label>
                        {getFieldDecorator("gender", {
                          initialValue: this.state.userinfo.Ref_Gender,
                          rules: [
                            {
                              required: true,
                              message: "Please select your gender!",
                            },
                          ],
                        })(
                          <Select
                            placeholder="Select Gender"
                            style={{ width: "100%" }}
                            id="gender"
                            name="gender"
                            onChange={handleChange}
                          >
                            {this.rendergenderList()}
                          </Select>
                        )}
                      </Form.Item>
                    </div>
                    {/* <div className="col-md-6 col-sm-6 col-xs-12">
                      <Form.Item>
                        <label>
                          City<span className="error">*</span>:
                        </label>
                        {getFieldDecorator("city", {
                          initialValue: this.state.userinfo.Ref_City,
                          rules: [
                            {
                              required: true,
                              message: "Please input your city!",
                            },
                          ],
                        })(
                          <Select style={{ width: "100%" }}>
                            {this.rendercityList()}
                          </Select>
                        )}
                      </Form.Item>
                    </div> */}
                  </div>
                </Card>

                {/* <Card style={{ marginBottom: "50px" }}>
                  <div style={{ marginBottom: "30px" }}>
                    <h3 className="loginHead">Education</h3>
                    <div className="headline1"></div>
                  </div>
                  <div className="row">
                    <div className="col-md-4 col-xs-12">
                      <Form.Item>
                        <label>
                          School Name:
                        </label>
                        {getFieldDecorator("schoolname", {
                          initialValue: this.state.userinfo.schoolname,

                          rules: [
                            {
                              required: false,
                              message: "Please input your school name!",
                            },
                          ],
                        })(<Input />)}
                      </Form.Item>
                    </div>

                    <div className="col-md-4 col-xs-12">
                      <Form.Item>
                        <label>
                          From:
                        </label>{" "}
                        <br />
                        {getFieldDecorator(
                          "schoolfrom",
                          {
                            initialValue: this.state.userinfo.schoolfrom,
                            rules: [
                              {
                                required: false,
                                message: "Please choose your from date",
                              },
                            ],
                          },
                          config
                        )(<DatePicker
                          style={{ width: "100%" }}
                        />)}
                      </Form.Item>
                    </div>
                    <div className="col-md-4 col-xs-12">
                      <Form.Item>
                        <label>
                          To:
                        </label>{" "}
                        <br />
                        {getFieldDecorator(
                          "schoolto",
                          {
                            initialValue: this.state.userinfo.schoolto,
                            rules: [
                              {
                                required: false,
                                message: "Please choose your to date",
                              },
                            ],
                          },
                          config
                        )(<DatePicker
                          style={{ width: "100%" }} />)}
                      </Form.Item>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4 col-xs-12">
                      <Form.Item>
                        <label>
                          College Name:
                        </label>
                        {getFieldDecorator("collegename", {
                          initialValue: this.state.userinfo.collegename,
                          rules: [
                            {
                              required: false,
                              message: "Please input your college name!",
                            },
                          ],
                        })(<Input />)}
                      </Form.Item>
                    </div>

                    <div className="col-md-4 col-xs-12">
                      <Form.Item>
                        <label>
                          From:
                        </label>{" "}
                        <br />
                        {getFieldDecorator(
                          "collegefrom",
                          {
                            initialValue: this.state.userinfo.collegefrom,
                            rules: [
                              {
                                required: false,
                                message: "Please choose your from date",
                              },
                            ],
                          },
                          config
                        )(<DatePicker
                          style={{ width: "100%" }} />)}
                      </Form.Item>
                    </div>
                    <div className="col-md-4 col-xs-12">
                      <Form.Item>
                        <label>
                          To:
                        </label>{" "}
                        <br />
                        {getFieldDecorator(
                          "collegeto",
                          {
                            initialValue: this.state.userinfo.collegeto,
                            rules: [
                              {
                                required: false,
                                message: "Please choose your to date",
                              },
                            ],
                          },
                          config
                        )(<DatePicker
                          style={{ width: "100%" }} />)}
                      </Form.Item>
                    </div>
                  </div>
                </Card> */}

                <Card style={{ marginBottom: "30px" }}>
                  <div style={{ marginBottom: "30px" }}>
                    <h3 className="loginHead">Bio & Interest</h3>
                    <div className="headline1"></div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-sm-6 col-xs-12">
                      <Form.Item>
                        <label>
                          Short Bio:
                        </label>
                        {getFieldDecorator("bio", {
                          initialValue: this.state.userinfo.Biography,
                          // rules: [
                          //   {
                          //     required: true,
                          //     message: "Please input your shor bio!",
                          //   },
                          //   {
                          //     maxLength: 30,
                          //     message:
                          //       "Should not exceed more than 30 characters",
                          //   },
                          // ],
                        })(<TextArea />)}
                      </Form.Item>
                    </div>
                    <div className="col-md-6 col-sm-6 col-xs-12">
                      <Form.Item>
                        <label>
                          Interest & Passion<span className="error">*</span>:
                        </label>
                        {getFieldDecorator("interest", {
                          initialValue: this.state.userinfo.Ref_Interest,
                          rules: [
                            {
                              required: true,
                              message: "Choose atleast one interest",
                            },
                            { validator: this.interestMaxLimit },
                          ],
                        })(
                          <Select mode="multiple" style={{ width: "100%" }}>
                            {this.renderinterestList()}
                          </Select>
                        )}
                      </Form.Item>
                    </div>
                  </div>
                </Card>

                <Form.Item>
                  <Button
                    htmlType="submit"
                    className="btn loginBtn color-1"
                    loading={this.state.iconLoading}
                    style={{ margin: "0" }}
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const WrappedRegistrationForm = Form.create({ name: "register" })(EditProfile);

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
    userprofile: state.userprofile,
  };
}

export default connect(mapStateToProps, {
  getProfile,
  genderList,
  countryList,
  interestList,
  editProfile,
  cityList,
  removeprofileimage,
})(WrappedRegistrationForm);
