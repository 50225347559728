import React, { Component } from "react";
import {
    getallLogs,
    getallportfolioLogs,
    postActions,
    reportPost,
    getFeedsLimit
    // postReportProblem
} from "../actions/livingAction";
// import { getallUsers } from "../actions/userAction";
import {
    Card, Avatar,
    Empty,
    Modal,
    Button,
    Popconfirm,
    Icon,
    Tabs,
    Form,
    Radio,
    Spin,
    message,
    Tooltip,
    Menu,
    Dropdown,
    Drawer,
} from "antd";
import { Link } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import { connect } from "react-redux";
import _ from "lodash";
import moment from "moment";
import ReadMoreAndLess from "react-read-more-less";
import request, { post } from "superagent";
import Verified from '../assests/img/verified.svg'
import Shimmer from "./shimmer";
import NoFeaturedCard from "./nofeaturedFeed";
import LazyImage from './lazyImage';
import Noprofileimg from '../assests/img/d-avatar.jpg'
import Linkify from 'react-linkify';
import { db } from "../firebase";
import { BASEURL } from '../actionTypes'
import InfinitScroll from 'react-infinite-scroll-component'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
    FacebookShareButton,
    TwitterShareButton,
    LinkedinShareButton,
    WhatsappShareButton,
} from "react-share"
import { Passers } from "prop-passer";


const { TabPane } = Tabs;

class ExplorerFeeds extends Component {
    constructor(props) {
        super(props);
        this.state = {
            citylist: "",
            userlist: "",
            personallogs: "",
            portfoliologs: "",
            visible: false,
            visible1: false,
            visible2: false,
            activereactions: "",
            activeimgset: [],
            slideIndex: 1,
            allposts: "",
            loading: false,
            hasMore: true,
            userPhoto: "",
            currentSlide: 0,
            active: false,
            author: "",
            id: "",
            urlvalue: window.location.origin + "/post/",
            urlvalue1: window.location.origin + "/logpost/",
            posttype: "",
            visibleDrawer: false,
            postimages: false,
            visibleimages: [],
            lastrecord: 0,
            limitrequest: 0,
            feeds: [],
            arraylength: true,
            loginuser: this.props.user.uid,
            copied: false
        };
        this.getfeeds = this.getfeeds.bind(this)
    }

    isBottom(el) {
        return el.getBoundingClientRect().bottom <= window.innerHeight;
    }

    // componentDidMount() {
    //   document.addEventListener("scroll", this.trackScrolling);
    //   // console.log(this.props.user.uid);
    //   this.props.getFeedsLimit().then(res => {
    //     console.log(res.feedperRquest);
    //     this.setState({
    //       limitrequest: res.feedperRquest,
    //     })
    //     this.getfeeds(this.props.user.uid, this.state.limitrequest)
    //   });
    // }

    componentWillUnmount() {
        document.removeEventListener("scroll", this.trackScrolling);
        this.setState({ loading: false });
    }


    getfeeds() {
        var dataobj = {
            uid: this.props.user.uid,
            limitperrequest: this.state.limitrequest,
            lastrecord: this.state.lastrecord
        };
        this.setState({
            loading: true
        })
        var existingfeeds = this.state.feeds;
        request
            .post(BASEURL + "/getfeedexplorerinfo")
            .send(dataobj)
            .then((res) => {
                // console.log(res);
                if (res.body.ReturnCode === 200) {
                    console.log(res.body.Data)
                    if (res.body.Data.length === 0) {
                        this.setState({
                            hasMore: false,
                            arraylength: false
                        })
                    }
                    else {
                        this.setState({
                            feeds: _.concat(existingfeeds, res.body.Data),
                            lastrecord: res.body.Data.length > 0 ? (res.body.Data[res.body.Data.length - 1].created_timestamp) : 0,
                            hasMore: true,
                            arraylength: false,
                        });
                    }
                } else {
                    this.setState({
                        feeds: res.body.Data,
                        loading: false,
                        arraylength: false,
                        // hasMore: false
                    });
                }
                this.setState({
                    loading: false,
                    // hasMore: false
                })
            })
            .catch((err) => { });
    }


    // trackScrolling = () => {
    //   const wrappedElement = document.getElementById("header");
    //   if (this.isBottom(wrappedElement)) {
    //     document.removeEventListener("scroll", this.trackScrolling);
    //     this.setState({
    //       loading: true
    //     })
    //     this.getfeeds(this.props.user.uid, this.state.limitperrequest)
    //   }
    // };


    showModal = (images, index) => {
        this.setState({
            visible: true,
            activeimgset: images,
            currentSlide: index
        });
    };

    postModal = (images, index) => {
        console.log(images)
        this.setState({
            postimages: true,
            visibleimages: images,
            currentSlide: index
        });
    };


    showreactionmodal = (reactions) => {
        this.setState({
            visible1: true,
            activereactions: reactions,
        });
    };

    onChange(a, b, c) {
        // console.log(a, b, c);
    }

    showDrawer = (postid, authorid, uid, type) => {
        console.log(postid, authorid, uid, type)
        this.setState({
            visibleDrawer: true,
            author: authorid,
            id: postid,
            user: uid,
            posttype: type,
        });
    };


    onClose = () => {
        this.setState({
            visibleDrawer: false,
        })
    }


    handleCancel = (e) => {
        // console.log(e);
        this.setState({
            visible: false,
            visible2: false,
            postimages: false,
        });
        setTimeout(
            function () {
                this.setState({
                    activeimgset: [],
                    visibleimages: []
                });
            }.bind(this),
            150
        );
    };


    handlereactionCancel = (e) => {
        // console.log(e);
        this.setState({
            visible1: false,
        });
        setTimeout(
            function () {
                this.setState({
                    activereactions: "",
                });
            }.bind(this),
            150
        );
    };


    postLiveAction = (logid, authorid, uid, type) => {
        var target = this.state.feeds;
        var acted = _.filter(target, {
            Id: logid,
        });
        var actionarr = acted[0].Action;
        var actedAction = _.filter(actionarr, {
            user_Id: uid,
        });
        console.log(acted, actionarr, actedAction);

        if (actedAction.length >= 1) {
            var filtered = _.filter(actionarr, {
                user_Id: uid,
                actionType: type,
                author: authorid,
            });
            var evens = _.remove(actionarr, function (n) {
                return n.user_Id === uid;
            });
            // console.log(evens, filtered, actionarr);
            if (filtered.length === 0) {
                actionarr.push({
                    user_Id: uid,
                    actionType: type,
                    author: authorid,
                });
                console.log(actionarr);
                this.updatelogs(acted, actionarr, target, logid);
            } else {
                this.updatelogs(acted, actionarr, target, logid);
            }
        } else {
            // console.log(actionarr);
            actionarr.push({
                user_Id: uid,
                actionType: type,
                author: authorid,
            });
            // console.log(actionarr);
            this.updatelogs(acted, actionarr, target, logid, authorid);
        }
        // console.log(actionarr);
    };


    updatelogs = (acted, actionarr, target, logid) => {
        var Actionupdated = actionarr;
        // console.log(actionarr, Actionupdated);

        const elementsIndex = target.findIndex((element) => element.Id === logid);
        // console.log(elementsIndex);
        let newArray = [...this.state.feeds];
        newArray[elementsIndex] = {
            ...newArray[elementsIndex],
            Action: actionarr,
        };
        // console.log(newArray);
        this.setState({
            feeds: newArray,
        });
    };


    postsaction = (logid, authorid, uid, type, actions, actionfig) => {
        // console.log(logid, authorid, uid, type, actions, actionfig)
        // this.postLiveAction(logid, authorid, uid, type, actions);
        var actionarr = actions;
        var subcollectionid = _.filter(actionarr, {
            User_id: uid,
        });
        // console.log(subcollectionid);
        var subid = subcollectionid.length === 0 ? "" : subcollectionid[0].subid;
        this.props.postActions(logid, authorid, uid, type, subid, actionfig).then((res) => {
            // console.log(res);
            var target = this.state.feeds;
            var id = logid;

            var updatedActionindex = actions.findIndex((element) => element.User_id === res.User_id);
            // console.log(updatedActionindex)
            if (actionfig === 2 || actionfig === 4) {
                actions.splice(updatedActionindex, 1);
                actions = _.remove(actions, function (n) {
                    return n.User_id !== uid;
                })
            } else {
                if (updatedActionindex < 0) {
                    actions.push({
                        Created_Date: res.Created_Date,
                        User_id: res.User_id,
                        actionType: res.actionType.toString(),
                        read: res.read,
                        Updated_Date: res.Updated_Date,
                        subid: res.subid
                    })
                } else {
                    actions[updatedActionindex] = {
                        Created_Date: res.Created_Date,
                        User_id: res.User_id,
                        actionType: res.actionType.toString(),
                        read: res.read,
                        Updated_Date: res.Updated_Date,
                        subid: res.subid
                    }
                }
            }

            const elementsIndex = target.findIndex((element) => element.Id === id);
            // console.log(elementsIndex, actions);

            let newArray = [...this.state.feeds];
            newArray[elementsIndex] = {
                ...newArray[elementsIndex],
                action: actions,
            };
            // console.log(newArray);
            this.setState({
                feeds: newArray,
            });
        });
    };


    handleSubmit = async (e) => {
        e.preventDefault();

        var post = this.state.id;
        var reportedid = this.state.author;
        var reporterid = this.props.user.uid;
        var typepost = this.state.posttype;
        var target = this.state.feeds;

        await this.props.form.validateFields((err, values) => {
            if (err && post === "", reportedid === "" && reporterid === "" && typepost === "") {
                // console.log('Received values of form: ', values, this.state.id, this.state.author, this.props.user.uid, this.state.posttype);
                message.error("Please ignore if you are not reporting this post")
                this.setState({
                    visibleDrawer: false
                })
            }
            else {
                this.props.reportPost(values, this.state.id, this.state.author, this.props.user.uid, this.state.posttype)
                    .then((res) => {
                        console.log(res);
                        this.setState({
                            visibleDrawer: false
                        })
                        message.success("Thanks for your feedback")
                        let newArray = [...this.state.feeds];
                        _.remove(newArray, ['Id', this.state.id])

                        console.log(newArray);
                        this.setState({
                            feeds: newArray,
                        });
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            }
        });
    };


    confirm = (id) => {
        this.deleteimage(id)
        // message.success('Log deleted');
    }

    cancel = (e) => {
        console.log(e);
        // message.error('Click on No');
    }

    //delete log image by id & value
    deleteimage = (id) => {
        console.log(id, this.state.feeds)
        var newArray = [...this.state.feeds];
        db.collection("Portfolio_Logs")
            .doc(id)
            .delete()
            .then(() => {
                message.error("Post deleted successfully")

                const idToRemove = id;

                _.remove(newArray, ['Id', idToRemove])

                console.log(newArray);
                this.setState({
                    feeds: newArray,
                });
            })
            .catch(function (error) {
                console.error("Error removing document: ", error);
            });
    }

    deletepersonallog = (id, type) => {
        this.setState({
            posttype: type
        })
        var newArray = [...this.state.feeds];
        db.collection("Personal_Logs")
            .doc(id)
            // console.log(Id)
            .delete()
            .then(() => {
                // console.log("Document successfully deleted!");
                message.error("Log deleted successfully")
                const idToRemove = id;

                _.remove(newArray, ['Id', idToRemove])

                console.log(newArray);
                this.setState({
                    feeds: newArray,
                });
            })
            .catch(function (error) {
                console.error("Error removing document: ", error);
            });
    }

    confirmLog = (id, type) => {
        this.deletepersonallog(id, type);
    }

    confirmdelete = (Id, value) => {
        this.deleteLogImage(Id, value)
        // message.success('Log deleted');
    }

    // deleteLogImage = (Id, value) => {
    //   var target = this.state.feeds
    //   console.log(Id, value)

    //   var findImage = _.filter(target, function (e) {
    //     return e.Id === Id;
    //   });
    //   console.log(findImage)

    //   var imagearray = findImage[0].upload_logs_image
    //   imagearray.splice(value, 1);
    //   console.log(imagearray)

    //   var imageindexvalue = imagearray[0];
    //   console.log(imageindexvalue)

    //   var imagevalue = imagearray.length === 0 ? "" : imagearray[0].image
    //   console.log(imagearray, imagevalue)


    //   var docRef = db.collection("Personal_Logs").doc(Id);
    //   docRef
    //     .get()
    //     .then((doc) => {
    //       if (doc.exists) {
    //         // console.log(doc.data().upload_logs_Image);
    //         var ListImage = doc.data().upload_logs_Image;
    //         console.log(ListImage)
    //         message.success("Log image deleted successfully");
    //         docRef
    //           .update({
    //             upload_logs_Image: imagevalue
    //           })
    //           .then(() => {
    //             const elementsIndex = target.findIndex((element) => element.Id === Id);

    //             let newArray = [...this.state.feeds];
    //             newArray[elementsIndex] = {
    //               ...newArray[elementsIndex],
    //               upload_logs_image: imagearray,
    //             };
    //             console.log(newArray);
    //             this.setState({
    //               feeds: newArray,
    //             });
    //             console.log("Document successfully updated!");
    //           })
    //           .catch((error) => {
    //             console.error("Error updating document: ", error);
    //           });
    //       } else {
    //         console.log("No such document!");
    //       }
    //     })

    //     .catch((error) => {
    //       console.log("Error getting document:", error);
    //     });
    // };


    deleteLogImage = (Id, value) => {
        var docRef = db.collection("Personal_Logs").doc(Id);
        docRef
            .get()
            .then(function (doc) {
                if (doc.exists) {
                    // console.log(doc.data().upload_logs_Image);
                    var ListImage = doc.data().upload_logs_Image;
                    ListImage.splice(value, 1);
                    message.success("Log image deleted successfully");
                    docRef
                        .update({
                            upload_logs_Image: ListImage
                        })
                        .then(function () {
                            // console.log("Document successfully updated!");
                            setTimeout(function () {
                                window.location.reload(false);
                            }, 1000);
                        })
                        .catch(function (error) {
                            // console.error("Error updating document: ", error);
                        });
                } else {
                    // console.log("No such document!");
                }
            })

            .catch(function (error) {
                console.log("Error getting document:", error);
            });
    };



    confirmdeleteLogs(Id, value) {
        this.deleteLogs(Id, value);
    }


    deleteLogs = (Id, value) => {
        var target = this.state.feeds;

        var docList = db.collection("Personal_Logs").doc(Id);
        docList
            .get()
            .then((doc) => {
                if (doc.exists) {
                    var ListLogs = doc.data().log_list;
                    var character = doc.data().character_count;
                    // console.log(ListLogs[value], ListLogs[value].description, ListLogs[value].description.length);
                    var logscount = character - ListLogs[value].description.length;
                    // console.log(logscount);
                    ListLogs.splice(value, 1);
                    console.log(ListLogs);
                    message.success("This log has been deleted successfully");
                    docList
                        .update({
                            log_list: ListLogs,
                            character_count: logscount
                        })
                        .then(() => {
                            const elementsIndex = target.findIndex((element) => element.Id === Id);

                            let newArray = [...this.state.feeds];
                            newArray[elementsIndex] = {
                                ...newArray[elementsIndex],
                                log_list: ListLogs,
                                character_count: logscount
                            };
                            console.log(newArray);
                            this.setState({
                                feeds: newArray,
                            });
                            console.log("Document successfully updated!");

                        })
                        .catch((error) => {
                            console.error("Error updating document: ", error);
                        });
                } else {
                    // console.log("No such document!");
                }
            })

            .catch((error) => {
                console.log("Error getting document:", error);
            });
    };


    abbreviateNumber(value) {
        var newValue = value;
        if (value >= 10000) {
            var suffixes = ["", "k", "M", "B", "T"];
            var suffixNum = Math.floor(("" + value).length / 4);
            var shortValue = '';
            for (var precision = 3; precision >= 2; precision--) {
                shortValue = parseFloat((suffixNum !== 0 ? (value / Math.pow(1000, suffixNum)) : value).toPrecision(precision));
                var dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g, '');
                if (dotLessShortValue.length <= 3) { break; }
            }
            if (shortValue % 2 !== 0) shortValue = shortValue.toFixed(2);
            newValue = shortValue + suffixes[suffixNum];
        }
        // console.log(newValue)
        return newValue;
    }


    getLikes = (actions) => {
        var likes = _.filter(actions, ["actionType", "true"]);
        var liketext = "";
        if (likes.length >= 2) {
            liketext = ""
        } else {
            liketext = ""
        }
        var likes = this.abbreviateNumber(likes.length)
        // console.log(likes)
        return likes;
        // return likes.length + " " + liketext;
    };


    getdisLikes = (actions) => {
        var dislikes = _.filter(actions, ["actionType", "false"]);
        var disliketext = "";
        if (dislikes.length >= 2) {
            disliketext = ""
        } else {
            disliketext = ""
        }
        var dislikes = this.abbreviateNumber(dislikes.length)
        return dislikes;
        // return dislikes.length + " " + disliketext;
    };


    getIsLiked = (actions, user) => {
        var isliked = _.filter(actions, { User_id: user, actionType: "true" });
        var isdisliked = _.filter(actions, { User_id: user, actionType: "false" });
        // console.log(isliked, isdisliked, actions, user);
        if (isliked.length >= 1) {
            return 2;
        } else if (isdisliked.length >= 1) {
            return 5;
        } else {
            return 1
        }
    };


    getIsdisLiked = (actions, user) => {
        var isliked = _.filter(actions, { User_id: user, actionType: "true" });
        var isdisliked = _.filter(actions, { User_id: user, actionType: "false" });
        // console.log(isliked, isdisliked, actions, user);
        if (isdisliked.length >= 1) {
            return 4;
        } else if (isliked.length >= 1) {
            return 5;
        } else {
            return 3;
        }
    };


    getAllReactions = () => {
        var users = this.state.userlist;
        var reacted = this.state.activereactions;
        var allreactors = [];
        _.forEach(reacted, function (value) {
            console.log(value, users);
            var all = _.filter(users, { Id: value.author });
            console.log(all);
            allreactors.push({
                Id: all[0].Id,
                photoURL: all[0].photoURL,
                First_Name: all[0].First_Name,
                Last_Name: all[0].Last_Name,
                Date_of_Birth: all[0].Date_of_Birth,
                Ref_Country: all[0].Ref_Country,
                Ref_City: all[0].Ref_City,
                Ref_Gender: all[0].Ref_Gender,
                Education: all[0].Education,
                Interest_Passion: all[0].Interest_Passion,
                Biography: all[0].Biography,
                Updated_At: all[0].Updated_At,
                actionType: value.actionType,
            });
            // console.log(allreactors);
        });

        if (allreactors.length === reacted.length) {
            // console.log(Updated_At);
            return (
                <ul className="list-unstyled reactionuserlist">
                    {_.map(allreactors, (user, index) => {
                        return (
                            <Link
                                to={("/profile/" + user.Id)}
                                key={index}
                            >
                                <li>
                                    <div className="userphoto">
                                        <span className={user.actionType === true ? "reaclike" : "reacdislike"}>
                                            <Icon type={user.actionType === true ? "like" : "dislike"}
                                                className="margin" id="liked " theme="filled" />
                                        </span>
                                        {user.photoURL === "" || user.photoURL === undefined ? (
                                            <Avatar
                                                style={{ color: "#f56a00", backgroundColor: "#fde3cf" }}
                                                size="small" src={Noprofileimg}
                                            >

                                            </Avatar>
                                        ) : (
                                            <Avatar src={user.photoURL} size="small" />
                                        )}
                                    </div>
                                    <div>
                                        <span className="userinfo">
                                            {user.First_Name} {user.Last_Name} <br />
                                        </span>
                                        <span>{user.Updated_At}</span>
                                        {/* <span
                      className={
                        user.actionType === true ? "Liked" : "DisLiked"
                      }
                    >
                      {user.actionType === true ? "Liked" : "DisLiked"}
                    </span> */}
                                    </div>
                                </li>
                            </Link>
                        );
                    })}
                </ul>
            );
        }
    };


    getLikeReactions = () => {
        var users = this.state.userlist;
        var reacted = _.filter(this.state.activereactions, { actionType: true });
        var allreactors = [];
        _.forEach(reacted, function (value) {
            console.log(value);
            var all = _.filter(users, { Id: value.author });
            console.log(all);
            if (all.length != 0) {
                allreactors.push({
                    Id: all[0].Id,
                    photoURL: all[0].photoURL,
                    First_Name: all[0].First_Name,
                    Last_Name: all[0].Last_Name,
                    Date_of_Birth: all[0].Date_of_Birth,
                    Ref_Country: all[0].Ref_Country,
                    Ref_City: all[0].Ref_City,
                    Ref_Gender: all[0].Ref_Gender,
                    Education: all[0].Education,
                    Interest_Passion: all[0].Interest_Passion,
                    Biography: all[0].Biography,
                    Updated_At: all[0].Updated_At,
                    actionType: value.actionType,
                });
            }
        });

        if (allreactors.length === reacted.length) {
            return (
                <ul className="list-unstyled reactionuserlist">
                    {_.map(allreactors, (user, index) => {
                        return (
                            <Link
                                to={
                                    user.Id === this.props.user.uid
                                        ? "/profile"
                                        : "/profile/" + user.Id
                                }
                                key={index}
                            >
                                <li>
                                    <div className="userphoto">
                                        <span className={user.actionType === false ? "reacdislike" : "reaclike"}>
                                            <Icon type={user.actionType === false ? "caret-down" : "caret-up"}
                                                className="margin" id="disliked " theme="filled" />
                                        </span>
                                        {user.photoURL === "" || user.photoURL === undefined ? (
                                            <Avatar
                                                size="small" src={Noprofileimg}
                                            >
                                                {/* {user.First_Name.charAt(0)} */}
                                            </Avatar>
                                        ) : (
                                            <Avatar src={user.photoURL} size="small" />
                                        )}
                                    </div>
                                    <div>
                                        <span className="userinfo">
                                            {user.First_Name} {user.Last_Name} <br />
                                        </span>
                                        {/* <span>{user.Updated_At}</span> */}
                                        {/* <span
                      className={
                        user.actionType === true ? "Liked" : "DisLiked"
                      }
                    >
                      {user.actionType === true ? "Liked" : "DisLiked"}
                    </span> */}
                                    </div>
                                </li>
                            </Link>
                        );
                    })}
                </ul>
            );
        }
    };


    getDisLikeReactions = () => {
        var users = this.state.userlist;
        var reacted = _.filter(this.state.activereactions, { actionType: false });
        var allreactors = [];
        _.forEach(reacted, function (value) {
            console.log(value);
            var all = _.filter(users, { Id: value.author });
            console.log(all);
            allreactors.push({
                Id: all[0].Id,
                photoURL: all[0].photoURL,
                First_Name: all[0].First_Name,
                Last_Name: all[0].Last_Name,
                Date_of_Birth: all[0].Date_of_Birth,
                Ref_Country: all[0].Ref_Country,
                Ref_City: all[0].Ref_City,
                Ref_Gender: all[0].Ref_Gender,
                Education: all[0].Education,
                Interest_Passion: all[0].Interest_Passion,
                Biography: all[0].Biography,
                Updated_At: all[0].Updated_At,
                actionType: value.actionType,
            });
            // console.log(allreactors);
        });

        if (allreactors.length === reacted.length) {
            return (
                <ul className="list-unstyled reactionuserlist">
                    {_.map(allreactors, (user, index) => {
                        return (
                            <Link
                                to={("/profile/" + user.Id)}
                                key={index}
                            >
                                <li>
                                    <div className="userphoto">
                                        <div
                                            data-icon={user.actionType === true ? "n" : "k"}
                                            className={
                                                user.actionType === true
                                                    ? "Liked icon"
                                                    : "icon DisLiked"
                                            }
                                        ></div>
                                        {user.photoURL === undefined ? (
                                            <Avatar
                                                style={{ color: "#f56a00", backgroundColor: "#fde3cf" }}
                                                size="small"
                                            >
                                                {user.First_Name.charAt(0)}
                                            </Avatar>
                                        ) : (
                                            <Avatar src={user.photoURL} size="small" />
                                        )}
                                    </div>
                                    <div>
                                        <span className="userinfo">
                                            {user.First_Name} {user.Last_Name} <br />
                                        </span>
                                        <span>{user.Biography}</span>
                                        {/* <span
                      className={
                        user.actionType === true ? "Liked" : "DisLiked"
                      }
                    >
                      {user.actionType === true ? "Liked" : "DisLiked"}
                    </span> */}
                                    </div>
                                </li>
                            </Link>
                        );
                    })}
                </ul>
            );
        }
    };


    componentWillMount() {
        this.props.getFeedsLimit().then(res => {
            // console.log(res.feedperRquest);
            this.setState({
                limitrequest: res.feedperRquest,
                loading: true
            })
            this.getfeeds(this.props.user.uid, this.state.limitrequest)
            this.setState({
                loading: false
            })
        });

    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.feeds.length !== this.state.feeds.length) {
            this.setState({
                feeds: this.state.feeds
            })
        }
    }


    refreshFeeds = () => {
        setTimeout(function () {
            window.location.reload(false);
        }, 300);
    }


    refreshReport = () => {
        setTimeout(function () {
            window.location.reload(false);
        }, 300);
    }


    renderUserInfo(username, userimg, author, created_timestamp, Is_Verified) {
        var timestamp = new Date(created_timestamp * 1000);
        // console.log(timestamp)

        // const renderProfileonHover = (
        //   <div className="col-md-12" className="profilehoverbox">
        //     <div className="col-md-4 no-pad" style={{ paddingLeft: '5px' }}>
        //       <Link
        //         to={author === this.props.user.uid ?
        //           ("/profile")
        //           :
        //           ("/profile/" + author)}
        //         onClick={() => this.refreshFeeds()}
        //         style={{ color: '#333' }}
        //       >
        //         <span className="userphoto">
        //           {userimg === "" ? (
        //             <Avatar
        //               size={60} src={Noprofileimg} />
        //           ) : (
        //             <Avatar src={userimg} size={60} />
        //           )}
        //         </span>
        //       </Link>
        //     </div>
        //     <div className="col-md-8 no-pad">
        //       <span
        //         className="userinfo"
        //         style={{
        //           marginLeft: "10px",
        //           fontWeight: "600",
        //           fontSize: "14px",
        //         }}
        //       >
        //         <Link
        //           to={author === this.props.user.uid ?
        //             ("/profile")
        //             :
        //             ("/profile/" + author)}
        //           onClick={() => this.refreshFeeds()}
        //           style={{ color: '#333' }}
        //         >
        //           {username}
        //         </Link>
        //       </span>
        //     </div>
        //   </div>
        // )

        return (
            <div className="postuserinfo">
                <Link
                    to={author === this.props.user.uid ?
                        ("/profile")
                        :
                        ("/profile/" + author)}
                    onClick={() => this.refreshFeeds()}
                >
                    <span className="userphoto">
                        {userimg === "" ? (

                            <Avatar
                                style={{ color: "#f56a00", backgroundColor: "#fde3cf" }}
                                size={46} src={Noprofileimg}
                                onClick={() => this.profileModal()}
                            >
                                {/* {username.charAt(0)} */}
                            </Avatar>
                        ) : (
                            <Avatar src={userimg} size={46}
                                onClick={() => this.profileModal()} />
                        )}
                    </span>
                </Link>
                <span
                    className="userinfo"
                    style={{
                        marginLeft: "10px",
                        fontWeight: "600",
                        fontSize: "14px",
                    }}
                >
                    <Link
                        to={author === this.props.user.uid ?
                            ("/profile")
                            :
                            ("/profile/" + author)}
                        onClick={() => this.refreshFeeds()}
                        style={{ color: '#333' }}
                    >
                        {username}
                        {/* <Dropdown overlay={renderProfileonHover}>
              <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                {username}
              </a>
            </Dropdown> */}
                    </Link>
                    <small
                        style={{ color: '#777', marginLeft: '5px' }}>created a new post
                        {Is_Verified === false ? null : (
                            <img src={Verified} className="feeds-verified" />
                        )}
                    </small>
                    <br />
                    <span className="memcity">
                        {moment(timestamp).startOf('seconds').fromNow()}
                    </span>
                </span>
            </div>
        );
    }


    renderUserLogs(username, userimg, author, created_timestamp, Is_Verified) {
        var timestamp = new Date(created_timestamp * 1000);
        // console.log(timestamp)
        return (
            <div className="postuserinfo">
                <Link
                    to={author === this.props.user.uid ?
                        ("/profile")
                        :
                        ("/profile/" + author)}
                    onClick={() => this.refreshFeeds()}
                    style={{ color: '#333' }}
                >
                    <span className="userphoto">
                        {userimg === "" ? (
                            <Avatar
                                style={{ color: "#f56a00", backgroundColor: "#fde3cf" }}
                                size={46} src={Noprofileimg}

                            >
                                {/* {username.charAt(0)} */}
                            </Avatar>
                        ) : (
                            <Avatar src={userimg} size={46}
                            />
                        )}
                    </span>
                </Link>
                <span
                    className="userinfo"
                    style={{
                        marginLeft: "10px",
                        fontWeight: "600",
                        fontSize: "14px",
                    }}
                >
                    <Link
                        to={author === this.props.user.uid ?
                            ("/profile")
                            :
                            ("/profile/" + author)}
                        onClick={() => this.refreshFeeds()}
                        style={{ color: '#333' }}
                    >
                        {username}
                    </Link>
                    <small
                        style={{ color: '#777', marginLeft: '5px' }}>created a new log
                        {Is_Verified === false ? null : (
                            <img src={Verified} className="feeds-verified" />
                        )}
                    </small>
                    <br />
                    <span className="memcity">
                        {moment(timestamp).startOf('seconds').fromNow()}
                    </span>
                </span>
            </div>
        );
    }


    onCopy = () => {
        this.setState({
            copied: true,
        });
        message.success("Copied shared link")
    };


    renderMenu(Id, type) {
        const {
            url = this.state.urlvalue + Id,
            title = "See the livathon post",
            shareImage = "https://www.steadylearner.com/static/images/brand/prop-passer.png",
            size = "2.5rem",
        } = this.props;


        const ShareList = Passers({
            url,
            className: "network__share-button",
        })({
            className: "network cursor-pointer hover transition--default",
            title: `Share ${String(this.state.loc)}`,
        })("li");

        const iconFont = {
            fontSize: '14px'
        }

        return (
            <ul className="feedpost-drop">
                <li>
                    <Popconfirm
                        title={type === "personal" ? ("Do you want to delete this Log?") :
                            ("Do you want to delete this post?")}
                        icon={
                            <Icon type="question-circle-o" style={{ color: 'red' }} />}
                        onConfirm={type === "personal" ? (() => this.confirmLog(Id, type)) :
                            (() => this.confirm(Id))}
                        onCancel={() => this.cancel()}
                        okText="Yes"
                        cancelText="No">
                        <span style={{ margin: 0 }}>
                            <i className="fas fa-trash" style={iconFont}></i> Delete this post</span>
                    </Popconfirm>
                </li>
                {type === "portfolio" ? (
                    <p style={{ margin: 0 }}>
                        <ShareList>
                            <FacebookShareButton
                                windowWidth={750}
                                windowHeight={600}
                                url={"https://livathon.com/" + "/post" + Id}
                                quote={"See the livathon Post"}
                            >
                                <i className="fab fa-facebook" style={iconFont}></i> Share to facebook
                            </FacebookShareButton>
                        </ShareList>
                        <ShareList>
                            <TwitterShareButton
                                windowWidth={750}
                                windowHeight={600}
                                title={title}
                                url={url}
                            >
                                <i className="fab fa-twitter" style={iconFont}></i> Share to twitter
                            </TwitterShareButton>
                        </ShareList>
                        <ShareList>
                            <WhatsappShareButton
                                windowWidth={750}
                                windowHeight={600}
                                title={"See the livathon post"}
                                url={url}
                                separator=":: "
                            >
                                <i className="fab fa-whatsapp-square" style={iconFont}></i>  share to whatsapp
                            </WhatsappShareButton>
                        </ShareList>
                        <ShareList>
                            <LinkedinShareButton
                                title={title}
                                url={"https://livathon.com/" + "/post" + Id}
                                windowWidth={750}
                                windowHeight={600}
                            >
                                <i className="fab fa-linkedin-in" style={iconFont}></i> Share to linkedIn
                            </LinkedinShareButton>
                        </ShareList>
                    </p>
                ) : null}
                <li>
                    <CopyToClipboard onCopy={this.onCopy}
                        text={type == "portfolio" ? this.state.urlvalue + Id : this.state.urlvalue1 + Id}>
                        <span><i className="fas fa-copy" style={iconFont}></i> Copy link</span>
                    </CopyToClipboard>
                </li>
            </ul>
        )
    }

    renderDrawerMenu(Id, authorid, uid, type) {
        const {
            url = this.state.urlvalue + Id,
            title = "Livathon",
            shareImage = "https://www.steadylearner.com/static/images/brand/prop-passer.png",
            size = "2.5rem",
        } = this.props;

        const iconFont = {
            fontSize: '14px'
        }

        const ShareList = Passers({
            url,
            className: "network__share-button",
        })({
            className: "network cursor-pointer hover transition--default",
            title: `Share ${String(this.state.loc)}`,
        })("li");

        return (
            <ul className="feedpost-drop">
                <li>
                    <span onClick={() => this.showDrawer(Id, authorid, uid, type)}><i class="fas fa-flag"
                        style={iconFont}></i> Report Post</span>
                </li>
                {type === "portfolio" ? (
                    <p style={{ margin: 0 }}>
                        <ShareList>
                            <FacebookShareButton
                                windowWidth={750}
                                windowHeight={600}
                                url={"https://livathon.com/" + "/post" + Id}
                                quote={"See the livathon Post"}
                            >
                                <i className="fab fa-facebook" style={iconFont}></i> Share to facebook
                            </FacebookShareButton>
                        </ShareList>
                        <ShareList>
                            <TwitterShareButton
                                windowWidth={750}
                                windowHeight={600}
                                title={title}
                                url={url}
                            >
                                <i className="fab fa-twitter" style={iconFont}></i> Share to twitter
                            </TwitterShareButton>
                        </ShareList>
                        <ShareList>
                            <WhatsappShareButton
                                windowWidth={750}
                                windowHeight={600}
                                title={"See the livathon post"}
                                url={url}
                                separator=":: "
                            >
                                <i className="fab fa-whatsapp-square" style={iconFont}></i>  share to whatsapp
                            </WhatsappShareButton>
                        </ShareList>
                        <ShareList>
                            <LinkedinShareButton
                                title={title}
                                url={"https://livathon.com/" + "/post" + Id}
                                windowWidth={750}
                                windowHeight={600}
                            >
                                <i className="fab fa-linkedin-in" style={iconFont}></i> Share to linkedIn
                            </LinkedinShareButton>
                        </ShareList>
                    </p>
                ) : null}
                <li>
                    <CopyToClipboard onCopy={this.onCopy}
                        text={type == "portfolio" ? this.state.urlvalue + Id : this.state.urlvalue1 + Id}>
                        <span><i className="fas fa-copy" style={iconFont}></i> Copy link</span>
                    </CopyToClipboard>
                </li>
            </ul>
        )
    }


    renderLogsList() {

        const componentDecorator = (href, text, key) => (
            <a href={href} key={key} target="_blank" rel="noopener noreferrer">
                {text}
            </a>
        );

        return (
            <InfinitScroll
                dataLength={this.state.feeds.length}
                next={this.getfeeds}
                hasMore={this.state.hasMore === true ? true : false}
                loader={<div className="text-center"><Spin tip="Loading..." /></div>}
                endMessage={<p className="text-center">All Caught Up</p>}
                initialScrollY="600px"
            >
                <div>
                    {this.state.feeds.map((logs, index) => (
                        <div className="postcard" style={{ marginBottom: "10px" }} key={index}>
                            <Card
                                className="feedscard"
                                title={logs.posttype === "portfolio" ? (
                                    this.renderUserInfo(
                                        logs.username,
                                        logs.userimg,
                                        logs.author,
                                        logs.created_timestamp,
                                        logs.Is_Verified
                                    )
                                ) : (
                                    this.renderUserLogs(
                                        logs.username,
                                        logs.userimg,
                                        logs.author,
                                        logs.created_timestamp,
                                        logs.Is_Verified
                                    )
                                )}
                                extra={logs.author === this.state.loginuser ? (
                                    <div style={{ display: 'inline-flex' }}>
                                        <Dropdown trigger={["click"]} placement="bottomRight"
                                            overlay={this.renderMenu(logs.Id, logs.posttype)}
                                            style={{ marginLeft: '10px' }}>
                                            <a className="ant-dropdown-link" href="#"
                                                onClick={e => e.preventDefault()}>
                                                <Icon type="more" style={{ fontSize: '20px', color: '#333' }} />
                                            </a>
                                        </Dropdown>
                                    </div>
                                ) : (
                                    <div style={{ display: 'inline-flex' }}>
                                        <Dropdown trigger={["click"]}
                                            overlay={this.renderDrawerMenu(logs.Id, logs.author, this.props.user.uid, logs.posttype)}
                                            style={{ marginLeft: '10px' }} >
                                            <a className="ant-dropdown-link" href="#"
                                                onClick={e => e.preventDefault()}>
                                                <Icon type="more" style={{ fontSize: '20px', color: '#333' }} />
                                            </a>
                                        </Dropdown>
                                    </div>
                                )}
                                style={{
                                    width: "100%",
                                    border: "1px solid #eee",
                                    borderRadius: "4px",
                                    padding: 0,
                                }}
                            >
                                <div style={logs.log_list.length === 0 ? { padding: '0' } : { padding: '15px' }}>
                                    {_.map(logs.log_list, (log, index) => {
                                        return (
                                            <div className="readdiv" key={index}
                                                style={{
                                                    wordBreak: 'break-all',
                                                    whiteSpace: 'pre-line',
                                                    marginBottom: '10px',
                                                    padding: "8px 10px",
                                                    border: "1px solid #f9f9f9",
                                                    display: "block",
                                                    position: "relative",
                                                    borderRadius: "2px"
                                                }}>
                                                <Linkify componentDecorator={componentDecorator}>
                                                    <ReadMoreAndLess
                                                        ref={this.ReadMore}
                                                        className="read-more-content"
                                                        charLimit={300}
                                                        readMoreText="Read more"
                                                        readLessText="Read less"
                                                    >
                                                        {log.description}
                                                    </ReadMoreAndLess>
                                                </Linkify>
                                                {logs.posttype === "personal" && logs.author === this.props.user.uid ? (
                                                    <div className="breakdes">
                                                        <span className="time">
                                                            {moment.unix(log.text_created / 1000).format("LT")}
                                                        </span>
                                                        <Popconfirm
                                                            title="Do you want to delete this log?"
                                                            icon={
                                                                <Icon type="question-circle-o" style={{ color: 'red' }} />}
                                                            onConfirm={() => this.confirmdeleteLogs(logs.Id, index)}
                                                            onCancel={() => this.cancel()}
                                                            okText="Yes"
                                                            cancelText="No">
                                                            <span style={{ marginLeft: '10px' }}>
                                                                <Icon type="delete"
                                                                    style={{ color: '#1c41ce', cursor: 'pointer' }} />
                                                            </span>
                                                        </Popconfirm>
                                                    </div>
                                                ) : (
                                                    <div>
                                                        {logs.posttype === "personal" && logs.author != this.props.user.uid ? (
                                                            <div className="breakdes">
                                                                <span className="time">
                                                                    {moment.unix(log.text_created / 1000).format("LT")}
                                                                </span>
                                                            </div>
                                                        ) : (
                                                            null
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        )
                                    })}
                                </div>
                                {logs.posttype === "portfolio" ? (
                                    <div>
                                        <Carousel
                                            showIndicators={false}
                                        >
                                            {_.map(logs.upload_logs_image, (logImage, index) => {
                                                return (
                                                    <div key={index} onClick={() =>
                                                        this.showModal(logs.upload_logs_image, index)
                                                    }>
                                                        <div className="col-md-12 col-xs-12 postimg">
                                                            <div className="imagecontainer1">
                                                                <LazyImage
                                                                    // <img 
                                                                    src={logImage.image}
                                                                    className="imgbanner1 img1"
                                                                    alt=""
                                                                    onClick={() =>
                                                                        this.showModal(logs.upload_logs_image, index)
                                                                    }
                                                                // />
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </Carousel>
                                    </div>
                                ) : (
                                    <div className="row text-center">
                                        {_.map(logs.upload_logs_image, (logImage, index) => {
                                            return (
                                                <div
                                                    className="col-md-4 col-xs-4 postimg"
                                                    style={{ padding: "15px" }}
                                                    key={index}
                                                >
                                                    {logs.posttype === "personal" && logs.author === this.props.user.uid ? (
                                                        <div className="imagecontainer">
                                                            <div className="button">
                                                                <Popconfirm
                                                                    title="Do you want to delete this Log image?"
                                                                    icon={
                                                                        <Icon type="question-circle-o" style={{ color: 'red' }} />}
                                                                    onConfirm={() => this.confirmdelete(logs.Id, index)}
                                                                    onCancel={() => this.cancel()}
                                                                    okText="Yes"
                                                                    cancelText="No">
                                                                    <Button
                                                                        type="default"
                                                                        block
                                                                        className="delete userfollowing"
                                                                    >
                                                                        <Icon type="delete" />
                                                                    </Button>
                                                                </Popconfirm>
                                                            </div>
                                                            <Tooltip title="Click to full view" placement="top">
                                                                <img
                                                                    src={logImage.image}
                                                                    onClick={() =>
                                                                        this.showModal(logs.upload_logs_image, index)
                                                                    }
                                                                    className="imgbanner"
                                                                    alt=""
                                                                />
                                                            </Tooltip>
                                                        </div>
                                                    ) : (
                                                        <div className="imagecontainer">
                                                            <Tooltip title="Click to full view" placement="top">
                                                                <img
                                                                    src={logImage.image}
                                                                    onClick={() =>
                                                                        this.showModal(logs.upload_logs_image, index)
                                                                    }
                                                                    className="imgbanner"
                                                                    alt=""
                                                                />
                                                            </Tooltip>
                                                        </div>
                                                    )}

                                                </div>
                                            );
                                        })}
                                    </div>
                                )}
                                {logs.posttype === "portfolio" ? (
                                    <div>
                                        <div className="postaction">
                                            {/* <div className="row">
                    <div className="col-xs-6 text-right"> */}
                                            <div className="postactionbtn">
                                                <div className="actioncount">
                                                    {logs.action.length === 0 ? null : (
                                                        <div>
                                                            <div className="spacereactors"
                                                                onClick={() => this.showreactionmodal(logs.action)}>
                                                                {/* <span className="isliked">
                                  <Icon type="caret-up"
                                    className="margin likeicon" id="liked " theme="filled" /></span> */}
                                                                {/* <p className="isliked"> */}
                                                                <span className="badge-success">
                                                                    {this.getLikes(logs.action)}</span>
                                                                {/* </p> */}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                                <Button
                                                    type="link"
                                                    className={this.getIsLiked(
                                                        logs.action,
                                                        this.props.user.uid)
                                                        === 1 ? "Like" : this.getIsLiked(
                                                            logs.action,
                                                            this.props.user.uid)
                                                            === 5 ? "Like" : "Liked"}
                                                    onClick={() =>
                                                        this.postsaction(logs.Id, logs.author, this.props.user.uid, true,
                                                            logs.action, this.getIsLiked(
                                                                logs.action,
                                                                this.props.user.uid))
                                                    }>
                                                    <Icon type="caret-up" className="margin likeicon"
                                                        theme="filled" />
                                                </Button>
                                            </div>
                                            {/* </div>
                    <div className="col-xs-6 text-left"> */}
                                            <div className="postactionbtn">
                                                <Button
                                                    type="link"
                                                    className={this.getIsdisLiked(
                                                        logs.action,
                                                        this.props.user.uid
                                                    ) === 3 ? "DisLike" : this.getIsdisLiked(
                                                        logs.action,
                                                        this.props.user.uid
                                                    ) === 5 ? "DisLike" : "DisLiked"}
                                                    onClick={() =>
                                                        this.postsaction(logs.Id, logs.author, this.props.user.uid, false,
                                                            logs.action, this.getIsdisLiked(
                                                                logs.action,
                                                                this.props.user.uid
                                                            ))
                                                    }
                                                >
                                                    <Icon type="caret-down"
                                                        className="margin likeicon" theme="filled" />
                                                    {/* {this.getIsdisLiked(logs.action, this.props.user.uid)} */}
                                                </Button>
                                                <div className="actioncount">
                                                    {logs.action.length === 0 ? null : (
                                                        <div>
                                                            <div className="spacereactors">
                                                                {/* <span className="isdisliked">
                                <Icon type="caret-down" className="margin" id="disliked"
                                  theme="filled" /> </span> */}
                                                                {/* <p className="isdisliked"> */}
                                                                <span className="badge-danger">
                                                                    {this.getdisLikes(logs.action)}</span>
                                                                {/* </p> */}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            {/* </div>
                  </div> */}
                                        </div>
                                        {/* <div className="postaction1">
                  <div className="row">
                    <div className="col-xs-12 text-left">
                      <div className="actioncount">
                        {logs.Action.length === 0 ? null : (
                          <div>
                            <Tooltip title="List view">
                              <p className="spacereactors"
                                onClick={() => this.showreactionmodal(logs.Action)}>
                                <span className="isliked">
                                  <Icon type="caret-up"
                                    className="margin likeicon" id="liked " theme="filled" /></span>
                                <span>{this.getLikes(logs.Action)}</span>

                              </p>
                            </Tooltip>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div> */}
                                    </div>
                                ) : null}
                            </Card>
                        </div>
                    ))}
                </div>
            </InfinitScroll>
        )
    }

    renderModalimages() {
        return (
            <Carousel
                selectedItem={this.state.currentSlide}
                renderArrowPrev={(onClickHandler, hasPrev, label) =>
                    hasPrev && (
                        <Button type="primary" onClick={onClickHandler} title={label}
                            className="arrowstyles"
                            style={{ left: 0 }}>
                            <Icon type="left" />
                        </Button>
                    )
                }
                renderArrowNext={(onClickHandler, hasNext, label) =>
                    hasNext && (
                        <Button type="primary" onClick={onClickHandler} title={label}
                            className="arrowstyles"
                            style={{ right: 0 }}>
                            <Icon type="right" />
                        </Button>
                    )
                }>
                {_.map(this.state.activeimgset, (logImage, index) => {
                    return (
                        <div className="carouselimg" key={index}>
                            <div className="carouselimginner">
                                <img src={logImage.image} className="imgbanner img1" alt="" />
                            </div>
                        </div>
                    );
                })}
            </Carousel>
        );
    }

    renderPortfolioimages() {
        return (
            <Carousel
                selectedItem={this.state.currentSlide}
                renderArrowPrev={(onClickHandler, hasPrev, label) =>
                    hasPrev && (
                        <Button type="primary" onClick={onClickHandler} title={label}
                            className="arrowstyles"
                            style={{ left: 0 }}>
                            <Icon type="left" />
                        </Button>
                    )
                }
                renderArrowNext={(onClickHandler, hasNext, label) =>
                    hasNext && (
                        <Button type="primary" onClick={onClickHandler} title={label}
                            className="arrowstyles"
                            style={{ right: 0 }}>
                            <Icon type="right" />
                        </Button>
                    )
                }>
                {_.map(this.state.visibleimages, (logImage, index) => {
                    return (
                        <div className="carouselimg" key={index}>
                            <div className="carouselimginner">
                                <img src={logImage.image} className="imgbanner img1" alt="" />
                            </div>
                        </div>
                    );
                })}
            </Carousel>
        );
    }

    renderDrawer() {
        const { getFieldDecorator } = this.props.form;
        return (
            <div>
                <div className="row">
                    <div className="col-md-12">
                        <p>What you are reporting will be completely anonymous . If you feel someone is in immediate danger
                            please follow up it with the local emergency services.</p>

                        <Form onSubmit={this.handleSubmit}>
                            <Form.Item label="Report Type">
                                {getFieldDecorator('report', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Choose the reason to report this post'
                                        }],
                                })
                                    (
                                        <Radio.Group>
                                            <Radio value="Its suspicious or spam">Its suspicious or spam</Radio>
                                            <Radio value="Its abusive or harmful">Its abusive or harmful</Radio>
                                            <Radio value="Its expresses intention of self harm or suicide">Its expresses intention of
                                                self harm or suicide</Radio>
                                            <Radio value="Its appropriate">Its appropriate</Radio>
                                        </Radio.Group>
                                    )}
                            </Form.Item>
                            <Button type="primary"
                                htmlType="submit">Report</Button>
                        </Form>
                    </div>
                </div>
            </div>
        )
    }


    renderModalReactions() {
        // console.log(this.state.activereactions);
        return (
            <Tabs defaultActiveKey="1" onChange={this.callback} animated={true} className="reacttab">
                {/* <TabPane tab="Likes" key="1">
          {this.getAllReactions()}
        </TabPane> */}
                <TabPane tab="Likes" key="1">
                    {this.state.activereactions === "" ? (
                        <Empty />
                    ) : (
                        <div>
                            {this.getLikeReactions()}
                        </div>
                    )}
                </TabPane>
                {/* <TabPane tab="DisLikes" key="3">
          {this.getDisLikeReactions()}
        </TabPane> */}
            </Tabs>
        );
    }

    render() {
        const { feeds, arraylength } = this.state;
        return (
            <div>
                <Drawer
                    title="Tell us why you are reporting this post/log?"
                    placement="right"
                    closable={false}
                    width={350}
                    destroyOnClose={true}
                    onClose={this.onClose}
                    visible={this.state.visibleDrawer}
                >
                    {this.renderDrawer()}
                </Drawer>

                {/* Image preview modal */}
                <Modal
                    className="carouselmodal"
                    title=""
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={null}
                    destroyOnClose={true}
                    centered={true}
                    width="60%"
                >
                    {this.renderModalimages()}
                </Modal>

                {/* Likes dislikes modal  */}
                <Modal
                    title="Reactions"
                    visible={this.state.visible1}
                    onCancel={this.handlereactionCancel}
                    footer={null}
                    centered={true}
                    className="reactionsmodal"
                >
                    {this.renderModalReactions()}
                </Modal>

                <Modal
                    className="carouselmodal"
                    title=""
                    visible={this.state.postimages}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    destroyOnClose={true}
                    footer={null}
                    centered={true}
                    width="60%"
                >
                    {this.renderPortfolioimages()}
                </Modal>
                <div className="alllogs" id="header">
                    {feeds.length === 0 && arraylength === true ? (
                        <div>
                            <Shimmer count={2} />
                        </div>
                    ) : (
                        <div>
                            {feeds.length === 0 ? (
                                <NoFeaturedCard />
                            ) : (
                                <div>
                                    {this.renderLogsList()}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div >
        );
    }
}

const WrappedExplorer = Form.create({ name: "register" })(ExplorerFeeds);


function mapStateToProps(state, ownProps) {
    return {
        user: state.user,
        personallogs: state.personallogs,
        portfoliologs: state.portfoliologs,
    };
}

export default connect(mapStateToProps, {
    getallLogs,
    getallportfolioLogs,
    postActions,
    reportPost,
    getFeedsLimit
    // postReportProblem
})(WrappedExplorer);
