import React, { Component } from "react";
import {
    postActions, getFollowers,
    getFollowing, removeFollowing, getPostCount,
    getPeopleCount, addFollowing, getFeedsLimit, getuserPersonalLogs
} from "../actions/livingAction";
import {
    createAccount, getallUserList,
    deleteUser, logout
} from "../actions/userAction";
import {
    Card, Avatar, Modal,
    Divider, Button, Icon, message,
    Tabs, Menu, Popconfirm,
} from "antd";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import _ from "lodash";
import { db } from "../firebase";
import moment from "moment";
import FollowersList from "./followersList"
import MenuWidget from "./menuWidget"
import UserPersonalLog from './UserPersonalLog'
import Noprofileimg from '../assests/img/d-avatar.jpg'
import Verified from '../assests/img/verified.svg'
import GridUserPortFolio from './griduserPortfolio'
import FollowingList from './followingList'
import { Carousel } from "react-responsive-carousel";
import Linkify from 'react-linkify';
import { BASEURL } from '../actionTypes'
import request from "superagent";


const { TabPane } = Tabs;

// function callback(key) {
//     // console.log(key);
// }

class UserPortFolio extends Component {
    constructor(props) {
        super(props);
        this.state = {
            citylist: "",
            userlist: "",
            portfoliologs: "",
            personallogs: [],
            visible: false,
            visible1: false,
            visible2: false,
            activeimgset: "",
            slideIndex: 1,
            allposts: "",
            activeuser: "",
            photoURL: "",
            First_Name: "",
            Last_Name: "",
            Full_Name: "",
            arraylength: true,
            Date_of_Birth: "",
            bio: "",
            followers: [],
            getfollowers: [],
            // getfollowing: [],
            CreatedDate: "",
            blockstatus: "",
            currentSlide: 0,
            personalcount: 0,
            portfoliocount: 0,
            followingcount: 0,
            followercount: 0,
            lastrecord: 0,
            hasMore: true,
            chunkedData: [],
            verified: false
        };
    }

    showModal = (images, index) => {
        this.setState({
            visible: true,
            currentSlide: index,
            activeimgset: images
        });
        // console.log(index)
    };

    handleCancel = e => {
        // console.log(e);
        this.setState({
            visible: false
        });
        setTimeout(
            function () {
                this.setState({
                    activeimgset: ""
                });
            }.bind(this),
            150
        );
    };

    handlefollowing = (followid) => {
        var uid = this.props.user.uid;
        this.props.addFollowing(uid, followid).then((res) => {
            // console.log(res);
            this.props.getFollowing(uid).then((res1) => {
                // console.log(res1);
                this.filterusers(res, res1);
            });
        });
    };


    handleunfollow = (id) => {
        var uid = this.props.user.uid;
        this.props.removeFollowing(id).then((res) => {
            // console.log(res);
            message.error("removed from your friend list")


        });
    };

    refreshFeeds = () => {
        setTimeout(function () {
            window.location.reload(false);
        }, 500);
    }

    fetchFollowersData = (uid) => {
        var uid = this.props.user.uid

        var followersarray = this.state.getfollowers;
        this.props.getFollowers(uid).then((res) => {
            this.setState({
                getfollowers: res,
                hasMore: true,
                arraylength: false
            })
            followersarray = _.chunk(this.state.getfollowers, 20)
            // console.log(followersarray.length);
            // console.log(followersarray)
            this.setState({
                getfollowers: followersarray[this.state.followerIndex],
                followerslength: followersarray.length,
                chunkedData: followersarray
            })
        });
    };

    addFollowers = async () => {
        var followersarray = this.state.getfollowers;
        var fullarray = followersarray;
        // console.log(fullarray)
        await this.setState({
            followerIndex: this.state.followerIndex + 1
        });

        var mergearray = _.concat(fullarray, this.state.chunkedData[this.state.followerIndex])
        // console.log(fullarray, mergearray);
        this.setState({
            getfollowers: mergearray,
        })
        this.renderFollowersList();
    }

    deleteAccount = () => {
        var dataobj = {
            uid: this.props.user.uid,
        };
        request
            .post(BASEURL + "/deleteuser")
            .send(dataobj)
            .then((res) => {
                console.log(res)
                if (res.body.Returncode === 200) {
                    message.info("Please wait while your account is deleting")
                    setTimeout(function () {
                       this.props.logout();
                    }, 500);
                    message.success("Your account has been deleted successfully")

                } else if (res.body.ReturnCode === 201) {
                    message.error("Your has not deleted!! Please after some time")
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    deleteConfirm = () => {
        this.deleteAccount();
    }

    deleteCancel = (e) => {

    }


    componentWillMount() {
        var uid = this.props.user.uid;


        this.props.getPostCount(uid).then(res => {
            // console.log(res);
            this.setState({
                portfoliocount: res.Portfolio,
                personalcount: res.Personal
            });
        }).catch(res => {
            this.setState({
                portfoliocount: 0,
                personalcount: 0
            });
        });


        this.props.getPeopleCount(uid).then(res => {
            // console.log(res)
            this.setState({
                followingcount: res.Following,
                followercount: res.Follower,
            })
        }).catch(res => {
            this.setState({
                followingcount: 0,
                followercount: 0
            })
        })


    }


    componentDidMount() {
        var uid = this.props.user.uid;

        var userRef = db.collection("User_Details").doc(uid);
        userRef
            .get()
            .then(doc => {
                if (doc.exists) {
                    var Full_Name = doc.data().First_Name + " " + doc.data().Last_Name;
                    // console.log(moment.unix(doc.data().Date_of_Birth).format("DD-MM-YYYY"));
                    this.setState({
                        First_Name: doc.data().First_Name,
                        Last_Name: doc.data().Last_Name,
                        Full_Name: Full_Name,
                        photoURL: doc.data().photoURL,
                        bio: doc.data().Biography,
                        Date_of_Birth: doc.data().Date_of_Birth,
                        verified: doc.data().Is_Verified,
                        CreatedDate: doc.data().CreatedDate
                    });
                } else {
                    console.log("No such document!");
                }
            })
            .catch(err => {
                console.log("Error getting document", err);
            });
    }


    renderFollowersList() {
        return _.map(this.state.getfollowers, (user, index) => {
            return (
                <li key={index} className="col-md-3 col-sm-4 col-xs-6 text-center">
                    <div className="userbox">
                        <span className="userphoto">
                            {user.photoURL === undefined || user.photoURL === "" ? (
                                <Avatar
                                    style={{
                                        color: "#f56a00",
                                        backgroundColor: "#fde3cf",
                                        textTransform: "uppercase",
                                    }}
                                    size={64}
                                    src={Noprofileimg}
                                >
                                    {/* {user.First_Name.charAt(0)} */}
                                </Avatar>
                            ) : (
                                <Avatar src={user.photoURL} size={64} />
                            )}
                        </span>
                        <br />
                        <Link
                            to={user.Id === this.props.user.uid ?
                                ("/profile")
                                :
                                ("/profile/" + user.Id)}
                            onClick={() => this.refreshFeeds()}
                        >
                            <span className="userinfo" style={{ minHeight: '42px' }}>
                                {user.First_Name} {user.Last_Name} <br />
                            </span>
                        </Link>
                        {/* <span className="memcity">
                        {this.renderCityname(user.Ref_City)}
                    </span> */}
                        {user.Follower_type === "Following" ? (

                            <Button
                                type="default"
                                block
                                className="userfollowing"
                                onClick={() => this.handleunfollow(user.circle_id)}
                            >
                                Following
                            </Button>
                        ) : (
                            <Button
                                type="default"
                                block
                                className="following"
                                onClick={() => this.handlefollowing(user.Id)}
                            >
                                Follow
                            </Button>

                        )}
                    </div>
                </li>
            );
        });
    }

    renderMenu(Id) {
        return (
            <Menu>
                <Menu.Item>
                    <Popconfirm
                        title="Do you want to delete this post?"
                        icon={
                            <Icon type="question-circle-o" style={{ color: 'red' }} />}
                        onConfirm={() => this.confirm(Id)}
                        onCancel={() => this.cancel()}
                        okText="Yes"
                        cancelText="No">
                        <span style={{ cursor: 'pointer' }}>
                            Delete this post</span>
                    </Popconfirm>
                </Menu.Item>
            </Menu >
        )
    }

    abbreviateNumber(value) {
        var newValue = value;
        if (value >= 10000) {
            var suffixes = ["", "k", "M", "B", "T"];
            var suffixNum = Math.floor(("" + value).length / 4);
            var shortValue = '';
            for (var precision = 3; precision >= 2; precision--) {
                shortValue = parseFloat((suffixNum !== 0 ? (value / Math.pow(1000, suffixNum)) : value).toPrecision(precision));
                var dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g, '');
                if (dotLessShortValue.length <= 3) { break; }
            }
            if (shortValue % 2 !== 0) shortValue = shortValue.toFixed(2);
            newValue = shortValue + suffixes[suffixNum];
        }
        // console.log(newValue)
        return newValue;
    }


    renderModalimages() {
        return (
            <div>
                <Carousel
                    showIndicators={false}>
                    <div className="carouselimg">
                        <div className="carouselimginner">
                            <img src={this.state.photoURL === undefined || this.state.photoURL === "" ?
                                Noprofileimg : this.state.photoURL}
                                className="imgbanner img1" alt="" />
                        </div>
                    </div>
                </Carousel>

            </div >
        );
    }

    render() {
        const { verified } = this.state;

        const cursor = {
            cursor: 'pointer'
        }

        var abbreviatedPostCount = this.abbreviateNumber(this.state.portfoliocount);
        var abbreviatedLogCount = this.abbreviateNumber(this.state.personalcount);
        var abbreviatedFollowingCount = this.abbreviateNumber(this.state.followingcount);
        var abbreviatedFollowersCount = this.abbreviateNumber(this.state.followercount);

        var post_count = "Post" + " " + "(" + abbreviatedPostCount + ")";

        var log_count = "Diary" + " " + "(" + abbreviatedLogCount + ")";

        var following = "Following" + " " + "(" + abbreviatedFollowingCount + ")";

        var followers = "Followers" + " " + "(" + abbreviatedFollowersCount + ")";

        const componentDecorator = (href, text, key) => (
            <a href={href} key={key} target="_blank" rel="noopener noreferrer" >
                {text}
            </a>
        );

        var timestamp1 = new Date(this.state.CreatedDate.seconds * 1000)
        // console.log(timestamp1)
        return (
            <div>
                <Modal
                    className="gridmodal carouselmodal"
                    title=""
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={null}
                    centered={true}
                    width="70%"
                >
                    {this.renderModalimages()}
                </Modal>

                <div className="container-fluid" >
                    <div className="row" style={{ marginTop: '80px' }}>
                        <div className="col-md-2 sticky hidden-xs hidden-widget"
                        >
                            <MenuWidget />
                            <div style={{ marginTop: '20px' }}>
                                <Card style={{ width: '100%' }}>
                                    <p style={{ margin: 0 }}>
                                        {/* <Icon type="clock-circle" theme="filled" /> */}
                                        Joined on <br /> <b>{moment(timestamp1).format('Do MMMM YYYY')}</b></p>
                                </Card>
                            </div>
                        </div>
                        <div className="col-md-10 col-xs-12">
                            {/* <div className="userbg"></div> */}
                            <div className="imageup" style={{ marginBottom: '20px' }}>
                                <div className="row" >
                                    <div className="col-md-3 col-xs-12">
                                        <div className="userimage text-center">
                                            {this.state.photoURL === undefined || this.state.photoURL === "" ? (
                                                // <img
                                                //   className="portfoliouserimg"
                                                //   src={EmptyImage}
                                                //   alt="User"
                                                // />
                                                <Avatar className="portfoliouserimg"
                                                    // style={{
                                                    //     color: "#f56a00", backgroundColor: "#fde3cf",
                                                    //     fontSize: '36px', marginBottom: '10px', textTransform: 'uppercase'
                                                    // }}
                                                    alt="userImage"
                                                    size={180} src={Noprofileimg}
                                                    onClick={() => this.showModal()}
                                                    style={cursor}
                                                >
                                                    {/* {this.state.First_Name.charAt(0)} */}
                                                </Avatar>
                                            ) : (
                                                <Avatar className="portfoliouserimg"
                                                    alt="userImage"
                                                    size={180} src={this.state.photoURL}
                                                    onClick={() => this.showModal()}
                                                    style={cursor}
                                                ></Avatar>

                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-5 portfolio hidden-xs">
                                        <h4 className="userinfo1">
                                            <b> {this.state.First_Name} {this.state.Last_Name}</b>
                                            <Divider type="vertical" />
                                            <span style={{ fontSize: "16px" }}>
                                                {moment.unix(this.state.Date_of_Birth).format("YYYY")}
                                            </span>
                                            {verified === false ? null : (
                                                <img src={Verified} className="verified" />
                                            )}
                                        </h4>
                                        <Linkify componentDecorator={componentDecorator}>
                                            <h6 className="m-b-20 usersub">{this.state.bio}</h6>
                                        </Linkify>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <Link to="/editprofile">
                                                    <Button
                                                        type="default"
                                                        block
                                                        className="userfollwg color-1"
                                                    >Edit profile
                                                    </Button>
                                                </Link>
                                            </div>
                                            <div className="col-md-4">
                                                <Popconfirm
                                                    title="Are you sure do you want to delete your Account?"
                                                    onConfirm={() => this.deleteConfirm()}
                                                    onCancel={() => this.deleteCancel}
                                                    okText="Yes"
                                                    cancelText="No"
                                                >
                                                    <Button
                                                        type="primary"
                                                        block
                                                    >Delete Account
                                                    </Button>
                                                </Popconfirm>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xs-12 text-center portfolio hidden-lg hidden-md hidden-sm">
                                        <h4 className="userinfo1" style={{ color: '#1c41ce' }}>
                                            <b> {this.state.First_Name} {this.state.Last_Name}</b>
                                            <Divider type="vertical" style={{ color: '#ccc' }} />
                                            <span style={{ fontSize: "16px", color: '#1c41ce' }}>
                                                {moment.unix(this.state.Date_of_Birth).format("YYYY")}
                                            </span>
                                            {verified === false ? null : (
                                                <img src={Verified} className="verified" />
                                            )}
                                        </h4>
                                        <Linkify componentDecorator={componentDecorator}>
                                            <h6 className="m-b-20 usersub"
                                                style={{ color: '#1c41ce' }}>
                                                {this.state.bio}</h6>
                                        </Linkify>
                                        <Link to="/editprofile">
                                            <Button
                                                type="default"
                                                block
                                                className="userfollwg color-1"
                                            >Edit profile
                                            </Button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="profile-detail">
                                <Tabs defaultActiveKey="1" className="feedstab">
                                    <TabPane tab={
                                        <span>
                                            <Icon type="table" theme="outlined" />
                                            {post_count}
                                        </span>} key="1">
                                        <GridUserPortFolio />
                                    </TabPane>
                                    <TabPane tab={
                                        <span>
                                            <Icon type="unordered-list" theme="outlined" />
                                            {log_count}
                                        </span>
                                    } key="2">
                                        <div>
                                            <UserPersonalLog />
                                        </div>
                                    </TabPane>
                                    <TabPane tab={
                                        <span>
                                            <Icon type="user-add" theme="outlined" />
                                            {following}
                                        </span>
                                    } key="3">
                                        <div>
                                            <FollowingList />
                                        </div>
                                    </TabPane>
                                    <TabPane tab={
                                        <span>
                                            <Icon type="usergroup-add" theme="outlined" />
                                            {followers}
                                        </span>
                                    } key="4">
                                        <div>
                                            <FollowersList />
                                        </div>
                                    </TabPane>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        user: state.user,
        allusers: state.allusers,
        portfoliologs: state.portfoliologs,
        personallogs: state.personallogs,
        getfollowers: state.getfollowers,
        getfollowing: state.getfollowing,
    };
}

export default connect(mapStateToProps, {
    createAccount,
    deleteUser,
    getuserPersonalLogs,
    removeFollowing,
    getallUserList,
    getFeedsLimit,
    getFollowers,
    getFollowing,
    postActions,
    getPostCount,
    getPeopleCount,
    addFollowing,
    logout
})(UserPortFolio);
