import { combineReducers } from "redux";
import userReducer from "./userReducer";
import loadingReducer from "./loadingReducer";
import personallogReducer from "./personalLogReducer";
import getFollowReducer from "./getFollowReducer";
import getFollowingReducer from "./getFollowingReducer";
import allusersReducer from "./alluserReducer";
import portfoliologReducer from "./portfolioReducer";
import modalReducer from "./modalReducer";
import getNotificationReducer from "./getNotificationReducer";
import getImagePortfolioReducer from "./getImagePortfolioReducer";
import chatmsgReducer from "./chatmsgReducer";
import chathistoryReducer from "./getchathistory";

const rootReducer = combineReducers({
  user: userReducer,
  loading: loadingReducer,
  personallogs: personallogReducer,
  getfollowers: getFollowReducer,
  getfollowing: getFollowingReducer,
  portfoliologs: portfoliologReducer,
  imagePortfolio: getImagePortfolioReducer,
  getNotification: getNotificationReducer,
  modalvisible: modalReducer,
  allusers: allusersReducer,
  chatmsgs: chatmsgReducer,
  chathistory: chathistoryReducer
});

export default rootReducer;
