import React, { Component } from "react";
import Logo from '../assests/img/logo-new.svg';
import _ from "lodash";
// import Navigation from '../common/header';
import {
    createAccount, genderList, countryList,
    interestList, cityList,
    // logout
} from "../actions/userAction";
import {
    Card, Form, Icon, Input, Button, Upload,
    message, DatePicker, Select
} from 'antd';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import PhoneInput from 'react-phone-input-2'
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from "react-places-autocomplete";
import Geocode from "react-geocode";
Geocode.setApiKey("AIzaSyCcugEZd84OFFpaKZP1Eo0uCMmc0mA8bSk");


const { Option } = Select;

const { Textarea } = Input;

// function onChange(date, dateString) {
//     console.log(date, dateString);
// }

function handleChange(value) {
    console.log(`selected ${value}`);
}

function disabledDate(current) {
    // Can not select days before today and today
    return current && current > moment().subtract(13, 'years');
}


function tagsChange(value) {
    // console.log(`selected ${value}`);
}

const { TextArea } = Input;

class Register extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            genderList: '',
            countryList: '',
            cityList: '',
            interestList: '',
            fileList1: [],
            Country_Code: "",
            latLng: {
                lat: "",
                lng: "",
            },
            address: "",
            iconLoading: false,
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    enterLoading = () => {
        this.setState({ loading: true });
    };

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleChangee = (address) => {
        this.setState({ address });
    };

    handlePhone = (Country_Code, selectCountry) => {
        console.log(Country_Code, selectCountry)
        this.setState({
            Country_Code
        });
    }

    getMyLocation = () => {
        const location =
            window.navigator && window.navigator.geolocation && navigator.geolocation;
        // navigator.geolocation.getCurrentPosition(this.showMap);
        // navigator.geolocation.getCurrentPosition(() => { }, (err) => { console.log(err) }, { timeout: Infinity })
        this.setState({
            loading: true,
        });
        if (location) {
            location.getCurrentPosition(
                (position) => {
                    this.setState({
                        currentLatLng: {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude,
                        },
                    });
                    Geocode.fromLatLng(
                        position.coords.latitude,
                        position.coords.longitude
                    ).then(
                        (response) => {
                            var results = response.results[0];
                            // console.log(response.results[0]);
                            this.handleState(results);
                        },
                        (error) => {
                            console.error(error);
                        }
                    );
                    // console.log(position, position.coords.longitude);
                },
                (error) => {
                    // this.setState({ latitude: 'err-latitude', longitude: 'err-longitude' })
                    this.setState({
                        loading: false,
                    });
                    message.warning(
                        "Enable browser location permission to detect your current location! or Try searching your location manually!"
                    );
                    console.log(error);
                }
            );
        }
    };


    handleSelect = (address) => {
        geocodeByAddress(address)
            .then((results) => {
                // console.log(getLatLng(results[0]));
                getLatLng(results[0]).then((latLng) => {
                    Geocode.fromLatLng(latLng.lat, latLng.lng).then(
                        (response) => {
                            const results = response.results[0];
                            console.log(address, results, latLng);
                            this.setState({
                                address: address,
                                latLng: latLng,
                            });
                        },
                        (error) => {
                            console.error(error);
                        }
                    );
                    // console.log("Success", latLng);
                });
                // console.log(address, results[0]);
            })
            .catch((error) => console.error("Error", error));
    };


    interestMaxLimit = async (rule, value, callback) => {
        if (value) {
            if (value.length > 10) {
                callback("should not select more than 10 interests");
            } else if (value.length <= 10) {
                callback();
            }
        }
        return;
    };


    handleSubmit = e => {
        this.setState({
            iconLoading: true,
            loading: true
        });

        var address = this.state.address;
        var location = this.state.latLng;
        var countryPhone = this.state.Country_Code;

        e.preventDefault();
        this.props.form.validateFields((err, values,) => {
            if (!err && address !== "" && location !== "", countryPhone !== "") {
                console.log('Received values of form: ', values, this.props.user.uid, this.state.fileList1, this.state.address, this.state.latLng, this.state.Country_Code);
                this.props.createAccount(values, this.props.user.uid, this.state.fileList1, this.state.address, this.state.latLng, this.state.Country_Code)
                    .then(res => {
                        console.log("res", res);
                        this.setState({
                            fileList1: [],
                            address: "",
                            latlng: "",
                            phone: "",
                            iconLoading: false,
                            loading: false
                        });
                        this.props.form.resetFields();
                        message.success("Your details saved successfully");

                        this.props.history.push("/feeds");
                    })
                .catch((error) => {
                    console.log(error)
                    this.setState({
                        iconLoading: false,
                        loading: false
                    })
                });
            }
            else {
                message.error("Please fill all required details carefully");
                this.setState({
                    iconLoading: false,
                    loading: false
                });
            }
        });
    };


    componentWillMount() {
        this.props.genderList()
            .then(res => {
                // console.log("res", res);
                this.setState({
                    genderList: res
                })
            }).catch(function (error) {
                message.error("error occurred while sending data");
            });


        this.props.interestList()
            .then(res => {
                // console.log("res", res);
                this.setState({
                    interestList: res
                })
            }).catch(function (error) {
                message.error("error occurred while sending data");
            });
    }


    rendergenderList() {
        return _.map(this.state.genderList, (gender, key) => {
            return (
                <Option value={gender.genderId} key={key}>{gender.genderName}</Option>
            );
        });
    }


    renderinterestList() {
        return _.map(this.state.interestList, (interest, key) => {
            return (
                <Option value={interest.interestName} key={key}>
                    {interest.interestName}
                </Option>
            );
        });
    }


    normFile = (e) => {
        console.log("Upload event:", e);
        if (Array.isArray(e)) {
            return e;
        }
        console.log(this.state.fileList1);
        return e && e.fileList;
    };

    render() {

        const { fileList1 } = this.state;

        const propsthumb = {
            multiple: false,
            listType: "picture-card",
            accept: ".png,.jpg",
            showUploadList: { showPreviewIcon: false, showRemoveIcon: true },
            onRemove: (file) => {
                this.setState((state) => {
                    const index = state.fileList1.indexOf(file);
                    const newFileList = state.fileList1.slice();
                    newFileList.splice(index, 1);
                    return {
                        fileList1: newFileList,
                    };
                });
                console.log(this.state.fileList1);
            },
            beforeUpload: (file) => {
                this.setState((state) => ({
                    fileList1: [...state.fileList1, file],
                }));
                return false;
            },
            fileList1,
        };

        const { getFieldDecorator } = this.props.form;

        const config = {
            rules: [{ type: 'object', required: true, message: 'Please select Date!' }],
        };

        return (
            <div>
                <div className="container-fluid userbg1">
                    <nav className="navbar navbar-default">
                        <div className="container-fluid navcontainer">
                            <div className="navbar-header">
                                <div className="navbar-toggle">
                                    {/* <Signup /> */}
                                </div>
                                <Link className="navbar-brand" to="#">
                                    <img
                                        src={Logo}
                                        className="logoimg img-responsive"
                                        alt="logo"
                                        style={{ height: '35px' }}
                                    />
                                </Link>
                            </div>
                            <div id="navbar" className="navbar-collapse collapse">
                                <ul className="nav navbar-nav navbar-right" style={{ marginTop: '30px' }}>
                                    {/* <li><Button className="btn loginBtn color-1"
                                            onClick={() => this.props.logout()} style={{ margin: '0' }}>
                                            Logout
                                          </Button>
                                        </li> */}
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
                <div className="container-fluid topup">
                    <div className="container">
                        <div className="row">
                            <p className="topregister">Register your information</p>
                            <Form onSubmit={this.handleSubmit} className="login-form">
                                <Form.Item>
                                    {getFieldDecorator("Photo_Url", {
                                        valuePropName: "fileList",
                                        getValueFromEvent: this.normFile,
                                    })(
                                        // <ImgCrop grid aspect={16 / 9}>
                                        <Upload name="Photo_Url" {...propsthumb}>
                                            {fileList1.length === 1 ? null : (
                                                <div>
                                                    <Icon type="plus" />
                                                    <div className="ant-upload-text">Upload</div>
                                                </div>
                                            )}
                                        </Upload>
                                        // </ImgCrop>
                                    )}
                                </Form.Item>
                                <Card style={{ marginBottom: '50px' }}>
                                    <div style={{ marginBottom: '30px' }}>
                                        <h3 className="loginHead">Personal Information</h3>
                                        <div className="headline1"></div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 col-sm-6 col-xs-12">
                                            <Form.Item>
                                                <label>First Name<span className="error">*</span>:</label>
                                                {getFieldDecorator('firstname', {
                                                    rules: [{ required: true, message: 'Please input your firstname!' }],
                                                })(<Input placeholder="First Name" id="firstname" name="firstname"
                                                    onChange={handleChange}

                                                />,
                                                )}
                                            </Form.Item>
                                        </div>

                                        <div className="col-md-6 col-sm-6 col-xs-12">
                                            <Form.Item>
                                                <label>Last Name<span className="error">*</span>:</label>
                                                {getFieldDecorator('lastname', {
                                                    rules: [{ required: true, message: 'Please input your lastname!' }],
                                                })(
                                                    <Input placeholder="Last Name" id="lastname" name="lastname"
                                                        onChange={this.handleChange}

                                                    />,
                                                )}
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 col-sm-12 col-xs-12">
                                            <Form.Item>
                                                <label>Year of Birth<span className="error">*</span>:</label> <br />
                                                {getFieldDecorator('dateofbirth', {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: 'Please choose your year of birth'
                                                        }
                                                    ],
                                                }, config)(
                                                    <DatePicker
                                                        id="dateofbirth"
                                                        disabledDate={disabledDate}
                                                        defaultPickerValue={moment().subtract(13, 'years')}
                                                        name="dateofbirth"
                                                        // onChange={handleChange}
                                                        style={{ width: '100%' }}
                                                        open={this.state.isOpen}
                                                        mode="year"
                                                        placeholder="Select Year"
                                                        format="YYYY"
                                                        onOpenChange={(status) => {
                                                            if (status) {
                                                                this.setState({ isOpen: true });
                                                            } else {
                                                                this.setState({ isOpen: false });
                                                            }
                                                        }}
                                                        onPanelChange={(v) => {
                                                            console.log(v);
                                                            this.setState({ isOpen: false });
                                                            this.props.form.setFieldsValue({
                                                                dateofbirth: v,
                                                            });
                                                        }}
                                                    />
                                                )}
                                            </Form.Item>
                                        </div>
                                        <div className="col-md-6 col-sm-12 col-xs-12">
                                            <div className="row">
                                                <div className="col-md-5 col-sm-6 col-xs-12 countrydiv">
                                                    <label className="countrylabel"
                                                    >Country Code:<span className="error">*</span>:</label>
                                                    <PhoneInput
                                                        inputProps={{
                                                            name: 'phone',
                                                            required: true,
                                                            autoFocus: true
                                                        }}
                                                        enableSearch={true}
                                                        country={''}
                                                        value={this.state.Country_Code}
                                                        onChange={this.handlePhone}
                                                    />
                                                </div>
                                                <div className="col-md-7 col-sm-6 col-xs-12">
                                                    <Form.Item>
                                                        <label>Mobile Number<span className="error">*</span>:
                                                        </label>
                                                        {getFieldDecorator('mobile', {
                                                            rules: [
                                                                {
                                                                    required: true,
                                                                    pattern: /^(\+{1}\d{2,3}\s?[(]{1}\d{1,3}[)]{1}\s?\d+|\+\d{2,3}\s{1}\d+|\d+){1}[\s|-]?\d+([\s|-]?\d+){1,2}$/,
                                                                    message: 'Please input your mobile number!'
                                                                },
                                                                {
                                                                    min: 10,
                                                                    message: 'Mobile number should contain 10 digits'
                                                                },

                                                            ],
                                                        })(<Input placeholder="Mobile Number" id="mobile"
                                                            maxLength={10} name="mobile" style={{ width: '100%' }} />
                                                        )}
                                                        <span style={{ fontSize: '12px', color: "#1c41ce", lineHeight: '20px' }}>
                                                            (<b>NOTE:</b> Collected only for invite purpose, this data will not be
                                                            shared to anyone)</span>
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6 col-sm-6 col-xs-12">
                                            {/* <Form.Item>
                                                <label>Country<span className="error">*</span>:</label>
                                                {getFieldDecorator('country', {
                                                    rules: [{ required: true, message: 'Please input your country!' }],
                                                })(
                                                    <Select placeholder="Select Country" style={{ width: '100%' }}
                                                        id="country" name="country" onChange={handleChange}
                                                    >
                                                        {this.rendercountryList()}
                                                    </Select>,
                                                )}
                                            </Form.Item> */}

                                            <Form.Item>
                                                <label>Location<span className="error">*</span>:</label>
                                                <PlacesAutocomplete
                                                    value={this.state.address}
                                                    onChange={this.handleChangee}
                                                    onSelect={this.handleSelect}
                                                >
                                                    {({
                                                        getInputProps,
                                                        suggestions,
                                                        getSuggestionItemProps,
                                                        loading,
                                                    }) => (
                                                        <div className="autocompletediv">
                                                            <input
                                                                {...getInputProps({
                                                                    placeholder: "Search Places ...",
                                                                    className: "location-search-input form-control",
                                                                })}
                                                            />
                                                            <div className="autocomplete-dropdown-container">
                                                                {loading && (
                                                                    <div className="autoloading">Loading...</div>
                                                                )}
                                                                {suggestions.map((suggestion) => {
                                                                    const className = suggestion.active
                                                                        ? "suggestion-item--active"
                                                                        : "suggestion-item";

                                                                    return (
                                                                        <div
                                                                            {...getSuggestionItemProps(suggestion, {
                                                                                className,
                                                                            })}
                                                                        >
                                                                            <span>{suggestion.description}</span>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                        </div>
                                                    )}
                                                </PlacesAutocomplete>
                                                <span style={{ fontSize: '12px', color: "#1c41ce", lineHeight: '20px' }}>
                                                    (<b>NOTE:</b> Choose your area or locality from the address provided. It is
                                                    collected for networking purposes only)</span>
                                            </Form.Item>
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-xs-12">
                                            <Form.Item>
                                                <label>Gender<span className="error">*</span>:</label>
                                                {getFieldDecorator('gender', {
                                                    rules: [{ required: true, message: 'Please select your gender!' }],
                                                })(
                                                    <Select placeholder="Select Gender" style={{ width: '100%' }}
                                                        id="gender" name="gender" onChange={handleChange}
                                                    >
                                                        {this.rendergenderList()}
                                                    </Select>,
                                                )}
                                            </Form.Item>
                                        </div>
                                        {/* <div className="col-md-6 col-sm-6 col-xs-12">
                                            <Form.Item>
                                                <label>City<span className="error">*</span>:</label>
                                                {getFieldDecorator('city', {
                                                    rules: [{ required: true, message: 'Please input your city!' }],
                                                })(
                                                    <Select placeholder="Select City" style={{ width: '100%' }}
                                                        id="country" name="country" onChange={handleChange}
                                                    >
                                                        {this.rendercityList()}
                                                    </Select>,
                                                )}
                                            </Form.Item>
                                        </div> */}
                                    </div>

                                </Card>
                                {/* 
                                <Card style={{ marginBottom: '50px' }}>
                                    <div style={{ marginBottom: '30px' }}>
                                        <h3 className="loginHead">Education</h3>
                                        <div className="headline1"></div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4 col-xs-12">
                                            <Form.Item>
                                                <label>School Name:</label>
                                                {getFieldDecorator('schoolname', {
                                                    rules: [{
                                                        required: false,
                                                        message: 'Please input your school name!'
                                                    }],
                                                })(
                                                    <Input placeholder="Kvm School" id="schoolname"
                                                        name="schoolname" onChange={handleChange}
                                                    />,
                                                )}
                                            </Form.Item>
                                        </div>

                                        <div className="col-md-4 col-xs-12">
                                            <Form.Item>
                                                <label>From:</label> <br />
                                                {getFieldDecorator('schoolfrom', {
                                                    rules: [{
                                                        required: false,
                                                        message: 'Please choose your from date'
                                                    }],
                                                }, config)(<DatePicker id="schoolFrom"
                                                    name="schoolFrom"
                                                    onChange={handleChange}
                                                    style={{ width: '100%' }} />)}
                                            </Form.Item>
                                        </div>
                                        <div className="col-md-4 col-xs-12">
                                            <Form.Item>
                                                <label>To:</label> <br />
                                                {getFieldDecorator('schoolto', {
                                                    rules: [{
                                                        required: false,
                                                        message: 'Please choose your to date'
                                                    }],
                                                }, config)(<DatePicker id="schoolTo" name="schoolTo"
                                                    picker="year"
                                                    onChange={handleChange}
                                                    style={{ width: '100%' }} />)}
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4 col-xs-12">
                                            <Form.Item>
                                                <label>College Name:</label>
                                                {getFieldDecorator('collegename', {
                                                    rules: [{
                                                        required: false,
                                                        message: 'Please input your college name!'
                                                    }],
                                                })(
                                                    <Input placeholder="Anna University" id="collegename" name="collegename"
                                                        onChange={handleChange}
                                                    />,
                                                )}
                                            </Form.Item>
                                        </div>

                                        <div className="col-md-4 col-xs-12">
                                            <Form.Item>
                                                <label>From:</label> <br />
                                                {getFieldDecorator('collegefrom', {
                                                    rules: [{
                                                        required: false,
                                                        message: 'Please choose your from date'
                                                    }],
                                                }, config)(<DatePicker id="collegeFrom" name="collegeFrom"
                                                    picker="year"
                                                    onChange={handleChange}

                                                    style={{ width: '100%' }} />)}
                                            </Form.Item>
                                        </div>
                                        <div className="col-md-4 col-xs-12">
                                            <Form.Item>
                                                <label>To:</label> <br />
                                                {getFieldDecorator('collegeto', {
                                                    rules: [{
                                                        required: false,
                                                        message: 'Please choose your to date'
                                                    }],
                                                }, config)(<DatePicker id="collegeTo" name="collegeTo"
                                                    picker="year"
                                                    onChange={handleChange}
                                                    style={{ width: '100%' }} />)}
                                            </Form.Item>
                                        </div>
                                    </div>
                                </Card> */}

                                <Card style={{ marginBottom: '30px' }}>
                                    <div style={{ marginBottom: '30px' }}>
                                        <h3 className="loginHead">Bio & Interest</h3>
                                        <div className="headline1"></div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 col-sm-6 col-xs-12">
                                            <Form.Item>
                                                <label>Short Bio:</label>
                                                {getFieldDecorator('bio', {
                                                    // rules: [
                                                    //     {
                                                    //         required: true,
                                                    //         message: 'Please input your shor bio!'
                                                    //     },
                                                    // ],
                                                })(
                                                    <TextArea placeholder="Eg: An actor by a profession" maxLength={140}
                                                        id="bio" name="bio" onChange={this.handleChange}
                                                    />,
                                                )}
                                            </Form.Item>
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-xs-12">
                                            <Form.Item>
                                                <label>Interest & passion<span className="error">*</span>:</label>
                                                {getFieldDecorator('interest', {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: '',
                                                        },
                                                        {
                                                            validator: this.interestMaxLimit
                                                        }
                                                    ],
                                                })(
                                                    <Select mode="multiple" style={{ width: '100%' }}
                                                        placeholder="Search and select yours interests"
                                                        onChange={tagsChange}>
                                                        {this.renderinterestList()}
                                                    </Select>)}
                                            </Form.Item>
                                        </div>
                                    </div>
                                </Card>

                                <Form.Item>
                                    <Button htmlType="submit"
                                        className="btn loginBtn color-1" loading={this.state.iconLoading}
                                        onClick={this.enterLoading} style={{ margin: '0' }}>
                                        Submit
                                    </Button>
                                    <p></p>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}


const WrappedRegistrationForm = Form.create({ name: "register" })(Register);
function mapStateToProps(state, ownProps) {
    return { user: state.user };
}

export default connect(
    mapStateToProps,
    {
        createAccount, genderList,
        countryList, cityList, interestList
    }
)(WrappedRegistrationForm);