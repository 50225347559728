import React, { Component } from "react";
import { Icon, Button } from "antd";
import Nowifi from '../assests/img/no-wifi.svg'


function hideLoadingDiv() {
    setTimeout(function () {
        document.getElementById('closeDiv').classList.add('onlineClose');
    }, 10000)
}

class OfflineContent extends Component {
    constructor() {
        super();
        this.state = {
            visible: true
        }
    }

    componentDidMount() {

    }

    onclose = () => {
        this.setState({
            visible: false
        })
    }

    refreshFeeds = () => {
        setTimeout(function () {
            window.location.reload(false);
        }, 500);
    };

    render() {
        const wifiimg = {
            height: '25px',
            width: '25px',
            marginRight: '10px'
        }
        return (
            <div>
                {this.state.visible === true ? (
                    <div className="offline-card">
                        <div className="offline-closable">
                            <div className="offline-content">
                                <div className="offline-message">
                                    <img src={Nowifi} alt="No-wifi" style={wifiimg} /> No internet connection
                                </div>
                                <div className="offline-description">
                                    You are currently offline <Button type="primary"
                                        onClick={() => this.refreshFeeds()}>Refresh</Button>

                                </div>
                                <a className="offline-close" onClick={() => this.onclose()}>
                                    <Icon type="close" />
                                </a>
                            </div>
                        </div>
                    </div>
                ) :
                    null
                }
            </div>
        )

    }
}

export default OfflineContent;
